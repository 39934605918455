<script>
import Equipment from "@/components/pages/journey_setup/Equipment.vue";
import api from "@/api";
import VueCookies from 'vue-cookies';

export default {
  name: "step-3",
  components: { Equipment },
  data() {
    return {
      step: 3,
      items: [],
      selected_equipments: [],
    };
  },
  async mounted() {
    let response = await api.getEquipments();
    this.items = response.data.results;

    let local_storage_data = VueCookies.get("journeySetupData");
    if (local_storage_data) {
      let obj = local_storage_data;
      this.selected_equipments =
        obj && obj.available_equipments && obj.available_equipments.length > 0
          ? obj.available_equipments
          : [];
    }
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    fitness_coach_text() {
      return `Meet your Fitness Coach (${this.step}/4)`;
    },
    equipments() {
      let data = [];
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        item.isSelected = this.selected_equipments.includes(item.id)
          ? true
          : false;
        let key = item.key.split("-");
        if (key.length == 1) {
          item.name = key[0];
        } else {
          item.name = key.join("");
        }
        data.push(item);
      }
      return data;
    },
  },
  methods: {
    handleEquipmentChanges() {
      // console.log("handleEquipmentChanges");
      setTimeout(() => {
        let data = [];
        var checkedBoxes = document.getElementsByName("equipments");
        checkedBoxes.forEach((item) => {
          if (item.value) {
            data.push(parseInt(item.value));
          }
        });
        // console.log("TODO: selected", data);
        this.$emit("setSelect", data);
      }, 500);
    },
  },
};
</script>

<template>
  <section>
    <div class="question-details mb-5">
      <div class="question-progress">
        {{ fitness_coach_text }}
      </div>
      <h1 class="question text-3xl font-black mb-5">
        What equipment do you usually have access to?
      </h1>
      <p>You can update your equipment list later in your profile.</p>
    </div>

    <div class="question-options mt-4" :class="iOS ? 'mb-4' : 'mb-28'">
      <div class="grid grid-cols-2 gap-4">
        <template v-for="(item, index) in equipments" :key="index">
          <equipment
            v-if="
              item.key == 'dumbbells' ||
              item.key == 'rollers-balls' ||
              item.key == 'sliders-bands' ||
              item.key == 'resistance-bands' ||
              item.key == 'Ropes' ||
              item.key == 'TRX' 
            "
            :item="item"
            @setSelect="handleEquipmentChanges"
          ></equipment>
        </template>
      </div>
    </div>
  </section>
</template>
