
<template>
   <ion-page class="max-w-md mx-auto">
       <ion-content :fullscreen="true">
           <div class="container p-6">
               <header class="mb-5">
                    <div class="text-xl font-medium text-center relative">
                        <span>Filters</span>
                        <div class="flex absolute -top-2.5 -right-1">
                            <router-link to="/home" class="relative">
                                <ion-icon :icon="closeCircle" class="block text-light text-5xl opacity-30"></ion-icon>
                                <ion-icon :icon="closeOutline" class="block text-black text-3xl absolute top-0 m-2 border border-transparent"></ion-icon>
                            </router-link>
                        </div>
                    </div>
               </header>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Types</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="primary" class="sm ml-6 mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Workout</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Warm Up</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Stretch flows</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Levels</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2 ml-6">
                            <div class="py-1 font-normal text-sm text-dark flex">1</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="primary" class="sm mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">2</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">3</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">4</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Aspirations</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="primary" class="sm ml-6 mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Aligment</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Health</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="primary" class="sm mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Aesthetic</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Fun</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Styles</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="primary" class="sm ml-6 mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Cardio</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Strength</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="primary" class="sm mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Power</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Core</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Mobility</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Flexibility</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Body targets</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2 ml-6">
                            <div class="py-1 font-normal text-sm text-dark flex">Arms</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="primary" class="sm mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Upper Body</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Abs</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Core</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Waist</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Ass</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Modes</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2 ml-6">
                            <div class="py-1 font-normal text-sm text-dark flex">Boxing</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="primary" class="sm mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Kickboxing</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Pilates</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Breath</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Bodyweight</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Load</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Intensity</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2 ml-6">
                            <div class="py-1 font-normal text-sm text-dark flex">HIIT</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="primary" class="sm mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">LIIT</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">LISS</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">SISS</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>


                <footer class="fixed w-full left-0 bottom-0 z-50">
                    <div class="filter-buttons max-w-md mx-auto bg-white px-6 py-2 flex">
                        <div class="my-auto ml-auto mr-5 text-dark font-medium">Clear filters</div>
                        <ion-button color="black" expand="block"><div class="px-2 py-3.5 text-white">Apply filter <span class="font-light">(34 results)</span></div></ion-button>
                    </div>
                </footer>
            </div>
            
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonContent, IonIcon, IonButton } from '@ionic/vue';
import { closeCircle, closeOutline, chevronUp, chevronDown } from 'ionicons/icons';

export default{
  components: { IonPage, IonContent, IonIcon, IonButton },
  data(){
      return { closeCircle, closeOutline, chevronUp, chevronDown }
  }
}
</script>

<style lang="scss" scoped>
.filter{
    .filter-options::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
ion-toggle {
    &.sm{
        width: 40px;
        height: 22.5px;
        --handle-width:19.5px;
        --handle-height:18px;
    }
    &.xs{
        width: 32px;
        height: 18px;
        --handle-width:15px;
    }
}
.filter-buttons{
    box-shadow: 0px -1px 10px #00000014;
}
</style>
