<script>
import _ from "lodash";
import bc from "@brightcove/player-loader";
import VueCookies from "vue-cookies";
import { alertController } from "@ionic/vue";
export default {
  name: "intro-video",
  data() {
    return {
      loading: false,
      //videoID: "6292489310001",
      videoID: "6306508354112",
      accountId: process.env.VUE_APP_BRIGHTCOVE_ID,
      videoUrl: "",
      playing: false,
      coverBg:
        "https://cf-images.ap-southeast-2.prod.boltdns.net/v1/static/6219829785001/99f14399-03da-44b7-9f77-04dc6eb76c96/c038e71f-1dbf-4d4a-9069-cfc6a22abe90/1280x720/match/image.jpg",
      player: null,
      isRecommended: false,
      isNotWorkout: false,
      isCompleted: false,
      playButton: false,
      offsetTop:"",
    };
  },
  mounted() {
    this.videoUrl = `https://players.brightcove.net/${this.accountId}/default_default/index.html?videoId=${this.videoID}`;
    this.loading = false;
    setTimeout(() => {
      this.offsetTop = document.getElementById("showIntroVideo").offsetTop;
      this.offsetTop = `${this.offsetTop - 150}px`;
      // let cover_image = document.getElementById("cover-image");
      // cover_image.style.height = `${offsetTop - 10}px`;
    }, 1000);

    this.videoInit(this.videoID);
  },
  methods: {
    async videoInit(vidoeoID, playafterInit = false) {
      if (!vidoeoID) console.log("brightcove video id not found", vidoeoID);
      if (vidoeoID) {
        const { ref } = await bc({
          refNode: this.$refs.video,
          videoId: vidoeoID,
          accountId: process.env.VUE_APP_BRIGHTCOVE_ID,
          playerId: "default",
          embedId: "default",
        });
        this.player = ref;
        this.player && this.eventAttach();
        this.playButton = true;
        if (playafterInit) {
          this.playVideoPause();
        }
      }
    },
    eventAttach() {
      if (this.player) {
        this.player.on("loadedmetadata", this.onLoadedmetadata);
        this.player.on("play", this.onPlay);
        this.player.on("timeupdate", this.onTimeupdate);
        this.player.on("pause", this.onPause);
        this.player.on("ended", this.onEnded);
      }
    },
    onLoadedmetadata() {
      var i,
        videoDuration,
        cuesAra = [],
        chapterStartTimesAra = [],
        chapterTitle = [],
        // +++  Use the array filter function to retrieve data structure that contains chapter cue points +++
        chapterTT = [].filter.call(this.player.textTracks(), function (tt) {
          return tt.kind === "chapters";
        });
      if (!_.isEmpty(chapterTT)) {
        //  Retrieve actual array of chapter cue points
        cuesAra = chapterTT[0].cues;
        // +++ Loop over chapter cue points and get start time of each  +++
        for (i = 0; i < cuesAra.length; i++) {
          chapterStartTimesAra[i] = cuesAra[i].startTime;
        }
        for (i = 0; i < cuesAra.length; i++) {
          chapterTitle[i] = cuesAra[i].text;
        }
        // +++ Call function to create marks in progress bar  +++
        // Get the video duration
        videoDuration = this.player.mediainfo.duration;
        this.videoDuration = videoDuration;
      }
    },
    onPlay(e) {
      this.$emit("onPlay", e.target);
      this.playing = true;
      // console.log("▶️ on play");

      let totalWatched = VueCookies.get(
        `totalWatched_Recom_${this.player.tagAttributes["data-video-id"]}`
      );
      this.counterWatch = setInterval(() => {
        totalWatched++;
        VueCookies.set(
          `totalWatched_Recom_${this.player.tagAttributes["data-video-id"]}`,
          totalWatched
        );
      }, 1000);
    },
    onTimeupdate(e) {
      this.$emit("onTimeupdate", e.target);

      let totalWatchedRecom = VueCookies.get(
        `totalWatched_Recom_${this.player.tagAttributes["data-video-id"]}`
      );
      // console.log("w--", this.isCompleted, totalWatchedRecom);
      if (!this.isCompleted) {
        let per50 = (100 * totalWatchedRecom) / this.player.duration();
        let per75 = (100 * this.player.currentTime()) / this.player.duration();
        if (per50 >= 50) {
          if (per75 >= 75) {
            if (!this.isCompleted) {
              // Update as completed
              // console.log("Update as compeleted");

              this.isCompleted = true;
            }
          }
        }
      }
      let totalWatched = VueCookies.get(
        `totalWatched_${this.player.tagAttributes["data-video-id"]}`
      );
      if (totalWatched != Math.floor(this.player.currentTime())) {
        VueCookies.set(
          `totalWatched_${this.player.tagAttributes["data-video-id"]}`,
          Math.floor(this.player.currentTime())
        );
      }
    },
    onPause(e) {
      this.$emit("onPause", e.target);
      // console.log("⏸ on pause");
      clearInterval(this.counterWatch);
    },
    onEnded(e) {
      this.$emit("onEnded", e.target);
      // console.log("⏹ on ended");
    },

    playNow: function (time = 0) {
      if (time) this.player.currentTime(time);
      this.player.play();
      // Disabled fullscreen
      // this.$el.getElementsByClassName("vjs-fullscreen-control")[0].click();
    },
    playVideoPause: async function (type = "", videoId = "") {
      if (type && videoId && type != this.videoType && !this.isRecommended) {
        this.videoType = type;
        this.player.dispose();
        if (this.player.isDisposed()) {
          this.videoInit(videoId, true);
        }
      } else {
        if (!this.videoID) {
          console.error("Activity video id not found.", this.activity);
          return;
        }
        // If player is already init
        if (this.player) {
          if (this.player.paused()) {
            let totalWatched = VueCookies.get(
              `totalWatched_${this.player.tagAttributes["data-video-id"]}`
            );
            if (totalWatched && totalWatched > 20) {
              const alert = await alertController.create({
                header: "Resume?",
                mode: "ios",
                message: "Would you like to resume where you left off?",
                buttons: [
                  {
                    text: "No",
                    handler: () => {
                      VueCookies.set(
                        `totalWatched_${this.player.tagAttributes["data-video-id"]}`,
                        0
                      );
                      this.playNow();
                    },
                  },
                  {
                    text: "Yes",
                    handler: () => {
                      this.playNow(totalWatched);
                    },
                  },
                ],
              });
              alert.present();
            } else {
              VueCookies.set(
                `totalWatched_${this.player.tagAttributes["data-video-id"]}`,
                0
              );
              this.playNow();
            }
          } else {
            this.player.pause();
          }
        }
      }
    },
  },
};
</script>



<template>
  <section>
    <div>
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <!-- <div
        v-if="!loading"
        id="player-container"
        style="
          position: relative;
          display: block;
          max-width: 100%;
          height: 100%;
        "
      >
        <div style="padding-top: 56.25%">
          <iframe
            :src="videoUrl"
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
            style="
              position: absolute;
              top: 0px;
              right: 0px;
              bottom: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
            "
          >
          </iframe>
          
        </div>
      </div> -->
      <div
        class="mobile-cover pt-2  relative block z-0"
        v-bind:class="[playing ? 'opacity-0' : '']"
        v-if="!playing"
      >
        <div class="absolute left-0 w-full h-full flex">
          <img
            class="m-auto w-24"
            src="/assets/images/play-button.svg"
            @click="playVideoPause"
          />
        </div>
        <img
          id="cover-image"
          class="cover-bg  top-0 left-0 w-full"
          :src="coverBg"
        />
      </div>

      <div
        class="cover md:relative rounded-l-5xl md:col-span-5 xl:col-span-6"
        v-bind:class="[playing ? '' : 'hidden']"
      >
        <img
          v-bind:class="[playing ? 'hidden' : '']"
          class="cover-bg  top-0 left-0 w-full h-full"
          :src="coverBg"
        />
        <div
          class="bg-black bg-opacity-80 z-99"
          v-bind:class="[playing ? 'playing' : '']"
        >
          <div id="player-container" class="video-container my-auto" style="!playing ? {{offsetTop}} : ''">
            <div ref="video" class="video"></div>
          </div>
        </div>
        <div
          v-bind:class="[playing ? 'hidden' : '']"
          class="absolute top-0 left-0 w-full h-full flex"
          v-if="isRecommended || (isNotWorkout && playButton)"
        >
          <img
            class="m-auto w-52"
            src="/assets/images/play-button.svg"
            @click="playVideoPause"
          />
        </div>
      </div>
    </div>
  </section>
</template>
