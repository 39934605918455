
<template>
  <ion-page class="max-w-md mx-auto">
    <ion-content :fullscreen="true">
      <div class="container p-6 setting-container">
        <header class="mb-5 relative">
          <div class="back flex -mt-1 -ml-4">
            <ion-back-button
              defaultHref="/home"
              :icon="chevronBack"
              class="text-xl"
            ></ion-back-button
            ><span class="my-auto -ml-2 pb-2px">Back</span>
          </div>
          <div class="flex absolute -top-0 -right-1">
            <router-link to="/home" class="relative">
              <ion-icon
                :icon="closeCircle"
                class="block text-light text-5xl opacity-30"
              ></ion-icon>
              <ion-icon
                :icon="closeOutline"
                class="
                  block
                  text-black text-3xl
                  absolute
                  top-0
                  m-2
                  border border-transparent
                "
              ></ion-icon>
            </router-link>
          </div>
          <div class="text-4.5xl font-bold leading-10">
            <div>Manage subscription</div>
          </div>
        </header>

        <div class="settings-items">
          <ion-list>
            <ion-item v-for="(item, i) in packages" :key="i++">
              <div class="rounded-md relative w-full" @click="changePlan(i)">
                <div class="flex items-center justify-between relative z-2">
                  <div class="px-2 leading-4">
                    <p class="m-0">{{ item.month }}</p>
                    <p class="text-xs w-32">{{ item.description }}</p>
                  </div>
                  <div class="flex items-center">
                    <h2 class="text-2xl tracking-tight">
                      {{ item.price }}
                    </h2>
                    <span class="px-0.5">/</span>
                    <span class="text-xs">month</span>
                  </div>
                  <ion-icon :icon="checkmarkOutline" size="meduim" :class="plan != i ? 'invisible' : 'visible'"> </ion-icon>
                </div>
              </div>
            </ion-item>
          </ion-list>
        </div>

        <div class="text-center mt-6">
          <a class="text-danger font-medium"> Cancel subscription</a>
        </div>
        
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonContent,
  IonBackButton,
  IonIcon,
} from "@ionic/vue";
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  logOutOutline,
  checkmarkOutline,
} from "ionicons/icons";

export default {
  components: { IonPage, IonContent, IonBackButton, IonIcon },
  data() {
    return {
      checkmarkOutline,
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      chevronBack,
      logOutOutline,
      plan: 1,
      packages: [
        {
          month: "1 month",
          price: "$75",
          description: "Billed Monthly",
        },
        {
          month: "3 months",
          price: "$50",
          description: "$150 billed quarterly",
        },
        {
          month: "12 months",
          price: "$35",
          description: "$420 billed anually",
        },
      ],
    };
  },
  methods: {
    changePlan(plan) {
      this.plan = plan;
    },
  },
};
</script>