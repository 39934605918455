<template>
  <container-cover>
    <div class="h-full bg-black bg-opacity-70 md:bg-white">
      <xali-header
        type="close-only"
        :settings="{ closeLink: `/home` }"
      ></xali-header>
      <div class="flex">
        <div
          class="
            max-w-md
            w-full
            mb-auto
            mx-auto
            px-6
            text-white
            md:text-secondary
          "
        >
          <div class="text-center">
            <ion-img
              class="mb-6 w-20 m-auto"
              :src="'/assets/images/icon.svg'"
            ></ion-img>
            <div class="inline-block relative">
              <!-- <ion-icon :icon="checkmarkCircle" class="block absolute top-0 -right-1.5 text-success z-10 text-2xl"></ion-icon> -->
              <ion-chip
                color="white"
                class="rounded-full px-6 bg-primary mb-10"
              >
                <ion-label color="white" class="font-hokyaa text-xl"
                  >Xali <span class="text-black">Journey</span>
                </ion-label>
              </ion-chip>
            </div>
            <h1 class="text-4xl font-bold mb-4">
              Well done {{ user.first_name }},
            </h1>
            <p class="text-md md:text-dark">
              You’re all set! You can access your personalised daily
              recommendations to keep you on track.
            </p>
          </div>
          <div class="max-w-lg mx-auto">
            <div class="recommendations text-lg leading-6 my-6 md:my-16">
              <div class="flex items-center justify-start mb-5">
                <div class="mr-2">
                  <ion-icon
                    class="my-auto stroke-current"
                    :src="iconMove"
                    size="large"
                  ></ion-icon>
                </div>
                <div>
                  <strong>Daily workouts</strong>

                  to keep you active
                </div>
              </div>
              <div class="flex items-center justify-start mb-5">
                <div class="mr-2">
                  <ion-icon
                    class="my-auto stroke-current"
                    :src="iconEat"
                    size="large"
                  ></ion-icon>
                </div>
                <div>
                  <strong>Daily recipes</strong>
                  to keep you nourished
                </div>
              </div>
              <div class="flex items-center justify-start mb-10">
                <div class="mr-2">
                  <ion-icon
                    class="my-auto stroke-current"
                    :src="iconGrow"
                    size="large"
                  ></ion-icon>
                </div>
                <div>
                  <strong>Daily articles</strong>
                  to keep you connected
                </div>
              </div>

              <ion-button
                color="white"
                expand="block"
                @click="setRecommendations()"
                :disabled="loading"
                :class="iOS ? 'ios-spacing' : ''"
              >
                <div
                  class="
                    px-5
                    py-3.5
                    text-black
                    md:bg-black md:text-white
                    w-full
                    max-w-md
                    rounded-lg
                  "
                >
                  {{ btnText }}
                </div>
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </container-cover>
</template>

<script>
import { IonIcon, IonImg, IonLabel, IonChip, IonButton } from "@ionic/vue";
import { checkmarkCircle } from "ionicons/icons";
import { defineComponent } from "vue";
import { iconMove, iconEat, iconGrow } from "@/_helpers/icons";

export default defineComponent({
  components: { IonIcon, IonImg, IonLabel, IonChip, IonButton },
  setup() {
    return {
      checkmarkCircle,
      iconMove,
      iconEat,
      iconGrow,
    };
  },
  data() {
    return {
      loading: false,
      btnText: "See my recommendations",
    };
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    user() {
      return this.$store.getters["accounts/getUser"];
    },
  },
  methods: {
    setRecommendations() {
      // this.$store.dispatch(`pages/getHomeData`, [380, 388, 386, 320, 354, 384]);
      this.loading = true;
      this.btnText = "Loading ...";
      window.location.href = "/home";
      // this.$store
      //   .dispatch(`pages/getHomeData`, [380, 388, 386, 320, 354, 384])
      //   .then(() => {
      //     setTimeout(() => {
      //       this.loading = false;
      //       this.$router.push(`/`);
      //     }, 2000);
      //   });
    },
  },
});
</script>

<style lang="scss" scoped></style>