<template>
  <!-- Loading -->
  <div
    class="
      carousel-card
      w-full
      rounded-2lg
      bg-gray-100
      p-5
      flex
      items-center
      justify-center
      overflow-hidden
      text-white
      skeleton
    "
    v-if="loading"
  >
    <div class="content w-full h-full flex flex-col">
      <div class="card-heading flex">
        <ion-skeleton-text
          animated
          class="py-2 w-2/5 mr-auto"
        ></ion-skeleton-text>
      </div>
      <div class="my-5">
        <img class="mx-auto hidden" src="/assets/images/play-button.svg" />
      </div>
      <div class="details text-left mt-auto">
        <ion-skeleton-text
          animated
          class="text-2xl font-semibold mb-3 w-3/4"
        ></ion-skeleton-text>
        <div class="chips text-xs">
          <ion-skeleton-text animated class="py-1 w-3/5"></ion-skeleton-text>
        </div>
      </div>
    </div>
  </div>

  <div
    class="
      carousel-card
      w-full
      rounded-2lg
      bg-gray-300
      p-5
      flex
      items-center
      justify-center
      overflow-hidden
      text-white
    "
    v-if="!loading"
  >
    <div
      class="cover-image w-full h-full rounded-2lg"
      v-if="data.cover"
      ref="cover"
      v-bind:class="[data.preview ? 'has-preview' : '']"
    >
      <ion-img :src="data.cover" class="h-full w-full"></ion-img>

      <div
        class="video-preview"
        v-if="validPreview(data.preview)"
        ref="preview"
      >
        <video
          autoplay
          muted
          loop
          playsinline
          preload="metadata"
          @error="previewLoadError"
        >
          <source
            :src="data.preview"
            type="video/mp4"
            @error="previewLoadError"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <div class="cover-image bg-primary w-full h-full" v-if="!data.cover"></div>

    <div class="content w-full h-full flex flex-col">
      <div class="card-heading flex">
        <div
          v-if="data.level"
          class="
            level
            my-auto
            mr-auto
            rounded-2xl
            bg-black bg-opacity-10
            text-white text-sm
            py-1
            px-4
          "
        >
          Level
          <span
            v-for="(lvl, i) in level"
            :key="i"
            v-bind:class="[lvl.check ? 'checked' : '']"
            class="status"
          ></span>
        </div>
        <div class="fav ml-auto" v-if="data.hasOwnProperty('favourite')">
          <ion-icon
            :icon="favouriteState ? heart : heartOutline"
            class="block text-3xl"
            @click.prevent="setupFavourite"
          ></ion-icon>
        </div>
      </div>
      <div class="play-button" v-if="data.play">
        <ion-img src="/assets/images/play-button.svg"></ion-img>
      </div>

      <div
        class="details mt-auto text-left"
        v-bind:class="[data.browse ? 'text-center' : 'text-left']"
      >
        <h3 v-if="series" class="text-base leading-6">
          {{ series.label }}
        </h3>
        <h2
          class="title text-2xl leading-6 font-semibold mb-2"
          v-bind:class="[data.browse ? 'flex justify-center' : '']"
          v-if="data.name"
        >
          <span v-html="data.name"></span>
          <span class="mx-2 inline-block flex" v-if="data.browse">
            <ion-icon :icon="chevronForward" class="my-auto"></ion-icon>
          </span>
        </h2>
        <div
          class="
            chips
            text-xs
            overflow-ellipsis overflow-hidden
            rounded-2lg
            whitespace-nowrap
          "
          v-if="data.tags"
        >
          <span
            v-for="(chip, i) in data.tags"
            :key="i"
            class="
              inline-block
              bg-black bg-opacity-10
              rounded-2lg
              py-0.5
              px-2.5
              mr-1
              cursor-auto
            "
          >
            <!-- <a v-bind:href="chip.url">{{ chip.text }}</a> -->
            <span v-html="chip.text"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Sample Item
// {
//   level: '2/4',
//   name:'Aerobic Movement 1',
//   tags:[
//     { text: 'Cardio', url: '/Cardio' },
//     { text: 'Sculpt & Tone', url: '/Sculpt&Tone' },
//     { text: '31 min', url: '/31min' },
//   ],
//   cover:'/assets/images/workout.png',
//   favourite: true
// },
import { IonSkeletonText, IonImg, IonIcon } from "@ionic/vue";
import { heartOutline, heart, chevronForward } from "ionicons/icons";
import { defineComponent } from "vue";
import VueCookies from "vue-cookies";
import _ from "lodash";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          level: false,
          name: "",
          tags: [],
          cover: "",
          preview: "/assets/images/fitness/style/core.mp4",
          favourite: true,
          browse: false,
        };
      },
    },
  },
  emits: ["setSelect"],
  components: { IonSkeletonText, IonImg, IonIcon },
  setup() {
    return { heartOutline, heart, chevronForward };
  },
  data() {
    let level = [];
    if (this.data.level) {
      var lvl = this.data.level.split("/");
      for (let i = 0; i < lvl[1]; i++) {
        level.push({ check: lvl[0] > i ? true : false, level: i });
      }
    }

    var invalidPreview = VueCookies.get("invalidPreview");
    invalidPreview = invalidPreview ? invalidPreview.split(",") : [];

    return {
      link: _.has(this.data, "link") ? this.data.link : "/",
      level: level,
      loading: true,
      favouriteState: this.data.favourite,
      invalidPreview,
      series: null,
    };
  },
  async mounted() {
    this.loading = false;
    // console.log("this.data", this.data.data);
    if (this.data.data && this.data.data.series) {
      // console.log("this.data", this.data.data.series);
      this.series = this.data.data.series;
    }
  },
  methods: {
    isExternal: function (link = "") {
      if (link.includes("http")) return true;
    },
    linkTo: function (link = "") {
      console.log("w--", link);
      let isIt = false;
      //if(link.includes("http")) isIt = true;
      //if(link.includes("xali.com.au/learn")) isIt = false;
      if (link.includes("xali.com.au/learn")) {
        window.location.href = link;
      }
      if (isIt) {
        //window.open(link);
      } else {
        //window.location.href = link;
      }
    },
    async setupFavourite() {
      let data = {
        type: this.data.parentType,
        action: this.favouriteState ? "remove" : "add",
        ids: this.data.id,
      };
      // console.log("data", data);
      // if (this.favouriteState) {
      //   this.$store.dispatch("accounts/favourite", {
      //     type: this.data.parentType,
      //     action: "remove",
      //     ids: this.data.id,
      //   });
      // } else {
      //   this.$store.dispatch("accounts/favourite", {
      //     type: this.data.parentType,
      //     action: "add",
      //     ids: this.data.id,
      //   });
      // }
      await this.$store.dispatch("accounts/setupFavourite", data);
      this.favouriteState = this.favouriteState != true;
      this.$emit("setSelect");
      return this.favouriteState;
    },
    validPreview: function (src) {
      if (!src) return false;
      if (this.invalidPreview.includes(src)) return false;
      return true;
    },
    previewLoadError: function () {
      // Init
      var invalidPreview = VueCookies.get("invalidPreview");
      invalidPreview = invalidPreview ? invalidPreview.split(",") : [];

      let cover =
        this.$refs["cover"].getElementsByClassName("video-preview")[0];
      cover.classList.add("hidden");
      this.$refs["cover"].classList.remove("has-preview");

      if (
        _.isArray(invalidPreview) &&
        !invalidPreview.includes(this.data.preview)
      ) {
        invalidPreview.push(this.data.preview);
        VueCookies.set("invalidPreview", _.sortedUniq(invalidPreview), 1800);
      }
    },
  },

  watch: {
    data(newData) {
      let level = [];
      if (newData.level) {
        var lvl = newData.level.split("/");
        for (let i = 0; i < lvl[1]; i++) {
          level.push({ check: lvl[0] > i ? true : false, level: i });
        }
      }
      this.level = level;
    },
  },
});
</script>

<style lang="scss" scoped>
.carousel-card {
  position: relative;
  height: 200px;
  &:hover {
    .cover-image {
      .video-preview {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .cover-image {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-size: cover;
    ion-img {
      min-height: 100%;
      min-width: 104%;
      object-fit: cover;
      z-index: 1;
      position: absolute;
      top: 0px;
      left: -2%;
      transition: visibility 0.5s 0.5s, opacity 0.5s linear;
    }
    &::after {
      background: transparent
        linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
        padding-box;
      content: "";
      height: 100px;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      opacity: 0.45;
      z-index: 10;
      border-radius: 0.625rem;
    }
  }
  .content {
    z-index: 3;
    .title {
      text-shadow: 0px 3px 6px #00000029;
    }
  }
  .level {
    .status {
      display: inline-block;
      width: 8px;
      height: 8px;
      border: solid 1px var(--ion-color-white);
      border-radius: 100%;
      margin-left: 3px;
      &.checked {
        background: var(--ion-color-white);
      }
    }
  }
  .play-button {
    position: absolute;
    top: 26%;
    left: calc(50% - 29px);
  }
  @screen md {
    height: 240px;
  }
  @screen 2xl {
    height: 300px;
    .play-button {
      top: calc(45% - 35px);
    }
  }
}

// In focus
ion-slides.focus {
  .swiper-slide-active {
    .cover-image.has-preview {
      ion-img {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.5s 0.5s, opacity 0.5s linear;
      }
      .video-preview {
        opacity: 1 !important;
        visibility: inherit !important;
      }
    }
  }
}
.video-preview {
  z-index: 5;
  background-color: #727272;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0.625rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  video {
    // left: -10px;
    // position: absolute;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // width: 100%;
    //
    position: absolute;
    object-fit: cover;
    height: 100%;
    left: 50%;

    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    border-radius: 0.625rem;
  }
}
</style>

