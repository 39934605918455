import workout from './modules/workout';
import accounts from "./accounts";
import pages from "./pages";
import {createStore} from 'vuex';
 
export default createStore({
    modules:{
      workout: workout,
      accounts: accounts,
      pages: pages
    }
});
 


