<template>
   <ion-page>
        <ion-content :fullscreen="true">
          <div class="container max-w-full min-h-screen px-0 cover-container bg-white">
            <div class="grid grid-cols-12 gap-0 min-h-screen">
              <div class="cover rounded-r-5xl hidden md:block md:col-span-5 lg:col-span-6">
                <img src="/assets/images/signupbg.jpg">
              </div>
              <div class="content flex flex-col col-span-12 md:col-span-7 lg:col-span-6 " v-bind:class="[contentcover ? '':'remove-cover']">
                <slot></slot>
              </div>
            </div>
          </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonContent, IonPage } from '@ionic/vue';
export default{
    props: {
        contentcover: {
            type: Boolean,
            default: true
        }
    },
    components: { IonContent, IonPage }
}
</script>


<style lang="scss" scoped>
  .cover{
    overflow: hidden;
    img{
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
  }
  .content{
    background-image: url('/assets/images/signupbg.jpg');
    background-size: cover;
    background-position: center;
  }
  .remove-cover{
    background-image: none;
  }
  @screen md {
    .content{
      background-image: none;
    }
  }
</style>
