import api from '../../api/index'

export default{
    namespaced: true,
    state: () => ({ //$store.state.workout.workoutQuestions
        workoutQuestions:[
            {
              'message':'Hey Emily, what are we gonna do with you today?',
              'avatar':'/assets/images/journey-1.jpg',
            },
            {
              'message':'Work it, work it hard!',
              'from':'right',
            },
            {
              'message':'I’m here, and I’m ready',
              'from':'right',
            },
            {
              'message':'Be gentle with me, please',
              'from':'right',
            },
            {
              'message':'Today I need grounding',
              'from':'right',
            }
        ]
    }),
    mutations: { //$store.commit('workout/sample');

    },
    actions: { //$store.dispatch('workout/sample');
      async getWorkoutQuestions({ commit }) {
        const response = await api.getWorkoutQuestions()
        commit('setFieldOptions', response.data)
      },
    },
    getters: {}
}