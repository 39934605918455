<template>
  <ion-page>
    <ion-content :fullscreen="false">
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <template v-for="(recipe, i) in recipes" :key="i++">
          <recipe-view
            v-if="active == recipe"
            :recipeid="recipe"
            :slideItem="false"
            :prevRecipe="getPrev()"
            :nextRecipe="getNext()"
            @move="move"
          ></recipe-view>
        </template>
        <EmptyRecommended v-if="recipes.length === 0" />
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import { defineComponent } from "vue";
import _ from "lodash";
import EmptyRecommended from "@/pages/eat/EmptyRecommended.vue";
export default defineComponent({
  components: { IonPage, IonContent, EmptyRecommended },
  data() {
    return {
      loading: true,
      active: null,
      recipes: [],
    };
  },
  mounted() {
    // console.log("HERE");
    const recommend = this.$store.getters["accounts/getRecommended"];
    console.log("recommend", recommend);
    if (!_.isEmpty(recommend.recipe)) {
      this.recipes = _.map(recommend.recipe.ids, (n) => {
        return n.toString();
      });
      if (this.$route.params.id) {
        this.active = this.$route.params.id;
      } else {
        this.active = this.recipes[0];
      }
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }

    if (_.isEmpty(recommend.recipe)) {
      console.log("Empty response recipe: ", recommend.recipe);
      console.log("this.recipes.length", this.recipes.length);
      this.loading = false;
    }
  },
  setup() {
    // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
    const slideOpts = {
      slidesPerView: 1,
      setWrapperSize: true,
      //allowTouchMove: true,
      shortSwipes: true,
      preventClicksPropagation: true,
      spaceBetween: -30,
      preventClicks: true,
      speed: 400,
    };
    return { slideOpts };
  },
  methods: {
    ionSlidesDidLoad: function (e) {
      var wrap = e.target.getElementsByClassName("swiper-wrapper")[0];
      wrap.classList.add("left-5");
    },
    getPrev: function () {
      let activeIndex = _.findIndex(this.recipes, (ac) => {
        return ac == this.active;
      });
      // console.log("activeIndex", activeIndex);
      if (activeIndex) {
        return this.recipes[--activeIndex];
      }
      return "";
    },
    getNext: function () {
      let activeIndex = _.findIndex(this.recipes, (ac) => {
        return ac == this.active;
      });
      if (activeIndex != this.recipes.length) {
        return this.recipes[++activeIndex];
      }
      return "";
    },
    move(variable) {
      if (variable == "prev") {
        this.active = this.getPrev();
        this.pushState(this.active);
      }
      if (variable == "next") {
        this.active = this.getNext();
        this.pushState(this.active);
      }
      //this.loading = true;
      //location.replace('/eat/recommended/'+this.active);
      //this.$router.replace('./'+this.active);
    },
    pushState(id) {
      const nextURL = `/eat/recommended/`;
      // const nextTitle = "Xali";
      // const nextState = "";
      // window.history.pushState(nextState, nextTitle, `${nextURL}${id}`);
      window.location.href = `${nextURL}${id}`;
    },
  },
});
</script>

<style lang="scss" scoped>
.swiper-wrapper,
.swiper-slide {
  height: 100vh !important;
  text-align: left;
  .ion-page {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>
