<template>
  <div class="recommended-carousel-container">
    <ion-slides ref="mySwiper" class="px-12" :options="slideOpts">
      <ion-slide class="flex-col pr-4 lg:pr-0">
        <div class="w-full flex px-3 z-30">
          <ion-chip color="white" class="rounded-tl-none rounded-br-none px-6 bg-primary m-0 z-10">
            <ion-label color="white" class="font-founders-condensed text-lg mt-2">RECOMMENDED WORKOUT</ion-label>
          </ion-chip>
        </div>
        <a v-if="hasRecommend" :href="hasRecommend" class="w-full h-full has-recommend">
           <WorkoutCard></WorkoutCard>
        </a>
        <a v-if="!hasRecommend" class="w-full h-full not-recommend" @click.prevent="toggleWorkoutSetup()">
           <WorkoutCard></WorkoutCard>
        </a>
      </ion-slide>
      <ion-slide class="flex-col pr-4 lg:pr-0">
        <div class="w-full flex px-3">
          <ion-chip color="white" class="rounded-tl-none rounded-br-none px-6 bg-primary m-0 z-10">
            <ion-label color="white" class="font-founders-condensed text-lg mt-2">RECOMMENDED RECIPE</ion-label>
          </ion-chip>
        </div>
        <a class="w-full h-full" @click.prevent="toggleRecipeSetup()">
        <RecipeCard></RecipeCard>
        </a>
      </ion-slide>
      <ion-slide class="flex-col pr-4 lg:pr-0">
        <div class="w-full flex px-3">
          <ion-chip color="white" class="rounded-tl-none rounded-br-none px-6 bg-primary m-0 z-10">
            <ion-label color="white" class="font-founders-condensed text-lg mt-2">RECOMMENDED ARTICLE</ion-label>
          </ion-chip>
        </div>
        <LearnCard></LearnCard>
      </ion-slide>
    </ion-slides>
  </div>
    <Modal :close_float="true" ref="modal">
      <template v-slot:header>
        <div class="w-full -mt-9 hidden sm:flex">
          <ion-chip color="white" class="m-0 rounded-full p-5 bg-primary mx-auto">
            <ion-label color="white" class="font-hokyaa text-xl">Xali <span class="text-black">Journey</span>
            </ion-label>
          </ion-chip>
        </div>
      </template>
      <!-- Workout Setup -->
      <WorkoutSetup v-if="isWorkoutSetup" ref="chatBox" :user="user" @close="handleModalClose"></WorkoutSetup>
      <!-- Recipe Setup -->
      <RecipeSetupChat v-if="isRecipeSetup" ref="chatBox" :user="user" @close="handleModalClose"></RecipeSetupChat>
    </Modal>
</template>

<script>
import WorkoutCard from './RecommendWorkoutCard.vue'
import RecipeCard from './RecommendRecipeCard.vue'
import LearnCard from './RecommendLearnCard.vue'

import Modal from "@/components/commons/Modal.vue";
import WorkoutSetup from "@/components/pages/WorkoutSetup-Chat.vue";
import RecipeSetupChat from "@/components/pages/RecipeSetup-Chat.vue";

import {
  IonSlides,
  IonSlide,
  IonLabel,
  IonChip,
} from "@ionic/vue";
import { chevronForward, checkmarkCircleOutline, heartOutline, heart } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonSlides,
    IonSlide,
    IonLabel,
    IonChip,
    RecipeCard,
    WorkoutCard,
    LearnCard,
    Modal,
    WorkoutSetup,
    RecipeSetupChat
  },
  setup() {
    return { chevronForward, checkmarkCircleOutline, heartOutline, heart }
  },
  data() {
    const slideOpts = {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesOffsetAfter: 0,
      allowTouchMove: true,
      shortSwipes: true,
      preventClicksPropagation: true,
      preventClicks: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        }
      }
    };
    return {
      hasRecommend: false,
      isWorkoutSetup: false,
      isRecipeSetup: false,
      recipe_message: "What’s Cooking?",
      slideOpts
    };
  },
  mounted() {
    const recommended = this.$store.getters["accounts/getRecommended"];
    if(recommended){
      if(recommended.activity.activity) this.hasRecommend = `/move/workout/view/${recommended.activity.activity}`;
    }
  },
  computed: {
    user() {
      return this.$store.getters["accounts/getUser"];
    }
  },
  methods: {
    handleModalClose() {
      this.$refs.modal.toggleModal();
    },
    toggleWorkoutSetup: function() {
      this.isWorkoutSetup = true;
      this.isRecipeSetup = false;
      this.$refs.modal.toggleModal();
    },
    toggleRecipeSetup: function() {
      this.isWorkoutSetup = false;
      this.isRecipeSetup = true;
      this.$refs.modal.toggleModal();
    },
  },
});
</script>

<style lang="scss" scoped>

.journey-card {
  position: relative;
  height: 400px;
  .cover-image {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-size: cover;
    border-radius: 0.625rem;
    ion-img {
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      z-index: 1;
      position: absolute;
      top: 0px;
      left: 0px;
    }
    &::after {
      background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
      content: "";
      height: 160px;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      opacity: 0.45;
      z-index: 10;
      border-radius: 0.625rem;
    }
  }
  .content {
    z-index: 3;
    .title {
      text-shadow: 0px 3px 6px #00000029;
    }
  }
  .play-button {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  @screen md {
    height: 580px;
  }
}

.journey-card{
  &.activity{
    &:hover{
      .cover-image{
        // ion-img {
        // }
        .video-preview{
          visibility: visible;
          opacity: 1;
        }
      }
      
    }
  }
}

</style>
