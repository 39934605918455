
<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <container-cover>
        <div class="bg-white flex h-full">
          <div class="subscription-container relative w-full">
            <div class="md:px-16 lg:px-22 xl:px-44">
              <header class="pt-8 px-6">
                <a
                  v-bind:href="`/subscription/choose-plan/`"
                  class="my-auto ml-auto relative"
                >
                  <ion-icon :icon="chevronBack" size="large"></ion-icon>
                </a>
              </header>

              <div class="body-content mt-8 px-6">
                <h2 class="text-4xl font-bold tracking-tight leading-8 mb-6">
                  Payment
                </h2>
                <div class="border-2 rounded-md border-primary p-5 relative">
                  <div
                    class="
                      bg-primary
                      absolute
                      w-full
                      h-full
                      left-0
                      top-0
                      opacity-50
                      z-1
                    "
                  ></div>
                  <div class="relative z-2">
                    <h2 class="text-2xl font-bold tracking-tight">
                      {{ packages[`package_${plan}`].month }}
                    </h2>
                    <p class="text-xs w-32">
                      {{ packages[`package_${plan}`].description }}
                    </p>
                    <ul class="leading-7 pl-3 mt-3 text-sm">
                      <li class="flex items-center">
                        <ion-icon
                          :icon="checkmarkCircleOutline"
                          size="small"
                          :color="'white'"
                          class="mr-1"
                        ></ion-icon>
                        <span><b>Move</b> unlimited</span>
                      </li>
                      <li class="flex items-center">
                        <ion-icon
                          :icon="checkmarkCircleOutline"
                          size="small"
                          :color="'white'"
                          class="mr-1"
                        ></ion-icon>
                        <span><b>Eat</b> unlimited</span>
                      </li>
                      <li class="flex items-center">
                        <ion-icon
                          :icon="checkmarkCircleOutline"
                          size="small"
                          :color="'white'"
                          class="mr-1"
                        ></ion-icon>
                        <span><b>Learn</b> unlimited</span>
                      </li>
                      <li class="flex items-center">
                        <ion-icon
                          :icon="checkmarkCircleOutline"
                          size="small"
                          :color="'white'"
                          class="mr-1"
                        ></ion-icon>
                        <span><b>Connect</b> unlimited</span>
                      </li>
                      <li class="flex items-center">
                        <ion-icon
                          :icon="checkmarkCircleOutline"
                          size="small"
                          :color="'white'"
                          class="mr-1"
                        ></ion-icon>
                        <span><b>Individualised journey</b> unlimited</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <h3 class="text-center mt-6 mb-2">
                  You’ll be charged ${{ packages[`package_${plan}`]._price * parseInt(packages[`package_${plan}`].recurringIntervalCount)}} once your free trial period is complete.
                </h3>

                <!-- <div v-if="app_payment == 'OFF'"> Payment is Disabled </div> -->
                <div class="my-5 hidden" id="payment-request-button-wrapper">
                  <div id="payment-request-button" style="width:100%">
                    <!-- A Stripe Element will be inserted here. -->
                  </div>
                  <h3 class="text-center my-2"> - OR - </h3>
                  <ion-button expand="block" color="white" class="text-xl mb-4 tracking-tight" @click="isCreditCardActive = !isCreditCardActive">
                
                    <span class="p-4 flex items-center text-black">    
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="
                        text-black
                        peer-placeholder-shown:text-gray-300
                        h-6
                        w-6
                        mr-2
                      "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                      />
                    </svg> Credit Card</span> 
                  </ion-button>

                </div>


                <div class="mt-5 hidden" id="payment-form-wrapper">
                  <div class="form" v-bind:class="[ isCreditCardActive ? 'opened' : '']">
                      <form class="flex flex-wrap gap-3 w-full payment-form">
                        <label class="relative w-full flex flex-col">
                          <span class="font-bold mb-3 text-lg">Card Details</span>
                          <input
                            v-model="name"
                            id="card-holder-name"
                            class="bg-white rounded-md peer pl-12 p-3 placeholder-black text-black"
                            type="text"
                            placeholder="Name"
                          />
                          <svg xmlns="http://www.w3.org/2000/svg"
                            class="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
                          </svg>
                        </label>

                        <div id="card-element" class="w-full bg-white p-3.5 rounded-md text-small"> </div>
                        
                      </form>
                    
                    <div class="py-2">
                      
                      <ion-button expand="block" color="white" class="text-xl mb-4 tracking-tight mx-0" type="submit" 
                        v-on:click="submitPaymentMethod()"
                        :disabled="loading">
                        <span class="p-4 flex text-black" v-if="!loading"> Subscribe </span>
                        <span class="p-4 flex text-black" v-if="loading"> <ion-spinner class="text-black"> </ion-spinner> </span>
                      </ion-button>
                    </div>
                  </div>
                </div>

                <div class="coupon">
                  <span class="cursor-pointer underline text-primary" @click="isShowCoupon = !isShowCoupon">Have a coupon code?</span>
                  <div class="flex" v-if="isShowCoupon">
                    <input v-model="coupon"
                      class="bg-white rounded-l-lg p-3 px-5 w-full placeholder-black text-black mt-3"
                      :class="isApplied ? 'rounded-md' : ''"
                      :readonly="isApplied"
                      type="text"
                      placeholder="Coupon code"/>
                    <button v-if="!isApplied" class="rounded-r-lg p-3 px-5 w-40 mt-3 d-block text-center" :class="coupon == '' ? 'bg-light cursor-not-allowed' : 'bg-primary cursor-pointer' " :disabled="!coupon" @click="applyCoupon()">
                      <span class="text-white" > Apply </span>
                    </button>
                  </div>

                  <div class="text-center my-2" v-if="isApplied">
                    <span class="">Coupon applied succesfully</span>
                  </div>

                  <div class="text-center my-2">
                    <span class="text-danger ">{{couponMessage}}</span>
                  </div>
                  
                </div>
              
              </div>

              <footer class="w-full bottom-0">
                <div class="w-full bottom-0 px-6 py-3 add-bg">

                  <p class="text-xs" :class="iOS ? 'ios-spacing' : ''">
                    Subscriptions automatically renew unless you turn off
                    auto-renew at least 24 hours before the end of the billing
                    period. <br />
                    You have read, understand and agree to our
                    <a
                      href="https://xali.com.au/terms-conditions/"
                      class="text-primary underline"
                      target="_blank"
                      >Terms & Conditions</a
                    >
                    &
                    <a
                      href="https://xali.com.au/privacy-policy/"
                      class="text-primary underline"
                      target="_blank"
                      >Privacy Policy</a
                    >.
                  </p>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </container-cover>
    </ion-content>
  </ion-page>
</template>
<script>
import { IonSpinner } from "@ionic/vue";
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  checkmarkCircleOutline,
  checkmarkOutline,
} from "ionicons/icons";
import { iconLogo } from "@/_helpers/icons";
import { trackingTrigger, setHtmlPageTitle } from "@/_helpers";
import store from "@/store";
import _ from "lodash";
import { toastController } from "@ionic/vue";
import moment from "moment";

export default {
  components: { IonSpinner },
  data() {
    return {
      //app_payment: process.env.VUE_APP_PAYMENT,
      isCreditCardActive:false,
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      iconLogo,
      chevronBack,
      checkmarkCircleOutline,
      checkmarkOutline,
      plan: this.$route.params.package ? this.$route.params.package : 1,
      loading: false,
      packages: {
        package_1: {
          month: "1 month",
          price: "$20",
          _price: 20,
          description: "$20 billed monthly",
          recurringIntervalCount: 1,
        },
        package_2: {
          month: "3 months",
          price: "$15",
          _price: 15,
          description: "$45 billed quarterly",
          recurringIntervalCount: 3,
        },
        package_3: {
          month: "12 months",
          price: "$9",
          _price: 9,
          description: "$108 billed anually",
          recurringIntervalCount: 12,
        },
      },
      stripeAPIToken: process.env.VUE_APP_STRIPE,
      stripe: "",
      elements: "",
      card: "",
      name: "",
      selectedPrice: {},
      coupon: "",
      isApplied: false,
      isShowCoupon: false,
      couponMessage: "",
    };
  },
  mounted() {
    
    setHtmlPageTitle("Payment");
    this.includeStripe(
      "js.stripe.com/v3/",
      function () {
        this.configureStripe();
      }.bind(this)
    );

    this.$store.dispatch(`accounts/getProduct`).then((res) => {
      if (!_.isEmpty(res)) {
        const prices = res[0].prices;
        if (prices) {
          const selectedP = this.packages[`package_${this.plan}`];
          const findPrice = _.find(prices, {
            recurringIntervalCount: selectedP["recurringIntervalCount"],
          });
          this.selectedPrice = findPrice;
        }
      } else {
        console.error("No product plan found");
      }
    });
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    getPlanDuration() {
      var duration = 0;
      var months = 1;

      if (this.plan == 1) {
        months = 1;
      }

      if (this.plan == 2) {
        months = 3;
      }

      if (this.plan == 3) {
        months = 12;
      }

      for (let i = 0; i < months; i++) {
        var now = new Date();
        var current;
        if (now.getMonth() == 11) {
          current = new Date(now.getFullYear() + i, 0, 1);
        } else {
          current = new Date(now.getFullYear(), now.getMonth() + i, 0, 1);
        }
        duration = duration + current.getDate();
      }

      return duration;
    },
    userData() {
      return this.$store.getters["accounts/getUser"];
    },
    nextRenewal() {
      var new_plan_duration = 0
      for (let i = 0; i < parseInt(this.selectedPrice.recurringIntervalCount); i++) {
        var now = new Date();
        var current;
        if (now.getMonth() == 11) {
          current = new Date(now.getFullYear() + i, 0, 1);
        } else {
          current = new Date(now.getFullYear(), now.getMonth() + i, 0, 1);
        }
        new_plan_duration = new_plan_duration + current.getDate();
      }
      
      var newDate = new Date();
      var currentDate = moment(newDate).add(new_plan_duration,'d').format("DD/MM/YYYY");
      return currentDate;
    },
    currentCost() {
      const userData = store.getters["accounts/getUser"];
      let currentCost = 0;
      for (let i = 1; i <= 3; i++) {
        let packageData = this.packages[`package_${i}`];
        if (
          userData.detail.subscription.plan_name == packageData.month ||
          packageData.month.includes(userData.detail.subscription.plan_name)
        ) {
          switch (i) {
            case 1:
              currentCost = 1 * packageData._price;
              break;
            case 2:
              currentCost = 3 * packageData._price;
              break;
            case 3:
              currentCost = 12 * packageData._price;
              break;
            default:
              currentCost = 0;
          }
        }
      }
      return currentCost;
    },
  },
  methods: {
    async openToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 5000,
        color: color,
      });
      return toast.present();
    },
    changePlan(plan) {
      this.plan = plan;
    },
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function (e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    async configureStripe() {
      
      this.stripe = await window.Stripe(process.env.VUE_APP_STRIPE);
      this.elements = this.stripe.elements();
      this.card = this.elements.create("card", {
        hidePostalCode: true,
        style: {
          base: {
            iconColor: "#000",
            color: "#000",
            fontSize: "14px",
            "::placeholder": {
              color: "#000",
            },
          },
        },
        wallets:{
          applePay:'auto',
          googlePay:'auto'
        },
        allow_promotion_codes: true
      });

      this.card.mount("#card-element");

      // For Googla and Apply pay
      console.log('Apple and Google pay - ',process.env.VUE_APP_AGPay);
      if(process.env.VUE_APP_AGPay == 'ON'){
      
        const selectedP = this.packages[`package_${this.plan}`];
        let price = '000';

        if(this.userData.detail.is_subscribed){
          price = (selectedP._price * selectedP.recurringIntervalCount)+"00";
        }
        var paymentRequest = this.stripe.paymentRequest({
          country: 'AU',
          currency: 'aud',
          total: {
            label: selectedP.month,
            amount: parseInt(price)
          },
        });
        var prButton = this.elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'default',
                theme: 'light',
                width: '100%',
                height: '52px'
              },
            },
          }
        );
        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then(function(result) {
          console.log(result,'checking')
          if (result) {
            prButton.mount('#payment-request-button');
            document.getElementById('payment-request-button-wrapper').classList.remove('hidden');
            document.getElementById('payment-form-wrapper').classList.remove('hidden');
            document.getElementById('payment-form-wrapper').classList.add('accordion');
          } else {
            document.getElementById('payment-request-button').style.display = 'none';
            document.getElementById('payment-request-button-wrapper').style.display = 'none';
            document.getElementById('payment-form-wrapper').classList.remove('hidden');
          }
        });

        paymentRequest.on('token', async(e)=>{
          this.payments(e.token.id, this.userData.full_name)
        });
      }else{
        document.getElementById('payment-form-wrapper').classList.remove('hidden');
      }

    },
    async submitPaymentMethod() {
      this.loading = true;
      this.addPaymentStatus = 1;
      this.stripe.createToken(this.card, { name: this.name })
        .then(async (e) => {
          this.payments(e.token.id, this.name)
        })
        .catch((error) => {
          this.loading = false;
          console.log(error, "stripe create token");
        });
    },
    payments(token, name){
      const selectedP = this.packages[`package_${this.plan}`];
      if (!_.isEmpty(this.selectedPrice) && selectedP) {
        const data = {
          payload: {
            email: this.userData.email,
            name: name,
            payment_method: token,
            price: this.selectedPrice["pk"],
          },
          coupon: ""
        };

        var new_plan_duration = 0
        for (let i = 0; i < parseInt(this.selectedPrice.recurringIntervalCount); i++) {
          var now = new Date();
          var current;
          if (now.getMonth() == 11) {
            current = new Date(now.getFullYear() + i, 0, 1);
          } else {
            current = new Date(now.getFullYear(), now.getMonth() + i, 0, 1);
          }
          new_plan_duration = new_plan_duration + current.getDate();
        }

        const newCost = this.selectedPrice.price / 100;

        let trackingInfo = {
          event: "plan-changed",
          old_plan_name: this.userData.detail.subscription.plan_name,
          old_plan_type: this.userData.detail.subscription.plan_type,
          old_plan_duration: this.userData.detail.subscription.remaining_days,
          old_cost: parseInt(this.currentCost),
          new_plan_name: `${this.selectedPrice.recurringIntervalCount} ${this.selectedPrice.recurringInterval}`,
          new_plan_type: `Paid`,
          new_plan_duration: new_plan_duration,
          new_cost: parseInt(newCost),
          expiration_date: this.nextRenewal,
          days_before_expiration: this.getPlanDuration,
          status: this.userData.detail.subscription.remaining_days != 0 && !this.userData.detail.is_expired ? 'active' : 'inactive'
        };

        let api = 'subscribePlan'

        if(this.coupon && this.isApplied){
          api = "subscribePlanWithCoupon"
          data.coupon = this.coupon
        }


        try {
          this.$store
            .dispatch(`accounts/${api}`, data)
            .then(async (response) => {
              console.log("subscribePlan -- ", response);
              if (response.status === 200 && response.data.customer_id) {
                if(!this.userData.detail.is_subscribed){
                  const result = await this.$store.dispatch("accounts/getMe");
                  trackingInfo = {
                    event: "plan-changed",
                    old_plan_name: this.userData.detail.subscription.plan_name,
                    old_plan_type: this.userData.detail.subscription.plan_type,
                    old_plan_duration: this.userData.detail.subscription.remaining_days,
                    old_cost: parseInt(this.currentCost),
                    new_plan_name: result.data.detail.subscription.plan_name,
                    new_plan_type: result.data.detail.subscription.plan_type,
                    new_plan_duration: result.data.detail.subscription.remaining_days,
                    days_before_expiration: result.data.detail.subscription.remaining_days,
                    expiration_date: result.data.detail.subscription.next_renewal,
                    status: 'active'
                  };
                  await trackingTrigger("plan-changed", trackingInfo); 
                }else{
                  await trackingTrigger("plan-changed", trackingInfo);
                  await trackingTrigger("payment-executed", {
                    event: "payment-executed",
                    plan_name: `Paid (${this.selectedPrice.recurringIntervalCount} ${this.selectedPrice.recurringInterval})`,
                    cost: `${newCost}`,
                  });
                }
                
                if (window.dataLayer) {
                  setTimeout(() => {
                    window.location.href = "/journey-setup";
                  }, 500);
                }
              } else {
                this.openToast(
                  "You have already subscribed for " +
                    this.userData.detail.subscription.plan_name,
                  "light"
                );
                this.loading = false;
              }
            })
            .catch((err) => {
              console.log(err, "error");
            });
        } catch (e) {
          console.log("Error", e);
          alert("Error please try again later.");
          this.loading = false;
        }
        //console.log("payLoad--", payLoad);
      }
    },
    applyCoupon(){

      const payload = {
        plan: this.selectedPrice["pk"],
        coupon: this.coupon
      };

      this.$store
        .dispatch(`pages/getPromoCode`, payload)
        .then((res) => {
          if(res){
            this.isApplied = true
            this.couponMessage = ""
          }else{
            this.couponMessage = "Invalid Coupon"
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
 
};
</script>

<style lang="scss" scoped>
.subscription-container {
  min-height: 100%;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.9);
  background-size: cover !important;
  background-position: center !important;
  overflow-y: auto;
  color: #fff;
  @media (max-width: 768px) {
    background: url("../../../public/assets/images/signupbg.jpg");
  }
  .logo {
    width: 150px;
  }
}
footer {
  .add-bg {
    @media (max-width: 768px) {
      margin-top: 16px;
      background: #000;
    }
  }
}
.payment-form {
  font-size: 15px !important;
}

#payment-form-wrapper{
  &.accordion{
    .form{
      max-height: 0;
      transition: max-height .3s ease-out;
      overflow: hidden;

      &.opened{
        max-height: 500px;
        transition: max-height .3s ease-in;
      }
    }
   
  }
}
</style>