<template>
  <div
    class="
      journey-card
      activity
      w-full
      rounded-2lg
      bg-gray-300
      p-5
      flex
      items-center
      justify-center
      overflow-hidden
      text-white
      -mt-4
      cursor-pointer
    "
    v-bind:class="{ hasRecommend: hasRecommend }"
  >
    <div class="cover-image w-full h-full">
      <ion-img
        :src="activity_cover"
        class="h-full w-full md hydrated"
      ></ion-img>
      <div class="video-preview" v-if="activity_preview && !hasRecommend">
        <video autoplay muted loop playsinline preload="metadata">
          <source :src="activity_preview" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <!--v-if-->
    <div class="content w-full h-full flex flex-col">
      <div class="card-heading flex" v-if="hasRecommend">
        <div class="fav ml-auto hidden">
          <ion-icon
            :icon="favouriteState ? heart : heartOutline"
            class="block text-3xl cursor-pointer"
            @click.prevent="favourite"
          ></ion-icon>
        </div>
      </div>
      <div
        class="play-button w-24 cursor-pointer -ml-4 -mt-4"
        v-if="hasRecommend && !recommended_completed"
      >
        <ion-img src="/assets/images/play-button.svg"></ion-img>
      </div>

      <div class="details text-left mt-auto cursor-pointer">
        <div v-if="!hasRecommend">
          <h2 class="title text-2xl font-semibold mb-1 flex">
            <span>Today's workout</span>
            <div class="flex my-auto ml-6 xl:ml-16 text-2xl xl:text-3xl">
              <ion-icon
                :icon="chevronForward"
                class="block opacity-10 -ml-3 xl:-ml-4"
              ></ion-icon>
              <ion-icon
                :icon="chevronForward"
                class="block opacity-30 -ml-3 xl:-ml-4"
              ></ion-icon>
              <ion-icon
                :icon="chevronForward"
                class="block opacity-70 -ml-3 xl:-ml-4"
              ></ion-icon>
              <ion-icon
                :icon="chevronForward"
                class="block -ml-3 xl:-ml-4"
              ></ion-icon>
            </div>
          </h2>
          <div class="description text-text-lg mb-3">
            Get ready for your tailored recommendation
          </div>
        </div>

        <div v-if="hasRecommend" class="cursor-pointer">
          <h2
            class="title text-2xl leading-6 font-semibold mb-2"
            v-if="recommend.name"
          >
            {{ recommend.name }}
          </h2>
          <div
            class="
              chips
              text-xs
              overflow-ellipsis overflow-hidden
              rounded-2lg
              whitespace-nowrap
            "
            v-if="recommend.tags"
          >
            <span
              v-for="(chip, i) in recommend.tags"
              :key="i"
              class="
                inline-block
                bg-black bg-opacity-10
                rounded-2lg
                py-0.5
                px-2.5
                mr-1
                cursor-auto
              "
            >
              <!-- <a v-bind:href="chip.url">{{chip.text}}</a> -->
              {{ chip.text }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        completed-cover
        absolute
        bg-primary bg-opacity-60
        w-full
        h-full
        z-20
        flex
      "
      v-if="recommended_completed"
    >
      <router-link
        :to="recommended_link"
        :href="recommended_link"
        class="block flex w-full h-full"
        replace
      >
        <ion-icon
          :icon="checkmarkCircleOutline"
          class="m-auto text-9xl"
        ></ion-icon>
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { IonImg, IonIcon } from "@ionic/vue";
import {
  chevronForward,
  checkmarkCircleOutline,
  heartOutline,
  heart,
} from "ionicons/icons";
import { slug, formatSlideData } from "@/_helpers";
import _ from "lodash";

export default defineComponent({
  props: {},
  components: { IonImg, IonIcon },
  setup() {
    return { chevronForward, checkmarkCircleOutline, heartOutline, heart };
  },
  data() {
    return {
      hasRecommend: false,
      activity_cover: "/assets/images/journey-1.jpg",
      activity_preview: "",
      favouriteState: false,
      recommended: "",
      recommended_completed: false,
      recommended_link: "",
      recommendedTitle: "",
      recommend: {},
    };
  },
  mounted() {
    // const userData = this.$store.getters["accounts/getUser"];
    // if (userData.recommendation.workout) {}
    const recommended = this.$store.getters["accounts/getRecommended"];
    if (!_.isEmpty(recommended.activity)) {
      if (recommended.activity.activity) {
        this.recommended_link = `/move/workout/view/${recommended.activity.activity}`;

        this.$store
          .dispatch(`pages/getActivity`, recommended.activity.activity)
          .then(() => {
            const recomData = this.$store.getters["pages/getCurrentActivity"];
            if (!_.isEmpty(recomData)) this.hasRecommend = true;
            this.recommend = formatSlideData(recomData, "activity");
            this.favouriteState = this.recommend.favourite;
            if (!_.isEmpty(this.recommend)) {
              this.activity_cover = this.recommend.cover;
              if (this.recommend.coverPortrait)
                this.activity_cover = this.recommend.coverPortrait;
              this.activity_preview = "";
            }
          });
      }

      if (recommended.activity.status == "Completed") {
        this.recommended_completed = true;
      }

      this.recommended = recommended.activity.benefit;

      // Update Activity Cover
      if (this.recommended) {
        // Image
        let assetsURL = "/assets/images/fitness/style/";
        this.activity_cover = `${assetsURL}${slug(this.recommended)}-p.jpg`;
        // Video preview
        if (process.env.VUE_APP_ASSETS_URL)
          assetsURL = process.env.VUE_APP_ASSETS_URL + `/video/benefits/`;
        this.activity_preview = `${assetsURL}${slug(this.recommended)}-p.mp4`;
        if (!_.isEmpty(this.recommend)) {
          this.activity_cover = this.recommend.cover;
          if (this.recommend.coverPortrait)
            this.activity_cover = this.recommend.coverPortrait;
          this.activity_preview = "";
        }
      }
    }
  },
  methods: {
    favourite: function () {
      const recommended = this.$store.getters["accounts/getRecommended"];
      if (this.favouriteState) {
        this.$store.dispatch("accounts/favourite", {
          type: this.recommend.parentType,
          action: "remove",
          ids: recommended.activity.activity.toString(),
        });
      } else {
        this.$store.dispatch("accounts/favourite", {
          type: this.recommend.parentType,
          action: "add",
          ids: recommended.activity.activity.toString(),
        });
      }
      this.favouriteState = this.favouriteState != true;
      return this.favouriteState;
    },
    toggleWorkoutSetup: function () {
      console.log("www--");
    },
  },
});
</script>

<style lang="scss" scoped>
.hasRecommend {
  .cover-image {
    &::before {
      background: transparent linear-gradient(0deg, #00000000 0%, #000000 100%)
        0% 0% no-repeat padding-box;
      content: "";
      height: 80px;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0.2;
      z-index: 10;
      border-radius: 0.625rem;
    }
  }
}
.journey-card {
  position: relative;
  height: 400px;
  .cover-image {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-size: cover;
    border-radius: 0.625rem;
    ion-img {
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      z-index: 1;
      position: absolute;
      top: 0px;
      left: 0px;
    }
    &::after {
      background: transparent
        linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
        padding-box;
      content: "";
      height: 160px;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      opacity: 0.45;
      z-index: 10;
      border-radius: 0.625rem;
    }
  }
  .content {
    z-index: 3;
    .title {
      text-shadow: 0px 3px 6px #00000029;
    }
  }
  .play-button {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  @screen md {
    height: 580px;
  }
}

.journey-card {
  &.activity {
    &:hover {
      .cover-image {
        // ion-img {
        // }
        .video-preview {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
.video-preview {
  z-index: 5;
  background-color: #727272;
  border-radius: 10px;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  video {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 105%;
  }
}
</style>