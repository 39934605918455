<script>
import { IonIcon, IonList, IonItem } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { mapGetters } from "vuex";
//import moment from "moment";
export default {
  name: "profile-items",
  emits: ["setSelect"],
  components: {
    IonList,
    IonItem,
    IonIcon,
  },
  data() {
    return {
      chevronForward,
    };
  },
  computed: {
    computed: {
      ...mapGetters({
        userData: "accounts/getUser",
      }),
    },
    userData() {
      return this.$store.getters["accounts/getUser"];
    },
    // nextRenewal() {
    //   var currentDate = moment();
    //   return currentDate
    //     .add(parseInt(this.userData.detail.subscription.remaining_days), "days")
    //     .format("DD/MM/YYYY ");
    // },
    nextRenewal() {
      var newDate = new Date(this.userData.detail.subscription.next_renewal);
      const today = new Date();
      var d = newDate.getDate();
      var m = newDate.getMonth() + 1; //Month from 0 to 11
      var y = newDate.getFullYear();
      var date = (d <= 9 ? "0" + d : d) + "-" + (m <= 9 ? "0" + m : m) + "-" + y;
      if (this.isDatePassed(newDate, today)) {
        date = "Ended";
      }

      return date;
    },
  },
  methods: {
    isDatePassed(givenDate, todayDate) {
      if (givenDate.setHours(0, 0, 0, 0) <= todayDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    },
    selected(item) {
      this.$emit("setSelect", item);
    },
    onclick() {
      setTimeout(() => {
        this.$emit("onClose");
      }, 2000);
    },
  },
};
</script>
<template>
  <ion-list>
    <ion-item>
      <div class="flex justify-between items-center w-full">
        <div>Plan</div>
        <div>
          {{ userData.detail.subscription.plan_name }}
          <span
            v-if="userData.detail.subscription.plan_type == 'Cancelled User'"
          >
            - Cancelled</span
          >
        </div>
      </div>
    </ion-item>
    <ion-item>
      <div
        class="flex justify-between items-center w-full"
        v-if="userData.detail.subscription.plan_type !== 'Trial User'"
      >
        <div
          v-if="
            userData.detail.subscription &&
            userData.detail.subscription.plan_type !== 'Cancelled User'
          "
        >
          Next Renewal
        </div>

        <div
          v-if="
            userData.detail.subscription &&
            userData.detail.subscription.plan_type == 'Cancelled User'
          "
        >
          Plan Ends
        </div>
        <div>
          {{ nextRenewal }}
        </div>
      </div>
      <div
        class="flex justify-between items-center w-full"
        v-if="userData.detail.subscription.plan_type == 'Trial User'"
      >
        <div>Trial Ends</div>
        <div>
          {{ nextRenewal }}
        </div>
      </div>
    </ion-item>
    <ion-item v-if="userData.detail.subscription.plan_type != 'Trial User'">
      <div class="flex justify-between items-center w-full">
        <div>Payment Method</div>
        <div>Credit Card</div>
      </div>
    </ion-item>
    <ion-item>
      <div
        class="flex justify-between items-center w-full cursor-pointer"
        @click="selected('Manage subscription')"
      >
        <div>Manage Subscription</div>
        <div>
          <ion-icon
            :icon="chevronForward"
            class="ml-5"
            size="medium"
          ></ion-icon>
        </div>
      </div>
    </ion-item>
  </ion-list>
</template>
