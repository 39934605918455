
<template>
   <ion-page class="max-w-md mx-auto">
       <ion-content :fullscreen="true">


            <ion-header class="user-header ion-no-border flex rounded-b-2xl z-40">
                <div class="container max-w-full px-6 py-6">
                    <div class="back flex -mt-1 -ml-4"><ion-back-button defaultHref="/home" :icon="chevronBack" class="text-xl"></ion-back-button><span class="my-auto -ml-2 pb-2px">Back</span></div>
                    <div class="search flex bg-light bg-opacity-30 rounded-2lg">
                        <ion-icon :icon="searchOutline" class="block text-2xl text-dark my-auto ml-2"></ion-icon>
                        <ion-input placeholder="Search a recipe" value="Mushroom"></ion-input>
                        <div class="my-auto ml-auto">
                            <router-link to="/home" class="relative">
                                <ion-icon :icon="closeOutline" class="block text-black text-xl bg-white rounded-full p-1 mr-1"></ion-icon>
                            </router-link>
                        </div>
                    </div>
                </div>
            </ion-header>

            <div class="container max-w-full px-6">
                <div class="search-results">

                    <a href="/eat/view/235" class="w-full h-full">
                    <div class="carousel-card w-full rounded-2lg bg-gray-300 p-5 flex items-center justify-center overflow-hidden text-white mb-5">
                        <div class="cover-image w-full h-full">
                            <ion-img class="h-full w-full" src="/assets/images/eat/nutrition-1.jpg"></ion-img>
                        </div>
                        <div class="content w-full h-full flex flex-col">
                        <div class="card-heading flex">
                            <div class="fav ml-auto">
                            <ion-icon :icon="heartOutline" class="block text-3xl"></ion-icon>
                            </div>
                        </div>
                        
                        <div class="details mt-auto text-left text-left">
                            <h2 class="title text-2xl leading-6 font-semibold mb-2">Mushroom Stir Fry</h2>
                            <div class="chips text-xs flex overflow-ellipsis overflow-hidden rounded-2lg">
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Breakfast</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Low inflammation</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Intermediate</a></span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </a>

                    <a href="/eat/view/235" class="w-full h-full">
                    <div class="carousel-card w-full rounded-2lg bg-gray-300 p-5 flex items-center justify-center overflow-hidden text-white mb-5">
                        <div class="cover-image w-full h-full">
                            <ion-img class="h-full w-full" src="/assets/images/eat/nutrition-2.jpg"></ion-img>
                        </div>
                        <div class="content w-full h-full flex flex-col">
                        <div class="card-heading flex">
                            <div class="fav ml-auto">
                            <ion-icon :icon="heartOutline" class="block text-3xl"></ion-icon>
                            </div>
                        </div>
                        
                        <div class="details mt-auto text-left text-left">
                            <h2 class="title text-2xl leading-6 font-semibold mb-2">Mushroom and Scallop Stir Fry</h2>
                            <div class="chips text-xs flex overflow-ellipsis overflow-hidden rounded-2lg">
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Breakfast</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Low inflammation</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Intermediate</a></span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </a>

                    <a href="/eat/view/235" class="w-full h-full">
                    <div class="carousel-card w-full rounded-2lg bg-gray-300 p-5 flex items-center justify-center overflow-hidden text-white mb-5">
                        <div class="cover-image w-full h-full">
                            <ion-img class="h-full w-full" src="/assets/images/eat/nutrition-3.jpg"></ion-img>
                        </div>
                        <div class="content w-full h-full flex flex-col">
                        <div class="card-heading flex">
                            <div class="fav ml-auto">
                            <ion-icon :icon="heartOutline" class="block text-3xl"></ion-icon>
                            </div>
                        </div>
                        
                        <div class="details mt-auto text-left text-left">
                            <h2 class="title text-2xl leading-6 font-semibold mb-2">Morning Immunity Bomb</h2>
                            <div class="chips text-xs flex overflow-ellipsis overflow-hidden rounded-2lg">
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Breakfast</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Low inflammation</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Intermediate</a></span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </a>

                    <a href="/eat/view/235" class="w-full h-full">
                    <div class="carousel-card w-full rounded-2lg bg-gray-300 p-5 flex items-center justify-center overflow-hidden text-white mb-5">
                        <div class="cover-image w-full h-full">
                            <ion-img class="h-full w-full" src="/assets/images/eat/nutrition-1.jpg"></ion-img>
                        </div>
                        <div class="content w-full h-full flex flex-col">
                        <div class="card-heading flex">
                            <div class="fav ml-auto">
                            <ion-icon :icon="heartOutline" class="block text-3xl"></ion-icon>
                            </div>
                        </div>
                        
                        <div class="details mt-auto text-left text-left">
                            <h2 class="title text-2xl leading-6 font-semibold mb-2">Mushroom Stir Fry</h2>
                            <div class="chips text-xs flex overflow-ellipsis overflow-hidden rounded-2lg">
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Breakfast</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Low inflammation</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Intermediate</a></span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </a>

                    <a href="/eat/view/235" class="w-full h-full">
                    <div class="carousel-card w-full rounded-2lg bg-gray-300 p-5 flex items-center justify-center overflow-hidden text-white mb-5">
                        <div class="cover-image w-full h-full">
                            <ion-img class="h-full w-full" src="/assets/images/eat/nutrition-2.jpg"></ion-img>
                        </div>
                        <div class="content w-full h-full flex flex-col">
                        <div class="card-heading flex">
                            <div class="fav ml-auto">
                            <ion-icon :icon="heartOutline" class="block text-3xl"></ion-icon>
                            </div>
                        </div>
                        
                        <div class="details mt-auto text-left text-left">
                            <h2 class="title text-2xl leading-6 font-semibold mb-2">Mushroom and Scallop Stir Fry</h2>
                            <div class="chips text-xs flex overflow-ellipsis overflow-hidden rounded-2lg">
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Breakfast</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Low inflammation</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Intermediate</a></span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </a>

                    <a href="/eat/view/235" class="w-full h-full">
                    <div class="carousel-card w-full rounded-2lg bg-gray-300 p-5 flex items-center justify-center overflow-hidden text-white mb-5">
                        <div class="cover-image w-full h-full">
                            <ion-img class="h-full w-full" src="/assets/images/eat/nutrition-3.jpg"></ion-img>
                        </div>
                        <div class="content w-full h-full flex flex-col">
                        <div class="card-heading flex">
                            <div class="fav ml-auto">
                            <ion-icon :icon="heartOutline" class="block text-3xl"></ion-icon>
                            </div>
                        </div>
                        
                        <div class="details mt-auto text-left text-left">
                            <h2 class="title text-2xl leading-6 font-semibold mb-2">Morning Immunity Bomb</h2>
                            <div class="chips text-xs flex overflow-ellipsis overflow-hidden rounded-2lg">
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Breakfast</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Low inflammation</a></span>
                                <span class="bg-black bg-opacity-10 rounded-2lg py-1 px-2 mr-1"><a href="">Intermediate</a></span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </a>


                </div>
            </div>

            <div class="results-options fixed bottom-5 left-0 w-full z-20">
                <div class="filter flex rounded-lg border border-black shadow bg-white w-60 mx-auto">
                    <ion-button color="white" class="w-1/2 m-0">
                        <div class="px-0 py-2.5 md:py-3.5 flex text-dark font-normal">
                            <ion-icon :icon="funnelOutline" class="text-lg mr-2"></ion-icon> Filters
                        </div>
                    </ion-button>
                    <div class="border-r"></div>
                    <ion-button color="white" class="w-1/2 m-0">
                        <div class="px-0 py-2.5 md:py-3.5 flex text-dark font-normal">
                            Sort <ion-icon :icon="swapVerticalOutline" class="text-lg ml-2"></ion-icon>
                        </div>
                    </ion-button>
                </div>
            </div>
       
        
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonContent, IonBackButton, IonIcon, IonImg } from '@ionic/vue';
import { searchOutline, chevronBack, stopwatchOutline, closeCircle, closeOutline, heartOutline, funnelOutline, swapVerticalOutline } from 'ionicons/icons';

export default{
  components: { IonPage, IonContent, IonBackButton, IonIcon, IonImg },
  data(){
      return { searchOutline, chevronBack, stopwatchOutline, closeCircle, closeOutline, heartOutline, funnelOutline, swapVerticalOutline }
  }
}
</script>

<style lang="scss" scoped>
.s-image{
    overflow: hidden;
    img, ion-img{
        height: 100%;
        width: 100%;
        object-fit: cover
    }
}


.carousel-card{
  position: relative;
  height: 200px;
  .cover-image{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-size: cover;
    ion-img{
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
    &::after{
      background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
      content: "";
      height: 100px;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      opacity: 0.45;
    }
  }
  .content{
    z-index: 3;
    .title{
      text-shadow: 0px 3px 6px #00000029;
    }
  }
  .level{
    .status{
      display: inline-block;
      width: 8px;
      height: 8px;
      border: solid 1px var(--ion-color-white);
      border-radius: 100%;
      margin-left: 3px;
      &.checked{
        background: var(--ion-color-white);
      }
    }
  }
  .play-button {
    position: absolute;
    top: 26%;
    left: calc(50% - 29px);
  }
  @screen md {
    height: 240px;
  }
  @screen 2xl {
    height: 300px;
      .play-button {
        top: calc(45% - 35px);
      }
  }
}
</style>
