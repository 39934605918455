<template>
  <div class="journey-box" v-if="loading">
    <div class="background rounded-2lg"></div>
    <div class="content p-6 lg:p-10">
      <div class="top grid grid-cols-4 mb-14 md:mb-20">
        <div class="col-span-1 flex">
          <ion-img :src="'/assets/images/icon-white.svg'"></ion-img>
        </div>
        <div
          class="col-span-3 flex flex-row-reverse justify-start -mt-10 md:mt-0"
        >
          <div
            class="
              rounded-full
              bg-gray-100
              h-14
              md:h-20
              w-14
              md:w-20
              flex
              items-center
              justify-center
              overflow-hidden
              -ml-3
              md:ml-0
            "
          ></div>
          <div
            class="
              rounded-full
              bg-gray-100
              h-14
              md:h-20
              w-14
              md:w-20
              flex
              items-center
              justify-center
              overflow-hidden
              -ml-3
              md:ml-0
            "
          ></div>
        </div>
      </div>
      <div class="chips flex uppercase md:text-xl font-hokyaa mb-5">
        <ion-skeleton-text
          animated
          class="py-4 w-1/12 rounded-full mr-2"
        ></ion-skeleton-text>
        <ion-skeleton-text
          animated
          class="py-4 w-2/12 rounded-full mr-2"
        ></ion-skeleton-text>
      </div>
      <div class="caption md:text-lg text-white">
        <ion-skeleton-text
          animated
          class="text-3xl md:text-4xl mb-4 md:mb-0 w-4/12"
        ></ion-skeleton-text>
        <ion-skeleton-text animated class="py-1 w-5/12"></ion-skeleton-text>
      </div>
      <div class="mt-20 max-w-full md:max-w-xs">
        <ion-skeleton-text
          animated
          class="w-12/12 rounded-md px-10 py-2.5 text-base md:text-xl md:py-3.5"
        ></ion-skeleton-text>
      </div>
    </div>
  </div>
  <div class="journey-box" v-if="!loading">
    <div class="background rounded-2lg"></div>
    <div class="content p-6 lg:p-10">
      <div class="top grid grid-cols-4 mb-14 md:mb-20">
        <div class="col-span-1 flex">
          <ion-img :src="'/assets/images/icon-white.svg'"></ion-img>
        </div>
        <div
          class="col-span-3 flex flex-row-reverse justify-start -mt-10 md:mt-0"
        >
          <!-- <div class="rounded-full h-14 md:h-20 w-14 md:w-20 flex items-center justify-center overflow-hidden -ml-3 md:ml-0">
            <img src="/assets/images/sam-s.jpg" />
          </div>
          <div class="rounded-full h-14 md:h-20 w-14 md:w-20 flex items-center justify-center overflow-hidden -ml-3 md:ml-0">
            <img src="/assets/images/naz-s.jpg" />
          </div> -->
        </div>
      </div>
      <div class="chips flex mb-5">
        <ion-chip color="white" class="rounded-full md:py-5 px-6 bg-black">
          <ion-label color="white" class="font-hokyaa text-base md:text-xl"
            >Xali <span class="imary">Journey</span>
          </ion-label>
        </ion-chip>
      </div>
      <div class="caption md:text-lg text-white">
        <h2 class="text-3xl md:text-4xl mb-4 md:mb-0">
          <strong
            >Hey there, {{ user.first_name }}! Let’s make this your
            space.</strong
          >
        </h2>
        <p>
          Answer a few short questions to unlock your personalised fitness and
          nutrition program.
        </p>
      </div>
      <div class="mt-20 max-w-full md:max-w-xs">
        <ion-button color="white" expand="block">
          <div
            class="px-10 py-2.5 text-base text-black md:text-xl md:py-3.5"
            @click.prevent="toggleJourneySetup()"
          >
            Let's get started!
          </div>
        </ion-button>
      </div>
    </div>
  </div>
  <Modal
    :close_float="true"
    height_class="h-full sm:h-4/5"
    :background="'/assets/images/journey-1.jpg'"
    ref="modal"
    class="journey-modal"
  >
    <template v-slot:header>
      <div class="w-full sm:-mt-8 sm:flex">
        <ion-chip color="white" class="my-0 rounded-full px-6 bg-black mx-auto">
          <ion-label color="white" class="font-hokyaa text-base"
            >Xali <span class="text-primary">Journey</span>
          </ion-label>
        </ion-chip>
      </div>
    </template>
    <div class="h-full flex flex-col">
      <div class="relative mt-auto">
        <div class="overflow-hidden h-2 mb-4 text-xs flex bg-light rounded">
          <div
            style="width: 30%"
            class="
              flex flex-col
              text-center
              whitespace-nowrap
              text-white
              justify-center
              bg-primary
              rounded
            "
          ></div>
        </div>
      </div>
      <div>
        <router-link to="/journey-setup">
          <ion-button color="white" expand="block">
            <div class="px-10 py-3.5 text-black">Let's do it</div>
          </ion-button>
        </router-link>
      </div>
    </div>
  </Modal>
</template>

<script>
import {
  IonImg,
  IonButton,
  IonSkeletonText,
  IonChip,
  IonLabel,
} from "@ionic/vue";
import Modal from "@/components/commons/Modal.vue";

export default {
  components: { IonImg, IonButton, IonSkeletonText, IonChip, IonLabel, Modal },
  data() {
    return { loading: true };
  },
  async mounted() {
    //window.setTimeout(() => {
    this.loading = false;
    //}, 2000)
  },
  computed: {
    user() {
      const user = this.$store.getters["accounts/getUser"];
      return user ? user : {};
    },
  },
  methods: {
    toggleJourneySetup: function () {
      //this.$refs.modal.toggleModal();
      //this.$router.push("/journey-setup/1");
      window.location.href = "/journey-setup";
    },
  },
};
</script>

<style lang="scss" scoped>
.journey-box {
  max-height: 560px;
  // overflow: hidden;
  position: relative;
  .background {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
  .background::after {
    content: "";
    background: var(--ion-color-primary);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    z-index: 2;
  }
  .content {
    position: relative;
    z-index: 20;
  }
  .background::before {
    content: "";
    background-image: url("/assets/images/signupbg.jpg");
    filter: blur(10px);
    -webkit-filter: blur(10px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-position: center;
    background-size: cover;
    z-index: 1;
  }
  @screen sm {
  }
}
</style>
