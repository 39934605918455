<template>
   
    <div class="signup-social h-full">
        <form action="/home" class="h-full flex flex-col">
            <div class="accounts my-auto">
                <p class="text-light text-center px-5 mb-5">You're about to continue with the following Facebook account:</p>
                <div class="account bg-black rounded-lg p-4 flex transition transform-gpu hover:scale-105 mb-5">
                    <a href="./signup" class="flex absolute top-0 right-0 -mt-3 -mr-3"><ion-icon :icon="closeCircle" class="text-3xl"></ion-icon></a>
                    <div class="flex m-auto">
                        <ion-avatar slot="start" class="w-12 mr-3 h-full relative">
                            <img class="w-full" src="https://gravatar.com/avatar/random?d=identicon">
                            <a href="./signup" class="flex absolute top-0 right-0 -mt-1 -mr-1 bg-white rounded-full p-0.5"><ion-icon :icon="logoFacebook" class="text-sm fill-primary"></ion-icon></a>
                        </ion-avatar>
                        <div>
                            <h3 class="font-medium">Emily Farrell</h3>
                            <p class="text-sm">emily.farrell@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="mt-auto">
                <div class="flex my-5">
                    <div class="flex"><ion-checkbox color="white" class="my-auto mr-4"></ion-checkbox></div>
                    <div><label for="sample" class="text-sm">By creating an account you agree to our <a href="tandc" class="underline">Terms of Service</a> & <a href="policy" class="underline">Privacy Policy</a>.</label> </div>
                </div>
                <ion-button color="white" expand="block"><div class="px-10 py-2.5 text-base md:py-3.5 text-black">Start my 14 days FREE trial</div></ion-button>
                <p class="text-xs text-light text-center mt-5">
                    You won't be charge. Once your trial ended, you’ll be invited to subscribe to a paid plan.
                </p>
            </div>
         </form>
    </div>

</template>
<script>
import { IonButton, IonCheckbox, IonIcon } from '@ionic/vue';
import { closeCircle, logoFacebook } from 'ionicons/icons';

export default{
    components: { IonButton, IonCheckbox, IonIcon },
    setup() {
        return { closeCircle, logoFacebook }
    }
}
</script>
