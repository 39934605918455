//import _ from "lodash";
function extractOption(fieldOptions, key) {
  if (fieldOptions[key]) {
    if (key === "LVL") {
      return [
        ...fieldOptions[key].map(option => {
          return {
            value: option.id,
            text: option.value
          };
        })
      ].filter(option => option.text !== "All");
    }

    if (key === "SRS") {
      return [
        ...fieldOptions[key].map(option => {
          return {
            value: option.id,
            text: option.label
          };
        })
      ];
    }
    return [
      ...fieldOptions[key].map(option => {
        return {
          value: option.id,
          text: option.label
        };
      })
    ];
  }
  return [];
}

function recipeExtractOption(fieldOptions, key) {
  if (!fieldOptions[key]) {
    return [{ value: null, text: `No Data` }];
  }
  return [
    ...fieldOptions[key].map(option => {
      return {
        value: option.id,
        text: option.label
      };
    })
  ];
}

export default {
  getHome(state) {
    return state.home;
  },
  getFitness(state) {
    return state.fitness;
  },
  getFitnessStretchflow(state) {
    return state.fitnessStretchflow;
  },
  getHomeData(state) {
    return state.home;
  },
  getCurrentCategory(state) {
    let category = JSON.parse(JSON.stringify(state.category))
    if (state.category.activity.length > 0) {
      category.activity.sort(function (a, b) {
        var activityA = a.name.toUpperCase();
        var activityB = b.name.toUpperCase();
        return (activityA < activityB) ? -1 : (activityA > activityB) ? 1 : 0;
      });
    }
    return category;
  },
  getCurrentActivity(state) {
    return state.activity;
  },
  getActivityPostProduction(state) {
    return state.activityPostProduction;
  },
  getNutrition(state) {
    return state.nutrition;
  },
  getNutritionCategory(state) {

    let category = JSON.parse(JSON.stringify(state.recipe_category))
    if (state.recipe_category.recipe.length > 0) {
      category.recipe.sort(function (a, b) {
        var recipeA = a.name.toUpperCase();
        var recipeB = b.name.toUpperCase();
        return (recipeA < recipeB) ? -1 : (recipeA > recipeB) ? 1 : 0;
      });
    }
    return category;
  },
  getCurrentRecipe(state) {
    return state.recipe;
  },
  getIngredients(state) {
    return state.ingredients.all;
  },
  getMoveData(state) {
    return state.move.data;
  },
  //ACTIVITY FIELD OPTIONS
  fieldOptionsChunked: state => {
    return state.fieldOptions;
  },
  levelOptions: state => {
    return extractOption(state.fieldOptions, "LVL");
  },
  modeOptions: state => {
    return extractOption(state.fieldOptions, "MDE");
  },
  sfModeOptions: state => {
    return extractOption(state.fieldOptions, "STRETCHFLOW_MDE");
  },
  intensityOptions: state => {
    return extractOption(state.fieldOptions, "HS");
  },
  sfIntensityOptions: state => {
    return extractOption(state.fieldOptions, "STRETCHFLOW_HS");
  },
  bodyPartsOptions: state => {
    return extractOption(state.fieldOptions, "BDY");
  },
  benefitsOptions: state => {
    return extractOption(state.fieldOptions, "BFT");
  },

  //RECIPE FIELD OPTIONS
  mealTimeOptions: state => {
    return recipeExtractOption(state.recipeFieldOptions, "MLT");
  },
  categoriesFieldOptions: state => {
    return recipeExtractOption(state.recipeFieldOptions, "CAT");
  },
  dietaryPreferencesOptions: state => {
    return recipeExtractOption(state.recipeFieldOptions, "DTP");
  },
  effectsOptions: state => {
    return recipeExtractOption(state.recipeFieldOptions, "EFFECTS");
  },
  intentionsOptions: state => {
    return recipeExtractOption(state.recipeFieldOptions, "INTENTIONS");
  },
  filterArgs: state => {
    return state.filterArgs;
  },
  dietaryPreferences: (state) => {
    return state.dietaryPreferences
  },
};