<script>
import { IonSpinner, IonIcon } from "@ionic/vue";

import api from "@/api";
import { checkmarkCircleSharp } from "ionicons/icons";
export default {
  name: "confirm-email",
  components: { IonSpinner, IonIcon },
  data() {
    return {
      checkmarkCircleSharp,
      loading: true,
      token: null,
      isValid: true,
    };
  },
  async mounted() {
    // console.log("Confirm Email");
    // let uri = window.location.search.substring(1);
    // let params = new URLSearchParams(uri);
    // console.log(params.get("token"));
    // console.log(this.$route.query.token);
    this.loading = true;
    this.token = this.$route.query.token;

    // this.token = this.$route.params.token;
    // console.log("this.token", this.token);
    // this.token = "test";
    const response = await api.confirmEmail(this.token);
    // console.log("response", response);
    if (response && response.status == 200 && response.data.message) {
      this.loading = false;
      this.isValid = true;

      setTimeout(() => {
        window.location.href = `/`;
      }, 3000);
    } else {
      this.loading = false;
      this.isValid = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <container-cover>
    <div class="bg-white flex h-full">
      <div class="max-w-sm w-full m-auto px-12">
        <h1 class="text-4xl font-bold">
          <span class="font-hokyaa font-normal text-primary mb-3">Xali</span>
        </h1>
        <p class="mb-16 text-sm"></p>
        <div class="text-center" v-if="loading">
          <ion-spinner class="p-5"></ion-spinner>
          <p>validating...</p>
        </div>

        <div
          v-if="!loading && !isValid"
          class="mt-10 mb-5 p-3 bg-primary bg-opacity-5 border-primary"
        >
          <div class="text-center">
            <div>Either the token is expired or invalid</div>
          </div>
        </div>

        <div
          v-if="!loading && isValid"
          class="mt-10 mb-5 p-3 bg-primary bg-opacity-5 border-primary"
        >
          <div class="text-center">
            <ion-icon
              :icon="checkmarkCircleSharp"
              class="text-primary text-7xl"
            ></ion-icon>
            <div>Thanks your email is now confirmed!</div>
          </div>
        </div>
      </div>
    </div>
  </container-cover>
</template>
