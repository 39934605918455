import { responseHandler, filterResults, formatSlideData } from "@/_helpers";
import api from "@/api";
import _ from "lodash";
import VueCookies from "vue-cookies";

const tokenKey = "userToken";
const refreshtokenKey = "userRefreshToken";
const userKey = "userData";
const fbKey = "fbUser";

export default {
  async switchApp({ commit }, token) {
    try {
      let response = await api.validateSwitchToken(token);
      response = responseHandler(response);
      commit("setResponseData", response.data);
      if (response.data.token.access) {
        commit("setToken", response.data);
        commit("setUser", response.data);
        response.data.switch = true;

        VueCookies.set(tokenKey, response.data.token.access);
        VueCookies.set(refreshtokenKey, response.data.token.refresh);
        VueCookies.set(userKey, response.data);
      }
      return response;
    } catch (e) {
      console.log("api.switchApp error ", e);
      return null;
    }
  },
  async logout({ commit }) {
    commit("setUser", {});
    commit("setToken", "");

    if (VueCookies.keys()) {
      VueCookies.keys().forEach(key => {
        // console.log("key", key);
        if (key !== "user_timezone") {
          VueCookies.remove(key);
        }

      });
    }
    return true;
  },
  async getipInfo({ state }, data) {
    try {
      let allow = true;
      const Http = new XMLHttpRequest();
      const url='http://ip-api.com/json';
      Http.open("GET", url);
      Http.send();

      Http.onreadystatechange = () => {
        if(Http.responseText){
          const info = JSON.parse(Http.responseText)
          //const notAllow = ['Canada','U.S','United States']
          const notAllow = []
          if(notAllow.includes(info.country)){
            console.log('Disable Facebook');
          }
        }
      }
      if(data) console.log('state--',state);
      return allow;
    } catch (e) {
      console.log("api.getipInfo error ", e.response);
      return null;
    }
  },
  async login({ commit }, data) {
    commit("setFormData", data);
    try {
      let response = await api.login(data);
      response = responseHandler(response);
      commit("setResponseData", response.data);
      if (response.data.token.access) {
        commit("setToken", response.data);
        commit("setUser", response.data);
        VueCookies.set(tokenKey, response.data.token.access);
        VueCookies.set(refreshtokenKey, response.data.token.refresh);
        VueCookies.set(userKey, response.data);
        // Remove FB Data
        VueCookies.remove(fbKey);
      }
      return response;
    } catch (e) {
      console.log("api.login error ", e.response);
      return null;
    }
  },
  async getProduct({ commit }) {
    try {
      let response = await api.getProduct();
      response = responseHandler(response);
      let product = filterResults(response.data.product);
      commit("setResponseData", response);
      return product
    } catch (e) {
      console.log("api.getProduct ", e);
      return null;
    }
  },
  async subscribePlan({ commit }, data) {
    try {
      let response = await api.subscribe(data);
      response = responseHandler(response);
      commit("setResponseData", response.data);
      // console.log('subscribePlan--', response);
      return response;
    } catch (e) {
      console.log("api.subscribe error ", e.response);
      return null;
    }
  },
  async subscribePlanWithCoupon({ commit }, data) {
    try {
      let response = await api.subscribeWithPromoCode(data);
      response = responseHandler(response);
      commit("setResponseData", response.data);
      // console.log('subscribePlan--', response);
      return response;
    } catch (e) {
      console.log("api.subscribe error ", e.response);
      return null;
    }
  },
  
  async cancelSubscription({ commit }, data) {
    try {
      let response = await api.cancelSubscription(data);
      response = responseHandler(response);
      commit("setResponseData", response.data);
      // console.log('subscribePlan--', response);
      return response;
    } catch (e) {
      console.log("api.cancelSubscription error ", e.response);
      return null;
    }
  },

  async create({ commit }, data) {
    VueCookies.remove(fbKey);
    commit("setFormData", data.formData);
    try {
      let response = await api.signup(data.coupon, data.formData);
      response = responseHandler(response);
      commit("setResponseData", response.data);
      return response;
    } catch (e) {
      console.log("api.signup error ", e);
      return null;
    }
  },
  async getMe({ commit }) {
    try {
      let response = await api.me();
      response = responseHandler(response);
      if ([401, 500].includes(response.status)) return response;

      const recipesIds =
        response.data &&
          response.data.recommendation &&
          response.data.recommendation.recipes
          ? response.data.recommendation.recipes
          : [];
      if (!_.isEmpty(recipesIds)) {
        let recipe = {};
        recipe.ids = recipesIds;
        commit("setRecommendRecipe", recipe);
      }

      const workoutRec =
        response.data &&
          response.data.recommendation &&
          response.data.recommendation.workout
          ? response.data.recommendation.workout
          : null;
      if (workoutRec) {
        commit("setRecommendActivity", workoutRec);
      }

      return response;
    } catch (e) {
      console.log("api.me error ", e);
      return null;
    }
  },
  async refreshToken({ commit }, token = "") {
    try {
      let response = await api.refresh(token);
      response = responseHandler(response);
      if (response.status != 200) return response;

      commit("setResponseData", response.data);
      if (response.data.token.access) {
        commit("setToken", response.data);
        VueCookies.set(tokenKey, response.data.token.access);
        VueCookies.set(refreshtokenKey, response.data.token.refresh);
      }
      return response;
    } catch (e) {
      console.log("api.refreshToken error", e);
      return null;
    }
  },
  async getJourneyQuestions({ commit }) {
    try {
      const response = await api.getJourneyQuestions();
      // Journey Questions
      let questions = {};
      questions.level = filterResults(response.data.activityFieldOptions);
      questions.allJourney = response.data.allJourney;
      questions.dietaryPreferenceCategories = filterResults(
        response.data.dietaryPreferenceCategories
      );
      questions.dietaryPreferences = filterResults(
        response.data.dietaryPreferences
      );
      commit("setJourneyQuestions", questions);
    } catch (e) {
      console.log("api.login getInitialActivity ", e);
      return null;
    }
  },
  async saveUsersJourney({ commit, state }) {
    // console.log("state.journeySetupResponse", state.journeySetupResponse);
    try {
      let data = {
        journey: state.journeySetupResponse.journey,
        level: state.journeySetupResponse.level,
        dietary_preferences: state.journeySetupResponse.temp_dietary_preferences,
        available_equipments: state.journeySetupResponse.available_equipments
      };
      console.log(data);
      const response = await api.saveUsersJourney(data);
      commit("setFormData", null);
      return response;
    } catch (e) {
      console.log("ERROR: api.saveUsersJourney ", e);
      return null;
    }
  },
  async setRecommendedActivity({ commit }, data = { emotion: 217, isAdapt: false }) {
    let emotion = data.emotion;
    if (!emotion) return "Error please provide emotion id";
    try {
      let response = await api.setRecommendedActivity({ emotion: emotion, isAdapt: data.isAdapt });
      response = responseHandler(response);

      commit("setRecommendActivity", response.data);
      return response;
    } catch (e) {
      console.log("api.setRecommendedActivity ", e);
      return null;
    }
  },
  async setRecommendedAdapt({ commit }, action = "level", level = "neutral") {
    if (!level) return "Error please provide emotion id";
    try {
      let response = await api.setRecommendedAdapt({
        action: action,
        level: level
      });
      response = responseHandler(response);

      commit("setRecommendActivity", response.data);
      return response;
    } catch (e) {
      console.log("api.setRecommendedAdapt ", e);
      return null;
    }
  },
  async setRecommendedActivityStatus({ state }, status = "") {
    if (!status) return "Error please provide status";
    try {
      let response = await api.setRecommendedActivityStatus({ status: status });
      response = responseHandler(response);

      console.log("state--", state);

      //commit("setRecommendActivity", response.data);
      return response;
    } catch (e) {
      console.log("api.setRecommendedActivityStatus ", e);
      return null;
    }
  },
  async setRecommendedRecipe({ dispatch }, benefits) {
    try {
      // Get Recommend ID
      let response = await api.setRecommendedRecipe({ benefit: benefits });
      response = responseHandler(response);
      // console.log("response", response);
      if (response.data && response.data.recipe) {
        await dispatch(`getRecommendedRecipe`, response.data.recipe);
      }

      return response;
    } catch (e) {
      console.log("api.me setRecommendedActivity ", e);
      return null;
    }
  },
  async getRecommendedLearn({ commit }, id = null) {
    try {
      let learn = await api.getLearn(id);
      learn = filterResults(learn.data.allLearn)
      // console.log("learn", learn);
      commit("setRecommendLearn", learn);
      return learn;
    } catch (e) {
      console.log("api.me getRecommendedLearn ", e);
      return null;
    }
  },
  async getRecommendedRecipe({ commit }, ids = []) {
    try {
      let recipe = {};
      recipe.ids = ids;
      // Get Recipe by given Id
      let recipes = await api.getRecipeByID(recipe.ids);
      recipe.recipes = _.map(filterResults(recipes.data.recipeByIds), data =>
        formatSlideData(data, "recipe")
      );

      commit("setRecommendRecipe", recipe);
      return recipes;
    } catch (e) {
      console.log("api.me setRecommendedActivity ", e);
      return null;
    }
  },
  // Favourites
  async getInitialFavourites({ commit, state }) {
    try {
      // console.log("dispatching ...");
      const recipes = await api.favouriteRecipes(`last:5`);
      let recipes_arr = _.map(
        filterResults(recipes.data.favouriteRecipe),
        data => formatSlideData(data, "recipe")
      );
      const workouts = await api.favouriteActivities(`last:5`);
      let workouts_arr = _.map(
        filterResults(workouts.data.favouriteActivity),
        data => formatSlideData(data, "activity")
      );
      const learns = await api.favouriteLearn();
      let learn_arr = _.map(
        filterResults(learns.data.userFavourites.learn),
        data => formatSlideData(data, "learn")
      );
      // console.log("recipes_arr", recipes_arr);
      // console.log("workouts_arr", workouts_arr);
      // console.log("learn_arr", learn_arr);
      commit("setFavouritesData", {
        recipes: recipes_arr,
        workouts: workouts_arr,
        learns: learn_arr
      });

      return state.favourites;
    } catch (e) {
      console.log("api.getInitialFavourites error ", e);
      return null;
    }
  },
  async favourite(
    { commit, dispatch },
    params = { type: "", action: "", ids: [] }
  ) {
    if (!params.type || !params.action || _.isEmpty(params.ids)) return null;
    if (!Array.isArray(params.ids)) params.ids = [params.ids];
    console.log("w1--", params);
    try {
      let response = await api.favourite(
        params.type,
        params.action,
        params.ids
      );
      response = responseHandler(response);
      commit("setResponseData", response);
      setTimeout(() => {
        dispatch("getInitialFavourites");
      }, 500);
      return response;
    } catch (e) {
      console.log("api.me favourite ", e);
      return null;
    }
  },
  async setupFavourite({ commit, dispatch }, obj) {
    try {
      let response = await api.favourite(
        obj.type,
        obj.action,
        [obj.ids]
      );
      response = responseHandler(response);
      commit("setResponseData", response);
      setTimeout(() => {
        dispatch("getInitialFavourites");
      }, 500);
      return response;
    } catch (e) {
      console.log("api.me favourite ", e);
      return null;
    }
  },
  // History
  async getHistories({ commit, state }) {
    try {
      const recipes = await api.recipeHistory(`last:5`);
      let recipes_arr = _.map(
        filterResults(recipes.data.recipeHistory),
        data => formatSlideData(data, "recipe")
      );

      const workouts = await api.activityHistory(`last:5`);
      let workouts_arr = _.map(
        filterResults(workouts.data.activityHistory),
        data => formatSlideData(data, "activity")
      );

      commit("setHistoryData", {
        recipes: recipes_arr,
        workouts: workouts_arr,
        learns: []
      });
      return state.history;

    } catch (e) {
      console.log("api.getHistories error ", e);
      return null;
    }
  },
  async getAllHistoryByType({ commit }, obj) {
    try {
      if (obj.type == "activity") {
        const response = await api.activityHistory(
          `first:5, offset:${obj.offset}`
        );
        let result = _.map(
          filterResults(response.data.activityHistory),
          data => formatSlideData(data, "activity")
        );
        // TODO: Remove comment to filter duplicates via frontend
        // const arrayUniqueByKey = filterDuplicates(result, 'id');
        // commit("setHistoryData", arrayUniqueByKey);
        commit("setHistoryData", result);
        return result;
      }
      if (obj.type == "recipe") {
        const response = await api.recipeHistory(
          `first:5, offset:${obj.offset}`
        );
        let result = _.map(filterResults(response.data.recipeHistory), data =>
          formatSlideData(data, "recipe")
        );
        // TODO: Remove comment to filter duplicates via frontend
        // const arrayUniqueByKey = filterDuplicates(result, 'id');
        // commit("setHistoryData", arrayUniqueByKey);
        commit("setHistoryData", result);
        return result;
      }

    } catch (e) {
      console.log("api.getAllHistoryByType error #1", e);
      return null;
    }
  },
};
