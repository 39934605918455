
<template>
  <container-cover>
    <div class="bg-white flex h-full ">
      <div class="subscription-container relative w-full ">
        <div class="md:px-16 lg:px-22 xl:px-44">
          <header class="pt-8 px-6">
            <a v-bind:href="`/home`" class="my-auto ml-auto relative">
              <ion-icon :icon="chevronBack" size="large"></ion-icon>
            </a>
          </header>

          <div class="body-content mt-5 px-6">
            <h2
              class="text-4xl font-bold tracking-tight leading-8 my-4 mb-5"
            >
              Choose your plan
            </h2>
            <p class="tracking-tight mb-4" v-if="userData">{{userData.email}}. Not you? <a href="/logout" class="text-primary"> Logout </a></p>
            <p class="tracking-tight mb-4" >You are almost ready to start your XALI journey! Choose a plan that suits you best and after your {{trialDuration}} you will automatically be billed. Cancel anytime within your {{trialDuration}} and you will not be charged.</p>
            <p class="tracking-tight font-medium-bold mb-4">Start your individualised journey with unlimited access to: </p>
            <ul class="leading-7 text-sm flex">
              <li class="flex items-center pr-2">
                <ion-icon
                  :icon="checkmarkCircleOutline"
                  size="small"
                  :color="'primary'"
                  class="mr-2"
                ></ion-icon>
                <span><b>Move</b></span>
              </li>
              <li class="flex items-center pr-2">
                <ion-icon
                  :icon="checkmarkCircleOutline"
                  size="small"
                  :color="'primary'"
                  class="mr-2"
                ></ion-icon>
                <span><b>Eat</b></span>
              </li>
            </ul>
             <ul class="leading-7 mb-6 text-sm flex">
             
              <li class="flex items-center pr-2">
                <ion-icon
                  :icon="checkmarkCircleOutline"
                  size="small"
                  :color="'primary'"
                  class="mr-2"
                ></ion-icon>
                <span><b>Learn</b></span>
              </li>
              <li class="flex items-center pr-2">
                <ion-icon
                  :icon="checkmarkCircleOutline"
                  size="small"
                  :color="'primary'"
                  class="mr-2"
                ></ion-icon>
                <span><b>Connect</b></span>
              </li>
            </ul>
            <div v-for="(item, i) in packages" :key="i">
              <div
                class="rounded-md mb-6 relative cursor-pointer py-2"
                @click="changePlan(item.order)"
                :class="plan == item.order ? 'border-2' : 'border'"
              >
              
                <div
                  class="
                    bg-primary
                    absolute
                    w-full
                    h-full
                    left-0
                    top-0
                    opacity-50
                    z-1
                  "
                  v-if="plan == item.order"
                ></div>

                
                <span v-if="item.popular" class="
                    absolute
                    right-4
                    -top-4 
                    z-3 bg-primary block rounded-md p-1 text-sm px-3"> Most popular </span>
                <div
                  class="
                    p-3
                    px-5
                    flex
                    items-center
                    justify-between
                    relative
                    z-2
                  "
                >
                  <span
                    class="rounded-full inline-block relative w-8 h-8"
                    :class="plan == item.order ? 'bg-primary border-2' : 'border'"
                  >
                    <ion-icon
                      :icon="checkmarkOutline"
                      size="meduim"
                      :color="'white'"
                      class="absolute top-0 bottom-0 right-0 left-0 m-auto"
                      v-if="plan == item.order"
                    >
                    </ion-icon>
                  </span>
                  <div class="px-2 leading-4">
                    <b>
                      <p class="m-0">{{ item.month }}</p>
                    </b>
                    <p class="text-xs w-32">{{ item.description }}</p>
                  </div>
                  <div class="flex items-center">
                    <h2 class="text-2xl font-bold tracking-tight">
                      {{ item.price }}
                    </h2>
                    <span class="px-0.5">/</span>
                    <span class="text-xs">month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div class="mt-5 w-full bottom-5 px-6">
              <a v-bind:href="packageUrl" class="my-auto ml-auto relative">
                <ion-button
                  expand="block"
                  color="white"
                  class="text-xl mb-4 tracking-tight"
                  type="submit"
                >
                  <span class="p-4 flex text-black">Select this plan</span>
                </ion-button>
              </a>

              <p class="text-sm tracking-tight mb-2  " :class="iOS ? 'ios-spacing' : ''">
                Subscriptions automatically renew unless you turn off
                auto-renew at least 24 hours before the end of the billing
                period.
              </p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </container-cover>
</template>
<script>
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  checkmarkCircleOutline,
  checkmarkOutline,
} from "ionicons/icons";
import _ from "lodash";
import { iconLogo } from "@/_helpers/icons";
import { setHtmlPageTitle } from "@/_helpers";
import { useRoute } from "vue-router";
import router from "@/router";

export default {
  components: {},
  data() {
    return {
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      iconLogo,
      chevronBack,
      checkmarkCircleOutline,
      checkmarkOutline,
      plan: 3,
      packageUrl: `/subscription/choose-plan/3/`,
      trialDuration: "free trial",
      packages: [
        {
          order: 1,
          month: "1 month",
          price: "$20",
          description: "$20 billed monthly",
          popular: false,
        },
        {
          order: 2,
          month: "3 months",
          price: "$15",
          description: "$45 billed quarterly",
          popular: false,
        },
        {
          order: 3,
          month: "12 months",
          price: "$9",
          description: "$108 billed anually",
          popular: true,
        },
      ],
    };
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    userData() {
      return this.$store.getters["accounts/getUser"];
    }
  },
  beforeMount() {
    const route = useRoute();
    if (route.query.token) {
        this.$store.dispatch("accounts/switchApp", route.query.token.replace("/", "")).then(res => {
          if(res.status == 200){
            this.$store.dispatch("accounts/getMe").then(result => {
              this.$store.commit("accounts/setUser", result.data);
            })
            
            setTimeout(() => {
              router.push(route.path);
            }, 1000);
          }else{
            router.push(route.path);
          }
      }).catch(error => {
        console.log(error)
        router.push(route.path);
      });
    }
  },
  async mounted() {

    const route = useRoute();
    if (route.query.coupon) {
      this.loading = true
      this.$store
        .dispatch(`pages/getCoupon`, route.query.coupon)
        .then((res) => {
          this.trialDuration = `${res.interval} ${res.duration} trial` 
        });
    }

    this.$store.dispatch("accounts/getMe").then(result => {
      console.log(result)
      this.$store.commit("accounts/setUser", result.data);
    })
    //this.packages = this.packages.reverse()    
    setHtmlPageTitle('Choose a plan');
    this.$store.dispatch(`accounts/getProduct`).then((res) => {
      if (!_.isEmpty(res)) {
        const prices = res[0].prices;
        if (prices) {
          console.log("w--", prices);
        }
      }
    });
  },
  methods: {
    changePlan(plan) {
      this.packageUrl = `/subscription/choose-plan/${plan}/`;
      this.plan = plan;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-container {
  min-height: 100%;
  overflow-y: auto;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.9);
  background-size: cover !important;
  background-position: center !important;
  color: #fff;

  @media (max-width: 768px) {
    background: url("../../../public/assets/images/signupbg.jpg");
  }
  .logo {
    width: 150px;
  }
}
</style>
