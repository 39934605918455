<template>
  <ion-content :fullscreen="true" class="z-40">
    <container-cover>
      <div class="bg-white flex h-full">
        <div class="subscription-container relative w-full lg:pt-10" v-if="isOpen">
          <div class="md:px-16 lg:px-22 xl:px-44">
            <div class="body-content mt-20 px-6">
              <ion-img
                :src="'/assets/images/logo-white.svg'"
                class="logo md:block"
              ></ion-img>
              <p class="mt-10 text-xl tracking-tight">Hi {{name}},</p>
              <h2
                class="text-4.5xl font-bold tracking-tight leading-8 my-4 mb-10"
              >
                Your 14 day free trial has just ended!
              </h2>
              <p class="mb-4 tracking-tight">
                We've loved having you in our community, and we hope you've
                enjoyed your Xali experience.
              </p>
              <p class="tracking-tight">
                We'd like to invite you to subscribe so you can continue
                accessing our premium content, supporting you on your journey.
              </p>
            </div>
            <footer>
              <div class="w-full px-6 mt-10">
                <a
                  v-bind:href="'/subscription/choose-plan/'"
                  class="my-auto ml-auto relative"
                >
                  <ion-button
                    expand="block"
                    color="white"
                    class="text-xl mb-4 tracking-tight"
                    type="submit"
                  >
                    <span class="p-4 flex text-black">Choose Plan</span>
                  </ion-button>
                </a>

                <a v-bind:href="'/logout/'" class="my-auto ml-auto relative">
                  <ion-button
                    expand="block"
                    color="black"
                    class="text-xl tracking-tight"
                    type="submit"
                  >
                    <span class="p-4 flex">Not right now</span>
                  </ion-button>
                </a>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </container-cover>
  </ion-content>
</template>
<script>
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
} from "ionicons/icons";

import { iconLogo } from "@/_helpers/icons";
import {setHtmlPageTitle } from "@/_helpers";

export default {
  name: "SubscriptionExpired",
  data() {
    return {
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      iconLogo,
      isOpen: true,
      name:''
    };
  },
  async mounted(){
    setHtmlPageTitle('Subscription Expired');
    const userData = await this.$store.getters["accounts/getUser"];
    this.name = userData.first_name
  },
  methods: {
    closeSubscription() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-container {
  top: 0;
  z-index: 999;
  min-height: 100%;
  background-size: cover!important;
  background-position: center!important;
  overflow-y: auto;
  color: #000;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.9);
  color: #fff;

  @media (max-width: 768px) {
    background: url("../../../public/assets/images/signupbg.jpg");
  }

  .logo {
    width: 150px;
  }
}
</style>
