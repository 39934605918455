<template>
  <div
    class="
      journey-card
      w-full
      rounded-2lg
      bg-gray-300
      p-5
      flex
      items-center
      justify-center
      overflow-hidden
      text-white
      -mt-4
      cursor-pointer
    "
  >
    <div class="cover-image w-full h-full">
      <ion-img
        :src="'/assets/images/eat/recipe-card-4.jpg'"
        class="h-full w-full md hydrated"
      ></ion-img>
    </div>
    <!--v-if-->
    <div class="content w-full h-full flex flex-col">
      <div class="details text-left mt-auto cursor-pointer">
        <h2 class="title text-2xl font-semibold mb-1 flex">
          <span>{{ recipe_message }}</span>
          <div class="flex my-auto ml-6 xl:ml-16 text-2xl xl:text-3xl">
            <ion-icon
              :icon="chevronForward"
              class="block opacity-10 -ml-3 xl:-ml-4"
            ></ion-icon>
            <ion-icon
              :icon="chevronForward"
              class="block opacity-30 -ml-3 xl:-ml-4"
            ></ion-icon>
            <ion-icon
              :icon="chevronForward"
              class="block opacity-70 -ml-3 xl:-ml-4"
            ></ion-icon>
            <ion-icon
              :icon="chevronForward"
              class="block -ml-3 xl:-ml-4"
            ></ion-icon>
          </div>
        </h2>
        <div class="description text-text-lg mb-3">
          Let Sam choose your 3 daily recipes
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { chevronForward } from "ionicons/icons";
import { IonImg, IonIcon } from "@ionic/vue";

export default defineComponent({
  props: {},
  components: { IonImg, IonIcon },
  setup() {
    return { chevronForward };
  },
  data() {
    return {
      recipe_message: "What’s Cooking?",
    };
  },
});
</script>

<style lang="scss" scoped>
.journey-card {
  position: relative;
  height: 400px;
  .cover-image {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-size: cover;
    border-radius: 0.625rem;
    ion-img {
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      z-index: 1;
      position: absolute;
      top: 0px;
      left: 0px;
    }
    &::after {
      background: transparent
        linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
        padding-box;
      content: "";
      height: 160px;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      opacity: 0.45;
      z-index: 10;
      border-radius: 0.625rem;
    }
  }
  .content {
    z-index: 3;
    .title {
      text-shadow: 0px 3px 6px #00000029;
    }
  }
  .play-button {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  @screen md {
    height: 580px;
  }
}

.journey-card {
  &.activity {
    &:hover {
      .cover-image {
        // ion-img {
        // }
        .video-preview {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
</style>

