
<template>
  <div class="subscription-container relative">
    <header class="pt-8 px-12">
      <ion-icon
        :icon="checkmarkCircle"
        size="large"
        :color="'white'"
      ></ion-icon>
    </header>

    <div class="body-content mt-8 px-12">
      <p class="text-xl tracking-tight">Thank you Emily!</p>
      <h2 class="text-3xl font-bold tracking-tight leading-8 my-4 mb-8">
        Your payment has been successful.
      </h2>

      <p class="mb-4 tracking-tight text-md">
        You are now part of Team Xali. Let’s get into it!
      </p>
    </div>

    <footer>
      <div class="absolute w-full bottom-5 px-12">
        <p class="mb-4 text-sm leading-6">
          <b>Important:</b>
          <br />
          You will be charged through your iTunes account at confirmation of
          purchase. Subscriptions automatically renew unless you turn off
          auto-renew at least 24 hours before the end of the billing period.
          <br />
          <br />
          For any subscription queries, head to our T&Cs.
        </p>
        <ion-button
          expand="block"
          color="white"
          text="black"
          class="text-xl mb-4 tracking-tight"
          type="submit"
        >
          <span class="p-4 flex text-black">Go to my dashboard</span>
        </ion-button>
      </div>
    </footer>
  </div>
</template>
<script>
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  checkmarkCircle,
} from "ionicons/icons";

import { iconLogo } from "@/_helpers/icons";

export default {
  components: {},
  data() {
    return {
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      iconLogo,
      chevronBack,
      checkmarkCircle,
      plan: {
        month: "1 month",
        price: "$80",
        description: "Cancel anytime after",
      },
    };
  },
  methods: {
    changePlan(plan) {
      this.plan = plan;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-container {
  height: 100vh;
  background: url("../../public/assets/images/journey-bg.jpg");
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.9);
  background-size: cover;
  background-position: center;
  overflow-y: auto;
  color: #fff;
  .scroll-y {
    background: red;
  }
  .logo {
    width: 150px;
  }
}
</style>
