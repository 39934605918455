<template>
  <router-link
    :to="link"
    v-if="!isExternal(link) && route !=='learn'"
    class="w-full h-full internal-Link"
  >
    <CarouselCard v-bind:data="data" @setSelect="selected"></CarouselCard>
  </router-link>
  <a :href="link" v-if="isExternal(link) && route !=='learn'" class="w-full h-full external-Link">
    <CarouselCard v-bind:data="data" @setSelect="selected"></CarouselCard>
  </a>

  <a @click="openLearn(link)" v-if="route ==='learn'" class="w-full h-full external-Link cursor-pointer">
    <CarouselCard v-bind:data="data" @setSelect="selected"></CarouselCard>
  </a>
</template>

<script>
import CarouselCard from "@/components/pages/carousel/CarouselCard.vue";
import { defineComponent } from "vue";
import _ from "lodash";
import { trackingTrigger } from "@/_helpers";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          level: false,
          name: "",
          tags: [],
          cover: "",
          preview: "/assets/images/fitness/style/core.mp4",
          favourite: true,
          browse: false,
        };
      },
    },
  },
  components: { CarouselCard },
  emits: ["setSelect"],
  setup() {
    return {};
  },
  data() {
    return {
      route: this.$route.name.toLowerCase(),
      link: _.has(this.data, "link") ? this.data.link : "/",
    };
  },
  methods: {
    openLearn(link){
      var learn = this.data
      console.log()
      console.log()
      var trackingInfo = {
        post_id: learn.id,
        category: learn.categories,
        id: learn.id,
      }
      trackingTrigger("blog-post-open", trackingInfo);
      setTimeout(() => {
        window.open(link);
      }, 500);

    },
    isExternal: function (link = "") {
      if (link.includes("http")) return true;
    },
    selected() {
      this.$emit("setSelect");
    },
  },
  watch: {
    data(newData) {
      this.link = _.has(newData, "link") ? newData.link : "/";
    },
  },
});
</script>




