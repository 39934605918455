/* eslint-disable */
import { api, getGraphQl, gql } from "@/api/config";
import router from "@/router";
import VueCookies from "vue-cookies";
import _ from "lodash";

export default {
  // Accounts Related
  validateSwitchToken(token) {
    return api.get(`/users/token/validate/${token}/`).catch(function (error) {
      return error.response;
    });
  },
  login(data) {
    console.log("data", data)
    return api.post(`/auth/token/`, data).catch(function (error) {
      return error.response;
    });
  },
  async forgotPassword(data) {
    return await api
      .post(`/users/forget/password/`, data)
      .then(function (res) {
        return res;
      })
      .catch(function (error) {
        return error.response;
      });
  },
  async validateResetPasswordToken(data) {
    return await api
      .post(`/users/validate/token/`, data)
      .catch(function (error) {
        return error.response;
      });
  },
  async resetPassword(data, token) {
    return await api
      .post(`/users/reset/password/${token}/`, data)
      .catch(function (error) {
        return error.response;
      });
  },
  subscribe(data) {
    return api.post(`/payment/subscription/`, data.payload).catch(function (error) {
      return error.response;
    });
  },
  subscribeWithPromoCode(data) {
    return api.post(`/payment/subscription/?promo_code=${data.coupon}`, data.payload).catch(function (error) {
      return error.response;
    });
  },
  cancelSubscription(data) {
    return api.post(`/payment/cancel/`, data).catch(function (error) {
      return error.response;
    });
  },
  getCoupon(coupon) {
    return api.get(`settings/coupon/${coupon}/`).catch(function (error) {
      return error.response;
    });
  },
  getPromoCode(data) {
    return api.get(`settings/promotion-code/${data.coupon}/?plan=${data.plan}`).catch(function (error) {
      return error.response;
    });
  },
  signup(coupon, data) {
    var url = coupon ? `/users/signup/?coupon=${coupon}` : `/users/signup/`
    return api
      .post(url,
        _.pick(data, ["first_name", "last_name", "email", "password"])
      )
      .then(function (res) {
        if (res.status == 201) return res;
        return res.response;
      })
      .catch(function (error) {
        return error.response;
      });
  },
  async confirmEmail(token) {
    return await api
      .post(`/users/confirm/email/${token}/`)
      .catch(function (error) {
        return error.response;
      });
  },
  me() {
    return api.get(`/users/detail/me/`).catch(function (error) {
      return error.response;
    });
  },
  refresh(token) {
    return api
      .post(`/auth/token/refresh/`, { refresh: token })
      .then(function (res) {
        if (res.response) return res.response;
        if (res) return res;
      })
      .catch(function (error) {
        return error.response;
      });
  },
  favourite(type, action = "add", ids = []) {
    return api
      .post(`/users/favourite/${type}/`, { action: action, ids: ids })
      .catch(function (error) {
        return error.response;
      });
  },
  favourites(type) {
    return api.get(`/users/favourite/${type}/`).catch(function (error) {
      return error.response;
    });
  },
  async fbLoginSignup(coupon, data) {
    var url = coupon ? `/users/signup/social/?coupon=${coupon}` : `/users/signup/social/`
    return await api.post(url, data);
  },
  async saveUsersJourney(data) {
    return await api.post(`/users/detail/journey/`, data);
  },
  async setRecommendedActivity(data) {
    if (data.isAdapt) {
      return await api.post(`/activities/recommend/?adapt=true`, { emotion: data.emotion });
    } else {
      return await api.post(`/activities/recommend/`, { emotion: data.emotion });
    }
  },
  async setRecommendedActivityStatus(data) {
    return await api.post(`/activities/recommend/status/`, data);
  },
  async setRecommendedAdapt(data) {
    return await api.post(`/activities/recommend/adapt/`, data);
  },
  async setRecommendedRecipe(data) {
    return await api.post(`/recipes/recommend/`, data);
  },
  async getEquipments() {
    return await api
      .get(`/activities/equipment/categories/?limit=20`)
      .catch(function (error) {
        return error.response;
      });
  },
  async searchActivity(search) {
    return await api
      .get(`/activities/app-search/?search=${search}`)
      .catch(function (error) {
        return error.response;
      });
  },
  getLearn(id = null) {
    return getGraphQl().query({
      query: gql`
        query{
          allLearn{
            edges{
              node{
                id,
                title,
                postId,
                featuredImage,
                link,
                categories,
                tags,
                pk
              }
            }
          }
        }
      `
    });
  },
  getProduct() {
    return getGraphQl().query({
      query: gql`
        query{
          product{
            edges{
              node{
                name
                active
                description
                uid
                prices{
                  id
                  pk
                  price
                  nickname
                  currency
                  recurringInterval
                  recurringIntervalCount
                  active
                  uid
                }
                pk
              }
            }
          }
        }
      `
    });
  },
  async searchData(ids) {
    let activities = ``;
    if (!_.isEmpty(ids)) {
      ids.forEach(id => {
        activities += `
          activity_${id} : activity(id:${id}){
            id
            workingTitle
            levels {
              value
            }
            tag
            videocomponentWorkout {
              id,
              longDescription,
              shortDescription,
              renderType,
              brightcoveId,
              duration
            }
            type,
            favourite,
            postProduction{
              id,
              landscapeThumbnail,
              landscapeVideo,
              portraitVideo,
              portraitThumbnail
            },
            primaryLevel{
              id,
              value,
              label
            },
            series {
              id, label
            }
          }
        `;
      });
    }
    return getGraphQl().query({
      query: gql`
        query {
            ${activities}
        }
      `
    });
  },

  async searchRecipes(search) {
    return await api
      .get(`/recipes/search/?search=${search}`)
      .catch(function (error) {
        return error.response;
      });
  },
  async searchDataRecipes(ids) {
    let recipes = ``;
    if (!_.isEmpty(ids)) {
      ids.forEach(id => {
        recipes += `
          recipe_${id} : recipe(id:${id}){
            id
            title
            video
            tag,
            skillLevels{
              id,
              label,
              value
            }
            featuredImagePortrait,
            featuredImageLandscape,
            favourite,
            prepTime,
            cookTime
          }
        `;
      });
    }
    return getGraphQl().query({
      query: gql`
        query {
            ${recipes}
        }
      `
    });
  },

  // Activity Related
  getInitialActivity(level = null, preferences = [], ids = []) {
    let levelParam = level ? `, primaryLevel:${level}` : ``;
    let recipeQ = `
      mostFavouriteRecipe(last: 5) {
        edges {
          node {
            id
            title
            video
            tag,
            skillLevels{
              id,
              label,
              value
            }
            featuredImagePortrait,
            featuredImageLandscape,
            favourite,
            prepTime,
            cookTime
          }
        }
      }
    `;
    if (!_.isEmpty(preferences)) {
      recipeQ = `
        allRecipe(dietaryPreferences: [${preferences.join()}], last: 5){
          edges {
            node {
              id
              title
              video
              tag,
              skillLevels{
                id,
                label,
                value
              }
              featuredImagePortrait,
              featuredImageLandscape,
              favourite,
              prepTime,
              cookTime
            }
          }
        }
      `;
    }

    let allActivity = `
      allActivity(last: 5, type: "workout" ${levelParam}) {
        edges {
          node {
            id
            workingTitle
            levels {
              value
            }
            tag
            videocomponentWorkout {
              id,
              longDescription,
              shortDescription,
              renderType,
              brightcoveId,
              duration
            }
            type,
            favourite,
            postProduction{
              id,
              landscapeThumbnail,
              landscapeVideo,
              portraitVideo,
              portraitThumbnail
            },
            primaryLevel{
              id,
              value,
              label
            },
            series {
              id, label
            },
          }
        }
      }
    `;

    // Overwrite activity with provided id
    if (!_.isEmpty(ids)) {
      allActivity = ``;
      ids.forEach(id => {
        allActivity += `
          activity_${id} : activity(id:${id}){
            id
            workingTitle
            levels {
              value
            }
            tag
            videocomponentWorkout {
              id,
              longDescription,
              shortDescription,
              renderType,
              brightcoveId,
              duration
            }
            type,
            favourite,
            postProduction{
              id,
              landscapeThumbnail,
              landscapeVideo,
              portraitVideo,
              portraitThumbnail
            },
            primaryLevel{
              id,
              value,
              label
            },
            series {
              id, label
            }
          }
        `;
      });
    }
    return getGraphQl().query({
      query: gql`
        query {
          recipe: ${recipeQ}
          ${allActivity}
        }
      `
    });
  },
  getJourneyQuestions() {
    return getGraphQl().query({
      query: gql`
        query {
          activityFieldOptions(optionFor: "LVL") {
            edges {
              node {
                id
                value
                label
              }
            }
          }
          allJourney {
            id
            label
            value
          }
          dietaryPreferenceCategories {
            edges {
              node {
                id
                label
                value
              }
            }
          }
          dietaryPreferences {
            edges {
              node {
                id
                label
                value
                category {
                  id
                }
              }
            }
          }
        }
      `
    });
  },
  getActivityCategory() {
    return getGraphQl().query({
      query: gql`
        query {
          exploreByStyle(Type: "workout") {
            id
            value
            label
            count
            optionFor
          }
          exploreByBodyPart(Type: "workout") {
            id
            value
            label
            count
            optionFor
          }
          exploreByMode(Type: "workout") {
            id
            value
            label
            count
            optionFor
          }
        }
      `
    });
  },
  async getTotalCountByActivity(args = "warmup") {
    if (!args.includes(":")) args = `type:"${args}"`;

    return await getGraphQl().query({
      query: gql`
        query {
          allActivity(${args}) {
            totalCount
          }
        }
      `
    });
  },
  async getTotalCountByFavourite(type) {
    if (type == "activity") {
      const response = await getGraphQl().query({
        query: gql`
          query {
            favouriteActivity {
              totalCount
            }
          }
        `
      });

      return response && response.data
        ? response.data.favouriteActivity.totalCount
        : 0;
    }
    if (type == "recipe") {
      const response = await getGraphQl().query({
        query: gql`
          query {
            favouriteRecipe {
              totalCount
            }
          }
        `
      });
      return response && response.data
        ? response.data.favouriteRecipe.totalCount
        : 0;
    }
    if (type == "learn") {
      const response = await getGraphQl().query({
        query: gql`
          query {
            userFavourites {
              learn {
                totalCount
              }
            }
          }
        `
      });
      // console.log("response", response);
      return response && response.data && response.data.userFavourites && response.data.userFavourites.learn.totalCount
        ? response.data.userFavourites.learn.totalCount
        : 0;
    }

  },
  async getTotalCountByHistory(type) {
    if (type == "activity") {
      const response = await getGraphQl().query({
        query: gql`
          query {
            activityHistory {
              totalCount
            }
          }
        `
      });

      return response && response.data
        ? response.data.activityHistory.totalCount
        : 0;
    }
    if (type == "recipe") {
      const response = await getGraphQl().query({
        query: gql`
          query {
            recipeHistory {
              totalCount
            }
          }
        `
      });
      return response && response.data
        ? response.data.recipeHistory.totalCount
        : 0;
    }
  },
  async getTotalCountFavouriteActivity() {
    return await getGraphQl().query({
      query: gql`
        query {
          allActivity {
            totalCount
          }
        }
      `
    });
  },
  getAllActivity(args = "workout") {
    if (!args.includes(":")) args = `type:"${args}"`;

    return getGraphQl().query({
      query: gql`
        query {
          allActivity(${args}){
            pageInfo{
              hasNextPage,
              hasPreviousPage,
            },
            totalCount,
            edges{
              node{
                id,
                type,
                workingTitle,
                favourite,
                levels {value},
                videocomponentWorkout{
                  id,
                  longDescription,
                  shortDescription,
                  renderType,
                  brightcoveId,
                  duration
                },
                tag,
                bodyPart {
                  id,
                  value,
                  label
                },
                mode{
                  id,
                  value,
                  label
                },
                postProduction{
                  id,
                  landscapeThumbnail,
                  landscapeVideo,
                  portraitVideo,
                  portraitThumbnail
                },
                primaryLevel{
                  id,
                  value,
                  label
                },
                series {
                  id, label
                }
              }
            }
          }
        }
      `
    });
  },
  getActivity(id) {
    return getGraphQl().query({
      query: gql`
        query {
          activityPostProduction(activity_Id:${id}){
            edges{
              node{
                id,
                landscapeThumbnail,
                landscapeVideo,
                portraitVideo,
                portraitThumbnail
              }
            }
          },
          activity(id:${id}){
            id,
            type,
            workingTitle,
            created,
            modified,
            favourite,
            levels{
              id,value,label
            }
            tag,
            spotifyPlaylistName,
            spotifyPlaylistLink,
            applePlaylistName,
            applePlaylistLink,
            withEquipment,
            needEquipment,
            cautions{
              id,
              label,
              value,
              count
            }
            notes,
            videocomponentWorkout{
              id,
              longDescription,
              shortDescription,
              renderType,
              brightcoveId,
              duration
            },
            postProduction{
              id,
              landscapeThumbnail,
              landscapeVideo,
              portraitVideo,
              portraitThumbnail
            },
            primaryLevel{
              id,
              value,
              label
            },
            selectedEquipments{
              selectedOption,
              equipment{
                id,
                key,
                label,
                additionalLabel
                category{
                  id,
                  key,
                  label
                }
              }
            }
          }
        }
      `
    });
  },
  getRecipeCategory(dietaryPreferences) {
    // console.log(dietaryPreferences)
    let param = `last: 5`;
    if (dietaryPreferences) param = `last: 5, dietaryPreferences:[${dietaryPreferences}]`;
    return getGraphQl().query({
      query: gql`
        query {
          mostFavouriteRecipe(${param}) {
            edges {
              node {
                id
                title
                video
                tag
                skillLevels {
                  id
                  label
                  value
                }
                featuredImagePortrait
                featuredImageLandscape
                favourite
                prepTime
                cookTime
              }
            }
          }
          type: exploreByMealTime {
            edges {
              node {
                id
                value
                label
                count
              }
            }
          }
          category: exploreByCategory {
            edges {
              node {
                id
                value
                label
                count
              }
            }
          }
          benefits: exploreByEffects {
            edges {
              node {
                id
                value
                label
                count
              }
            }
          }
          level: exploreBySkillLevel {
            edges {
              node {
                id
                value
                label
                count
              }
            }
          }
        }
      `
    });
  },
  getAllRecipe(args = "") {
    if (!args == "") args = `(${args})`;

    return getGraphQl().query({
      query: gql`
        query {
          allRecipe${args}{
            totalCount
            edgeCount,
            pageInfo{
              hasNextPage,
            }
            edges{
              node{
                id,
                title,
                author,
                video,
                tag,
                featuredImagePortrait,
                featuredImageLandscape,
                favourite,
                prepTime,
                cookTime,
                mealTimes{
                  edges{
                    node{
                      id,
                      value,
                      label
                    }
                  }
                },
                categories{
                  edges{
                    node{
                      id,
                      value,
                      label
                    }
                  }
                },
                effects{
                  edges{
                    node{
                      id,
                      value,
                      label
                    }
                  }     
                },
                skillLevels{
                  id,
                  value,
                  label
                },
                intentions{
                  edges{
                    node{
                      id,
                      value,
                      label
                    }
                  }     
                },
              }
            }
        }  
      }
      `
    });
  },
  getRecipeByID(ids = []) {
    if (_.isEmpty(ids)) return false;

    return getGraphQl().query({
      query: gql`
        query{
          recipeByIds(ids: [${ids.join()}] ){
            edges {
              node {
                id
                title
                video
                tag
                featuredImagePortrait,
                featuredImageLandscape,
                favourite,
                prepTime,
                cookTime
              }
            }
          }
        }
      `
    });
  },
  async getRecipe(id) {
    let gqlresponse = await getGraphQl().query({
      query: gql`
        query {
            recipe(id:${id}){
              relatedRecipes{
                edges{
                  node{
                    id,
                    title,
                    author,
                    video,
                    tag,
                    featuredImagePortrait,
                    featuredImageLandscape,
                    favourite,
                    prepTime,
                    cookTime,
                    mealTimes{
                      edges{
                        node{
                          id,
                          value,
                          label
                        }
                      }
                    },
                    categories{
                      edges{
                        node{
                          id,
                          value,
                          label
                        }
                      }
                    },
                    effects{
                      edges{
                        node{
                          id,
                          value,
                          label
                        }
                      }     
                    },
                    skillLevels{
                      id,
                      value,
                      label
                    },
                    intentions{
                      edges{
                        node{
                          id,
                          value,
                          label
                        }
                      }     
                    },
                  }
                }
              },
              id,
              title,
              author,
              featuredImagePortrait,
              featuredImageLandscape,
              favourite,
              tag,
              prepTime,
              cookTime,
              skillLevels{
                id,
                label
              },
              description,
              storage{
                edges{
                  node{
                    id,
                    value,
                    label
                  }
                }
              }
              serves,
              minimumServing,
               maximumServing,
              ingredientGroups{
                id,
                name,
                position,
                items{
                  id,
                  position,
                  amount,
                  note,
                  ingredient{
                    name,
                    unit {
                      id,
                      name
                    },
                    image
                  }
                }
              },
              instructionGroups{
                id,
                name,
                instructions{
                  id,
                  instruction
                }
              }
          }
        }
      `
    });

    // *******
    // Remove this REST request once all property is on graphql
    // let res = await api.get(`/recipes/recipe/${id}`).catch(function(error) {
    //   return error.response;
    // });
    // gqlresponse.data.recipe = _.merge(res.data, gqlresponse.data.recipe);

    return gqlresponse;
  },
  getIngredient() {
    // Save Temporary ****
    let response = VueCookies.get("temp_ingredients");
    if (response) return response;

    return api
      .get(`/recipes/ingredient/?limit=999`)
      .then(response => {
        VueCookies.set("temp_ingredients", response);
        return response;
      })
      .catch(function (error) {
        return error.response;
      });
  },
  getActivityStretchflow() {
    return getGraphQl().query({
      query: gql`
        query {
          latest: allActivity(last: 10, type: "stretch_flow") {
            edges {
              node {
                id
                workingTitle
                levels {
                  value
                }
                tag
                videocomponentWorkout {
                  id
                  longDescription
                  shortDescription
                  renderType
                  brightcoveId
                  duration
                }
                type
                favourite
                postProduction {
                  id
                  landscapeThumbnail
                  landscapeVideo
                  portraitVideo
                  portraitThumbnail
                }
                primaryLevel {
                  id
                  value
                  label
                },
                series {
                  id, label
                }
              }
            }
          }
          activity: allActivity(last: 99, type: "stretch_flow") {
            edges {
              node {
                id
                workingTitle
                levels {
                  value
                }
                tag
                videocomponentWorkout {
                  id
                  longDescription
                  shortDescription
                  renderType
                  brightcoveId
                  duration
                }
                type
                favourite
                postProduction {
                  id
                  landscapeThumbnail
                  landscapeVideo
                  portraitVideo
                  portraitThumbnail
                }
                primaryLevel {
                  id
                  value
                  label
                }
                series {
                  id
                  label
                }
              }
            }
          }
        }
      `
    });
  },
  favouriteRecipes(args) {
    return getGraphQl().query({
      query: gql`
        query {
          favouriteRecipe(${args}) {
            edges {
              node {
                id
                title
                video
                tag
                featuredImagePortrait
                featuredImageLandscape
                favourite,
                prepTime,
                cookTime
              }
            }
          }
        }
      `
    });
  },
  async favouriteActivities(args) {
    return await getGraphQl().query({
      query: gql`
        query {
          favouriteActivity(${args}) {
            edges {
              node {
                id
                workingTitle
                levels {
                  value
                }
                tag
                videocomponentWorkout {
                  id,
                  longDescription,
                  shortDescription,
                  renderType,
                  brightcoveId,
                  duration
                }
                type
                favourite
                postProduction {
                  id
                  landscapeThumbnail
                  landscapeVideo
                  portraitVideo
                  portraitThumbnail
                },
                primaryLevel{
                  id,
                  value,
                  label
                }
              }
            }
          }
        }
      `
    });
  },

  favouriteLearn() {
    return getGraphQl().query({
      query: gql`
        query {
          userFavourites{
            learn {
              edges {
                node {
                  id,
                  title,
                  postId,
                  featuredImage,
                  link,
                  categories,
                  tags,
                  pk
                }
              }
            }
          }
        }
      `
    });
  },
  async fieldOptions() {
    return await getGraphQl().query({
      query: gql`
        query {
          activityFieldOptions {
            edges {
              node {
                pk
                value
                label
                optionFor
              }
            }
          }
        }
      `
    });
  },
  async recipeFieldOptions() {
    return await getGraphQl().query({
      query: gql`
        query {
          recipeFieldOptions {
            edges {
              node {
                pk
                value
                label
                optionFor
              }
            }
          }
        }
      `
    });
  },

  filterActivity(args) {
    return getGraphQl().query({
      query: gql`
      query {
        allActivity(${args}){
          pageInfo{
            hasNextPage,
            hasPreviousPage,
          },
          totalCount,
          edges{
            node{
              id,
              type,
              workingTitle,
              favourite,
              levels {value},
              videocomponentWorkout{
                id,
                longDescription,
                shortDescription,
                renderType,
                brightcoveId,
                duration
              },
              tag,
              bodyPart {
                id,
                value,
                label
              },
              mode{
                id,
                value,
                label
              },
              postProduction{
                id,
                landscapeThumbnail,
                landscapeVideo,
                portraitVideo,
                portraitThumbnail
              },
              primaryLevel{
                id,
                value,
                label
              },
              series {
                id, label
              }
            }
          }
        }
      }
      `
    });
  },

  filterRecipe(args = "") {
    if (!args == "") args = `(${args})`;
    return getGraphQl().query({
      query: gql`
        query {
          allRecipe${args}{
            totalCount
            edgeCount,
            pageInfo{
              hasNextPage,
            }
            edges{
              node{
                id,
                title,
                author,
                video,
                tag,
                featuredImagePortrait,
                featuredImageLandscape,
                favourite,
                prepTime,
                cookTime,
                mealTimes{
                  edges{
                    node{
                      id,
                      value,
                      label
                    }
                  }
                },
                categories{
                  edges{
                    node{
                      id,
                      value,
                      label
                    }
                  }
                },
                effects{
                  edges{
                    node{
                      id,
                      value,
                      label
                    }
                  }     
                },
                skillLevels{
                  id,
                  value,
                  label
                },
                intentions{
                  edges{
                    node{
                      id,
                      value,
                      label
                    }
                  }     
                },
              }
            }
        }  
      }
      `
    });
  },

  getDietaryPreferencesOptions() {
    return getGraphQl().query({
      query: gql`
        query {
          dietaryPreferenceCategories {
            edges {
              node {
                id
                label
                value
                preferences(last: 30){
                  edges{
                    node{
                        id
                        label
                        value
                    }
                  }
                }
              }
            }
          }
          dietaryPreferences {
            edges {
              node {
                id
                label
                value
                category {
                  id
                }
              }
            }
          }
        }
      `
    });
  },
  activityHistory(args) {
    return getGraphQl().query({
      query: gql`
        query{
          activityHistory(${args}) {
            edges {
              node {
                id
                count
                lastViewed
                workingTitle
                levels {
                  value
                }
                tag
                videocomponentWorkout {
                  id,
                  longDescription,
                  shortDescription,
                  renderType,
                  brightcoveId,
                  duration
                }
                type
                postProduction {
                  id
                  landscapeThumbnail
                  landscapeVideo
                  portraitVideo
                  portraitThumbnail
                },
                primaryLevel{
                  id,
                  value,
                  label
                }
              }
            }
          }
        }
      `
    });
  },
  recipeHistory(args) {
    return getGraphQl().query({
      query: gql`
        query {
          recipeHistory(${args}) {
            edges {
              node {
                id
                count
                lastViewed
                title
                video
                tag
                featuredImagePortrait
                featuredImageLandscape
                prepTime,
                cookTime
              }
            }
          }
        }
      `
    });
  },
  async getTotalCountPopularRecipe() {
    return await getGraphQl().query({
      query: gql`
      query {
        mostFavouriteRecipe {
          totalCount
        }
      }
      `
    });
  },
  getPopularRecipes(args) {
    return getGraphQl().query({
      query: gql`
        query {
          mostFavouriteRecipe(${args}) {
            edges {
              node {
                id
                title
                video
                tag
                skillLevels {
                  id
                  label
                  value
                }
                featuredImagePortrait
                featuredImageLandscape
                favourite
                prepTime
                cookTime
              }
            }
          }
        }
      `
    });
  },
  async updateTimezone(data) {
    return await api
      .post(`/users/detail/timezone/`, data)
      .then(function (res) {
        return res;
      })
      .catch(function (error) {
        return error.response;
      });
  },
  async searchLoadmoreActivity(args) {

    return getGraphQl().query({
      query: gql`
        query {
          allActivity(${args}){
            pageInfo{
              hasNextPage,
              hasPreviousPage,
            },
            totalCount,
            edges{
              node{
                id,
                type,
                workingTitle,
                favourite,
                levels {value},
                videocomponentWorkout{
                  id,
                  longDescription,
                  shortDescription,
                  renderType,
                  brightcoveId,
                  duration
                },
                tag,
                bodyPart {
                  id,
                  value,
                  label
                },
                mode{
                  id,
                  value,
                  label
                },
                postProduction{
                  id,
                  landscapeThumbnail,
                  landscapeVideo,
                  portraitVideo,
                  portraitThumbnail
                },
                primaryLevel{
                  id,
                  value,
                  label
                },
                series {
                  id, label
                }
              }
            }
          }
        }
      `
    });
  },
};
