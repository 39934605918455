<template>
  <ion-page>
    <ion-content
      :scroll-events="true"
      @ionScroll="$refs.header.setFixed($event.detail.scrollTop)"
    >
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <!-- Heading -->
        <xali-header ref="header"></xali-header>

        <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
          <div class="heading flex flex-col sm:flex-row">
            <h1 class="text-4.5xl font-bold">Favourites</h1>
            <div
              class="
                search
                flex
                bg-black bg-opacity-5
                rounded-2lg
                mt-4
                sm:hidden
              "
            >
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
              <ion-input placeholder="Search a workout"></ion-input>
            </div>
            <div class="search-button ml-auto my-auto hidden sm:block">
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
            </div>
          </div>
        </div>

        <div class="container max-w-full mt-0 px-0" v-if="!loading">
          <section class="mb-7 md:mb-11" v-if="workouts.length">
            <Carousel
              title="Move"
              v-bind:items="workouts"
              :link="{ text: 'Browse All', url: '/favourites/activity' }"
            ></Carousel>
          </section>

          <section class="mb-7 md:mb-11" v-if="recipes.length">
            <Carousel
              title="Recipes"
              v-bind:items="recipes"
              :link="{ text: 'Browse All', url: '/favourites/recipe' }"
            ></Carousel>
          </section>

          <section class="mb-7 md:mb-11" v-if="learns.length">
            <Carousel
              title="Learn"
              v-bind:items="learns"
              :link="{ text: 'Browse All', url: '/favourites/learn' }"
            ></Carousel>
          </section>
        </div>

        <!-- Footer -->
        <xali-footer></xali-footer>
        <div class="h-20 md:hidden"></div>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import { heartOutline, searchOutline } from "ionicons/icons";
import { IonContent, IonPage, IonIcon, IonInput } from "@ionic/vue";
import Carousel from "@/components/pages/carousel/Carousel.vue";

export default {
  name: "Library",
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonInput,
    Carousel,
  },
  data() {
    return {
      searchOutline,
      heartOutline,
      loading: true,
      type: "all",
      workouts: [],
      recipes: [],
      learns: [],
    };
  },
  computed: {
    favourites() {
      return this.$store.getters["accounts/getFavouritesData"];
    },
  },
  watch: {
    favourites(data) {
      // console.log(data);
      // this.recipes = [];
      // this.workouts = [];
      setTimeout(() => {
        this.recipes =
          data && data.recipes && data.recipes.length ? data.recipes : [];
        this.workouts =
          data && data.workouts && data.workouts.length ? data.workouts : [];
        this.learns =
          data && data.learns && data.learns.length ? data.learns : [];
      }, 500);
    },
  },
  async mounted() {
    // console.log("L this.type", this.type);
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.$store.dispatch("accounts/getInitialFavourites").then(() => {
        // console.log("response", response);
      });
    }, 500);
  },
};
</script>
