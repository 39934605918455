
<template>
  <div class="subscription-container relative">
    <header class="mb-5 px-12">
      <div class="flex absolute top-4 right-4">
        <router-link to="/home" class="">
          <ion-icon
            :icon="closeCircle"
            class="block text-light text-5xl opacity-30"
          ></ion-icon>
          <ion-icon
            :icon="closeOutline"
            class="
              block
              text-black text-3xl
              absolute
              top-0
              m-2
              border border-transparent
            "
          ></ion-icon>
        </router-link>
      </div>
    </header>

    <div class="body-content mt-20 px-12">
      <ion-img :src="iconLogo" class="logo"></ion-img>
      <p class="mt-16 text-xl tracking-tight">Hi {{name}},</p>
      <h2 class="text-4.5xl font-bold tracking-tight leading-8 my-4 mb-10">
        Your 14 day free trial has just ended!
      </h2>
      <p class="mb-4 tracking-tight">
        We've loved having you in our community, and we hope you've enjoyed your
        Xali experience.
      </p>
      <p class="tracking-tight">
        We'd like to invite you to subscribe so you can continue accessing our
        premium content, supporting you on your journey.
      </p>
    </div>
    <footer>
      <div class="absolute w-full bottom-5 px-6">
        <ion-button
          expand="block"
          color="white"
          class="text-xl mb-4 tracking-tight"
          type="submit"
        >
          <span class="p-4 flex text-black">Choose Plan</span>
        </ion-button>

        <ion-button
          expand="block"
          color="black"
          class="text-xl tracking-tight"
          type="submit"
        >
          <span class="p-4 flex">Not right now</span>
        </ion-button>
      </div>
    </footer>
  </div>
</template>
<script>
import { IonIcon } from "@ionic/vue";
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
} from "ionicons/icons";

import { iconLogo } from "@/_helpers/icons";

export default {
  components: { IonIcon },
  data() {
    return { closeCircle, closeOutline, chevronUp, chevronDown, iconLogo, name:'' };
  },
  async mounted(){
    const userData = await this.$store.getters["accounts/getUser"];
    this.name = userData.first_name
  },
};
</script>

<style lang="scss" scoped>
.subscription-container {
  height: 100vh;
  background: url("../../public/assets/images/journey-bg.jpg");
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.9);
  background-size: cover;
  background-position: center;
  overflow-y: auto;
  color: #fff;
  .scroll-y {
    background: red;
  }
  .logo {
    width: 150px;
  }
}
</style>
