<script>
import { IonIcon, IonCheckbox, alertController } from "@ionic/vue";
import { chevronForward, chevronDown, chevronUp } from "ionicons/icons";
import journeySetupData from "@/data/journey_setup_data.json";
export default {
  name: "step-4",
  components: { IonIcon, IonCheckbox },
  emits: ["setSelect"],
  props: ["items"],
  data() {
    return {
      step: 4,
      open: journeySetupData.open,
      chevronForward,
      chevronDown,
      chevronUp,
    };
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    fitness_coach_text() {
      return `Meet your Nutritionist Coach (${this.step}/4)`;
    },
    max_level() {
      return Math.max.apply(
        Math,
        this.items.map(function (o) {
          return parseInt(o.value);
        })
      );
    },
  },
  methods: {
    isGluten(item) {
      if (item.value == "gluten") {
        this.presentAlert();
      }
    },
    async presentAlert() {
      const alert = await alertController.create({
        cssClass: ["text-center"],
        mode: "ios",
        header: "Fun fact",
        message: "All of our recipes are gluten free!",
        buttons: [
          {
            text: "Understood",
            handler: () => {
              console.log("Confirm Okay");
            },
          },
        ],
      });
      return alert.present();
    },
    clickAll(id) {
      setTimeout(() => {
        let category = document.getElementById(`category-${id}`);
        console.log("category.checked", category.checked);
        let checkedBoxes = document.getElementsByClassName(`preference-${id}`);
        for (let i = 0; i < checkedBoxes.length; i++) {
          let elem = checkedBoxes[i];
          elem.checked = category.checked;
        }
      }, 100);
    },
    setOpen: function (step, i) {
      this.open[step][i] = !this.open[step][i];
    },
    isOpen: function (step, i, is_recommend) {
      if (is_recommend) {
        let temp = this.open[step][i];
        temp = !temp;
        if (temp && is_recommend) return true;
      } else {
        if (this.open[step][i]) return true;
      }
    },
    checkPreference(id) {
      setTimeout(() => {
        let category = document.getElementById(`category-${id}`);
        let checkedBoxes = document.getElementsByClassName(`preference-${id}`);
        let total_checked = 0;
        for (let i = 0; i < checkedBoxes.length; i++) {
          let elem = checkedBoxes[i];
          if (elem.checked) {
            total_checked = total_checked + 1;
          }
        }
        if (total_checked > 0) {
          category.checked = true;
        } else {
          category.checked = false;
        }
      }, 100);
    },
    selected(step, i, item) {
      console.log("item", item);
      this.$emit("setSelect", item);
    },
  },
};
</script>

<style lang="scss" scoped>
/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .alert-wrapper .alert-title {
  text-align: center;
}
.recommended {
  --background: yellow;
  --color: red;
}
</style>

<template>
  <section>
    <div class="question-details mb-5">
      <div class="question-progress">
        {{ fitness_coach_text }}
      </div>
      <h1 class="question text-3xl font-black mb-5">
        Do you have any dietary preferences?
      </h1>
      <p>I eat:</p>
    </div>

    <div class="question-options mt-4" 
        :class="iOS ? 'mb-14' : 'mb-28'">
      <template v-for="(item, index) in items" :key="index">
        <div
          v-if="item.preferences.length > 0 || item.value == 'gluten'"
          class="border rounded-lg mb-5 py-3.5 px-6"
          v-bind:class="{
            'bg-primary bg-opacity-5 border-primary': item.isChecked,
          }"
        >
          <div class="flex" @click="isGluten(item)">
            <ion-checkbox
              v-model="item.isChecked"
              mode="ios"
              class="my-auto mr-3 w-5 h-5 item-chkbx"
              :value="item.id"
              :id="`category-${item.id}`"
              @click="clickAll(item.id)"
              :disabled="item.disabled"
            ></ion-checkbox>
            <div class="my-auto mr-auto">
              <div class="font-medium text-primary">
                {{ item.label }}
              </div>
            </div>
            <div
              v-if="item.preferences.length > 1"
              class="my-auto flex cursor-pointer"
              @click="setOpen(step, index)"
            >
              <span class="my-auto mx-2">details</span>
              <ion-icon
                :icon="isOpen(step, index) ? chevronUp : chevronDown"
                class="mt-2 text-xs"
              ></ion-icon>
            </div>
          </div>
          <!-- Diet category has a single child or single diet preference. Hide the child element -->
          <div
            v-if="item.preferences.length == 1"
            class="invisible"
            data-v-527f2bc8=""
            v-show="isOpen(step, index)"
          >
            <div class="description my-5 ml-5 md:ml-6">
              <div
                v-for="(preference, indx) in item.preferences"
                :key="indx"
                class="flex mb-3"
              >
                <ion-checkbox
                  v-model="preference.isChecked"
                  mode="ios"
                  class="my-auto mr-3 w-5 h-5 item-chkbx"
                  name="chkbx"
                  :value="preference.id"
                  v-bind:class="`preference-${item.id}`"
                ></ion-checkbox>
                <div class="my-auto mr-auto">
                  <div class="font-medium text-primary">
                    {{ preference.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Diet category having multiple child or multiple diet preferences -->
          <div
            v-if="item.preferences.length > 1"
            class="open"
            data-v-527f2bc8=""
            v-show="isOpen(step, index)"
          >
            <div class="description my-5 ml-5 md:ml-6">
              <div
                v-for="(preference, indx) in item.preferences"
                :key="indx"
                class="flex mb-3"
              >
                <ion-checkbox
                  v-model="preference.isChecked"
                  mode="ios"
                  class="my-auto mr-3 w-5 h-5 item-chkbx"
                  name="chkbx"
                  :value="preference.id"
                  v-bind:class="`preference-${item.id}`"
                  @click="checkPreference(item.id)"
                ></ion-checkbox>
                <div class="my-auto mr-auto">
                  <div class="font-medium text-primary">
                    {{ preference.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>
