import { createRouter, createWebHistory } from "@ionic/vue-router";
import Home from "../pages/Home.vue";
import Login from "../pages/Login.vue";
import Signup from "../pages/Signup.vue";
import JourneySetup from "../pages/Journey-Setup.vue";
import JourneySuccess from "../pages/Journey-Success.vue";
import NotFound from "../pages/404.vue";

// Move
import Move from "../pages/move/Move.vue";
import Library from "../pages/move/Library.vue";
import Category from "../pages/move/Category.vue";
import Activity from "../pages/move/Activity.vue";
import ActivityList from "../pages/move/List.vue";
import ActivitySearch from "../pages/move/Search.vue";

// Recipe
// import LibraryRecipe from "../pages/recipe/Library.vue";
// import CategoryRecipe from "../pages/recipe/Category.vue";
// import Recipe from "../pages/recipe/Recipe.vue";
// import RecommendedRecipe from "../pages/recipe/Recommended.vue";
// import PopularRecipe from "../pages/recipe/Popular.vue";

// Eat/Nutrition
import Eat from "@/pages/eat/Eat.vue";
import LibraryRecipe from "@/pages/eat/Library.vue";
import CategoryRecipe from "@/pages/eat/Category.vue";
import Recipe from "@/pages/eat/Recipe.vue";
import RecommendedRecipe from "@/pages/eat/Recommended.vue";
import PopularRecipe from "@/pages/eat/Popular.vue";
import recipeSearch from "@/pages/eat/Search.vue";

// Favourites
import Favourite from "@/pages/favourite/Favourite.vue";
import FavouriteLibrary from "@/pages/favourite/Library.vue";
import FavouriteList from "@/pages/favourite/List.vue";

// Forgot Password
import ForgotPassword from "@/pages/ForgotPassword.vue";
// Reset Password
import ResetPassword from "@/pages/ResetPassword.vue";

// Confirm Email
import ConfirmEmail from "@/pages/ConfirmEmail.vue";

// Learn
import Learn from "@/pages/Learn.vue";

// History
import History from "@/pages/history/History.vue";
import HistoryLibrary from "@/pages/history/Library.vue";
import HistoryList from "@/pages/history/List.vue";

import { authGuard, logout, switchApp, trackingTrigger, setHtmlPageTitle } from "@/_helpers";
import staticRoutes from "@/static/index";
import VueCookies from "vue-cookies";
//import mixpanel from 'mixpanel-browser';


import ChoosePlan from '../pages/subscription/Choose-Plan.vue'
import SubscriptionExpired from '../pages/subscription/Subscription-Expired.vue'
import Payment from '../pages/subscription/Payment.vue'
import PaymentSuccess from '../pages/subscription/Payment-Success.vue'
import CancelSuccess from '../pages/subscription/Cancel-Success.vue'


// Recommendation workout
import store from "../store";
let recommended = "/move";
const recomm = store.getters["accounts/getRecommended"];
if (recomm.activity) {
  if (recomm.activity.activity) {
    recommended = "/move/workout/view/" + recomm.activity.activity;
  }
}

const routes = [
  { path: "/", name: "Root", meta: { title: "Home" }, component: Home, beforeEnter: authGuard },
  { path: "/home", name: "Home", meta: { title: "Home" }, component: Home, beforeEnter: authGuard },
  // {
  //   path: "/home/:profile?",
  //   name: "Home",
  //   component: Home
  // },
  // { path: '/', component: Home },
  // { path: '/home', component: Home },
  {
    path: "/login",
    name: "Login",
    meta: { title: "Login" },
    component: Login
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: logout
    //component: Login,
  },
  {
    path: "/switch/:token",
    beforeEnter: switchApp
  },
  {
    path: "/signup",
    name: "Signup",
    meta: { title: "Signup" },
    component: Signup
  },
  {
    path: "/signup/:action?",
    name: "Signup",
    component: Signup
  },
  {
    path: "/journey-setup/",
    redirect: "/journey-setup/intro",
    beforeEnter: authGuard
  },
  {
    path: "/journey-setup/:step?",
    name: "Journey",
    meta: { title: "Journey Setup" },
    component: JourneySetup,
    beforeEnter: authGuard
  },
  {
    path: "/journey-success",
    name: "Journey Success",
    meta: { title: "Journey Success" },
    component: JourneySuccess,
    beforeEnter: authGuard
  },
  {
    path: "/move",
    component: Move,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "Move",
        meta: { title: "Move" },
        component: Library
      },
      {
        path: "search/:search",
        name: "Activity Search",
        meta: { title: "Search" },
        component: ActivitySearch
      },
      {
        path: "recommended",
        meta: { title: "Recomended" },
        redirect: recommended
      },
      {
        path: ":type",
        name: "ActivityList",
        meta: { title: "Move" },
        component: ActivityList
      },
      {
        path: ":type/:category/:id",
        name: "ActivityCategory",
        meta: { title: "Move" },
        component: Category
      },
      {
        path: ":type/view/:id",
        name: "ActivityView",
        meta: { title: "Move" },
        component: Activity,
        beforeEnter: authGuard
      }
    ]
  },
  {
    path: "/favourites",
    meta: { title: "Favourites" },
    component: Favourite,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "FavouriteLibrary",
        meta: { title: "Favourites Library" },
        component: FavouriteLibrary
      },
      {
        path: ":type",
        name: "FavouriteList",
        meta: { title: "Favourites List" },
        component: FavouriteList
      }
    ]
  },
  {
    path: "/history",
    meta: { title: "History" },
    component: History,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "HistoryLibrary",
        meta: { title: "History Library" },
        component: HistoryLibrary
      },
      {
        path: ":type",
        name: "HistoryList",
        meta: { title: "History List" },
        component: HistoryList
      }
    ]
  },
  // {
  //   path: "/nutrition",
  //   name: "RecipeLibrary",
  //   component: LibraryRecipe,
  //   beforeEnter: authGuard
  // },
  // {
  //   path: "/eat/:type/:category",
  //   name: "CategoryRecipe",
  //   component: CategoryRecipe,
  //   beforeEnter: authGuard
  // },
  // {
  //   path: "/eat/view/:id?",
  //   name: "Recipe",
  //   component: Recipe,
  //   beforeEnter: authGuard
  // },
  // {
  //   path: "/eat/recommended",
  //   name: "Recommended Recipe",
  //   component: RecommendedRecipe,
  //   beforeEnter: authGuard
  // },
  // {
  //   path: "/eat/popular",
  //   name: "Popular Recipe",
  //   component: PopularRecipe,
  //   beforeEnter: authGuard
  // },
  {
    path: "/eat",
    meta: { title: "Eat" },
    component: Eat,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "Eat",
        meta: { title: "Eat" },
        component: LibraryRecipe
      },
      {
        path: "search/:search",
        name: "Nutrition Search",
        meta: { title: "Search" },
        component: recipeSearch
      },
      {
        path: ":type/:category",
        name: "CategoryRecipe",
        meta: { title: "Category Recipe" },
        component: CategoryRecipe
      },
      {
        path: "view/:id?",
        name: "Recipe",
        meta: { title: "Recipe" },
        component: Recipe,

      },
      {
        path: "recommended",
        name: "Recommended Recipe",
        meta: { title: "Recommended Recipe" },
        component: RecommendedRecipe,

      },
      {
        path: "recommended/:id",
        name: "Recommended Related Recipe",
        meta: { title: "Recommended Related Recipe" },
        component: RecommendedRecipe,

      },
      {
        path: "popular",
        name: "Popular Recipe",
        meta: { title: "Popular Recipe" },
        component: PopularRecipe,

      },
    ]
  },
  {
    path: "/learn",
    name: "Learn",
    meta: { title: "Learn" },
    component: Learn,
    beforeEnter: authGuard
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: { title: "Forgot Password" },
    component: ForgotPassword
  },
  {
    path: "/reset/password",
    name: "ResetPassword",
    meta: { title: "Reset Password" },
    component: ResetPassword
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    meta: { title: "Not Found" },
    component: NotFound
  },
  {
    name: "Subscription Expired",
    path: '/subscription/expired',
    component: SubscriptionExpired,
    beforeEnter: authGuard
  },
  {
    name: "Choose Plan",
    path: '/subscription/choose-plan',
    component: ChoosePlan,
    //beforeEnter: authGuard
  },
  {
    name: "Payment",
    path: '/subscription/choose-plan/:package',
    component: Payment,
    beforeEnter: authGuard
  },
  {
    path: '/subscription/payment-success',
    component: PaymentSuccess,
    beforeEnter: authGuard
  },
  {
    name: "Cancel Success",
    path: '/subscription/cancel-success',
    component: CancelSuccess,
    beforeEnter: authGuard
  },
  {
    path: "/confirm/email",
    name: "ConfirmEmail",
    meta: { title: "Confirm Email" },
    component: ConfirmEmail
  },
  ...staticRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// window.popStateDetected = false
// window.addEventListener('popstate', () => {
//   window.popStateDetected = true;
//   router.go(window.location.href)
// })

//router.beforeEach((to, from, next) => {
// const IsItABackButton = window.popStateDetected
// window.popStateDetected = false
// if (IsItABackButton && from.meta.someLogica) {
//   next(false) 
//   return ''
// }
// next()
// console.log("to", to);
// let title = to.meta && to.meta.title ? to.meta.title : null;
// window.document.title = title ? `${title} | Xali` : 'Xali'
// VueCookies.set("from", from);
// next();
//})

router.afterEach(async () => {
  // console.log("routes changed")
  // console.log("route.name", router.currentRoute.value.meta.title);
  let title = router.currentRoute && router.currentRoute.value && router.currentRoute.value.meta && router.currentRoute.value.meta.title ? router.currentRoute.value.meta.title : null;
  setHtmlPageTitle(title);

  //mixpanel.init('0d6ef7a3e32086155c06f4b31d5e414c', { debug: true });
  if (VueCookies.get("userData")) {
    //let userData = VueCookies.get("userData");

    await trackingTrigger('pageview')
    // mixpanel.track('Pageview', {
    //   $first_name: userData.first_name,
    //   $last_name: userData.last_name,
    //   $email: userData.email,
    //   $signup_date: userData.date_joined
    // });

    // let currentEvent = VueCookies.get("event");
    // var _learnq = _learnq || [];
    // var kData = {
    //   '$email' : userData.email,
    //   '$first_name' : userData.first_name,
    //   '$last_name' : userData.last_name,
    //   'Signup Date' : userData.date_joined
    // }

    // setTimeout(() => {

    //   var identify = _learnq.push(['identify', kData ]);
    //   console.log('K identify - ',identify);

    //   let kEvent;
    //   if(currentEvent == 'login') kEvent = _learnq.push(['track', 'Login', kData]);
    //   if(currentEvent == 'signup') kEvent = _learnq.push(['track', 'Signup', kData]);
    //   console.log('K Event',currentEvent, kEvent);

    // }, 500)


  }
})

export default router;

// export const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// });
