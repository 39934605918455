<script>
import { IonIcon, IonCheckbox } from "@ionic/vue";
import { checkBox, uncheckBox, rollersballs } from "@/_helpers/icons";
export default {
  name: "equipmemt",
  components: { IonIcon, IonCheckbox },
  emits: ["setSelect"],
  props: ["item"],
  data() {
    return {
      isChecked: false,
      rollersballs,
      checkBox,
      uncheckBox,
    };
  },
  mounted() {
    if (this.item.isSelected) {
      this.selected(this.item);
    }
  },
  computed: {
    iconpath() {
      var images = require.context("@/assets/images/icons/", false, /\.svg$/);
      var name = this.item.name.toLowerCase()
      if(name) name = name.replace(/\s/g, '-') 
      console.log(name)
      return images("./" + name + ".svg");
    },
  },
  methods: {
    selected(item) {
      // console.log("item.isChecked", item.isChecked);
      item.isChecked = !item.isChecked;
      // console.log("item.isChecked", item.isChecked);

      var element = document.getElementById(`icon-equipment-${item.name}`);
      if (item.isChecked) {
        element.classList.remove("text-inactive");
        element.classList.add("text-primary");
        this.isChecked = true;
      } else {
        element.classList.remove("text-primary");
        element.classList.add("text-inactive");
        this.isChecked = false;
      }
      this.$emit("setSelect", item);
    },
  },
};
</script>
<style scoped>
.equipment {
  zoom: 3;
}
.text-inactive {
  color: #b5b5b5;
}
.check-mark {
  zoom: 1.7;
}
</style>
<template>
  <div
    :id="`box-${item.id}`"
    class="cursor-pointer border rounded-lg mr-1 mb-5 py-3.5"
    v-bind:class="{
      'bg-primary bg-opacity-5 border-primary selected': item.isChecked,
    }"
    @click="selected(item)"
  >
    <div class="flex flex-col">
      <div>
        <ion-checkbox
          v-model="isChecked"
          mode="ios"
          class="invisible"
          name="equipments"
          :value="item.id"
        ></ion-checkbox>
        <ion-icon
          v-if="item.isChecked"
          :id="`chkbx-${item.id}`"
          class="float-right check-mark mr-2 text-primary"
          :src="checkBox"
        ></ion-icon>
      </div>

      <div>
        <div class="my-auto mr-auto text-center">
          <ion-icon
            :id="`icon-equipment-${item.name}`"
            class="mx-auto w-7 mb-1 mt-auto equipment text-inactive"
            :src="iconpath"
          ></ion-icon>

          <div
            class="font-medium"
            v-bind:class="[item.isChecked ? 'text-primary' : 'text-muted']"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
