<script>
import timezones from "@/data/timezones.json";
import api from "@/api";
import {
  IonList,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  toastController,
} from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import VueCookies from "vue-cookies";
export default {
  name: "profile-items",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      chevronForward,
      user_timezone: "Australia/Brisbane",
      timezones: timezones,
      onmounted: true,
    };
  },
  mounted() {
    this.user_timezone = this.userData.detail.tz_info.timezone;
  },
  computed: {
    userData() {
      return this.$store.getters["accounts/getUser"];
    },
  },
  watch: {
    user_timezone() {
      this.handleTimezoneChange();
    },
  },
  methods: {
    async handleTimezoneChange() {
      // console.log("Updating timezone", this.user_timezone);
      VueCookies.set("user_timezone", this.user_timezone);
      const response = await api.updateTimezone({
        timezone: this.user_timezone,
      });
      // console.log("response", response.data.message);
      const result = await this.$store.dispatch("accounts/getMe");
      // console.log("result", result.data);
      this.$store.commit("accounts/setUser", result.data);
      if (!this.onmounted) {
        this.openToast(response.data.message, "success");
      }

      this.onmounted = false;
    },
    async openToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 5000,
        color: color,
      });
      return toast.present();
    },
  },
};
</script>
<template>
  <ion-list>
    <ion-item>
      <div class="flex justify-between items-center w-full">
        <div>First Name</div>
        <div>
          {{ userData.first_name }}
          <!-- <ion-icon
            :icon="chevronForward"
            class="ml-5"
            size="medium"
          ></ion-icon> -->
        </div>
      </div>
    </ion-item>
    <ion-item>
      <div class="flex justify-between items-center w-full">
        <div>Last Name</div>
        <div>
          {{ userData.last_name }}
          <!-- <ion-icon
            :icon="chevronForward"
            class="ml-5"
            size="medium"
          ></ion-icon> -->
        </div>
      </div>
    </ion-item>
    <ion-item>
      <ion-label>Timezone</ion-label>
      <ion-select
        v-model="user_timezone"
        ok-text="Okay"
        cancel-text="Dismiss"
        type="ios"
      >
        <ion-select-option
          v-for="(timezone, i) in timezones"
          :key="i"
          :value="timezone"
          >{{ timezone }}</ion-select-option
        >
      </ion-select>
    </ion-item>

    <!-- <ion-item>
      <div class="flex justify-between items-center w-full">
        <div>
          Gender
        </div>
        <div>
          {{ userData.gender }}
          <ion-icon
            :icon="chevronForward"
            class="ml-5"
            size="medium"
          ></ion-icon>
        </div>
      </div>
    </ion-item>
    <ion-item>
      <div class="flex justify-between items-center w-full">
        <div>
          Age
        </div>
        <div>
          {{ userData.age }}
          <ion-icon
            :icon="chevronForward"
            class="ml-5"
            size="medium"
          ></ion-icon>
        </div>
      </div>
    </ion-item>
    <ion-item>
      <div class="flex justify-between items-center w-full">
        <div>
          Height
        </div>
        <div>
          {{ userData.height }}
          <ion-icon
            :icon="chevronForward"
            class="ml-5"
            size="medium"
          ></ion-icon>
        </div>
      </div>
    </ion-item>
    <ion-item>
      <div class="flex justify-between items-center w-full">
        <div>
          Weight
        </div>
        <div>
          {{ userData.weight }}
          <ion-icon
            :icon="chevronForward"
            class="ml-5"
            size="medium"
          ></ion-icon>
        </div>
      </div>
    </ion-item> -->
  </ion-list>
</template>
