<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <div
          class="
            container
            max-w-full
            min-h-screen
            px-0
            cover-container
            bg-white
            md:mb-20
          "
        >
          <div class="grid grid-cols-12 gap-0 min-h-screen">
            <div
              class="
                content
                flex flex-col
                col-span-12
                md:col-span-7
                xl:col-span-6
              "
            >
              <xali-header
                type="header-w-back"
                :settings="{
                  back: true,
                  logo: false,
                  favorite: true,
                  recommended: isRecommended,
                  videoCompleted: videoCompleted
                }"
              ></xali-header>
              <div
                class="
                  mobile-cover
                  -mt-16
                  pt-2
                  h-72
                  relative
                  block
                  md:hidden
                  z-0
                "
              >
                <img
                  class="cover-bg absolute top-0 left-0 w-full h-full"
                  :src="activity.coverBg.mobile"
                  v-bind:class="[playing ? 'opacity-0' : '']"
                />

                <div
                  class="absolute top-0 left-0 w-full h-full flex"
                  v-if="playButton"
                >
                  <img
                    class="m-auto w-24 cursor-pointer"
                    src="/assets/images/play-button.svg"
                    @click="playVideoPause"
                  />
                </div>
                <div
                  class="bottom-cover absolute bottom-0 w-full"
                  v-if="!playing"
                >
                  <svg
                    fill="#ffffff"
                    version="1.2"
                    baseProfile="tiny-ps"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 750 189"
                    class="w-full"
                  >
                    <path
                      id="Shape 1"
                      class="shp0"
                      d="M0 140C0 140 71.98 183.72 213 189C510 200.11 750 7 750 7L750 207.11C750 207.11 267 207.11 0 205.11C0 154.11 0 140 0 140Z"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="
                  workout-container
                  w-full
                  max-w-md
                  m-auto
                  mt-0
                  md:mt-4
                  px-6
                  lg:px-0
                  z-10
                "
              >
                <div class="heading flex flex-col">
                  <div
                    class="connection flex z-10"
                    v-bind:class="[
                      isRecommended
                        ? '-mt-4'
                        : 'order-last md:order-first mb-4',
                    ]"
                  >
                    <ion-chip
                      color="white"
                      class="
                        rounded-tl-none rounded-br-none
                        px-6
                        bg-primary
                        m-0
                        mr-auto
                      "
                      v-if="isRecommended"
                    >
                      <ion-label
                        color="white"
                        class="
                          font-founders-condensed
                          text-lg
                          uppercase
                          mt-2
                          whitespace-nowrap
                        "
                        >Recommended {{ recommended }} workout</ion-label
                      >
                    </ion-chip>
                    <div class="my-auto flex md:hidden">
                      <a
                        v-if="activity.spotify"
                        v-bind:href="activity.spotify.link"
                        v-bind:title="activity.spotify.name"
                        target="_new"
                        class="mx-2"
                      >
                        <ion-icon
                          class="text-black w-6"
                          :src="iconSpotify"
                          size="large"
                        ></ion-icon>
                      </a>
                      <a
                        v-if="activity.apply_music"
                        v-bind:href="activity.apply_music.link"
                        v-bind:title="activity.apply_music.name"
                        target="_new"
                        class="mx-2"
                      >
                        <ion-icon
                          class="text-black w-6"
                          :src="iconApplemusic"
                          size="large"
                        ></ion-icon>
                      </a>
                    </div>
                  </div>
                  <h1 class="text-3xl md:text-4xl leading-8 font-bold my-5">
                    {{ activity.title }}
                  </h1>
                  <div class="chips text-sm text-dark mb-5 md:mb-0">
                    <span
                      v-if="activity.level.length"
                      class="
                        level
                        bg-black bg-opacity-5
                        rounded-2lg
                        py-1
                        px-3
                        mr-2
                        whitespace-nowrap
                        mb-2
                      "
                      >Level
                      <span
                        v-for="(lvl, i) in activity.level"
                        :key="i"
                        v-bind:class="[lvl.check ? 'checked' : '']"
                        class="status"
                      ></span>
                    </span>
                    <span
                      v-for="(chip, i) in activity.tags"
                      :key="i"
                      class="
                        bg-black bg-opacity-5
                        rounded-2lg
                        py-0.5
                        px-3
                        mr-2
                        inline-block
                        mb-1
                      "
                    >
                      <!-- <a v-bind:href="chip.url">{{ chip.text }}</a> -->
                      {{ chip.text }}
                    </span>
                  </div>
                  <div class="connection my-8 hidden md:flex">
                    <a
                      v-if="activity.spotify"
                      v-bind:href="activity.spotify.link"
                      v-bind:title="activity.spotify.name"
                      target="_new"
                    >
                      <ion-button fill="outline" color="black" class="mr-3">
                        <div class="px-2 py-1 md:py-1.5 flex">
                          <ion-icon
                            class="text-black w-5 mr-3"
                            :src="iconSpotify"
                            size="large"
                          ></ion-icon>
                          <span class="my-auto">Spotify</span>
                        </div>
                      </ion-button>
                    </a>
                    <a
                      v-if="activity.apply_music"
                      v-bind:href="activity.apply_music.link"
                      v-bind:title="activity.apply_music.name"
                      target="_new"
                    >
                      <ion-button fill="outline" color="black" class="mr-3">
                        <div class="px-2 py-1 md:py-1.5 flex">
                          <ion-icon
                            class="text-black w-5 mr-3"
                            :src="iconApplemusic"
                            size="large"
                          ></ion-icon>
                          <span class="my-auto">Apple Music</span>
                        </div>
                      </ion-button>
                    </a>
                  </div>
                </div>
                <div class="body mb-8" :class="!isRecommended && !iOS? 'pb-40' : ''">
                  <div class="details mb-8" v-if="activity.equipment">
                    <div class="title text-lg font-medium mb-2">Equipment</div>
                    <div
                      class="description font-light"
                      v-bind:class="[
                        totalEquipment > 5 ? 'grid grid-cols-2' : '',
                      ]"
                    >
                      <div
                        class="pr-3"
                        v-for="(equip, i) in activity.equipment"
                        :key="i"
                      >
                        <p class="mt-2 font-medium hidden">{{ equip.name }}</p>
                        <div v-for="(item, i) in equip.items" :key="i">
                          <p v-if="item.option !='NO' && item.option !='No'">
                          {{ item.name }}
                          <span class="text-sm" v-if="item.option == 'Optional' || item.option == 'OPTIONAL'"
                            >({{item.option}})</span
                          >
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="details mb-8" v-if="activity.caution">
                    <div class="title text-lg font-medium mb-2">Caution</div>
                    <div class="description font-light">
                      <p>{{ activity.caution }}</p>
                    </div>
                  </div>
                  <div class="details mb-8" v-if="activity.details">
                    <div class="title text-lg font-medium mb-2">Details</div>
                    <div class="description font-light">
                      <p>{{ activity.details }}</p>
                    </div>
                  </div>

                  <div
                    class="
                      details
                      w-full
                      bottom-0
                      left-0
                      z-10
                      bg-white
                      md:block
                      hidden
                    "
                    v-if="!isRecommended && !isNotWorkout"
                  >
                    <ion-button
                      v-if="activity.videos.chapterised.id"
                      expand="block"
                      color="primary"
                      class="text-white mb-4"
                      type="submit"
                      @click="
                        playVideoPause(
                          'chapterised',
                          activity.videos.chapterised.id
                        )
                      "
                    >
                      <div class="p-5 flex items-center">
                        Full Session
                        <span
                          class="font-light ml-1"
                          v-if="activity.videos.chapterised.duration"
                        >
                          ({{ activity.videos.chapterised.duration }})</span
                        ><ion-icon
                          :icon="playCircleOutline"
                          size="large"
                        ></ion-icon>
                      </div>
                    </ion-button>
                    <ion-button
                      v-if="activity.videos.standalone.id"
                      expand="block"
                      color="primary"
                      fill="outline"
                      class="text-white"
                      type="submit"
                      @click="
                        playVideoPause(
                          'standalone',
                          activity.videos.standalone.id
                        )
                      "
                    >
                      <div class="p-5 flex items-center">
                        Workout Only
                        <span
                          class="font-light ml-1"
                          v-if="activity.videos.standalone.duration"
                        >
                          ({{ activity.videos.standalone.duration }})</span
                        ><ion-icon
                          :icon="playCircleOutline"
                          size="large"
                        ></ion-icon>
                      </div>
                    </ion-button>
                  </div>
                </div>
                <div
                  class="adapt pb-12"
                  v-if="isRecommended"
                >
                  <ion-button
                    @click.prevent="toggleWorkoutSetup()"
                    color="black"
                  >
                    <div
                      class="
                        px-2
                        py-2
                        text-base text-white
                        md:text-lg md:py-3
                        flex
                      "
                    >
                      <ion-icon
                        class="mr-3 w-5 my-auto"
                        :src="iconSettings"
                        size="large"
                      ></ion-icon>
                      <span class="my-auto">Change workout</span>
                    </div>
                  </ion-button>
                </div>
              </div>
            </div>

            <div
              class="
                cover
                md:relative
                rounded-l-5xl
                md:block md:col-span-5
                xl:col-span-6
              "
              v-bind:class="[playing ? '' : 'hidden']"
            >
              <img
                v-bind:class="[playing ? 'hidden' : '']"
                class="cover-bg absolute top-0 left-0 w-full h-full"
                :src="activity.coverBg.desktop"
              />
              <div
                class="videoWrapper flex bg-black bg-opacity-80 z-99"
                v-bind:class="[playing ? 'playing' : '']"
              >
                <div class="video-container my-auto">
                  <div ref="video" class="video"></div>
                </div>
              </div>
              <div
                v-bind:class="[playing ? 'hidden' : '']"
                class="absolute top-0 left-0 w-full h-full flex"
                v-if="playButton"
              >
                <img
                  class="m-auto w-52 cursor-pointer"
                  src="/assets/images/play-button.svg"
                  @click="playVideoPause"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="
            details
            px-5
            w-full
            z-10
            bg-white
            md:hidden
            sm:block 
          "
          :class="iOS ? 'ios-spacing' : 'fixed p-5 bottom-0 left-0'"
          v-if="!isRecommended && !isNotWorkout"
        >
          <ion-button
            v-if="activity.videos.chapterised.id"
            expand="block"
            color="primary"
            class="text-white mb-4"
            type="submit"
            @click="
              playVideoPause('chapterised', activity.videos.chapterised.id)
            "
          >
            <div class="p-5 flex items-center">
              Full Session
              <span
                class="font-light"
                v-if="activity.videos.chapterised.duration"
              >
                ( {{ activity.videos.chapterised.duration }})</span
              >
              <ion-icon :icon="playCircleOutline" size="large"></ion-icon>
            </div>
          </ion-button>
          <ion-button
            v-if="activity.videos.standalone.id"
            expand="block"
            color="primary"
            fill="outline"
            class="text-white"
            type="submit"
            @click="playVideoPause('standalone', activity.videos.standalone.id)"
          >
            <div class="p-5 flex items-center">
              Workout Only
              <span
                class="font-light"
                v-if="activity.videos.standalone.duration"
              >
                ( {{ activity.videos.standalone.duration }})</span
              >
              <ion-icon :icon="playCircleOutline" size="large"></ion-icon>
            </div>
          </ion-button>
        </div>
      </template>

      <Modal :close_float="true" ref="modal">
        <template v-slot:header>
          <div class="w-full -mt-9 hidden sm:flex">
            <ion-chip
              color="white"
              class="m-0 rounded-full p-5 bg-primary mx-auto"
            >
              <ion-label color="white" class="font-hokyaa text-xl"
                >Xali <span class="text-black">Journey</span>
              </ion-label>
            </ion-chip>
          </div>
        </template>
        <!-- Workout Setup -->
        <WorkoutSetup
          ref="chatBox"
          :user="user"
          @close="handleModalClose"
        ></WorkoutSetup>
      </Modal>
    </ion-content>
    <ion-fab
      vertical="bottom"
      horizontal="start"
      slot="fixed"
      title="Switch to Xali Admin"
      v-if="isAdmin"
    >
      <a :href="editURL">
        <ion-fab-button title="Switch to Xali Admin">
          <ion-icon :icon="pencilOutline"></ion-icon>
        </ion-fab-button>
      </a>
    </ion-fab>
  </ion-page>
</template>

<script>
import WorkoutSetup from "@/components/pages/WorkoutSetup-Chat.vue";
import Modal from "@/components/commons/Modal.vue";
import { iconApplemusic, iconSpotify, iconSettings } from "@/_helpers/icons";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonChip,
  IonButton,
  IonIcon,
  IonFab,
  IonFabButton,
  alertController,
} from "@ionic/vue";
import { timeConvert, trackingTrigger } from "@/_helpers";
import { pencilOutline, playCircleOutline } from "ionicons/icons";
import _ from "lodash";
import bc from "@brightcove/player-loader";
import VueCookies from "vue-cookies";

export default {
  components: {
    IonContent,
    IonPage,
    IonLabel,
    IonChip,
    IonButton,
    IonIcon,
    IonFab,
    IonFabButton,
    WorkoutSetup,
    Modal,
  },
  data() {
    let activity = {
      title: "",
      tags: [],
      level: [],
      spotify: {},
      apply_music: {},
      equipment: [],
      caution: "",
      details: "",
      coverBg: {
        desktop: "/assets/images/journey-1.jpg",
        mobile: "/assets/images/journey-1.jpg",
      },
      fs: sessionStorage.getItem("fullscreen"),
    };

    return {
      loading: true,
      id: null,
      type: "workout",
      activity: activity,
      isRecommended: false,
      isNotWorkout: false,
      recommended: "",
      pencilOutline,
      playCircleOutline,
      editURL: `${process.env.VUE_APP_BACKEND_URL}/#/update-workout/${this.$route.params.id}/`,
      isAdmin: this.$store.getters["accounts/getisAdmin"],
      player: null,
      playing: false,
      playButton: false,
      totalEquipment: 0,
      iconSettings,
      iconApplemusic,
      iconSpotify,
      standalonePlayer: null,
      chapterisedPlayer: null,
      videoType: '',
      counterWatch: null,
      isCompleted: false,
      videoCompleted: false
    };
  },
  mounted() {
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    this.$store.dispatch(`pages/getActivity`, this.id).then(() => {
      this.activity = this.formatData(
        this.$store.getters["pages/getCurrentActivity"]
      );

      if(this.activity.videoID && this.activity.videos){
        if(this.activity.videos.chapterised.id == this.activity.videoID){
          this.videoType = 'chapterised'
        }
         if(this.activity.videos.standalone.id == this.activity.videoID){
          this.videoType = 'standalone'
        }
      }

      const recomm = this.$store.getters["accounts/getRecommended"];
      if (recomm.activity.activity == this.id) {
        this.isRecommended = true;
        this.recommended = recomm.activity.benefit;
        if (recomm.activity.status == "completed") {
          this.isCompleted = true;
        }
      }
      this.activity = {
        ...this.activity,
        _id: this.id,
        isRecommend: this.isRecommended,
      };
      trackingTrigger("workout-open", {
        formated: this.activity,
        raw: this.$store.getters["pages/getCurrentActivity"],
        videoType: this.videoType
      });

      this.loading = false;
      setTimeout(() => {
        this.videoInit(this.activity.videoID);
      }, 1000);

      // console.log('Recommended Video ID', this.activity.videoID);
      // console.log('Standalone Video ID', this.activity.videos['standalone']);
      // console.log('Chapterised Video ID', this.activity.videos['chapterised']);

      if (
        this.$store.getters["pages/getCurrentActivity"].type == "WARMUP" ||
        this.$store.getters["pages/getCurrentActivity"].type == "STRETCH_FLOW"
      ) {
        this.isNotWorkout = true;
      }
    });
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    user() {
      return this.$store.getters["accounts/getUser"];
    },
  },
  methods: {
    handleModalClose() {
      this.$refs.modal.toggleModal();
    },
    toggleWorkoutSetup: function () {
      if (this.recommended_completed) return false;
      //var vm = this;f
      this.$refs.modal.toggleModal();
      setTimeout(() => {
        this.$refs.chatBox.scrollToEnd();
      }, 100);
    },
    getThumbnail: function (data, size = "portraitThumbnail") {
      var thumbnail = "/assets/images/journey-1.jpg";
      if (!_.isEmpty(data)) {
        if (data[0][size]) thumbnail = data[0][size];
      }
      return thumbnail;
    },
    capitalizeFirstLetter(str) {
      return str[0].toUpperCase() + str.slice(1).toLowerCase();
    },
    formatData: function (data) {
      // console.log('data-',data);
      let level = [];
      if (_.has(data, "levels")) {
        if (!_.isEmpty(data.levels)) {
          var lvl = _.last(data.levels).value;
          if (data.primaryLevel) lvl = data.primaryLevel.value; // Replace if primary level is exist
          for (let i = 0; i < 4; i++) {
            level.push({ check: lvl > i ? true : false, level: i });
          }
        }
      }

      let description = "";
      if (!_.isEmpty(data.videocomponentWorkout)) {
        const standalone = _.find(data.videocomponentWorkout, {
          renderType: "STANDALONE",
        });
        if (standalone) description = standalone.longDescription;
        // const chapter = _.find(data.videocomponentWorkout, {
        //   renderType: "CHAPTERISED"
        // });
        // if (chapter && this.type == 'workout') description = chapter.longDescription;
      }

      let videoID = false;
      let videos = {};
      if (!_.isEmpty(data.videocomponentWorkout)) {
        const standalone = _.find(data.videocomponentWorkout, {
          renderType: "STANDALONE",
        });
        if (standalone) {
          //if(standalone.duration){
          if (standalone.brightcoveId) videoID = standalone.brightcoveId;
          //}
          videos.standalone = {
            id: standalone.brightcoveId,
            duration: timeConvert(standalone.duration, "milisecs").trim(),
          };
        }
        const chapter = _.find(data.videocomponentWorkout, {
          renderType: "CHAPTERISED",
        });

        if (chapter) {
          //if(chapter.duration){
          if (chapter.brightcoveId) videoID = chapter.brightcoveId;
          //}
          videos.chapterised = {
            id: chapter.brightcoveId,
            duration: timeConvert(chapter.duration, "milisecs").trim(),
          };
        }
        if (Number.isNaN(Number(videoID))) videoID = false;
      }

      let equipments = {};
      if (!_.isEmpty(data.selectedEquipments)) {
        data.selectedEquipments.forEach((data) => {
          let key = data.equipment.category.key;
          if (!_.has(equipments, key)) {
            equipments[key] = {
              name: data.equipment.category.label,
              items: [],
            };
          }
          equipments[key].items.push({
            name: data.equipment.additionalLabel
              ? data.equipment.additionalLabel
              : data.equipment.label,
            option: this.capitalizeFirstLetter(data.selectedOption),
          });
          this.totalEquipment++;
        });
      }

      // Duration tag
      if (data.videocomponentWorkout) {
        let durations = [];
        data.videocomponentWorkout.forEach((vCom) => {
          if (vCom.duration && vCom.renderType == "STANDALONE") {
            durations.push(timeConvert(vCom.duration, "milisecs"));
          }
        });
        if (durations) {
          data.videocomponentWorkout.forEach((vCom) => {
            if (vCom.duration && vCom.renderType == "CHAPTERISED") {
              durations = [];
              durations.push(timeConvert(vCom.duration, "milisecs"));
            }
          });
        }
        if (!_.isEmpty(durations)) data.tag.push(durations.join(" - "));
      }

      let caution = [];
      if (data.cautions) {
        data.cautions.forEach((cau) => {
          caution.push(cau.label);
        });
      }
      let title = data.workingTitle;
      window.document.title = title ? `${title} | Xali` : "Xali";
      return {
        title: data.workingTitle,
        tags: _.map(data.tag, (data) => {
          return { text: data, url: "" };
        }),
        level: level,
        spotify: data.spotifyPlaylistLink
          ? { name: data.spotifyPlaylistName, link: data.spotifyPlaylistLink }
          : {},
        apply_music: data.applePlaylistLink
          ? { name: data.applePlaylistName, link: data.applePlaylistLink }
          : {},
        //equipment:_.map(data.selectedEquipments, (data) => { return { required: data.equipment.selected_option == "required" ? true:false, name: data.equipment.label }} ),
        equipment: equipments,
        caution: caution.join(", "),
        details: description,
        videoID: videoID,
        videos: videos,
        coverBg: {
          desktop: this.getThumbnail(
            this.$store.getters["pages/getActivityPostProduction"]
          ),
          mobile: this.getThumbnail(
            this.$store.getters["pages/getActivityPostProduction"],
            "landscapeThumbnail"
          ),
        },
        //coverBg: this.getThumbnail(this.$store.getters["pages/getActivityPostProduction"])
      };
    },
    async videoInit(vidoeoID, playafterInit = false) {
      if (!vidoeoID) console.log("brightcove video id not found", vidoeoID);
      if (vidoeoID) {
        const { ref } = await bc({
          refNode: this.$refs.video,
          videoId: vidoeoID,
          accountId: process.env.VUE_APP_BRIGHTCOVE_ID,
          playerId: "default",
          embedId: "default",
        });
        this.player = ref;
        this.player && this.eventAttach();
        this.playButton = true;
        console.log("this.playButton", this.playButton);
        if (playafterInit) {
          this.playVideoPause();
        }
      }
    },
    eventAttach() {
      if (this.player) {
        this.player.on("loadedmetadata", this.onLoadedmetadata);
        this.player.on("play", this.onPlay);
        this.player.on("timeupdate", this.onTimeupdate);
        this.player.on("pause", this.onPause);
        this.player.on("ended", this.onEnded);
      }
    },
    onLoadedmetadata() {
      var i,
        videoDuration,
        cuesAra = [],
        chapterStartTimesAra = [],
        chapterTitle = [],
        // +++  Use the array filter function to retrieve data structure that contains chapter cue points +++
        chapterTT = [].filter.call(this.player.textTracks(), function (tt) {
          return tt.kind === "chapters";
        });
      if (!_.isEmpty(chapterTT)) {
        //  Retrieve actual array of chapter cue points
        cuesAra = chapterTT[0].cues;
        // +++ Loop over chapter cue points and get start time of each  +++
        for (i = 0; i < cuesAra.length; i++) {
          chapterStartTimesAra[i] = cuesAra[i].startTime;
        }
        for (i = 0; i < cuesAra.length; i++) {
          chapterTitle[i] = cuesAra[i].text;
        }
        // +++ Call function to create marks in progress bar  +++
        // Get the video duration
        videoDuration = this.player.mediainfo.duration;
        this.videoDuration = videoDuration;
        // Call the function to add the marks in the progress control
        this.addMarkers(chapterStartTimesAra, videoDuration, chapterTitle);
      }
    },
    onPlay(e) {
      this.$emit("onPlay", e.target);
      this.playing = true;
      console.log("▶️ on play");

      let time = VueCookies.get(
        `totalWatched_${this.player.tagAttributes["data-video-id"]}`
      );
      let per_watched = (100 * time) / this.player.duration();
      trackingTrigger("workout-video-play", {
        formated: {
          ...this.activity,
          durationWatched: time,
          perWatched: per_watched,
        },
        raw: this.$store.getters["pages/getCurrentActivity"],
        videoType: this.videoType
      });

      this.videoCompleted = {
        formated: {
          ...this.activity,
          durationWatched: time,
          perWatched: per_watched,
        },
        raw: this.$store.getters["pages/getCurrentActivity"],
        videoType: this.videoType
      }

      let totalWatched = VueCookies.get(
        `totalWatched_Recom_${this.player.tagAttributes["data-video-id"]}`
      );
      this.counterWatch = setInterval(() => {
        totalWatched++;
        VueCookies.set(
          `totalWatched_Recom_${this.player.tagAttributes["data-video-id"]}`,
          totalWatched
        );
      }, 1000);
    },
    onTimeupdate(e) {
      this.$emit("onTimeupdate", e.target);

      let totalWatchedRecom = VueCookies.get(
        `totalWatched_Recom_${this.player.tagAttributes["data-video-id"]}`
      );
      //console.log("w--", this.isCompleted, totalWatchedRecom);
      if (!this.isCompleted) {
        let per50 = (100 * totalWatchedRecom) / this.player.duration();
        let per75 = (100 * this.player.currentTime()) / this.player.duration();
        if (per50 >= 50) {
          if (per75 >= 75) {
            if (!this.isCompleted) {
              trackingTrigger("workout-video-end", {
                formated: this.activity,
                raw: this.$store.getters["pages/getCurrentActivity"],
                videoType: this.videoType
              });

              // Update as completed
              console.log("Update as compeleted");
              this.$store.dispatch(
                "accounts/setRecommendedActivityStatus",
                "completed"
              );
              this.isCompleted = true;
            }
          }
        }
      }
      let totalWatched = VueCookies.get(
        `totalWatched_${this.player.tagAttributes["data-video-id"]}`
      );
      if (totalWatched != Math.floor(this.player.currentTime())) {
        VueCookies.set(
          `totalWatched_${this.player.tagAttributes["data-video-id"]}`,
          Math.floor(this.player.currentTime())
        );
      }
    },
    onPause(e) {
      this.$emit("onPause", e.target);
      console.log("⏸ on pause");
      clearInterval(this.counterWatch);

      let time = VueCookies.get(
        `totalWatched_${this.player.tagAttributes["data-video-id"]}`
      );
      let per_watched = (100 * time) / this.player.duration();
      trackingTrigger("workout-video-pause", {
        formated: {
          ...this.activity,
          durationWatched: time,
          perWatched: per_watched,
        },
        raw: this.$store.getters["pages/getCurrentActivity"],
        videoType: this.videoType
      });

      this.videoCompleted = {
        formated: {
          ...this.activity,
          durationWatched: time,
          perWatched: per_watched,
        },
        raw: this.$store.getters["pages/getCurrentActivity"],
        videoType: this.videoType
      }
    },
    onEnded(e) {
      this.$emit("onEnded", e.target);
      console.log("⏹ on ended");

      let time = VueCookies.get(
        `totalWatched_${this.player.tagAttributes["data-video-id"]}`
      );
      let per_watched = (100 * time) / this.player.duration();
      trackingTrigger("workout-video-end", {
        formated: {
          ...this.activity,
          durationWatched: time,
          perWatched: per_watched,
        },
        raw: this.$store.getters["pages/getCurrentActivity"],
        videoType: this.videoType
      });
      setTimeout(() => {
        trackingTrigger("workout-video-completed", {
          formated: {
            ...this.activity,
            durationWatched: time,
            perWatched: per_watched,
          },
          raw: this.$store.getters["pages/getCurrentActivity"],
          videoType: this.videoType
        });
      }, 1000);
      
    },
    addMarkers(cuePointsAra, videoDuration, chapterTitle) {
      var iMax = cuePointsAra.length,
        i,
        playheadWell = this.$el.getElementsByClassName(
          "vjs-progress-control vjs-control"
        )[0];
      for (i = 0; i < iMax; i++) {
        var elem = document.createElement("div");
        elem.className = "vjs-marker vjs-marker" + i;
        elem.id = "cp" + i;
        elem.setAttribute("time", cuePointsAra[i]);
        elem.style.left = (cuePointsAra[i] / videoDuration) * 100 + "%";
        elem.innerHTML =
          '<div class="marker"></div><span>' + chapterTitle[i] + "</span>";
        playheadWell.appendChild(elem);
      }
      // Add Next
      var control = this.$el.getElementsByClassName("vjs-control-bar")[0];
      elem = document.createElement("div");
      elem.className = "next-chapter";
      elem.innerHTML = "Next";
      control.appendChild(elem);

      const button = this.$el.getElementsByClassName("next-chapter")[0];
      button.addEventListener("click", (event) => {
        console.log("Next", event);
        let next =
          this.$el.getElementsByClassName("vjs-selected")[0].nextSibling;
        next.click();
        //vjs-selected
        console.log("--", next);
      });
    },
    playNow: function (time = 0) {
      console.log(time,'start')
      if (time) this.player.currentTime(time);
      this.player.play();
      this.$el.getElementsByClassName("vjs-fullscreen-control")[0].click();

      if (time == 0 || !time) {
        let per_watched = (100 * time) / this.player.duration();
        trackingTrigger("workout-video-start", {
          formated: {
            ...this.activity,
            durationWatched: time,
            perWatched: per_watched,
          },
          raw: this.$store.getters["pages/getCurrentActivity"],
          videoType: this.videoType
        });
      }
    },
    playVideoPause: async function (type = "", videoId = "") {
      if (type && videoId && type != this.videoType && !this.isRecommended) {
        this.videoType = type;
        this.player.dispose();
        if (this.player.isDisposed()) {
          this.videoInit(videoId, true);
        }
      } else {
        if (!this.activity.videoID) {
          console.error("Activity video id not found.", this.activity);
          return;
        }
        // If player is already init
        if (this.player) {
          if (this.player.paused()) {
            let totalWatched = VueCookies.get(
              `totalWatched_${this.player.tagAttributes["data-video-id"]}`
            );
            if (totalWatched && totalWatched > 20) {
              const alert = await alertController.create({
                header: "Resume?",
                mode: "ios",
                message: "Would you like to resume where you left off?",
                buttons: [
                  {
                    text: "No",
                    handler: () => {
                      VueCookies.set(
                        `totalWatched_${this.player.tagAttributes["data-video-id"]}`,
                        0
                      );
                      this.playNow();
                    },
                  },
                  {
                    text: "Yes",
                    handler: () => {
                      this.playNow(totalWatched);
                    },
                  },
                ],
              });
              alert.present();
            } else {
              VueCookies.set(
                `totalWatched_${this.player.tagAttributes["data-video-id"]}`,
                0
              );
              this.playNow();
            }
          } else {
            this.player.pause();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chapterised-standalone {
  display: none;
  &.active {
    display: block;
  }
}
.level {
  .status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border: solid 1px var(--ion-color-dark);
    border-radius: 100%;
    margin-left: 3px;
    &.checked {
      background: var(--ion-color-dark);
    }
  }
}
.cover,
.mobile-cover {
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  .cover-bg {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}
</style>