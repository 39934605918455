<template>
  <div class="results-options fixed bottom-5 left-0 w-full z-20">
    <div
      class="
        filter
        flex
        rounded-lg
        border border-black
        shadow
        bg-white
        w-60
        mx-auto
      "
    >
      <ion-button
        class="w-1/2 m-0"
        @click="openFilter"
        :color="hasFilter ? 'primary' : 'white'"
      >
        <div
          class="px-0 py-2.5 md:py-3.5 flex text-dark font-normal"
          :class="hasFilter ? 'text-white' : ''"
        >
          <ion-icon :icon="funnelOutline" class="text-lg mr-2"></ion-icon>
          Filters
        </div>
      </ion-button>
      <ion-button color="white" class="w-1/2 m-0">
        <div class="px-0 py-2.5 md:py-3.5 flex text-dark font-normal">
          Sort
          <ion-icon :icon="swapVerticalOutline" class="text-lg ml-2"></ion-icon>
        </div>
      </ion-button>
    </div>
  </div>
  <Modal :close_float="true" ref="modal" class="filter-container">
    <!-- Workout Setup -->
    <div class="overflow-y-auto h-full pb-10 scroll-wrapper">
      <div class="">
        <header class="mb-5">
          <div class="text-xl font-medium text-center relative">
            <span>Filters</span>
          </div>
        </header>
        <!-- <div class="filter">
          <div class="filter-name text-lg">Meal types</div>
          <div class="filter-options flex py-2 overflow-x-auto">
            <ion-button
              expand="block"
              fill="outline"
              class="sm mr-2"
              :color="
                filterParams.mealTimes.includes(parseInt(meal.value))
                  ? 'primary'
                  : 'light'
              "
              :class="
                filterParams.mealTimes.includes(parseInt(meal.value))
                  ? 'bg-primary bg-opacity-10'
                  : ''
              "
              v-for="meal in mealTimeOptions"
              :key="meal.id"
              @click="filtersFunc('mealTimes', meal.value)"
            >
              <div
                class="py-1 text-sm flex font-medium"
                :class="
                  !filterParams.mealTimes.includes(parseInt(meal.value))
                    ? 'text-dark'
                    : ''
                "
              >
                {{ meal.text }}
              </div>
            </ion-button>
          </div>
          <div class="border-b border-light mx-6 my-3"></div>
        </div>
        <div class="filter">
          <div class="filter-name text-lg">Intentions</div>
          <div class="filter-options flex py-2 overflow-x-auto">
            <ion-button
              expand="block"
              fill="outline"
              class="sm mr-2"
              :color="
                filterParams.intentions.includes(parseInt(intention.value))
                  ? 'primary'
                  : 'light'
              "
              :class="
                filterParams.intentions.includes(parseInt(intention.value))
                  ? 'bg-primary bg-opacity-10'
                  : ''
              "
              v-for="intention in intentionsOptions"
              :key="intention.id"
              @click="filtersFunc('intentions', intention.value)"
            >
              <div
                class="py-1 text-sm flex font-medium"
                :class="
                  !filterParams.intentions.includes(parseInt(intention.value))
                    ? 'text-dark'
                    : ''
                "
              >
                {{ intention.text }}
              </div>
            </ion-button>
          </div>
          <div class="border-b border-light mx-6 my-3"></div>
        </div> -->
        <div class="filter">
          <div class="filter-name text-lg">Benefits</div>
          <div class="filter-options flex py-2 overflow-x-auto">
            <ion-button
              expand="block"
              fill="outline"
              class="sm mr-2"
              :color="
                filterParams.effects.includes(parseInt(effect.value))
                  ? 'primary'
                  : 'light'
              "
              :class="
                filterParams.effects.includes(parseInt(effect.value))
                  ? 'bg-primary bg-opacity-10'
                  : ''
              "
              v-for="effect in effectsOptions"
              :key="effect.id"
              @click="filtersFunc('effects', effect.value)"
            >
              <div
                class="py-1 text-sm flex font-medium"
                :class="
                  !filterParams.effects.includes(parseInt(effect.value))
                    ? 'text-dark'
                    : ''
                "
              >
                {{ effect.text }}
              </div>
            </ion-button>
          </div>
          <div class="border-b border-light mx-6 my-3"></div>
        </div>
        <div class="filter">
          <div class="filter-name text-lg">Category</div>
          <div class="filter-options flex py-2 overflow-x-auto">
            <ion-button
              expand="block"
              fill="outline"
              class="sm mr-2"
              :color="
                filterParams.categories.includes(parseInt(category.value))
                  ? 'primary'
                  : 'light'
              "
              :class="
                filterParams.categories.includes(parseInt(category.value))
                  ? 'bg-primary bg-opacity-10'
                  : ''
              "
              v-for="category in categoriesFieldOptions"
              :key="category.id"
              @click="filtersFunc('categories', category.value)"
            >
              <div
                class="py-1 text-sm flex font-medium"
                :class="
                  !filterParams.categories.includes(parseInt(category.value))
                    ? 'text-dark'
                    : ''
                "
              >
                {{ category.text }}
              </div>
            </ion-button>
          </div>
          <div class="border-b border-light mx-6 my-3"></div>
        </div>
        <div class="dietary">
          <div class="filter-name text-lg my-4">Dietary preferences</div>
          <template
            v-for="(item, index) in remappedDietaryPreferences"
            :key="index"
          >
            <div class="filter" v-if="item.label != 'Gluten'">
              <div class="filter-parent flex">
                <ion-toggle
                  color="primary"
                  mode="ios"
                  class="sm my-auto"
                  v-model="parents[`id_${item.id}`]"
                  @click="selectAll(item, parents[`id_${item.id}`])"
                ></ion-toggle>
                <span class="mx-4 text-lg">{{ item.label }}</span>
                <ion-icon :icon="chevronUp" class="ml-auto my-auto"></ion-icon>
              </div>
              <div
                class="filter-options py-2 px-14"
                v-if="item.preferences.length"
              >
                <div
                  class="filter-option flex mb-2"
                  v-for="(pref, i) in item.preferences"
                  :key="i"
                >
                  <ion-toggle
                    :checked="pref.isChecked"
                    color="primary"
                    mode="ios"
                    class="xs my-auto"
                    v-model="pref.isChecked"
                    @click="checkParent(pref, item)"
                  ></ion-toggle>
                  <span class="mx-5 text-sm">{{ pref.label }}</span>
                </div>
              </div>
            </div>
          </template>

          <div class="border-b border-light mt-5 mb-3"></div>
        </div>

        <!-- <div class="filter">
          <div class="filter-name text-lg">Skill Level</div>
          <div class="filter-options flex py-2 overflow-x-auto">
            <ion-button
              expand="block"
              fill="outline"
              class="sm mr-2"
              :color="
                filterParams.skillLevels.includes(parseInt(skill.value))
                  ? 'primary'
                  : 'light'
              "
              :class="
                filterParams.skillLevels.includes(parseInt(skill.value))
                  ? 'bg-primary bg-opacity-10'
                  : ''
              "
              v-for="skill in skillLevelOptions"
              :key="skill.id"
              @click="filtersFunc('skillLevels', skill.value)"
            >
              <div
                class="py-1 text-sm flex font-medium"
                :class="
                  !filterParams.skillLevels.includes(parseInt(skill.value))
                    ? 'text-dark'
                    : ''
                "
              >
                {{ skill.text }}
              </div>
            </ion-button>
          </div>
        </div> -->
        <footer class="absolute w-full left-0 bottom-0 z-50">
          <div
            class="
              rounded-3xl
              container
              max-w-full
              px-5
              py-3
              bottom-0
              next-container
              bg-white
              flex
              justify-center
            "
          >
            <div
              class="my-auto mr-5 text-dark font-medium cursor-pointer"
              @click="clearFilter"
            >
              Clear filters
            </div>
            <ion-button color="black" expand="block" @click="applyFilters">
              <div class="text-white">Apply Filters</div>
            </ion-button>
          </div>
        </footer>
      </div>
    </div>
    <!-- <WorkoutSetup ref="chatBox" :user="user" @close="handleModalClose"></WorkoutSetup> -->
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/commons/Modal.vue";
import { IonIcon, IonButton, IonToggle } from "@ionic/vue";
import VueCookies from "vue-cookies";
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  searchOutline,
  chevronBack,
  stopwatchOutline,
  heartOutline,
  funnelOutline,
  swapVerticalOutline,
} from "ionicons/icons";

export default {
  name: "FilterTemplate",
  components: { IonIcon, IonButton, Modal, IonToggle },
  props: ["currentPage", "category"],
  data() {
    return {
      parents: {},
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      searchOutline,
      chevronBack,
      stopwatchOutline,
      heartOutline,
      funnelOutline,
      swapVerticalOutline,
      filter: false,
      filterParams: {
        mealTimes: [],
        intentions: [],
        effects: [],
        categories: [],
        dietaryPreferences: [],
        skillLevels: [],
      },
      hasFilter: false,
    };
  },

  computed: {
    ...mapGetters({
      mealTimeOptions: "pages/mealTimeOptions",
      intentionsOptions: "pages/intentionsOptions",
      effectsOptions: "pages/effectsOptions",
      categoriesFieldOptions: "pages/categoriesFieldOptions",
      skillLevelOptions: "pages/skillLevelOptions",
    }),
    dietaryPreferences() {
      return this.$store.getters[`pages/dietaryPreferences`];
    },
    remappedDietaryPreferences() {
      let data = [];
      const journeySetupData = VueCookies.get("journeySetupData");
      var selected_df = journeySetupData.dietary_preferences;
      if (this.dietaryPreferences.categories.length) {
        const categories = this.dietaryPreferences.categories;
        for (let i = 0; i < categories.length; i++) {
          var preferences = [];
          var isParentChecked = true;

          if (categories[i].preferences.edges.length) {
            for (let n = 0; n < categories[i].preferences.edges.length; n++) {
              categories[i].preferences.edges[n].node.isChecked = false;
              if (
                selected_df.includes(
                  parseInt(categories[i].preferences.edges[n].node.id)
                )
              ) {
                categories[i].preferences.edges[n].node.isChecked = true;
              } else {
                isParentChecked = false;
              }
              preferences.push(categories[i].preferences.edges[n].node);
            }
          } else {
            isParentChecked = false;
          }

          data.push({
            id: parseInt(categories[i].id),
            label: categories[i].label,
            value: categories[i].value,
            isChecked: isParentChecked,
            disabled: categories[i].value == "gluten" ? true : false,
            preferences: preferences,
          });
        }
      }
      return data;
    },
    
  },
  mounted() {
    this.$store.dispatch(`pages/getDietaryPreferencesOptions`);
    const journeySetupData = VueCookies.get("journeySetupData");
    this.filterParams.dietaryPreferences = journeySetupData.dietary_preferences;
    this.checkFilter();
    this.compressParents()
  },
  methods: {
    compressParents() {
      const journeySetupData = VueCookies.get("journeySetupData");
      var selected_df = journeySetupData.dietary_preferences;
      if (this.dietaryPreferences.categories.length) {
        const categories = this.dietaryPreferences.categories;
        for (let i = 0; i < categories.length; i++) {
          var isParentChecked = true;

          if (categories[i].preferences.edges.length) {
            for (let n = 0; n < categories[i].preferences.edges.length; n++) {
              categories[i].preferences.edges[n].node.isChecked = false;
              if (
                selected_df.includes(
                  parseInt(categories[i].preferences.edges[n].node.id)
                )
              ) {
                categories[i].preferences.edges[n].node.isChecked = true;
              } else {
                isParentChecked = false;
              }
            }
          } else {
            isParentChecked = false;
          }

          this.parents[`id_${categories[i].id}`] = isParentChecked;
        }
      }
    },
    checkParent(item, parent) {
      this.remappedDietaryPreferences.find((option) => {
        if (option.id == parent.id) {
          option.preferences.forEach((pref) => {
            if (pref.id == item.id) {
              option.isChecked = !item.isChecked ? true : false;
              this.parents[`id_${parent.id}`] = !this.parents[`id_${parent.id}`] ? true : false;
            } else {
              if (pref.isChecked) {
                option.isChecked = true;
                this.parents[`id_${parent.id}`] = true;
              }
            }
          });
        }
      });
    },
    selectAll(parent, status) {
      this.remappedDietaryPreferences.find((option) => {
        if (option.id == parent.id) {
          option.isChecked = !status ? true : false;  
          option.preferences.forEach((pref) => {
            pref.isChecked = !status ? true : false;
          });
        }
      });
    },
    handleModalClose() {
      this.$refs.modal.toggleModal();
    },
    openFilter() {
      this.$refs.modal.toggleModal();
      //this.filter = true;
    },
    filtersFunc(type, value) {
      if (this.filterParams[type].includes(parseInt(value))) {
        this.filterParams[type].splice(
          this.filterParams[type].indexOf(parseInt(value)),
          1
        );
      } else {
        this.filterParams[type].push(parseInt(value));
      }
    },
    checkFilter() {
      if (
        this.filterParams.mealTimes.length ||
        this.filterParams.intentions.length ||
        this.filterParams.effects.length ||
        this.filterParams.categories.length ||
        this.filterParams.dietaryPreferences.length ||
        this.filterParams.skillLevels.length
      ) {
        this.hasFilter = true;
      } else {
        this.hasFilter = false;
      }
    },

    applyFilters() {
      this.args = "";
      this.filterParams.dietaryPreferences = [];
      this.remappedDietaryPreferences.forEach((category) => {
        category.preferences.forEach((preference) => {
          if (preference.isChecked) {
            this.filterParams.dietaryPreferences.push(parseInt(preference.id));
          }
        });
      });
      const params = JSON.parse(JSON.stringify(this.filterParams));

      if (params.mealTimes.length) {
        this.args += `,mealTimes:[${params.mealTimes}]`;
      }

      // if (params.intentions.length) {
      //   this.args += `,intentions:[${params.intentions}]`;
      // }

      if (params.effects.length) {
        this.args += `,effects:[${params.effects}]`;
      }

      if (params.categories.length) {
        this.args += `,categories:[${params.categories}]`;
      }

      if (params.skillLevels.length) {
        this.args += `,skillLevels:[${params.skillLevels}]`;
      }

      if (params.dietaryPreferences.length) {
        this.args += `,dietaryPreferences:[${params.dietaryPreferences}]`;
      }

      this.checkFilter();

      this.$emit("filter-updates", {
        loading: true,
      });

      this.$refs.modal.toggleModal();

      this.$store
        .dispatch(`pages/filterRecipe`, {
          id: this.category,
          args: this.args,
        })
        .then(() => {
          this.$emit("filter-updates", {
            args: this.args,
            loading: false,
          });
        });
    },
    clearFilter() {
      this.args = "";
      this.filterParams = {
        mealTimes: [],
        intentions: [],
        effects: [],
        categories: [],
        dietaryPreferences: [],
        skillLevels: [],
      };

      this.remappedDietaryPreferences.forEach((category) => {
        category.isChecked = false;
        category.preferences.forEach((preference) => {
          preference.isChecked = false;
        });
      });

      this.$refs.modal.toggleModal();
      this.$emit("filter-updates", {
        loading: true,
      });

      this.$store
        .dispatch(`pages/filterRecipe`, {
          id: this.category,
          page: 1,
          args: this.args,
        })
        .then(() => {
          this.$emit("filter-updates", {
            args: this.args,
            loading: false,
          });
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.filter {
  .filter-options {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      @media (max-width: 767px) {
        display: none;
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0.45);
      border-radius: 10px;
      box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
      transition: all 0.2s;
      &:hover {
        background: rgba(0, 0, 0, 0.55);
      }
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.45);
      }
    }

    &::-webkit-scrollbar:vertical {
      display: none;
    }
  }
}
ion-toggle {
  &.sm {
    width: 40px;
    height: 22.5px;
    --handle-width: 19.5px;
    --handle-height: 18px;
  }
  &.xs {
    width: 32px;
    height: 18px;
    --handle-width: 15px;
  }
}
.filter-buttons {
  box-shadow: 0px -1px 10px #00000014;
}
.scroll-wrapper {
  &::-webkit-scrollbar {
    width: 7px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    margin: 54px 0 53px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
    transition: all 0.2s;
    &:hover {
      background: rgba(0, 0, 0, 0.55);
    }
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.45);
    }
  }
}
</style>
