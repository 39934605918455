
<template>
  <div class="subscription-container relative">
    <header class="pt-8 px-6">
      <ion-icon :icon="chevronBack" size="large"></ion-icon>
    </header>

    <div class="body-content mt-8 px-6">
      <h2 class="text-4xl font-bold tracking-tight leading-8 my-4 mb-6">
        Choose Plan!
      </h2>
      <p class="mb-8 tracking-tight">Your subscription includes:</p>
      <ul class="leading-7 pl-10 mb-14 text-sm">
        <li class="flex items-center">
          <ion-icon
            :icon="checkmarkCircleOutline"
            size="small"
            :color="'primary'"
            class="mr-2"
          ></ion-icon>
          <span><b>Move</b> unlimited</span>
        </li>
        <li class="flex items-center">
          <ion-icon
            :icon="checkmarkCircleOutline"
            size="small"
            :color="'primary'"
            class="mr-2"
          ></ion-icon>
          <span><b>Eat</b> unlimited</span>
        </li>
        <li class="flex items-center">
          <ion-icon
            :icon="checkmarkCircleOutline"
            size="small"
            :color="'primary'"
            class="mr-2"
          ></ion-icon>
          <span><b>Learn</b> unlimited</span>
        </li>
        <li class="flex items-center">
          <ion-icon
            :icon="checkmarkCircleOutline"
            size="small"
            :color="'primary'"
            class="mr-2"
          ></ion-icon>
          <span><b>Connect</b> unlimited</span>
        </li>
        <li class="flex items-center">
          <ion-icon
            :icon="checkmarkCircleOutline"
            size="small"
            :color="'primary'"
            class="mr-2"
          ></ion-icon>
          <span><b>Individualised journey</b> unlimited</span>
        </li>
      </ul>
      <div v-for="(item, i) in packages" :key="i++">
        <div
          class="rounded-md mb-5 relative"
          @click="changePlan(i)"
          :class="plan == i ? 'border-2' : 'border'"
        >
          <div
            class="
              bg-primary
              absolute
              w-full
              h-full
              left-0
              top-0
              opacity-50
              z-1
            "
            v-if="plan == i"
          ></div>
          <div class="p-3 px-5 flex items-center justify-between relative z-2">
            <span
              class=" rounded-full inline-block relative w-8 h-8"
              :class="plan == i ? 'bg-primary border-2' : 'border'"
            >
              <ion-icon
                :icon="checkmarkOutline"
                size="meduim"
                :color="'white'"
                class="absolute top-0 bottom-0 right-0 left-0 m-auto"
                v-if="plan == i"
              >
              </ion-icon>
            </span>
            <div class="px-2 leading-4">
              <b
                ><p class="m-0">{{ item.month }} </p></b
              >
              <p class="text-xs w-32">{{ item.description }}</p>
            </div>
            <div class="flex items-center">
              <h2 class="text-2xl font-bold tracking-tight">{{ item.price }}</h2>
              <span class="px-0.5">/</span> <span class="text-xs">month</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="absolute w-full bottom-5 px-6">
        <ion-button
          expand="block"
          color="white"
          class="text-xl mb-4 tracking-tight"
          type="submit"
        >
          <span class="p-4 flex text-black">Select this plan</span>
        </ion-button>

        <p class="text-sm tracking-tight">
          Subscriptions automatically renew unless you turn off auto-renew at
          least 24 hours before the end of the billing period.
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  checkmarkCircleOutline,
  checkmarkOutline,
} from "ionicons/icons";

import { iconLogo } from "@/_helpers/icons";

export default {
  components: {},
  data() {
    return {
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      iconLogo,
      chevronBack,
      checkmarkCircleOutline,
      checkmarkOutline,
      plan: 2,
      packages: [
        {
          month: "1 month",
          price: "$80",
          description: "Cancel anytime",
        },
        {
          month: "3 months",
          price: "$70",
          description: "Cancel anytime after",
        },
        {
          month: "12 months",
          price: "$70",
          description: "Cancel anytime after",
        },
      ],
    };
  },
  methods: {
    changePlan(plan) {
      this.plan = plan;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-container {
  height: 100vh;
  background: url("../../public/assets/images/journey-bg.jpg");
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.9);
  background-size: cover;
  background-position: center;
  overflow-y: auto;
  color: #fff;
  .scroll-y {
    background: red;
  }
  .logo {
    width: 150px;
  }
}
</style>
