<template>
  <div class="workout-setup-chat px-6 pb-6 h-full flex">
    <div class="chat-box min-w-full" ref="chatBox">
      <div class="space h-1/6"></div>
      <Chat
        v-for="(chat, i) in chatData"
        :key="i"
        :avatar="chat.avatar"
        :from="chat.from"
        :type="chat.type"
        :selected="chat.selected"
      >
        <div
          @click.prevent="chatCallback(i, chat.group)"
          class="py-3 px-6 -my-3 -mx-6 cursor-pointer min-w-full"
          v-html="chat.message"
          v-if="chat.callback"
        ></div>
        <template v-if="chat.type == 'action'">
          <div class="min-w-full flex" v-html="chat.message"></div>
        </template>
        <template v-if="!chat.type">
          <span v-if="!chat.callback" v-html="chat.message"></span>
        </template>
      </Chat>
      <div class="bubble-chat mb-3" v-if="showAction">
        <div class="flex">
          <div class="min-w-full flex">
            <ion-button color="black" class="ml-auto" @click="gotoWorkout">
              <div class="px-6 py-3.5 font-normal">Let’s do it!</div>
            </ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { IonButton } from "@ionic/vue";
import Chat from "../commons/BubbleChat.vue";
import { trackingTrigger } from "@/_helpers";
import _ from "lodash";

export default {
  components: { IonButton, Chat },
  props: ["user"],
  data() {
    return { chatData: [], showAction: false, actionLink: "" };
  },
  mounted() {
    this.pageName = this.$route.name;
    //if (this.pageName == "ActivityView") {
    //   const recomm = this.$store.getters["accounts/getRecommended"];
    //   const activityid = this.$route.params.id;
    //   if(!_.isEmpty(recomm.activity)){
    //     if(recomm.activity.activity == activityid){
    //       setTimeout(() => {
    //         this.chatData = [
    //           {
    //             message: `Today’s workout not hitting the mark? Tell us why.`,
    //             avatar: "/assets/images/journey-1.jpg",
    //             group: 2
    //           },
    //           {
    //             message: "It’s too hard",
    //             from: "right",
    //             callback: "down",
    //             group: 2
    //           },
    //           {
    //             message: "It’s too easy",
    //             from: "right",
    //             callback: "up",
    //             group: 2
    //           },
    //           {
    //             message: "I’m on a time limit",
    //             from: "right",
    //             callback: "limit",
    //             group: 2
    //           },
    //           {
    //             message: "I don’t have the equipment",
    //             from: "right",
    //             callback: "equipment",
    //             group: 2
    //           },
    //           {
    //             message: "I have an injury",
    //             from: "right",
    //             callback: "injury",
    //             group: 2
    //           },
    //           {
    //             message: "Other",
    //             from: "right",
    //             callback: "N/A",
    //             group: 2
    //           }
    //         ];
    //       }, 100);
    //     }else{
    //       setTimeout(() => {
    //         this.chatData = [
    //           {
    //             message: `This is an invalid action please try again.`,
    //             avatar: "/assets/images/journey-1.jpg",
    //             group: 1
    //           },
    //         ];
    //       }, 100);
    //     }
    //   }
    // }else{

    setTimeout(() => {
      this.chatData = [
        {
          message: `Hey ${this.user.first_name}, how do you want to feel today?`,
          avatar: "/assets/images/journey-1.jpg",
          group: 1,
        },
        {
          message:
            "<span class='font-medium'>Energised</span><br> I’m strong, I’ve got this.",
          from: "right",
          callback: 216,
          group: 1,
        },
        {
          message:
            "<span class='font-medium'>Motivated</span><br> I'm looking for focus and clarity",
          from: "right",
          callback: 217,
          group: 1,
        },
        {
          message:
            "<span class='font-medium'>Recharged</span><br> I need nurturing",
          from: "right",
          callback: 218,
          group: 1,
        },
        {
          message:
            "<span class='font-medium'>Grounded</span><br> I'd like a calming stretch flow",
          from: "right",
          callback: 219,
          group: 1,
        },
      ];
      this.scrollToEnd();
    }, 200);

    //}
  },
  methods: {
    gotoWorkout() {
      this.$emit("close");
      this.$router.push(this.actionLink);

      // const data = this.$store.getters["accounts/getRecommended"];
      if (data.activity) {
        console.log("W1--", data.activity.status);
        // if(data.activity.status == 'Recommended'){
        //   this.$store.dispatch("accounts/setRecommendedActivityStatus",'opened');
        // }
        // if(data.activity.status == 'opened'){
        //   this.$store.dispatch("accounts/setRecommendedActivityStatus",'viewed');
        // }
      }
    },
    scrollToEnd: function (delay = 300) {
      setTimeout(() => {
        var chatBox = this.$refs.chatBox;
        if (chatBox) {
          chatBox.scrollTop = chatBox.lastElementChild.offsetTop;
        }
      }, delay);
    },
    showRecommend: function ( newData = {}) {
      let data = {}
      if(_.isEmpty(newData)){
        data = this.$store.getters["accounts/getRecommended"];
      }else{
        data.activity = newData;
      }
      if (!_.isEmpty(data.activity)) {
        if (data.activity.activity) {
          this.actionLink = `/move/workout/view/${data.activity.activity}`;
          //console.log('this.actionLink--',this.actionLink);
          //this.$router.replace(this.actionLink);
          window.location.replace(this.actionLink);
          setTimeout(() => {
            this.$emit("close");
          }, 200);
        }
      }
      // if(!_.isEmpty(data.activity)){
      //   if(data.activity.activity){
      //     this.actionLink = `/move/workout/view/${data.activity.activity}`;
      //     setTimeout(() => {
      //       this.$router.push(this.actionLink);
      //       setTimeout(() => {
      //         this.$emit("close");
      //       }, 200);
      //     }, 200);
      //     //this.showAction = true;
      //     this.scrollToEnd();
      //   }
      // }else{
      //   this.$store.dispatch("accounts/setRecommendedActivity",217).then(() => {
      //     this.showRecommend();
      //   })
      // }
    },
    chatCallback: async function (id, group = null) {
      let vm = this;
      if (id && group) {
        this.chatData.forEach((cData) => {
          if (cData.group == group) {
            cData.selected = false;
          }
        });
      }
      this.chatData[id].selected = true;

      // New Recommendation
      if (this.chatData[id].callback && this.chatData[id].group == 1) {
        trackingTrigger("workout-recommended",this.chatData[id]);
        try {
          let isAdapt = false;
          if (this.pageName == "ActivityView") isAdapt = true;
          this.$store
            .dispatch("accounts/setRecommendedActivity", {
              emotion: this.chatData[id].callback,
              isAdapt,
            })
            .then((value) => {
              console.log("New Recommendation--",value);
              this.showRecommend(value.data);
            })
            .catch(function (error) {
              //console.log('error--',error);
            });
        } catch (error) {
          //console.log('Wqq1--',error);
        }
      }

      this.scrollToEnd();
    },
  },
};
</script>

<style lang="scss" scoped>
.workout-setup-chat {
  position: relative;
  overflow: hidden;
  &::before {
    background: transparent
      linear-gradient(180deg, var(--unnamed-color-ffffff) 0%, #ffffff00 100%) 0%
      0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #ffffff 0%, #ffffff00 100%)
      0% 0% no-repeat padding-box;
    content: "";
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .chat-box {
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    margin-right: -41px;
    padding-right: 23px;
    @media (pointer: none), (pointer: coarse) {
      margin-right: -10px;
      padding-right: 10px;
    }
  }
}
</style>
