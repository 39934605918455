import { filterResults, formatSlideData, responseHandler, decodeID } from "@/_helpers";
import api from "@/api";
// import store from "@/store";
import _ from "lodash";
import VueCookies from "vue-cookies";

export default {
  // Get all Activity Record
  async getAllActivityData({ commit, state }) {
    try {
      const type = state.fitness.type;
      const response = await api.getAllActivity(type);
      // Format
      state.allActivity = _.map(
        filterResults(response.data.allActivity),
        data => formatSlideData(data, "activity")
      );
      commit("setAllActivityData", state.allActivity);
    } catch (e) {
      console.log("api.getAllActivityData", e);
      return null;
    }
  },
  // Homepage
  async getHomeData({ commit, state }, ids = []) {
    try {
      let level;
      let pref = [];
      let journeySetup = VueCookies.get("journeySetupData");
      if (journeySetup) {
        level = journeySetup.level;
        pref = journeySetup.dietary_preferences;
        // await store.dispatch(`accounts/getJourneyQuestions`).then(()=> {
        //   //const jQ = store.getters["accounts/getJourneyQuestions"];
        // })
      }
      const response = await api.getInitialActivity(level, pref, ids);
      // Format
      if (!_.isEmpty(ids)) {
        // If id is provided
        let allActivity = []
        ids.forEach(id => {
          allActivity.push(decodeID(response.data['activity_' + id]))
        });
        state.home.fitness = _.map(
          allActivity,
          data => formatSlideData(data, "activity")
        );
      } else {
        // console.log('w--', filterResults(response.data.allActivity));
        // If id is not specify
        state.home.fitness = _.map(
          filterResults(response.data.allActivity),
          data => formatSlideData(data, "activity")
        );
      }

      state.home.nutrition = _.map(filterResults(response.data.recipe), data =>
        formatSlideData(data, "recipe")
      );

      // Add Browse More
      state.home.fitness.push({
        name: "Browse More",
        link: "/move",
        tags: [
          { text: "Body part", url: "" },
          { text: "Mode", url: "" },
          { text: "Warm ups", url: "" },
          { text: "Stretch flow", url: "" }
        ],
        cover: "/assets/images/browse-all-move.jpg",
        browse: true
      });
      state.home.nutrition.push({
        name: "Browse More",
        link: "/eat",
        tags: [
          { text: "Most popular", url: "" },
          { text: "Category", url: "" },
          { text: "Benefits", url: "" }
        ],
        cover: "/assets/images/browse-all-eat.jpg",
        browse: true
      });

      commit("setHomeData", state.home);
    } catch (e) {
      console.log("api.login getInitialActivity #1", e);
      return null;
    }
  },
  // Fitness Page
  async getFitnessData({ commit, state }) {
    try {
      const response = await api.getActivityCategory();

      // Format
      state.fitness.style = _.map(
        filterResults(response.data.exploreByStyle),
        data =>
          formatSlideData(
            { ...data, type: "fitness", category: "style" },
            "explore"
          )
      );
      state.fitness.bodypart = _.map(
        filterResults(response.data.exploreByBodyPart),
        data =>
          formatSlideData(
            { ...data, type: "fitness", category: "bodypart" },
            "explore"
          )
      );
      state.fitness.mode = _.map(
        filterResults(response.data.exploreByMode),
        data =>
          formatSlideData(
            { ...data, type: "fitness", category: "mode" },
            "explore"
          )
      );

      let level;
      let levelParam = "";
      let journeySetup = VueCookies.get("journeySetupData");
      if (journeySetup) {
        level = journeySetup.level;
        if (level) {
          // console.log(`levels_Id:${level}`);
          levelParam = `, levels_Id:${level}`;
        }
      }

      const warmup = await api.getAllActivity(
        `last:5, type:"warmup" ${levelParam}`
      );
      state.fitness.warmup = _.map(
        filterResults(warmup.data.allActivity),
        data => formatSlideData(data, "activity")
      );

      state.fitness.warmup.push({
        name: "More Warm Ups",
        link: "/move/warmup",
        tags: [
          { text: "More", url: "" },
          { text: "Mode", url: "" },
          { text: "Warm Ups", url: "" }
        ],
        cover: "/assets/images/browse-all-move.jpg",
        browse: true
      });

      const workouts = await api.getAllActivity(
        `last:5, type:"workout" ${levelParam}`
      );
      state.fitness.workout = _.map(
        filterResults(workouts.data.allActivity),
        data => formatSlideData(data, "activity")
      );

      state.fitness.workout.push({
        name: "More Workouts",
        link: "/move/workout",
        tags: [
          { text: "More", url: "" },
          { text: "Mode", url: "" },
          { text: "Workouts", url: "" }
        ],
        cover: "/assets/images/browse-all-move.jpg",
        browse: true
      });

      const stretchflow = await api.getAllActivity(
        `last:5, type:"stretch_flow" ${levelParam}`
      );
      state.fitness.stretchflow = _.map(
        filterResults(stretchflow.data.allActivity),
        data => formatSlideData(data, "activity")
      );

      state.fitness.stretchflow.push({
        name: "More Stretch Flows",
        link: "/move/stretch-flow",
        tags: [
          { text: "More", url: "" },
          { text: "Mode", url: "" },
          { text: "Stretch Flow", url: "" }
        ],
        cover: "/assets/images/browse-all-move.jpg",
        browse: true
      });

      commit("setFitnessData", state.fitness);
    } catch (e) {
      console.log("api.getFitnessData #1", e);
      return null;
    }
  },
  async getAllActivityByType({ commit, state }, obj) {
    try {
      // console.log("obj", obj);
      let params = `type:"${state.move.type}"`;
      if (obj !== "") {
        params = `${obj}`;
      }
      const response = await api.getAllActivity(
        `${params}, first:${state.move.first}, offset:${state.move.offset}`
      );
      let data = _.map(filterResults(response.data.allActivity), data =>
        formatSlideData(data, "activity")
      );
      state.move.data = data;
      commit("setMoveData", data);
      return data;
    } catch (e) {
      console.log("api.getAllActivityByType error #1", e);
      return null;
    }
  },
  async getAllFavouritesByType({ commit }, obj) {
    try {
      if (obj.type == "activity") {
        const response = await api.favouriteActivities(
          `first:5, offset:${obj.offset}`
        );
        let result = _.map(
          filterResults(response.data.favouriteActivity),
          data => formatSlideData(data, "activity")
        );
        commit("setFavouritesData", result);
        return result;
      }
      if (obj.type == "recipe") {
        const response = await api.favouriteRecipes(
          `first:5, offset:${obj.offset}`
        );
        let result = _.map(filterResults(response.data.favouriteRecipe), data =>
          formatSlideData(data, "recipe")
        );
        commit("setFavouritesData", result);
        return result;
      }
      if (obj.type == "learn") {
        let response = await api.favouriteLearn();
        let learns = filterResults(response.data.userFavourites.learn)

        const learnObj = _.map(learns, (data) => {
          const nFormat = {
            name: `${data.title}`,
            id: data.id ? parseInt(data.id) : null,
            tags: _.map(data.categories, function (data) {
              return { text: data, url: "" };
            }),
            cover: `${data.featuredImage}`,
            link: `${data.link}?src=app`,
            favourite: true,
            play: false,
            parentType: "learn",
          };
          return nFormat;
        });
        // console.log("learnObj", learnObj);
        commit("setFavouritesData", learnObj);

        return learnObj;
      }
    } catch (e) {
      console.log("api.getAllActivityByType error #1", e);
      return null;
    }
  },
  // Category Page
  async getCategoryData({ commit, dispatch, state }, id) {
    await dispatch(`getFitnessData`);

    let keyID = "";
    if (state.category.category == "style") keyID = "benefit";
    if (state.category.category == "bodypart") keyID = "bodyPart_Id";
    if (state.category.category == "mode") keyID = "mode_Id";

    let journeySetup = VueCookies.get("journeySetupData");
    let level = "";
    if (journeySetup) {
      level = journeySetup.level ? `,levels_Id: ${journeySetup.level}` : "";
      // console.log("level", level);
    }

    await api
      .getAllActivity(
        keyID
          ? `${keyID}:${state.category.id}, first:12, offset:0, type: "${state.fitness.type}" ${level}`
          : ``
      )
      .then(response => {
        let allcategory = [
          ...state.fitness.style,
          ...state.fitness.mode,
          ...state.fitness.bodypart
        ];
        const selectedCtg = _.find(allcategory, { id: id });

        if (selectedCtg) {
          let stateCtg = {
            id: id,
            type: state.fitness.type,
            title: selectedCtg.name,
            category: selectedCtg.data.category,
            activity: _.map(filterResults(response.data.allActivity), data =>
              formatSlideData(data, "activity")
            )
          };
          commit("setCurrentCategory", stateCtg);
        } else {
          commit("setCurrentCategory", {});
        }
      });
  },
  async getActivity({ commit }, id) {
    try {
      const response = await api.getActivity(id);
      commit("setCurrentActivity", response.data.activity);
      commit(
        "setActivityPostProduction",
        filterResults(response.data.activityPostProduction)
      );
      return response;
    } catch (e) {
      console.log("api.getActivity error #1", e);
      return null;
    }
  },
  // Recipe Page
  async getRecipeData({ commit, state }, dietary_preferences) {
    try {
      const response = await api.getRecipeCategory(dietary_preferences);

      // Format
      state.nutrition.mostpopular = _.map(
        filterResults(response.data.mostFavouriteRecipe),
        data => formatSlideData(data, "recipe")
      );
      state.nutrition.mostpopular.push({
        name: "Browse More",
        link: "/eat/popular",
        tags: [
          { text: "Most popular", url: "" },
          { text: "Category", url: "" },
          { text: "Benefits", url: "" }
        ],
        cover: "/assets/images/browse-all-eat.jpg",
        browse: true
      });

      state.nutrition.type = _.map(filterResults(response.data.type), data =>
        formatSlideData({ ...data, category: "type" }, "explore-recipe")
      );
      state.nutrition.category = _.map(
        filterResults(response.data.category),
        data =>
          formatSlideData({ ...data, category: "category" }, "explore-recipe")
      );
      state.nutrition.benefits = _.map(
        filterResults(response.data.benefits),
        data =>
          formatSlideData({ ...data, category: "benefits" }, "explore-recipe")
      );
      state.nutrition.level = _.map(filterResults(response.data.level), data =>
        formatSlideData({ ...data, category: "level" }, "explore-recipe")
      );

      commit("setNutritionData", state.nutrition);
    } catch (e) {
      console.log("api.login getRecipeData #1", e);
      return null;
    }
  },
  // Category Page
  async getRecipeCategoryData({ commit, dispatch, state }, data) {

    try {

      // console.log('Q1--');
      await dispatch(`getRecipeData`).then(async () => {
        // Get Category/Type Details
        const type = state.recipe_category.type;
        const selectedCtg = _.find(state.nutrition[type], { value: data.category });
        commit("setNutritionCategory", {
          key: "title",
          value: selectedCtg.name
        });
        commit("setNutritionCategory", { key: "id", value: selectedCtg.id });
      });



      let keyID = "";
      if (state.recipe_category.type == "type") keyID = "mealTimes_Id";
      if (state.recipe_category.type == "category") keyID = "categories_Id";
      if (state.recipe_category.type == "benefits") keyID = "effects_Id";
      if (state.recipe_category.type == "level") keyID = "skillLevels_Id";
      if (state.recipe_category.type == "level") keyID = "skillLevels_Id";
      let response = await api.getAllRecipe(
        keyID ? `${keyID}:${state.recipe_category.id}, first:12, offset:0, dietaryPreferences: [${data.journey}]` : ``
      );

      commit("setNutritionCategory", {
        key: "recipe",
        value: _.map(filterResults(response.data.allRecipe), data =>
          formatSlideData(data, "recipe")
        )
      });
      return response.data.allRecipe;
    } catch (e) {
      console.log("api.login getRecipeData #1", e);
      return null;
    }
  },
  // Get Recipe
  async getRecipe({ commit }, id) {
    try {
      // get ingredients
      //let ids = _.union([334,335,336,32,335,336]);
      //await dispatch(`getIngredient`, []).then(async () => { });

      const response = await api.getRecipe(id);
      // console.log(response.data.recipe.relatedRecipes)
      if (_.has(response.data.recipe, "relatedRecipes") && response.data.recipe.relatedRecipes.edges.length > 0) {
        response.data.recipe.relatedRecipes = _.map(filterResults(response.data.recipe.relatedRecipes), data =>
          formatSlideData(data, "recipe")
        )
      } else {
        response.data.recipe.relatedRecipes = [];
      }

      commit("setCurrentRecipe", response.data.recipe);
      return response;
    } catch (e) {
      console.log("api.getActivity error #1", e);
      return null;
    }
  },
  // Get all ingredients or single ingredient by id
  async getIngredient({ commit }, id = []) {
    try {
      let response = await api.getIngredient();
      response = responseHandler(response);
      response = response.data.results;
      commit("setIngredient", response);

      if (!_.isEmpty(id)) {
        response = _.filter(response, v => _.includes(id, v.id));
        commit("setCurrentIngredient", response);
      }

      return response;
    } catch (e) {
      console.log("api.getIngredient error #1", e);
      return null;
    }
  },
  // Fitness Page
  async getFitnessStretchflowData({ commit, state }) {
    try {
      const response = await api.getActivityStretchflow();

      // Format
      state.fitnessStretchflow.latest = _.map(
        filterResults(response.data.latest),
        data => formatSlideData(data, "activity")
      );
      state.fitnessStretchflow.activity = _.map(
        filterResults(response.data.activity),
        data => formatSlideData(data, "activity")
      );

      commit("setFitnessData", state.fitnessStretchflow);
    } catch (e) {
      console.log("api.getFitnessStretchflowData getFitnessData #1", e);
      return null;
    }
  },
  // ACTIVITY LOADMORE
  async loadmoreActivity({ commit, state }, data) {
    const page = data.page * 12;
    var args = `first:12, offset:${page},` + data.args
    var selectedCtg = false

    if (!data.isRedirected) {
      //const search = data.search;
      let keyID = "";
      if (state.category.category == "style") keyID = "benefit";
      if (state.category.category == "bodypart") keyID = "bodyPart_Id";
      if (state.category.category == "mode") keyID = "mode_Id";

      let journeySetup = VueCookies.get("journeySetupData");
      let level = "";
      if (journeySetup) {
        level = journeySetup.level ? `,levels_Id: ${journeySetup.level}` : "";
        // console.log("level", level);
      }

      args = keyID
        ? `${keyID}:${state.category.id}, first:12, offset:${page}, type: "${state.fitness.type}" ${level}`
        : ``;

      if (data.args) {
        args = keyID
          ? `${keyID}:${state.category.id}, first:12, offset:${page},` + data.args
          : ``;
      }

      let allcategory = [
        ...state.fitness.style,
        ...state.fitness.mode,
        ...state.fitness.bodypart
      ];
      selectedCtg = _.find(allcategory, { id: data.id });
    }

    let response = await api.getAllActivity(args);

    let stateCtg = {}
    if (selectedCtg) {
      // console.log(data.page)
      // console.log(selectedCtg, 'ture')
      stateCtg = {
        id: data.id,
        type: state.fitness.type,
        title: selectedCtg.name,
        category: selectedCtg.data.category,
        activity: _.map(filterResults(response.data.allActivity), data =>
          formatSlideData(data, "activity")
        )
      }
      commit("addCurrentCategory", stateCtg);
    } else {
      let params = new URLSearchParams(data.args);
      // console.log(selectedCtg, 'asd')
      stateCtg = {
        id: data.id,
        type: params.get("type"),
        title: params.get("type"),
        category: "",
        activity: _.map(filterResults(response.data.allActivity), data =>
          formatSlideData(data, "activity")
        )
      }
      if (data.page !== 0) {
        commit("addCurrentCategory", stateCtg);
      } else {
        commit("setCurrentCategory", stateCtg);
      }
    }

    response.data.allActivity['formatted'] = stateCtg.activity
    return response.data.allActivity
  },

  //ACTIVITY FIELD OPTIONS
  async fieldOptions({ commit }) {
    try {
      const response = await api.fieldOptions();

      const finalOptions = {};
      response.data.activityFieldOptions.edges.forEach(option => {
        if (typeof finalOptions[option.node.optionFor] == "undefined") {
          finalOptions[option.node.optionFor] = [];
        }
        finalOptions[option.node.optionFor].push({
          id: option.node.pk,
          value: option.node.value,
          label: option.node.label,
          optionFor: option.node.optionFor
        });
      });
      commit("setFieldOptions", finalOptions);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
  async filterActivity({ state, commit }, data) {
    var args = `,first:12, offset:0`;
    if (data.args) {
      args = data.args + `,first:12, offset:0`;
    }
    try {
      let keyID = "";
      if (state.category.category == "style") keyID = "benefit";
      if (state.category.category == "bodypart") keyID = "bodyPart_Id";
      if (state.category.category == "mode") keyID = "mode_Id";

      const response = await api.filterActivity(
        keyID ? `${keyID}:${state.category.id},` + args : ``
      );

      let allcategory = [
        ...state.fitness.style,
        ...state.fitness.mode,
        ...state.fitness.bodypart
      ];
      const selectedCtg = _.find(allcategory, { id: data.id });

      if (selectedCtg) {
        let stateCtg = {
          id: data.id,
          type: state.fitness.type,
          title: selectedCtg.name,
          category: selectedCtg.data.category,
          activity: _.map(filterResults(response.data.allActivity), data =>
            formatSlideData(data, "activity")
          )
        };
        commit("setCurrentCategory", stateCtg);
      }
      return response.data.allActivity;
    } catch (e) {
      return null;
    }
  },

  //RECIPE FIELD OPTIONS
  async recipeFieldOptions({ commit }) {
    try {
      const response = await api.recipeFieldOptions();

      const finalOptions = {};
      response.data.recipeFieldOptions.edges.forEach(option => {
        if (typeof finalOptions[option.node.optionFor] == "undefined") {
          finalOptions[option.node.optionFor] = [];
        }
        finalOptions[option.node.optionFor].push({
          id: option.node.pk,
          value: option.node.value,
          label: option.node.label,
          optionFor: option.node.optionFor
        });
      });
      commit("setRecipeFieldOptions", finalOptions);
    } catch (e) {
      console.log(e);
      return null;
    }
  },

  /// RECIPES
  // async searchRecipes({ commit, state }) {
  //   try {
  //     let keyID = "";
  //     if (state.recipe_category.type == "type") keyID = "mealTimes_Id";
  //     if (state.recipe_category.type == "category") keyID = "categories_Id";
  //     if (state.recipe_category.type == "benefits") keyID = "effects_Id";
  //     if (state.recipe_category.type == "level") keyID = "skillLevels_Id";

  //     let response = await api.getAllRecipe(
  //       keyID ? `${keyID}:${state.recipe_category.id}, first:12, offset:0` : ``
  //     );
  //     commit("setNutritionCategory", {
  //       key: "recipe",
  //       value: _.map(filterResults(response.data.allRecipe), data =>
  //         formatSlideData(data, "recipe")
  //       )
  //     });
  //     return response;
  //   } catch (e) {
  //     console.log("api.getAllRecipe error #1", e);
  //     return null;
  //   }
  // },

  async loadmore({ commit, state }, data) {
    var page = data.page * 12;

    try {
      let keyID = "";
      if (state.recipe_category.type == "type") keyID = "mealTimes_Id";
      if (state.recipe_category.type == "category") keyID = "categories_Id";
      if (state.recipe_category.type == "benefits") keyID = "effects_Id";
      if (state.recipe_category.type == "level") keyID = "skillLevels_Id";


      var args = keyID
        ? keyID
          ? `${keyID}:${state.recipe_category.id}, first:12, offset:${page},dietaryPreferences: [${data.journey}]`
          : ``
        : ``;

      if (data.args) {
        args = keyID
          ? `${keyID}:${state.recipe_category.id}, first:12, offset:${page}, ${data.args}`: ``;
      }

      let response = await api.getAllRecipe(args);
      commit("addNutritionCategory", {
        key: "recipe",
        value: _.map(filterResults(response.data.allRecipe), data =>
          formatSlideData(data, "recipe")
        )
      });
      return response.data.allRecipe;
    } catch (e) {
      console.log("api.getAllRecipe error #1", e);
      return null;
    }
  },

  async filterRecipe({ commit, state }, data) {
    var args = `,first:12, offset:0`;
    if (data.args) {
      args = data.args + `,first:12, offset:0`;
    }
    
    console.log(args)
    console.log(state.recipe_category.type)
    try {
      let keyID = "";
      if (state.recipe_category.type == "type") keyID = "mealTimes_Id";
      if (state.recipe_category.type == "category") keyID = "categories_Id";
      if (state.recipe_category.type == "benefits") keyID = "effects_Id";
      if (state.recipe_category.type == "level") keyID = "skillLevels_Id";

      let response = await api.filterRecipe(
        keyID ? `${keyID}:${state.recipe_category.id}` + args : ``
      );

      commit("setNutritionCategory", {
        key: "recipe",
        value: _.map(filterResults(response.data.allRecipe), data =>
          formatSlideData(data, "recipe")
        )
      });
      return response.data.allRecipe;
    } catch (e) {
      console.log(e);
      return null;
    }
  },

  async getDietaryPreferencesOptions({ commit }) {
    try {
      let response = await api.getDietaryPreferencesOptions();

      let categories = filterResults(response.data.dietaryPreferenceCategories);

      let dietaryPreferences = filterResults(response.data.dietaryPreferences);

      commit("setDietaryPreferences", {
        categories: categories,
        data: dietaryPreferences
      });
      return response.data.allRecipe;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
  async getPopularRecipes({ commit, state }, obj) {
    try {
      let rowsperpage = obj.rowsperpage ? obj.rowsperpage : 5;
      const response = await api.getPopularRecipes(
        `first:${rowsperpage}, offset:${obj.offset}`
      );
      // Format
      let result = _.map(
        filterResults(response.data.mostFavouriteRecipe),
        data => formatSlideData(data, "recipe")
      );
      state.nutrition.mostpopular = result;
      commit("setNutritionData", state.nutrition);
      return result;

    } catch (e) {
      console.log("api.getPopularRecipes error #1", e);
      return null;
    }
  },
  async searchActivity({ commit }, search) {
    try {

      // GET INITAL DATA FROM REST
      const response = await api.searchActivity(search);
      let activityIds = [];
      if (response.data.length) {
        // GET ACTIVITY IDS
        for (let i = 0; response.data.length > i; i++) {
          if (!activityIds.includes(response.data[i].activity_id)) {
            activityIds.push(response.data[i].activity_id)
          }
        }

        // GET ACTIVITIES FROM IDS
        let activities = [];
        try {
          let res = await api.searchData(activityIds);
          for (let i = 0; activityIds.length > i; i++) {
            if (res.data[`activity_${activityIds[i]}`] != null) {
              res.data[`activity_${activityIds[i]}`].id = activityIds[i]
              activities.push(res.data[`activity_${activityIds[i]}`])
            }
          }
          activities = _.map(
            activities,
            data => formatSlideData(data, "activity")
          );
          return activities
        } catch (e) {
          console.log("api.searchData error #1", e);
          commit("setActivities", []);
          return [];
        }
      } else {
        commit("setActivities", []);
        return [];
      }

    } catch (e) {
      console.log("api.searchActivity error #1", e);
      commit("setActivities", []);
      return [];
    }
  },
  async searchRecipes({ commit }, search) {
    console.log(commit)
    try {

      // GET INITAL DATA FROM REST
      const response = await api.searchRecipes(search);
      let recipeIds = [];
      if (response.data.length) {
        // GET RECIPES IDS
        for (let i = 0; response.data.length > i; i++) {
          if (!recipeIds.includes(response.data[i].id)) {
            recipeIds.push(response.data[i].id)
          }
        }

        // GET RECIPES FROM IDS
        let recipes = [];
        try {
          let res = await api.searchDataRecipes(recipeIds);
          for (let i = 0; recipeIds.length > i; i++) {
            if (res.data[`recipe_${recipeIds[i]}`] != null) {
              res.data[`recipe_${recipeIds[i]}`].id = recipeIds[i]
              recipes.push(res.data[`recipe_${recipeIds[i]}`])
            }
          }
          return _.map(
            recipes,
            data => formatSlideData(data, "recipe")
          );
        } catch (e) {
          console.log("api.searchDataRecipes error #1", e);
          return [];
        }

      } else {
        return [];
      }


    } catch (e) {
      console.log("api.searchRecipes error #1", e);

      return [];
    }
  },

  async searchLoadmoreActivity({ commit }, page) {
    console.log(commit)
    try {
      const offset = page * 12;
      var args = `first:12, offset:${offset}`
      const response = await api.searchLoadmoreActivity(args);
      let activitites = _.map(
        filterResults(response.data.allActivity),
        data => formatSlideData(data, "activity")
      );
      return {
        formatted: activitites,
        raw: response.data.allActivity
      }

    } catch (e) {
      console.log("api.searchRecipes error #1", e);

      return null;
    }
  },

  async searchLoadmoreRecipe({ commit }, page) {
    console.log(commit)
    try {
      const offset = page * 12;
      var args = `first:12, offset:${offset}`
      const response = await api.getAllRecipe(args);
      let recipes = _.map(
        filterResults(response.data.allRecipe),
        data => formatSlideData(data, "recipe")
      );
      return {
        formatted: recipes,
        raw: response.data.allRecipe
      }

    } catch (e) {
      console.log("api.searchRecipes error #1", e);

      return null;
    }
  },

  async getCoupon({commit}, data) {
    console.log(commit)
    try {
      const response = await api.getCoupon(data);
      return response.data;
    } catch (e) {
      console.log("api.getCoupon error ", e);
      return null;
    }
  },

  async getPromoCode({commit}, data) {
    console.log(commit)
    try {
      const response = await api.getPromoCode(data);
      return response.data;
    } catch (e) {
      console.log("api.getPromoCode error ", e);
      return null;
    }
  },

};
