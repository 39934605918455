<script>
import { IonSpinner } from "@ionic/vue";
import ResetPasswordForm from "@/components/forms/ResetPassword.vue";
import api from "@/api";
export default {
  name: "reset-password",
  components: { IonSpinner, ResetPasswordForm },
  data() {
    return {
      loading: true,
      token: null,
      isValid: true
    };
  },
  async mounted() {
    // console.log("Reset Password page");
    // let uri = window.location.search.substring(1);
    // let params = new URLSearchParams(uri);
    // console.log(params.get("token"));
    // console.log(this.$route.query.token);
    this.loading = true;
    this.token = this.$route.query.token;
    const response = await api.validateResetPasswordToken({
      token: this.token
    });
    // console.log("response", response);
    if (
      response &&
      response.status == 200 &&
      response.data.message == "Valid token."
    ) {
      this.loading = false;
      this.isValid = true;
    } else {
      this.loading = false;
      this.isValid = false;
    }
    // this.token = this.$route.params.token;
    // console.log("this.token", this.token);
    // this.token = "test";
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <container-cover>
    <div class="bg-white flex h-full">
      <div class="max-w-sm w-full m-auto px-12">
        <h1 class="text-4xl font-bold">
          Welcome to
          <span class="font-hokyaa font-normal text-primary mb-3">Xali</span>
        </h1>
        <p class="mb-16 text-sm"></p>
        <div class="text-center" v-if="loading">
          <ion-spinner class="p-5"></ion-spinner>
          <p>validating...</p>
        </div>

        <div
          v-if="!loading && !isValid"
          class="mt-10 border rounded-lg mb-5 py-3.5 px-6 bg-primary bg-opacity-5 border-primary"
        >
          Either the token is expired or invalid. Please try
          <router-link to="/forgot-password">
            <span style="color: blue; cursor:pointer; font-weight:bold;"
              >again</span
            >
          </router-link>
        </div>

        <ResetPasswordForm
          v-if="!loading && isValid"
          :token="token"
        ></ResetPasswordForm>
      </div>
    </div>
  </container-cover>
</template>
