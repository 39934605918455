<template>
  <container-cover>
    <div class="bg-white flex h-full">
      <div class="max-w-sm w-full m-auto px-12">
        <h1 class="mt-5 text-4xl font-bold text-center">
          Welcome to
          <span class="font-hokyaa font-normal text-primary mb-3">Xali</span>
        </h1>
        <p class="mb-8 md:mt-16 text-sm"></p>
        <LoginForm v-if="!loading" :fbSDKLoaded="fbSDKLoaded"></LoginForm>
      </div>
    </div>

    <!-- <xali-loading loadingStyle="loader" :fixed="false" wrapperClass="bg-opacity-80">Loading</xali-loading> -->
  </container-cover>
</template>

<script>
//import { initFacebookSdk } from '@/_helpers';
import LoginForm from "../components/forms/Login.vue";
import router from "@/router";
import { initFacebookSdk } from "@/_helpers";
export default {
  components: { LoginForm },
  data() {
    return {
      loading: true,
      fbSDKLoaded: false,
    };
  },
  created() {
    // redirect to home if already logged in
    if (this.$store.getters["accounts/isAuthenticated"]) {
      router.push("/");
    }
  },
  mounted() {
    setTimeout(() => {
      if (window.fbAsyncInit && window.FB) {
        // console.log("Facebook JS SDK detected");
        this.fbSDKLoaded = true;
      } else {
        initFacebookSdk().then(() => {
          // console.log(response);
          this.fbSDKLoaded = true;
          // console.log("Facebook JS SDK Loaded");
        });
      }
      this.loading = false;
    }, 500);
  },
  methods: {
    logout: async function () {
      if (this.$route.name == "Logout" || this.$route.href == "/logout") {
        await this.$store.dispatch("accounts/logout").then(() => {
          //this.$router.push({ path: "/login" });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
