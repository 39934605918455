

<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <container-cover>
        <div class="bg-white flex h-full">
          <div class="subscription-container relative w-full">
            <div class="md:px-16 lg:px-22 xl:px-44">
              <header class="pt-8 px-12">
                <ion-icon
                  :icon="checkmarkCircle"
                  size="large"
                  :color="'white'"
                ></ion-icon>
              </header>

              <div class="body-content mt-8 px-12">
                <p class="text-xl tracking-tight">
                  Thank you {{ userData.full_name }}!
                </p>
                <h2
                  class="text-3xl font-bold tracking-tight leading-8 my-4 mb-8"
                >
                  We’ll miss you {{ userData.full_name }}!
                </h2>

                <p class="mb-4 tracking-tight text-lg">
                  Your Xali subscription has been cancelled.
                </p>
                <p class="mb-4 tracking-tight text-md">
                  You will be able to stay connected until {{ nextRenewal }}!
                </p>
              </div>

              <footer>
                <div
                  class="
                    absolute
                    bottom-5
                    left-0
                    px-12
                    md:px-16
                    lg:px-22
                    xl:px-44
                    w-full
                  "
                >
                  <a href="/home">
                    <ion-button
                      expand="block"
                      color="white"
                      text="black"
                      class="text-lg mb-4 tracking-tight"
                      type="submit"
                    >
                      <span class="p-4 flex text-black">Return to Home</span>
                    </ion-button>
                  </a>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </container-cover>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  checkmarkCircle,
} from "ionicons/icons";

import { iconLogo } from "@/_helpers/icons";

export default {
  components: {},
  data() {
    return {
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      iconLogo,
      chevronBack,
      checkmarkCircle,
    };
  },

  computed: {
    userData() {
      return this.$store.getters["accounts/getUser"];
    },
    // nextRenewal() {
    //   var currentDate = moment();
    //   return currentDate
    //     .add(parseInt(this.userData.detail.subscription.remaining_days), "days")
    //     .format("DD/MM/YYYY ");
    // },
    nextRenewal() {
      var newDate = new Date(this.userData.detail.subscription.next_renewal);
      var d = newDate.getDate();
      var m = newDate.getMonth() + 1; //Month from 0 to 11
      var y = newDate.getFullYear();
      return (d <= 9 ? "0" + d : d) + "-" + (m <= 9 ? "0" + m : m) + "-" + y;
    }
  },
};
</script>

<style lang="scss" scoped>
.subscription-container {
  min-height: 100%;
  overflow-y: auto;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.9);
  background-size: cover !important;
  background-position: center !important;
  color: #fff;

  @media (max-width: 768px) {
    background: url("../../../public/assets/images/signupbg.jpg");
  }
  .logo {
    width: 150px;
  }
}
</style>
