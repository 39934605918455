<template>
  <div>
    <div v-if="showModal" class="modal-container overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
        <div v-bind:style="{ 'background-image': 'url(' + background + ')' }" v-bind:class="[height_class ? height_class:'min-h-8/10 h-4/5']" class="modal-box relative w-full my-6 mx-auto mt-auto mb-0 sm:my-auto sm:max-w-sm border-0 sm:rounded-3xl shadow-lg bg-white">
          <!--content-->
          <div class="relative flex flex-col w-full h-full outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-4 pb-0" v-if="header">
              <header class="my-auto w-full">
                <slot name="header"></slot>
              </header>
              <a href="#" class="my-auto ml-2 md:ml-auto z-50" v-bind:class="[close_float ? 'absolute top-2 right-2':'relative']" @click.prevent="toggleModal()">
                <ion-icon :icon="closeCircle" class="block text-light text-5xl"></ion-icon>
                <ion-icon :icon="close" class="block text-black text-3xl absolute top-0 m-2 border border-transparent"></ion-icon>
              </a>
            </div>
            <!--body-->
            <div class="relative p-6 flex-auto h-full" v-bind:class="[close_float ? 'pt-1':'']">
              <main class="h-full">
                <slot></slot>
              </main>
            </div>
            <!-- Action -->
            <div class="flex items-start justify-between p-4 pb-0 hidden">
              <slot name="action"></slot>
            </div>
          </div>
        </div>
      
    </div>
    <div v-if="showModal" class="modal-cover opacity-80 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { closeCircle, close } from 'ionicons/icons';

export default {
  name: "small-modal",
  components: { IonIcon },
  props: {
    header: {
        type: Boolean,
        default: true
    },
    close_float: {
        type: Boolean,
        default: false
    },
    background: {
        type: String,
        default: ''
    },
    height_class: {
        type: String,
        default: 'min-h-8/10 h-4/5'
    },
  },
  data() {
    return { showModal: false }
  },
  setup() {
    return { close, closeCircle }
  },
  methods: {
    toggleModal: function(){
      this.showModal = !this.showModal;
      return this.showModal;
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-container{
  max-height: 1024px;
  margin: auto;
}
.modal-cover{
  // backdrop-filter: blur(5px);
  // -webkit-backdrop-filter: blur(5px);
}
.modal-box{
  background-size: cover;
  background-position: center;
}

.filter-container {
  .modal-container {
    .modal-box {
      @media (min-width: 767px){
        max-width: 95% !important;
      }
    }
  }
}
</style>