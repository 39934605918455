<template>
  <ion-page>
    <ion-content
      :scroll-events="true"
      @ionScroll="
        $refs.header.setFixed($event.detail.scrollTop),
          setSliderFocus($event.detail.scrollTop)
      "
    >
      <xali-loading loadingStyle="loader" :fixed="true" v-if="loading"
        >Loading</xali-loading
      >

      <!-- Heading -->
      <xali-header ref="header"></xali-header>

      <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
        <div class="heading flex flex-col sm:flex-row">
          <h1 class="text-4.5xl font-bold">Move</h1>
          <div
            class="search flex bg-black bg-opacity-5 rounded-2lg mt-4 sm:hidden"
          >
            <ion-icon
              :icon="searchOutline"
              class="block text-2xl text-dark my-auto ml-2"
            ></ion-icon>
            <ion-input
              @ionInput="search = $event.target.value"
              placeholder="Search a workout"
              v-on:keyup="updateSearchValue"
            ></ion-input>
          </div>
          <div class="search-button ml-auto my-auto hidden sm:block">
            <ion-icon
              :icon="searchOutline"
              class="block text-2xl text-dark ml-auto"
              @click="openSearch"
              v-if="!searchStatus"
            >
            </ion-icon>

            <div
              class="search flex bg-light bg-opacity-30 rounded-md block mt-2.5"
              v-if="searchStatus"
              :class="searchBtn ? 'mr-1' : ''"
            >
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
              <ion-input
                @ionInput="search = $event.target.value"
                placeholder="Search a workout"
                v-on:keyup="updateSearchValue"
                autofocus="true"
                ref="searchbar"
              ></ion-input>
            </div>
          </div>
          <router-link :to="`/move/search/${searchValue}`" v-if="searchBtn">
            <ion-button class="bg-primary rounded-md mt-3" fill="outline">
              <div class="px-2 py-3 font-normal flex text-white">Search</div>
            </ion-button>
          </router-link>
        </div>
      </div>

      <div class="container max-w-full mt-0 px-0" v-if="!loading">
        <template v-if="type == 'stretchflow'">
          <!-- Stretchflow -->
          <section class="mb-7 md:mb-11" v-if="workoutWarmUps.length">
            <Carousel
              title="Explore by Latest"
              v-bind:items="stretchflowLatest"
            ></Carousel>
          </section>

          <div class="carousel-title flex mb-4 px-6 lg:px-12">
            <h2 class="text-2xl md:text-3xl font-semibold">Stretch flows</h2>
          </div>
          <div
            class="
              container
              max-w-full
              mt-0
              px-6
              lg:px-12
              grid grid-cols-12
              gap-0
              sm:gap-4
            "
          >
            <div
              v-for="(item, i) in stretchflow"
              :key="i++"
              class="col-span-12 sm:col-span-6 lg:col-span-4 mb-5"
            >
              <CarouselItem v-bind:data="item"></CarouselItem>
            </div>
          </div>
          {{ type }}
        </template>
        <template v-else>
          <section class="mb-7 md:mb-11" v-if="workoutStyle.length">
            <Carousel
              title="Explore by benefit"
              v-bind:items="workoutStyle"
            ></Carousel>
          </section>
          <section class="mb-7 md:mb-11" v-if="workoutBody.length">
            <Carousel
              title="Explore by body part"
              v-bind:items="workoutBody"
            ></Carousel>
          </section>
          <section class="mb-7 md:mb-11" v-if="workoutMode.length">
            <Carousel
              title="Explore by mode"
              v-bind:items="workoutMode"
            ></Carousel>
          </section>

          <!-- Warm ups -->
          <section class="mb-7 md:mb-11" v-if="workoutWarmUps.length">
            <Carousel
              title="Warm ups"
              v-bind:items="workoutWarmUps"
              :link="{ text: 'Browse All', url: '/move/warmup' }"
            ></Carousel>
          </section>
          <!-- Work Oouts -->
          <section class="mb-7 md:mb-11" v-if="workouts.length">
            <Carousel
              title="Workouts"
              v-bind:items="workouts"
              :link="{ text: 'Browse All', url: '/move/workout' }"
            ></Carousel>
          </section>
          <!-- Stretch Flow -->
          <section class="mb-7 md:mb-11" v-if="workoutStretchFlow.length">
            <Carousel
              title="Stretch flows"
              v-bind:items="workoutStretchFlow"
              :link="{ text: 'Browse All', url: '/move/stretch-flow' }"
            ></Carousel>
          </section>
        </template>
      </div>

      <!-- Footer -->
      <xali-footer></xali-footer>
      <div class="h-20 md:hidden"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { heartOutline, searchOutline } from "ionicons/icons";
import Carousel from "@/components/pages/carousel/Carousel.vue";
import CarouselItem from "@/components/pages/carousel/CarouselItem.vue";
import { setSliderFocus } from "@/_helpers";
import _ from "lodash";
import { IonContent, IonPage, IonButton, IonIcon, IonInput } from "@ionic/vue";

export default {
  name: "Library",
  components: {
    IonContent,
    IonPage,
    Carousel,
    IonButton,
    IonIcon,
    IonInput,
    CarouselItem,
  },
  data() {
    return {
      loading: true,
      type: "workout",
      workoutStyle: [],
      workoutBody: [],
      workoutMode: [],
      workoutWarmUps: [],
      workoutStretchFlow: [],
      searchOutline,
      heartOutline,
      stretchflowLatest: [],
      stretchflow: [],
      workouts: [],
      searchBtn: false,
      searchStatus: false,
      searchValue: "",
    };
  },
  mounted() {
    this.type = this.$route.params.type ? this.$route.params.type : "all";
    // console.log("L this.type", this.type);
    this.$store.commit("pages/setFitnessType", this.type);

    if (this.type == "stretchflow") {
      this.$store.dispatch(`pages/getFitnessStretchflowData`).then(() => {
        // console.log("w--", this.$store.getters["pages/getFitnessStretchflow"]);
        this.stretchflowLatest =
          this.$store.getters["pages/getFitnessStretchflow"].latest;
        this.stretchflow =
          this.$store.getters["pages/getFitnessStretchflow"].activity;
        setTimeout(() => {
          this.loading = false;
        }, 100);
      });
    } else {
      this.$store.dispatch(`pages/getFitnessData`).then(() => {
        this.workoutStyle = this.removeEmpty(
          this.$store.getters["pages/getFitness"].style
        );
        // this.workoutBody = this.removeEmpty(
        //   this.$store.getters["pages/getFitness"].bodypart
        // );
        this.workoutBody = this.$store.getters["pages/getFitness"].bodypart;
        this.workoutMode = this.removeEmpty(
          this.$store.getters["pages/getFitness"].mode
        );
        this.workoutWarmUps = this.$store.getters["pages/getFitness"].warmup;
        this.workoutStretchFlow =
          this.$store.getters["pages/getFitness"].stretchflow;
        this.workouts = this.$store.getters["pages/getFitness"].workout;
        // console.log("this.workoutStyle--", this.workoutStyle);

        // Trigger focus slider
        setTimeout(() => {
          this.setSliderFocus(1);
        }, 1000);

        setTimeout(() => {
          this.loading = false;
        }, 100);
      });
    }
  },
  watch: {
    "$route.params.type"(value) {
      this.type = value;
      this.$store.commit("pages/setFitnessType", this.type);
    },
  },
  methods: {
    removeEmpty(data) {
      data = _.map(data, function square(ctg) {
        if (ctg.data.count) return ctg;
      });
      return _.compact(data);
    },
    setSliderFocus(sTop) {
      setSliderFocus(sTop);
    },
    updateSearchValue(e) {
      if (this.search && e.keyCode === 13) {
        this.searchFunc()
      }
      this.searchValue = this.search;
      if (this.search) {
        this.searchBtn = true;
      } else {
        this.searchBtn = false;
      }
    },
    openSearch() {
      this.searchStatus = true;
      this.$refs.searchbar.$el.focus();
    },
  },
};
</script>
