
<template>
   <ion-page class="max-w-md mx-auto">
       <ion-content :fullscreen="true">
           <div class="container p-6 setting-container">
               <header class="mb-5 relative">
                   <div class="back flex -mt-1 -ml-4"><ion-back-button defaultHref="/home" :icon="chevronBack" class="text-xl"></ion-back-button><span class="my-auto -ml-2 pb-2px">Back</span></div>
                    <div class="flex absolute -top-0 -right-1">
                        <router-link to="/home" class="relative">
                            <ion-icon :icon="closeCircle" class="block text-light text-5xl opacity-30"></ion-icon>
                            <ion-icon :icon="closeOutline" class="block text-black text-3xl absolute top-0 m-2 border border-transparent"></ion-icon>
                        </router-link>
                    </div>
                    <div class="text-4.5xl font-bold leading-10">
                        <div>Settings</div>
                    </div>
               </header>
                

                <div class="settings-items">

                </div>

                <ion-img :src="'/assets/images/icon.svg'" class="absolute bottom-16 -right-12 w-36 opacity-10"></ion-img>
                <footer class="fixed w-full left-0 bottom-0 z-50">
                    <div class="border-t max-w-md mx-auto bg-white px-6 py-2.5 flex">
                        <div class="my-auto">Logout</div>
                        <ion-icon :icon="logOutOutline" class="text-xl ml-auto my-auto"></ion-icon>
                    </div>
                </footer>
            </div>
            
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonContent, IonBackButton, IonIcon, IonImg } from '@ionic/vue';
import { closeCircle, closeOutline, chevronUp, chevronDown, chevronBack, logOutOutline } from 'ionicons/icons';

export default{
  components: { IonPage, IonContent, IonBackButton, IonIcon, IonImg },
  data(){
      return { closeCircle, closeOutline, chevronUp, chevronDown, chevronBack, logOutOutline }
  }
}
</script>

<style lang="scss" scoped>
// .setting-container{
//     background-image: url('/assets/images/icon.svg');
// }
.filter{
    .filter-options::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
ion-toggle {
    &.sm{
        width: 40px;
        height: 22.5px;
        --handle-width:19.5px;
        --handle-height:18px;
    }
    &.xs{
        width: 32px;
        height: 18px;
        --handle-width:15px;
    }
}

</style>
