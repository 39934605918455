<template>
   <router-view></router-view>
</template>

<script>
//import {} from '@ionic/vue';
export default{
  components: { }
}
</script>

<style lang="scss" scoped>

</style>
