const config = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "profile email",
  prompt: "select_account",
  response_type: "id_token permission",
  cookiepolicy: "single_host_origin"
};

export function initGoogle() {
  return new Promise(resolve => {
    // load google sdk script
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/api:client.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");

    var googleInit = setInterval(() => {
      if (window.gapi) {
        window.gapi.load("auth2", function() {
          // Retrieve the singleton for the GoogleAuth library and set up the client.
          const auth2 = window.gapi.auth2.init(config);
          window.gauth2 = auth2;
          resolve(auth2);
        });
        clearInterval(googleInit);
      }
    }, 500);
  });
}
