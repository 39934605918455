<template>
  <!-- Loading -->
  <div v-bind:class="[fixed ? 'fixed':'',wrapperClass]" class="h-full w-full bg-white z-50">
    <!-- default -->
    <div slot="fixed" class="text-black h-full w-full flex flex-col" v-if="loadingStyle == 'loader'">
        <div class="m-auto flex flex-col">
            <ion-spinner name="bubbles" color="primary" class="mx-auto transform scale-200"></ion-spinner>
            <ion-img :src="'/assets/images/icon.svg'" class="w-10 spinner hidden"></ion-img>
            <p class="font-light my-8" v-if="loadingText">{{loadingText}}</p>
        </div>
        <div class="my-8 mx-auto w-24"><ion-img :src="'/assets/images/logo.svg'"></ion-img></div>
    </div>
    <div slot="fixed" class="text-black h-full w-full flex" v-else>
        <div class="m-auto"><ion-img :src="'/assets/images/logo.svg'"></ion-img></div>
    </div>
  </div>
</template>

<script>
import { IonSpinner, IonImg } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        wrapperClass: {
            type: String,
            default: ''
        },
        fixed: {
            type: Boolean,
            default: true
        },
        loadingStyle: {
            type: String,
            default: ''
        },
        loadingText: {
            type: String,
            default: ''
        },
    },
    components: { IonSpinner, IonImg }
});
</script>

<style lang="scss" scoped>
.spinner {
  animation: spin 0.75s infinite linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

