<script>
import { searchOutline } from "ionicons/icons";
import CarouselItem from "@/components/pages/carousel/CarouselItem.vue";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonInput,
  IonSpinner,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
export default {
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonInput,
    IonSpinner,
    IonImg,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    CarouselItem,
  },
  data() {
    return {
      searchOutline,
      loading: true,
      search: this.$route.params.search ? this.$route.params.search : "",
      items: [],
      loadmoreBtn: true,
      loadmoreLoading: false,
      currentPage: 0,
      title: "Search",
      searchStatus: false,
      searchBtn: false,
    };
  },
  mounted() {
    this.$store.dispatch(`pages/searchActivity`, this.search).then((res) => {
      this.loading = false;
      this.items = res;
    });
  },

  methods: {
    searchFunc() {
      this.loading = true;
      console.log(this.search, "this.search");
      const nextURL = `./move/search/${this.search}`;
      const nextTitle = "Search | Xali";
      const nextState = "";
      window.history.pushState(nextState, nextTitle, nextURL);
      if (this.search == "" || this.search == null) {
        this.items = [];
        this.searchLoadmoreActivity();
      } else {
        this.$store
          .dispatch(`pages/searchActivity`, this.search)
          .then((res) => {
            this.loading = false;
            this.items = res;
          });
      }
    },
    searchLoadmoreActivity() {
      this.loadmoreLoading = true;
      this.$store
        .dispatch(`pages/searchLoadmoreActivity`, this.currentPage)
        .then((res) => {
          var currentItems = JSON.parse(JSON.stringify(res.formatted));

          this.items = this.items.concat(currentItems);

          this.loadmoreBtn = res.raw.pageInfo.hasNextPage;
          this.loadmoreLoading = false;
          this.currentPage = this.currentPage + 1;
          this.loading = false;
        });
    },

    updateSearchValue(e) {
      if (this.search && e.keyCode === 13) {
        this.searchFunc()
      }
      this.searchValue = this.search;
      if (this.search) {
        this.searchBtn = true;
      } else {
        this.searchBtn = false;
      }
    },
    openSearch() {
      this.searchStatus = true;
      this.$refs.searchbar.$el.focus();
    },
  },
};
</script>

<template>
  <ion-page>
    <ion-content>
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <!-- Heading -->
        <xali-header
          :settings="{ back: true, hidemobilenav: true }"
        ></xali-header>
        <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
          <div class="heading flex flex-col sm:flex-row">
            <h1 class="text-4.5xl font-bold">{{ title }}</h1>
            <div
              class="
                search
                flex
                bg-black bg-opacity-5
                rounded-2lg
                mt-4
                sm:hidden
              "
            >
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
              <ion-input
                :value="search"
                @ionInput="search = $event.target.value"
                placeholder="Search a workout"
                v-on:keyup="searchFunc"
              ></ion-input>
            </div>
            <!-- <div class="search-button ml-auto my-auto hidden sm:block">
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark ml-auto"
                @click="openSearch"
                v-if="!searchStatus"
              >
              </ion-icon>

              <div
                class="
                  search
                  flex
                  bg-light bg-opacity-30
                  rounded-md
                  block
                  mt-2.5
                "
                v-if="searchStatus"
              >
                <ion-icon
                  :icon="searchOutline"
                  class="block text-2xl text-dark my-auto ml-2"
                ></ion-icon>
                <ion-input
                  :value="search"
                  @ionInput="search = $event.target.value"
                  placeholder="Search a recipe"
                  v-on:keyup="searchFunc"
                ></ion-input>
              </div>
            </div> -->

            <div class="search-button ml-auto my-auto hidden sm:block">
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark ml-auto"
                @click="openSearch"
                v-if="!searchStatus"
              >
              </ion-icon>

              <div
                class="
                  search
                  flex
                  bg-light bg-opacity-30
                  rounded-md
                  block
                  mt-2.5
                "
                v-if="searchStatus"
                :class="searchBtn ? 'mr-1' : ''"
              >
                <ion-icon
                  :icon="searchOutline"
                  class="block text-2xl text-dark my-auto ml-2"
                ></ion-icon>
                <ion-input
                  @ionInput="search = $event.target.value"
                  placeholder="Search a workout"
                  v-on:keyup="updateSearchValue"
                  autofocus="true"
                  ref="searchbar"
                  :value="search"
                ></ion-input>
              </div>
            </div>
            <ion-button
              class="bg-primary rounded-md mt-3"
              fill="outline"
              @click="searchFunc"
              v-if="searchBtn"
            >
              <div class="px-2 py-3 font-normal flex text-white">Search</div>
            </ion-button>
          </div>
        </div>

        <h3 class="px-6 text-2xl" v-if="!items">No result found.</h3>
        <div
          class="
            container
            max-w-full
            mt-0
            px-6
            lg:px-12
            grid grid-cols-12
            gap-0
            sm:gap-4
          "
          v-if="items"
        >
          <div
            v-for="(item, i) in items"
            :key="i++"
            class="col-span-12 sm:col-span-6 lg:col-span-4 mb-5"
          >
            <CarouselItem v-bind:data="item"></CarouselItem>
          </div>
        </div>

        <div
          class="mt-10 mb-10 flex flex-col"
          v-if="loadmoreLoading && !search"
        >
          <ion-spinner
            name="bubbles"
            color="primary"
            class="mx-auto transform scale-200"
          ></ion-spinner>
          <ion-img
            :src="'/assets/images/icon.svg'"
            class="w-10 spinner hidden"
          ></ion-img>
        </div>
        <div
          class="mt-10 mb-10 flex justify-center"
          v-if="loadmoreBtn && !loadmoreLoading && !search"
          id="loadmore"
        >
          <ion-infinite-scroll
            @ionInfinite="searchLoadmoreActivity($event)"
            threshold="100px"
            id="infinite-scroll"
            :disabled="!loadmoreBtn"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more data..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </div>

        <xali-footer></xali-footer>
        <div class="h-20 md:hidden"></div>
      </template>
    </ion-content>
  </ion-page>
</template>
