<template>
  <ion-page>
    <ion-content>
      <!-- Heading -->
      <xali-header ref="header" :settings="{ back: true, hidemobilenav: true }">
      </xali-header>
      <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
        <div class="heading flex flex-col sm:flex-row">
          <h1 class="text-4.5xl font-bold">Recommended</h1>
        </div>
      </div>

      <div class="container max-w-full mt-20 px-6 lg:px-12 text-center">
        NO <br />RECOMMENDATIONS<br />RECORDED
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from "@ionic/vue";
export default {
  components: {
    IonContent,
    IonPage,
  },
};
</script>
