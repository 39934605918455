
<template>
   <ion-page class="max-w-md mx-auto">
       <ion-content :fullscreen="true">
           <div class="container p-6">
               <header class="mb-5">
                    <div class="text-xl font-medium text-center relative">
                        <span>Filters</span>
                        <div class="flex absolute -top-2.5 -right-1">
                            <router-link to="/home" class="relative">
                                <ion-icon :icon="closeCircle" class="block text-light text-5xl opacity-30"></ion-icon>
                                <ion-icon :icon="closeOutline" class="block text-black text-3xl absolute top-0 m-2 border border-transparent"></ion-icon>
                            </router-link>
                        </div>
                    </div>
               </header>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Meal types</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="primary" class="sm ml-6 mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Breakfast</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Launch</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Dinner</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Smoothie</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">After Rising</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-5">
                            <div class="py-1 font-normal text-sm text-dark flex">Anytime</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Intentions</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="primary" class="sm ml-6 mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">I need to eat</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Refuel</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Energy Smash</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Anytime</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-5">
                            <div class="py-1 font-normal text-sm text-dark flex">After Workout</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Effects</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="primary" class="sm ml-6 mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Restorative</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Power / Strength</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="primary" class="sm mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Cardio </div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Brain Function</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Stress</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-5">
                            <div class="py-1 font-normal text-sm text-dark flex">Immunity</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>
                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Category</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="primary" class="sm ml-6 mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Soup</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Freezable</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="primary" class="sm mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Sprinkles & Toppings</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Tonic</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">How to</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Dressing & Dips</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-5">
                            <div class="py-1 font-normal text-sm text-dark flex">Hot Side</div>
                        </ion-button>
                   </div>
                   <div class="border-b border-light mx-6 my-3"></div>
                </div>

                <div class="dietary">
                    <div class="filter-name text-lg my-4">Dietary preferences</div>
                    <div class="filter">
                    <div class="filter-parent flex">  
                        <ion-toggle checked color="primary" mode="ios" class="sm my-auto"></ion-toggle>
                        <span class="mx-4 text-lg">Meat</span>
                        <ion-icon :icon="chevronUp" class="ml-auto my-auto"></ion-icon>
                    </div>
                    <div class="filter-options py-2 px-14">
                        <div class="filter-option flex mb-2">  
                            <ion-toggle checked color="primary" mode="ios" class="xs my-auto"></ion-toggle>
                            <span class="mx-5 text-sm">Beef</span>
                        </div>
                        <div class="filter-option flex mb-2">  
                            <ion-toggle checked color="primary" mode="ios" class="xs my-auto"></ion-toggle>
                            <span class="mx-5 text-sm">Chicken</span>
                        </div>
                        <div class="filter-option flex mb-2">  
                            <ion-toggle checked color="primary" mode="ios" class="xs my-auto"></ion-toggle>
                            <span class="mx-5 text-sm">Kangaroo</span>
                        </div>
                        <div class="filter-option flex mb-2">  
                            <ion-toggle checked color="primary" mode="ios" class="xs my-auto"></ion-toggle>
                            <span class="mx-5 text-sm">Lamb</span>
                        </div>
                        <div class="filter-option flex mb-2">  
                            <ion-toggle checked color="primary" mode="ios" class="xs my-auto"></ion-toggle>
                            <span class="mx-5 text-sm">Pork</span>
                        </div>
                    </div>
                    </div>
                    <div class="filter mb-3">
                        <div class="filter-parent flex">  
                            <ion-toggle checked color="primary" mode="ios" class="sm my-auto"></ion-toggle>
                            <span class="mx-4 text-lg">Seafood</span>
                            <ion-icon :icon="chevronDown" class="ml-auto my-auto"></ion-icon>
                        </div>
                    </div>
                    <div class="filter mb-3">
                        <div class="filter-parent flex">  
                            <ion-toggle checked color="primary" mode="ios" class="sm my-auto"></ion-toggle>
                            <span class="mx-4 text-lg">Eggs</span>
                        </div>
                    </div>
                    <div class="filter mb-2">
                        <div class="filter-parent flex">  
                            <ion-toggle checked color="primary" mode="ios" class="sm my-auto"></ion-toggle>
                            <span class="mx-4 text-lg">Dairy</span>
                        </div>
                    </div>
                    <div class="filter mb-3">
                        <div class="filter-parent flex">  
                            <ion-toggle checked color="primary" mode="ios" class="sm my-auto"></ion-toggle>
                            <span class="mx-4 text-lg">Nuts</span>
                        </div>
                    </div>
                    <div class="filter mb-3">
                        <div class="filter-parent flex">  
                            <ion-toggle color="primary" mode="ios" class="sm my-auto"></ion-toggle>
                            <span class="mx-4 text-lg">Gluten</span>
                        </div>
                    </div>
                    <div class="border-b border-light mt-5 mb-3"></div>
                </div>

                <div class="filter -mx-6">
                   <div class="filter-name text-lg px-6">Skill Level</div>
                   <div class="filter-options flex py-2 overflow-x-scroll">
                        <ion-button expand="block" fill="outline" color="primary" class="sm ml-6 mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Easy</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="light" class="sm mr-2">
                            <div class="py-1 font-normal text-sm text-dark flex">Intermediate</div>
                        </ion-button>
                        <ion-button expand="block" fill="outline" color="primary" class="sm mr-2 bg-primary bg-opacity-10">
                            <div class="py-1 font-medium text-sm flex">Advanced</div>
                        </ion-button>
                   </div>
                </div>
                <footer class="fixed w-full left-0 bottom-0 z-50">
                    <div class="filter-buttons max-w-md mx-auto bg-white px-6 py-2 flex">
                        <div class="my-auto ml-auto mr-5 text-dark font-medium">Clear filters</div>
                        <ion-button color="black" expand="block"><div class="px-2 py-3.5 text-white">Apply filter <span class="font-light">(34 results)</span></div></ion-button>
                    </div>
                </footer>
            </div>
            
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonContent, IonIcon, IonButton, IonToggle } from '@ionic/vue';
import { closeCircle, closeOutline, chevronUp, chevronDown } from 'ionicons/icons';

export default{
  components: { IonPage, IonContent, IonIcon, IonButton, IonToggle },
  data(){
      return { closeCircle, closeOutline, chevronUp, chevronDown }
  }
}
</script>

<style lang="scss" scoped>
.filter{
    .filter-options::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
ion-toggle {
    &.sm{
        width: 40px;
        height: 22.5px;
        --handle-width:19.5px;
        --handle-height:18px;
    }
    &.xs{
        width: 32px;
        height: 18px;
        --handle-width:15px;
    }
}
.filter-buttons{
    box-shadow: 0px -1px 10px #00000014;
}
</style>
