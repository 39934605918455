<template>
  <div>
    <!-- Default -->
    <div v-if="fixedHeader && type == 'default'" class="h-20 mb-6"></div>
    <ion-header
      class="user-header ion-no-border bg-white flex rounded-b-2xl z-40"
      :class="[fixedHeader ? fixedHeader : 'no']"
      v-if="type == 'default'"
      :style="'top:' + topPosition + 'px;'"
    >
      <div class="container max-w-full px-6 my-5 lg:px-12">
        <div class="grid grid-cols-12">
          <div class="col-span-3 md:col-span-2 flex">
            <router-link to="/" class="flex">
              <ion-img
                :src="iconLogo"
                class="logo my-auto"
                v-bind:class="[settings.back ? 'hidden md:block' : '']"
              ></ion-img>
            </router-link>
            <div class="back flex -mt-1 -ml-4 md:hidden" v-if="settings.back">
              <ion-back-button
                defaultHref="/"
                :icon="chevronBack"
                text=""
                class="text-xl"
              ></ion-back-button>
              <span
                class="my-auto ml-1 pb-0px cursor-pointer"
                @click="$router.go(-1)"
                >Back</span
              >
            </div>
          </div>
          <!-- Desktop -->
          <div class="col-span-9 md:col-span-10 flex justify-end">
            <div class="nav my-auto mr-5 hidden md:block">
              <div class="flex flex-row content-end gap-10 text-xl">
                <!-- <router-link to="/home" v-slot="{ href, navigate }"><a :href="href" @click="navigate"> Home</a></router-link> -->
                <router-link
                  class="hover:text-primary"
                  to="/"
                  v-bind:class="[isActive(['Root', 'Home']) ? 'active' : '']"
                  >Home</router-link
                >
                <router-link
                  class="hover:text-primary"
                  to="/move/"
                  v-bind:class="[
                    isActive([
                      'ActivityLibrary',
                      'ActivityList',
                      'ActivityCategory',
                      'ActivityView',
                      'Move',
                    ])
                      ? 'active'
                      : '',
                  ]"
                  >Move</router-link
                >
                <router-link
                  class="hover:text-primary"
                  to="/eat"
                  v-bind:class="[
                    isActive([
                      'RecipeLibrary',
                      'CategoryRecipe',
                      'Recipe',
                      'Eat',
                    ])
                      ? 'active'
                      : '',
                  ]"
                  >Eat</router-link
                >
                <router-link
                  class="hover:text-primary"
                  to="/learn"
                  v-bind:class="[isActive(['Learn']) ? 'active' : '']"
                  >Learn</router-link
                >
                <a @click="connect()" class="hover:text-primary cursor-pointer"
                  >Connect</a
                >
              </div>
            </div>
            <div class="block my-auto mr-2">
              <slot name="secondary"></slot>
            </div>
            <div
              class="
                rounded-full
                w-10
                flex
                items-center
                justify-center
                overflow-hidden
                ml-3
              "
            >
              <ion-icon
                :icon="heartOutline"
                class="
                  block
                  -mr-1
                  text-2xl text-black
                  my-auto
                  hover:text-primary
                "
                @click="gotoFavourites()"
              ></ion-icon>
            </div>
            <div
              class="
                rounded-full
                h-10
                w-10
                flex
                items-center
                justify-center
                overflow-hidden
                ml-3
              "
            >
              <div @click="openSetting" class="cursor-pointer">
                <!-- <ion-icon
                v-if="!fbImage"
                :icon="personCircleOutline"
                class="text-4xl hidden"
              ></ion-icon> -->
                <img v-if="!userImage" :src="defaultUser" class="w-8 h-8" />
                <img v-if="userImage" :src="userImage" />
              </div>
              <!-- User Account -->
              <div
                class="
                  setting-container
                  fixed
                  bg-white
                  h-full
                  top-0
                  right-0
                  p-5
                  z-50
                  shadow-md
                  overflow-y-auto
                "
                v-if="isSetting"
              >
                <div class="account-container">
                  <header class="mb-5 relative">
                    <div
                      class="back flex w-full items-center"
                      :class="true ? 'justify-end' : 'justify-between'"
                    >
                      <div class="flex items-center" v-if="false">
                        <ion-icon :icon="chevronBack" size="medium"></ion-icon>
                        <span class="my-auto pb-2px">Back</span>
                      </div>
                      <ion-icon
                        @click="closeSetting"
                        :icon="close"
                        class="block text-black text-2xl cursor-pointer"
                        size="large"
                      ></ion-icon>
                    </div>
                    <div
                      v-if="title !== 'account'"
                      class="back flex -mt-1 -ml-4"
                    >
                      <ion-back-button
                        defaultHref="/"
                        @click="handleSelect('account')"
                        :icon="chevronBack"
                        text=""
                        class="text-xl"
                      ></ion-back-button>
                      <span
                        class="my-auto ml-1 pb-0px cursor-pointer"
                        @click="handleSelect('account')"
                        >Back</span
                      >
                    </div>
                    <div class="text-4.5xl font-bold leading-10">
                      <div class="capitalize">{{ title }}</div>
                    </div>
                  </header>
                  <div class="settings-items -ml-3">
                    <SettingItems
                      v-if="title == 'account'"
                      @setSelect="handleSelect"
                      @onClose="closeSetting"
                    />
                    <ProfileItems v-if="title == 'profile'" />
                    <SubscriptionItems v-if="title == 'subscription'" 
                      @setSelect="handleSelect"
                      @onClose="closeSetting"
                      />
                    <SubscriptionPackages v-if="title == 'Manage subscription'" 
                      @setSelect="handleSelect"
                      @onClose="closeSetting"
                      />
                    <CancelSubscription v-if="titleCondition == 'cancel-subscription'" 
                      @setSelect="handleSelect"
                      @onClose="closeSetting"
                      />
                  </div>
                  <ion-img
                    :src="'/assets/images/icon.svg'"
                    class="fixed bottom-16 -right-12 w-36 opacity-10"
                  ></ion-img>
                  <footer class="fixed w-full right-0 bottom-0 z-50">
                    <div
                      class="
                        border-t
                        max-w-md
                        mx-auto
                        bg-white
                        px-6
                        py-2.5
                        flex
                        cursor-pointer
                      "
                      @click="logout"
                    >
                      Logout
                      <ion-icon
                        :icon="logOutOutline"
                        class="text-xl ml-auto my-auto"
                      ></ion-icon>
                    </div>
                  </footer>
                </div>
              </div>
              <!-- End User Account -->
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile -->
      <div
        class="
          mobile-nav
          z-40
          fixed
          top-auto
          w-full
          bg-white
          grid grid-cols-5
          text-xs text-center text-dark
          md:hidden
        "
        v-if="!settings.hidemobilenav"
      >
        <div class="col-span-1">
          <router-link
            to="/"
            class="w-full h-full flex flex-col p-2 cursor-default"
          >
            <ion-icon
              v-bind:class="[
                isActive(['Root', 'Home']) ? 'text-primary' : 'text-secondary',
              ]"
              class="mx-auto w-7 mb-1 mt-auto"
              :src="iconHome"
              size="large"
            ></ion-icon>
            <span
              class="block h-5"
              v-bind:class="[
                isActive(['Root', 'Home']) ? 'text-primary' : 'text-secondary',
              ]"
              >Home</span
            >
          </router-link>
        </div>
        <div class="col-span-1">
          <router-link
            to="/move"
            class="w-full h-full flex flex-col p-2 cursor-default"
          >
            <ion-icon
              v-bind:class="[
                isActive([
                  'ActivityLibrary',
                  'ActivityList',
                  'ActivityCategory',
                  'ActivityView',
                  'Move',
                ])
                  ? 'text-primary'
                  : 'text-secondary',
              ]"
              class="mx-auto w-7 mb-1 mt-auto"
              :src="iconMove"
              size="large"
            ></ion-icon>
            <span
              class="block h-5"
              v-bind:class="[
                isActive([
                  'ActivityLibrary',
                  'ActivityList',
                  'ActivityCategory',
                  'ActivityView',
                  'Move',
                ])
                  ? 'text-primary'
                  : 'text-secondary',
              ]"
              >Move</span
            >
          </router-link>
        </div>
        <div class="col-span-1">
          <router-link
            to="/eat"
            class="w-full h-full flex flex-col p-2 cursor-default"
          >
            <ion-icon
              v-bind:class="[
                isActive(['RecipeLibrary', 'CategoryRecipe', 'Recipe', 'Eat'])
                  ? 'text-primary'
                  : 'text-secondary',
              ]"
              class="mx-auto w-7 mb-1 mt-auto"
              :src="iconEat"
              size="large"
            ></ion-icon>
            <span
              class="block h-5"
              v-bind:class="[
                isActive(['RecipeLibrary', 'CategoryRecipe', 'Recipe', 'Eat'])
                  ? 'text-primary'
                  : 'text-secondary',
              ]"
              >Eat</span
            >
          </router-link>
        </div>
        <div class="col-span-1">
          <router-link to="/learn" class="w-full h-full flex flex-col p-2">
            <ion-icon
              :class="[
                isActive(['Learn'])
                  ? 'text-primary'
                  : 'text-secondary',
              ]"
              class="mx-auto w-7 mb-1 mt-auto"
              :src="iconGrow"
              size="large"
            ></ion-icon>
            <span class="block h-5" 
              :class="[
                isActive(['Learn'])
                  ? 'text-primary'
                  : 'text-secondary',
              ]">Learn</span>
          </router-link>
        </div>
        <div class="col-span-1">
          <a
            @click="connect()"
            target="_blank"
            class="w-full h-full flex flex-col p-2 cursor-pointer"
          >
            <ion-icon
              class="mx-auto color-secondary w-7 mb-1 mt-auto"
              :src="iconConnect"
              size="large"
            ></ion-icon>
            <span class="block h-5">Connect</span>
          </a>
        </div>
      </div>
    </ion-header>
    <!-- Header with Back -->
    <ion-header
      class="ion-no-border header-w-back"
      v-bind:class="[wrapClass]"
      v-if="type == 'header-w-back'"
    >
      <div class="grid grid-cols-6">
        <div class="back flex" v-if="settings.back" @click.prevent="goBack()">
          <ion-icon
            :icon="chevronBack"
            class="
              block
              -ml-3.5
              text-4xl
              md:text-5xl
              text-white
              md:text-black
              cursor-pointer
              z-40
            "
          ></ion-icon>
          <!-- <ion-back-button defaultHref="/home" :icon="chevronBack" class="block text-xl text-white md:text-black -ml-4 -mt-1.5"></ion-back-button> -->
        </div>
        <div class="logo flex col-span-4">
          <template v-if="settings.logo">
            <ion-img
              :src="'/assets/images/logo-white.svg'"
              class="logo mx-auto w-32 block md:hidden"
            ></ion-img>
            <ion-img
              :src="'/assets/images/logo.svg'"
              class="logo mx-auto w-32 hidden md:block"
            ></ion-img>
          </template>
        </div>
        <div class="flex ml-auto" v-if="settings.favorite">
          <ion-icon
            @click.prevent="favourite"
            :icon="favouriteState ? heart : heartOutline"
            class="
              block
              -mr-1
              text-4xl text-white
              md:text-black
              my-auto
              ml-auto
              cursor-pointer
            "
          ></ion-icon>
        </div>
      </div>
    </ion-header>
    <!-- Header Close Only -->
    <ion-header class="ion-no-border" v-if="type == 'close-only'">
      <div class="header-w-close-only">
        <div class="flex py-4 px-5">
          <a :href="settings.closeLink" class="my-auto ml-auto relative">
            <ion-icon
              :icon="closeCircle"
              class="block text-lighter text-5xl"
            ></ion-icon>
            <ion-icon
              :icon="close"
              class="
                block
                text-black text-3xl
                absolute
                top-0
                m-2
                border border-transparent
              "
            ></ion-icon>
          </a>
        </div>
      </div>
    </ion-header>
  </div>
</template>

<script>
import {
  IonHeader,
  IonImg,
  IonIcon,
  IonBackButton,
  alertController,
} from "@ionic/vue";
import {
  chevronBack,
  chevronForward,
  heart,
  heartOutline,
  closeCircle,
  close,
  personCircleOutline,
  logOutOutline,
} from "ionicons/icons";
import {
  iconHome,
  iconMove,
  iconEat,
  iconGrow,
  iconConnect,
  iconLogo,
  defaultUser,
} from "@/_helpers/icons";
import { getOS, trackingTrigger } from "@/_helpers";
import VueCookies from "vue-cookies";
import _ from "lodash";
import SettingItems from "@/components/commons/SettingItems.vue";
import ProfileItems from "@/components/commons/ProfileItems.vue";
import SubscriptionItems from "@/components/commons/SubscriptionItems.vue";
import SubscriptionPackages from "@/components/commons/Packages.vue";
import CancelSubscription from "@/components/commons/CancelSubscription.vue";
export default {
  props: {
    type: {
      type: String,
      default: "default",
    },
    settings: {
      type: Object,
      default: function () {
        return {
          //back: true, logo: true, favorite:false
          //back: false, hidemobilenav:false
          back: false,
          recommended: false,
        };
      },
    },
    wrapClass: {
      type: String,
      default: "py-3 md:py-8 px-6 lg:px-10",
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonHeader,
    IonImg,
    IonIcon,
    IonBackButton,
    ProfileItems,
    SettingItems,
    SubscriptionItems,
    SubscriptionPackages,
    CancelSubscription,
  },
  data() {
    return {
      chevronBack,
      heart,
      heartOutline,
      closeCircle,
      close,
      personCircleOutline,
      iconHome,
      iconMove,
      iconEat,
      iconGrow,
      iconConnect,
      iconLogo,
      defaultUser,
      pageName: "Root",
      favouriteState: true,
      isSetting: false,
      chevronForward,
      logOutOutline,
      topPosition: 0,
      windowWidth: 0,
      mobileBreakpoint: 768,
      fixedHeader: "",
      title: "account",
      titleCondition: "",
    };
  },
  mounted() {
    
    this.pageName = this.$route.name;
    // console.log("this.pageName", this.pageName);
    if (this.pageName == "ActivityView") {
      const activity = this.$store.getters["pages/getCurrentActivity"];
      this.favouriteState = activity.favourite ? true : false;
    }
    if (
      this.pageName == "Recipe" ||
      this.pageName == "Recommended Recipe" ||
      this.pageName == "Recommended Related Recipe"
    ) {
      const activity = this.$store.getters["pages/getCurrentRecipe"];
      // console.log("activity", activity);
      this.favouriteState = activity.favourite ? true : false;
    }
  },
  computed: {
    userData() {
      return this.$store.getters["accounts/getUser"];
    },
    responseData() {
      let response = this.$store.getters["getResponseData"];
      return response;
    },
    userImage() {
      let fbImage = this.fbImage();
      let gImage = this.gImage();
      if (fbImage && !gImage) {
        return fbImage;
      }

      if (gImage && !fbImage) {
        return gImage;
      }

      return null;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    logout() {
      this.closeSetting();
      this.isSetting = false;
      setTimeout(() => {
        this.$router.replace("/logout");
      }, 100);
    },
    openSetting() {
      this.isSetting = true;
    },
    closeSetting() {
      this.isSetting = false;
    },
    isActive: function (names) {
      if (_.includes(names, this.pageName)) return true;
    },
    favourite: function () {
      let type, id;
      if (this.pageName == "ActivityView") type = "activity";
      if (this.pageName == "Recipe") type = "recipe";
      if (this.pageName == "Recommended Recipe") type = "recipe";
      if (this.pageName == "Recommended Related Recipe") type = "recipe";
      id = this.$route.params.id
        ? this.$route.params.id
        : this.settings.recipeid;

      // console.log("id", id);
      if (this.favouriteState) {
        this.$store.dispatch("accounts/favourite", {
          type: type,
          action: "remove",
          ids: id,
        });
      } else {
        this.$store.dispatch("accounts/favourite", {
          type: type,
          action: "add",
          ids: id,
        });
      }
      this.favouriteState = this.favouriteState != true;
      return this.favouriteState;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.mobileBreakpoint <= this.windowWidth) {
        this.setFixed(0);
      }
    },
    setFixed: function (top = 0) {
      if (this.mobileBreakpoint <= this.windowWidth) {
        if (getOS() != "Mac OS") {
          this.topPosition = top;
        } else {
          this.fixedHeader = "fixed";
          this.topPosition = 0;
        }
      } else {
        this.fixedHeader = "";
        this.topPosition = 0;
      }
    },
    fbImage() {
      let fbData = this.$store.getters["accounts/getFbUserData"];
      let img_url = null;
      if (
        fbData &&
        fbData.fb.user.id &&
        fbData.fb.auth &&
        fbData.fb.auth.accessToken
      ) {
        // console.log(fbData.fb.user.id, fbData.fb.auth.accessToken);
        img_url = `https://graph.facebook.com/${fbData.fb.user.id}/picture?access_token=${fbData.fb.auth.accessToken}`;
      }
      return img_url;
    },
    gImage() {
      let gUser = VueCookies.get("gUser");
      // console.log("gUser", gUser);
      if (gUser) {
        return gUser.google.user.image;
      }
      return null;
    },
    handleSelect(item) {
      // console.log("item", item);
      if(item == "cancel-subscription"){
        this.title = `We'll miss you ${this.userData.full_name}!`
        this.titleCondition = item
      }else{
        this.titleCondition = ""
        this.title = item;
      }
    },
    goBack() {
      if(this.settings.videoCompleted){
        setTimeout(() => {
          trackingTrigger("workout-video-completed", this.settings.videoCompleted);
          if (this.settings.recommended) {
            window.location.href = "/";
          } else {
            history.back();
          }
        }, 1000);
      }else{
        if (this.settings.recommended) {
          window.location.href = "/";
        } else {
          history.back();
        }
      }
    },
    async connect() {
      const alert = await alertController.create({
        cssClass: ["my-custom-class"],
        mode: "ios",
        header: "Connect on Facebook",
        message:
          "This will open our facebook group page, would you like to continue?",
        buttons: [
          {
            text: "No",
            handler: () => {
              console.log("Confirm Okay");
            },
          },
          {
            text: "Yes",
            handler: () => {
              trackingTrigger("connect-open");
              setTimeout(() => {
                window.open("https://www.facebook.com/groups/914482186165247");
              }, 500);
            },
          },
        ],
      });
      return alert.present();
    },
    gotoFavourites() {
      window.location.href = `/favourites`;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-header {
  .logo {
    max-width: 60px;
  }
  .nav {
    .active {
      color: var(--ion-color-primary);
      font-weight: 500;
    }
  }
  @screen md {
    min-height: 85px;
    box-shadow: 0px 3px 6px #0000000a;
    .logo {
      max-width: 90px;
    }
  }
}
.header-w-back {
  background: transparent
    linear-gradient(0deg, #00000000 0%, rgba(0, 0, 0, 0.45) 100%) 0% 0%
    no-repeat padding-box;
  @screen md {
    background: none;
  }
}

.mobile-nav {
  box-shadow: -5px -5px 10px rgb(0, 0, 0, 0.2);
  bottom: 0px;
  // margin-bottom: env(safe-area-inset-bottom);
  // bottom: env(safe-area-inset-bottom);
}
.setting-container {
  width: 400px;

  @media (max-width: 767px) {
    width: 100%;
  }
  footer {
    width: 400px;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
