import store from "@/store";
import router from "@/router";

export async function authGuard(to, from, next) {
  const token = store.getters["accounts/getToken"];
  // If don't have token session redirect to login
  if (!token) {
    router.push({ path: "/login", query: { returnUrl: to.fullPath } });
  }
  // if have token validate it by getting /me data
  if (token) {
    // console.log("authenticating...token");
    const me = await store.dispatch("accounts/getMe");
    store.commit("accounts/setUser", me.data);

    if (to.name !== undefined && to.name != "Payment" && !me.data.detail.is_subscribed && me.data.detail.subscription.plan_name != "Free" && !me.data.detail.is_expired) {
      router.push({ path: "/subscription/choose-plan/" });
    }

    if (
      (me.data.detail.is_expired && me.data.detail.subscription.plan_name != "Free") ||
      (!me.data.detail.subscription.remaining_days && me.data.detail.subscription.plan_name != "Free")
    ) {
      if (to.name !== undefined && to.name.toLocaleLowerCase() != 'root' && to.name != "Payment") {
        router.push({ path: "/" });
      }
    }


    next();
  } else {
    next();
  }
}
export async function logout(to) {
  console.log("Logout");
  //console.log("Invalid user token please login again.");
  await store.dispatch("accounts/logout").then(() => {
    router.replace({ path: "/login", query: { returnUrl: to.query.returnUrl } });
  });
}

export async function switchApp(to) {
  let validate = true;
  const nextURL = to.query.next;

  if (store.getters["accounts/isAuthenticated"]) {
    const userData = store.getters["accounts/getUser"];
    if (userData.switch) {
      validate = false;
      router.push(nextURL ? nextURL : "/");
    } else {
      validate = true;
    }
  }
  if (validate) {
    const token = to.params.token;
    if (!token) router.push({ path: "/404" });
    await store.dispatch("accounts/switchApp", token).then(res => {
      console.log("q1--", res, nextURL);
      if (res.status != 200) {
        router.push({
          path: "/404",
          query: { message: "Invalid token provided" }
        });
      } else {
        router.push(nextURL ? nextURL : "/");
      }
    });
  }
}
