
<template>
  <div class="p-4">
    <p class="font-bold mb-4">
      Thanks for letting us be a part of your health journey.
    </p>
    <p class="font-bold">
      Would you mind letting us know the reason/s why you’re leaving?
    </p>

    <div class="feedback-list mt-6 mb-2">
      <ul class="list-style-type p-0 m-0">
        <li
          v-for="choice in feedbackChoices"
          :key="choice.id"
          class="inline-block cursor-pointer"
          @click="addChoice(choice.value)"
        >
          <span
            class="block rounded-md mr-2 mb-2 text-sm px-2 py-1 font-medium"
            :class="
              selectedFeedback.includes(choice.value)
                ? 'bg-opacity-5 bg-primary active'
                : ''
            "
          >
            {{ choice.value }}
          </span>
        </li>
      </ul>
    </div>
    <ion-textarea
      @ionInput="suggestion = $event.target.value"
      placeholder="Tell us your suggestion"
      class="border-b mb-8"
    ></ion-textarea>
    <p class="mb-8">
      Your subscription will remain active until {{ nextRenewal }}. After that, you
      won’t be able to access any Xali content.
    </p>

    <ion-button
      expand="block"
      color="danger"
      fill="outline"
      class="mb-2"
      @click="cancel()"
    >
      <span class="block p-4 text-danger w-full"> Pause my subscription </span>
    </ion-button>

    <ion-button
      expand="block"
      color="danger"
      class="cursor-pointer mb-10"
      @click="cancel()"
    >
      <span class="block p-4 cursor-pointer "> Cancel subscription </span>
    </ion-button>
  </div>
</template>
<script>
import { IonTextarea } from "@ionic/vue";
import moment from "moment";
import { trackingTrigger } from "@/_helpers";
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  logOutOutline,
  checkmarkOutline,
} from "ionicons/icons";

export default {
  components: { IonTextarea },
  data() {
    return {
      checkmarkOutline,
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      chevronBack,
      logOutOutline,
      selectedFeedback: [],
      suggestion: "",
      feedbackChoices: [
        {
          id: 1,
          value: "Too expensive",
        },
        {
          id: 2,
          value: "Not using it enough",
        },
        {
          id: 3,
          value: "Needed a different workout style",
        },
        {
          id: 4,
          value: "Not reaching my goal",
        },
        {
          id: 6,
          value: "It's just not for me",
        },
        {
          id: 7,
          value: "Other",
        },
      ],
    };
  },
  computed: {
    userData() {
      return this.$store.getters["accounts/getUser"];
    },
    nextRenewal() {
      var newDate = new Date(this.userData.detail.subscription.next_renewal);
      var d = newDate.getDate();
      var m = newDate.getMonth() + 1; //Month from 0 to 11
      var y = newDate.getFullYear();
      return (d <= 9 ? "0" + d : d) + "-" + (m <= 9 ? "0" + m : m) + "-" + y;
    }
  },
  methods: {
    changePlan(plan) {
      this.plan = plan;
    },
    addChoice(value) {
      if (this.selectedFeedback.includes(value)) {
        this.selectedFeedback.splice(this.selectedFeedback.indexOf(value), 1);
      } else {
        this.selectedFeedback.push(value);
      }
    },
    cancel() {
      var args = {
        cancellation_reason: this.selectedFeedback,
        suggestion: this.suggestion,
      };
      const trackingInfo = {
        event: "plan-cancelled",
        reason: this.selectedFeedback,
        cancellation_date: moment().format("DD/MM/YYYY "),
        plan_name: `${this.userData.detail.subscription.plan_type} (${this.userData.detail.subscription.plan_name})`,
        plan_duration: `${this.userData.detail.subscription.plan_name}`,
        status: this.userData.detail.subscription.remaining_days != 0 && !this.userData.detail.is_expired ? 'active' : 'inactive'
      };

      try {
        this.$store
          .dispatch("accounts/cancelSubscription", args)
          .then(async (response) => {
            if (response) {
              await trackingTrigger("plan-cancelled", trackingInfo);
              if (window.dataLayer) {
                setTimeout(() => {
                  window.location.href = "/subscription/cancel-success";
                }, 500);
              }
            }
          });
      } catch (e) {
        console.log("Error", e);
        alert("Error please try again later.");
      }
    },
  },
};
</script>

<style lang="scss">
.feedback-list {
  span {
    border: 1px solid #dcdcdc;
    color: #727272;
    &.active {
      border: 1px solid #00b5b6;
      color: #00b5b6;
    }
  }
}
</style>