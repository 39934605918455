/* eslint-disable */
// ----------------------
// REST API

import router from "@/router";
import store from "@/store";
import VueCookies from "vue-cookies";
import moment_tz from "moment-timezone";

const axios = require("axios").default;
import { setup } from "axios-cache-adapter";
window.moment_tz = moment_tz;
// console.log("moment.tz.guess()", moment_tz.tz.guess());

const tz = moment_tz.tz.guess();
const api = setup({
  // `axios` options
  baseURL: process.env.VUE_APP_API_URL,
  // `axios-cache-adapter` options
  cache: {
    maxAge: 3 * 1000
  }
});

// Refresh 401 request and try refresh once
api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      VueCookies.get("userToken")
    ) {
      originalRequest._retry = true;
      try {
        const rtoken = store.getters["accounts/getRefreshToken"];
        const response = await store.dispatch("accounts/refreshToken", rtoken);
        if (response.status === 200) {
          const me = await store.dispatch("accounts/getMe");
          return me;
        } else {
          console.log("interceptors refresh token error");
          router.replace({ path: "/logout" });
        }
        return response;
      } catch (e) {
        console.log("interceptors refresh token error");
        router.replace({ path: "/logout" });
        return null;
      }
    }
    return error;
  }
);
//  add token on header if token is exist
api.interceptors.request.use(function (config) {
  const token = VueCookies.get("userToken");
  let user_timezone = VueCookies.get("user_timezone");
  config.headers["User-Timezone"] = user_timezone ? user_timezone : tz;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Add user timezone on header
// api.interceptors.request.use(config => {
//   config.headers["User-Timezone"] = tz ? tz : "Australia/Sydney";
//   return config;
// });

// ------------------------
// graphQL
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";

export function getGraphQl() {
  const httpLink = new HttpLink({
    uri: process.env.VUE_APP_GQL_URL
  });
  const authLink = setContext((_, { headers }) => {
    const token = VueCookies.get("userToken");

    let user_timezone = VueCookies.get("user_timezone");

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "User-Timezone": user_timezone ? user_timezone : tz
      }
    };
  });
  const graphql = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false
    })
  });
  return graphql;
}

export { api, gql };
