import _ from "lodash";
import VueCookies from "vue-cookies";


export default {
  isAuthenticated: state => !!state.token,
  getToken(state) {
    return state.token;
  },
  getRefreshToken(state) {
    return state.refreshToken;
  },
  getUser(state) {
    if (_.isEmpty(state.user)) return VueCookies.get("userData");
    return state.user;
  },
  getisAdmin(state) {
    return state.user.is_admin;
  },
  getFormData(state) {
    return state.formData;
  },
  getResponseData(state) {
    return state.responseData;
  },
  getFbUserData(state) {
    return VueCookies.get(state.fbUser);
  },
  getJourneyQuestions(state) {
    return state.journeyQuestions;
  },
  getJourneySetupResponse(state) {
    return state.journeySetupResponse;
  },
  getRecommended(state) {
    let sessionsRecom = VueCookies.get("userRecommend");
    if (sessionsRecom) return sessionsRecom;
    return state.recommend;
  },
  getFavouritesData(state) {
    return state.favourites;
  },
  getHistoryData(state) {
    return state.history;
  }
};
