<script>
export default {
  name: "journey-pwa",
  data: () => ({
    openIcon: "",
    plusIcon: "",
  }),
  mounted() {
    var svg = require.context("@/assets/images/", false, /\.svg$/);
    this.openIcon = svg("./safari-option-icon.svg");
    this.plusIcon = svg("./add.svg");
  },

  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
  },
};
</script>

<template>
  <h2 class="question text-3xl font-black mb-5">Quick access</h2>
  <p>
    Add <b class="uppercase">Xali</b> on the Home Screen of your mobile to
    easily access to it in the future!
  </p>

  <p class="mt-4" v-if="iOS">
    Press the
    <span> <ion-icon class="text-black w-6" :src="openIcon"></ion-icon> </span> button and
    then the <span><ion-icon class="w-6 text-black" :src="plusIcon"></ion-icon> </span> add
    to homescreen
  </p>

  <div class="flex justify-center mt-6">
    <img src="/assets/images/home-screen-mockup.png" />
  </div>
</template>
