
<template>
  <ion-list>
    <ion-item v-for="(item, i) in packages" :key="i++">
      <div
        class="rounded-md relative w-full cursor-pointer"
        @click="changePlan(item.order, item.month)"
      >
        <div class="flex items-center justify-between relative z-2">
          <div class="pr-2 leading-4">
            <p class="m-0 text-xl">{{ item.month }}</p>
            <p class="text-xs w-32">{{ item.description }}</p>
          </div>
          <div class="flex items-center">
            <h2 class="text-2xl tracking-tight">
              {{ item.price }}
            </h2>
            <span class="px-0.5">/</span>
            <span class="text-xs">month</span>
          </div>
          <ion-icon
            :icon="checkmarkOutline"
            size="meduim"
            :class="plan != item.order ? 'invisible' : 'visible'"
          >
          </ion-icon>
        </div>
      </div>
    </ion-item>
  </ion-list>
  <div class="text-center mt-6 pl-4" v-if="showUpdatePackage">
    <a v-bind:href="packageUrl">
      <ion-button expand="block" color="black">
        <span class="block p-4"> Confirm </span>
      </ion-button>
    </a>

    <ion-button
      expand="block"
      color="white"
      @click="changePlan(0)"
      class="mt-2"
    >
      <span class="block p-4 text-black w-full"> Cancel </span>
    </ion-button>
  </div>
  <div class="cancel z-50 w-full" :class="!iOS ? 'absolute left-0 bottom-12' : ''">
    <ion-button
      expand="block"
      color="white"
      @click="selected('cancel-subscription')"
      v-if="userData.detail.subscription.plan_type !== 'Cancelled User'"
    >
      <span class="block p-4 text-danger w-full"> Cancel subscription </span>
    </ion-button>
  </div>
</template>
<script>
import { IonIcon } from "@ionic/vue";
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  logOutOutline,
  checkmarkOutline,
} from "ionicons/icons";

import { mapGetters } from "vuex";

export default {
  components: { IonIcon },
  data() {
    return {
      checkmarkOutline,
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      chevronBack,
      logOutOutline,
      plan: 0,
      showUpdatePackage: false,
      packageUrl: `/subscription/choose-plan/1/`,
      packages: [
        {
          order: 1,
          month: "1 month",
          price: "$20",
          description: "$20 billed monthly",
        },
        {
          order: 2,
          month: "3 months",
          price: "$15",
          description: "$45 billed quarterly",
        },
        {
          order: 3,
          month: "12 months",
          price: "$9",
          description: "$108 billed anually",
        },
      ],
    };
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    ...mapGetters({
      userData: "accounts/getUser",
    }),
  },
  mounted() {
    for (let i = 0; this.packages.length > i; i++) {
      console.log(
        this.packages[i].month.toLowerCase(),
        this.userData.detail.subscription.plan_name.toLowerCase()
      );
      if (
        this.packages[i].month.toLowerCase() ==
        this.userData.detail.subscription.plan_name.toLowerCase()
      ) {
        this.plan = i + 1;
      }
    }

    //this.packages = this.packages.reverse()
  },
  methods: {
    changePlan(id, value) {
      if (id == 0) {
        this.showUpdatePackage = false;
        this.plan = 0;
      } else {
        this.packageUrl = `/subscription/choose-plan/${id}/`;
        this.plan = id;
        this.showUpdatePackage =
          value.toLowerCase() !==
          this.userData.detail.subscription.plan_name.toLowerCase()
            ? true
            : false;
      }
    },

    selected(item) {
      this.$emit("setSelect", item);
    },
    onclick() {
      setTimeout(() => {
        this.$emit("onClose");
      }, 2000);
    },
  },
};
</script>