<script>
import {
  IonSkeletonText,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonIcon,
  IonSpinner,
  toastController,
  alertController,
} from "@ionic/vue";
import { logoApple, logoFacebook, logoGoogle } from "ionicons/icons";
import {
  loginFacebookSdk,
  initGoogle,
  signinGoogle,
  trackingTrigger,
} from "@/_helpers";
import _ from "lodash";
import { iconEye } from "@/_helpers/icons";
import VueCookies from "vue-cookies";
//import mixpanel from 'mixpanel-browser';

export default {
  props: ["fbSDKLoaded"],
  components: {
    IonSkeletonText,
    IonButton,
    IonLabel,
    IonInput,
    IonItem,
    IonIcon,
    IonSpinner,
  },
  data() {
    let formData = {
      username: "",
      password: "",
    };
    return {
      logoApple,
      logoFacebook,
      logoGoogle,
      // fbSDKLoading: true,
      formData,
      errors: {},
      loading: false,
      showEye: false,
      iconEye,
      fullLoading: false,
      first_login: "no",
      disableFacebook: false,
      disableGoogle: false,
    };
  },
  async mounted() {

    this.checkLocationAllow()

    // this.fbSDKLoading = true;
    // initFacebookSdk().then(response => {
    //   console.log(response);
    //   this.fbSDKLoading = false;
    //   console.log("Facebook JS SDK Loaded");
    // });

    initGoogle().then(() => {
      // console.log("Google JS SDK Loaded");
    });
    //mixpanel.init('0d6ef7a3e32086155c06f4b31d5e414c', {debug: true});
    //mixpanel.track('login');
    this.first_login = VueCookies.get("first_login");
    // console.log("first_login", this.first_login);
  },
  watch: {
    "formData.password"() {
      // console.log(this.formData.password);
      if (this.formData.password) {
        this.showEye = true;
      } else {
        this.showEye = false;
      }
    },
  },
  methods: {
    async presentAlert() {
      const alert = await alertController.create({
        cssClass: ["my-custom-class"],
        mode: "ios",
        header: "Error",
        message: "No active account found with the given credentials",
        buttons: [
          {
            text: "close",
            handler: () => {
              console.log("Confirm Okay");
            },
          },
        ],
      });
      return alert.present();
    },
    loginApple() {
      // https://dev.to/heyitsarpit/how-to-add-signin-with-apple-on-your-website-43m9
      // https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
      console.log("HERE");
    },
    async loginGoogle() {
      await signinGoogle().then(async (response) => {
        if (response) {
          this.loading = true;
          console.log("Login success!", response);
          //this.$router.replace("/");
          //VueCookies.set("event", "login");
          if(response.is_new){
            await trackingTrigger("signup", {
              "method": "Google Connect"
            });            
            setTimeout(() => {
              window.location.href = "/journey-setup";
            }, 1000);
          }else{
            await trackingTrigger("login", {
              "method": "Google Connect"
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          }
          // if (this.first_login === "yes") {
          //   window.location.href = "/journey-setup";
          // } else {
          //   window.location.href = "/";
          // }
        }
      });
    },
    async loginFacebook() {
      let response = await loginFacebookSdk();
      if (response) {
        this.loading = true;
        //this.openToast("Login success!", "success");
        console.log("Login success!", response);
        //this.$router.replace("/");
        //VueCookies.set("event", "login");
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get("debug");
        if (!debug) {
          if(response.is_new){
            await trackingTrigger("signup", {
              "method": "Facebook"
            });
            setTimeout(() => {
              window.location.href = "/journey-setup";
            }, 1000);
          }else{
            await trackingTrigger("login", {
              "method": "Facebook"
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          }
        }

        // if (this.first_login === "yes") {
        //   window.location.href = "/journey-setup";
        // } else {
        //   window.location.href = "/";
        // }
      }
    },
    async submitForm() {
      this.errors = {};
      Object.keys(this.formData).forEach((field) => {
        if (!this.formData[field])
          this.errors[field] =
            '<span class="capitalize">' + field + "</span> is required.";
      });

      // Submit to API services
      if (_.isEmpty(this.errors, true)) {
        this.fullLoading = true;
        this.loading = true;
        try {
          await this.$store
            .dispatch("accounts/login", this.formData)
            .then(async (response) => {
              if (response.status === 200) {
                this.fullLoading = true;
                this.loading = false;
                this.formData = { username: "", password: "" };
                //this.openToast("Login success!", "success");
                // this.$router.replace(
                //   this.$route.query.returnUrl
                //     ? this.$route.query.returnUrl
                //     : "/"
                // );

                //VueCookies.set("event", "login");
                await trackingTrigger("login", {
                  "method": "Email"
                });
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const debug = urlParams.get("debug");
                if (!debug) {
                  // setTimeout(()=> {
                  //   var isIdentifiedC = setInterval(() => {
                  //     if(window._learnq.isIdentified() && window.identify) {
                  //       clearInterval(isIdentifiedC);
                  //       let redirect = this.$route.query.returnUrl ? this.$route.query.returnUrl: "/";
                  //       window.location.href = redirect;
                  //     }
                  //   }, 100);
                  // }, 1000)
                  // let counter = 0;
                  // var klavLogin = setInterval(() => {
                  //   counter++;
                  //   if(counter == 100){
                  //     let redirect = this.$route.query.returnUrl ? this.$route.query.returnUrl: "/";
                  //     window.location.href = redirect;
                  //   }
                  //   if(window.klaviyo.login && window.identifyLogin) {
                  //     clearInterval(klavLogin);
                  //     setTimeout(()=> {
                  //       let redirect = this.$route.query.returnUrl ? this.$route.query.returnUrl: "/";
                  //       window.location.href = redirect;
                  //     }, 500)
                  //   }
                  // }, 50);
                  if (window.dataLayer) {
                    setTimeout(() => {
                      let redirect = this.$route.query.returnUrl
                        ? this.$route.query.returnUrl
                        : "/";
                      if (this.first_login === "yes") {
                        window.location.href = "/journey-setup";
                      } else {
                        window.location.href = redirect;
                      }
                    }, 500);
                  }
                }
              } else {
                // this.openToast(
                //   "No active account found with the given credentials",
                //   "danger"
                // );
                this.presentAlert();
                this.loading = false;
              }
            });
        } catch (e) {
          this.presentAlert();
          this.fullLoading = false;
          this.loading = false;
        }
      }
    },
    async openToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
        color: color,
      });
      return toast.present();
    },
    showPassword() {
      let ele = document.getElementById("mypassword");
      let icon = document.getElementById("eyeIcon");
      if (ele.type === "password") {
        ele.type = "text";
        icon.classList.add("text-primary");
      } else {
        ele.type = "password";
        icon.classList.remove("text-primary");
      }
    },
    async checkLocationAllow(){
      const Http = new XMLHttpRequest();
      Http.open("GET", 'https://ipinfo.io?token=c8dba6e991f0b6');
      Http.send();
      Http.onreadystatechange = () => {
        if(Http.responseText){
          const info = JSON.parse(Http.responseText)
          //const notAllow = ['Canada','CA','U.S','US','United States']
          const notAllow = []
          if(notAllow.includes(info.country)){
            console.log('Disable Facebook');
            this.disableFacebook = true
            this.disableGoogle = true
            return false
          }
        }
      }
    }
  },
};
</script>
<style>
/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .alert-wrapper .alert-title {
  text-align: center;
}
ion-item {
  --background-focused: #ffffff;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  margin-top: 12px;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px trasparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>

<template>
  <xali-loading
    v-if="fullLoading"
    loadingStyle="loader"
    :fixed="true"
    wrapperClass="z-100 top-0 left-0"
    >Loading</xali-loading
  >

  <ion-skeleton-text
    animated
    class="text-4xl md:text-5xl w-3/12 hidden"
  ></ion-skeleton-text>
  <div class="hidden">{{ $store.state.account }}</div>
  <template v-if="fbSDKLoaded && !disableFacebook">
    <ion-button
      expand="block"
      fill="outline"
      color="black"
      @click="loginFacebook"
    >
      <div class="px-10 py-2.5 md:py-3.5 flex">
        <ion-icon :icon="logoFacebook" class="text-lg mr-2"></ion-icon>
        Login with Facebook
      </div>
    </ion-button>
  </template>
  <div class="my-5"></div>

  <ion-button
    id="customBtn"
    @click="loginGoogle"
    expand="block"
    fill="outline"
    color="black"
    v-if="!disableGoogle"
  >
    <div class="px-10 py-2.5 md:py-3.5 flex">
      <ion-icon :icon="logoGoogle" class="text-lg mr-2"></ion-icon>
      Login with Google
    </div>
  </ion-button>

  <div class="my-5"></div>
  <!-- <ion-button expand="block" fill="outline" color="black" @click="loginApple">
    <div class="px-10 py-2.5 md:py-3.5 flex">
      <ion-icon :icon="logoApple" class="text-lg mr-2"></ion-icon>
      Login with Apple
    </div>
  </ion-button> -->
  <div class="line mt-12 mb-7 md:mb-8 flex">
    <span class="bg-white m-auto p-4 -mt-8">or</span>
  </div>
  <div class="login-form relative">
    <form @submit.prevent="submitForm" action="./" method="post">
      <ion-item>
        <ion-label position="floating">Email Address</ion-label>
        <ion-input
          v-model="formData.username"
          v-on:keyup.enter="submitForm"
          id="login-username"
        ></ion-input>
      </ion-item>
      <div class="text-sm text-danger px-2" v-if="errors.username">
        Please enter a valid email address.
      </div>
      <!-- <ion-item>
        <ion-label position="floating">Password</ion-label>
        <ion-input
          type="password"
          v-model="formData.password"
          v-on:keyup.enter="submitForm"
        ></ion-input>
      </ion-item> -->
      <ion-item>
        <ion-label position="floating">Password</ion-label>
        <ion-input
          id="mypassword"
          type="password"
          v-model="formData.password"
          v-on:keyup.enter="submitForm"
        >
        </ion-input>
        <ion-icon
          id="eyeIcon"
          :icon="iconEye"
          v-if="showEye"
          class="mt-10 cursor-pointer"
          slot="end"
          @click="showPassword"
        ></ion-icon>
      </ion-item>
      <div class="text-sm text-danger px-2" v-if="errors.password">
        Please enter a valid password.
      </div>
      <div class="text-right mt-5 mr-3">
        <p class="my-auto">
          <router-link to="/forgot-password" class="text-primary"
            >Forgot Password</router-link
          >
        </p>
      </div>

      <div class="flex py-10" v-if="loading">
        <ion-spinner
          name="bubbles"
          color="primary"
          class="mx-auto transform scale-200"
        ></ion-spinner>
      </div>

      <div class="mt-8 md:mt-20 mb-20 flex" v-if="!loading">
        <p class="my-auto">
          Don't have an account? <br /><router-link
            to="/signup"
            class="text-primary"
            >Sign up now!</router-link
          >
        </p>
        <ion-button
          expand="block"
          color="black"
          class="float-right ml-auto text-xl"
          type="submit"
          :disabled="loading"
        >
          <span class="py-4 px-1" v-if="!loading">Login</span>
          <span class="py-2.5 px-1" v-if="loading"
            ><ion-spinner name="bubbles"></ion-spinner
          ></span>
        </ion-button>
      </div>
    </form>
  </div>
</template>
