<template>
  <container-cover>
    <xali-header
      type="header-w-back"
      :settings="{ back: true, logo: true }"
    ></xali-header>

    <div class="mt-auto min-h-8/10 rounded-t-2lg">
      <div
        class="
          max-w-sm
          m-auto
          w-full
          bg-white
          py-5
          rounded-t-2lg
          h-full
          flex flex-col
        "
      >
        <div class="signup-form-container px-6">
          <div class="text-center mb-11">
            <h1 class="text-3xl font-bold mb-1">Start your free trial</h1>
            <!-- <p class="text-xl">No payment details required</p> -->
          </div>
          <template v-if="action == 'social'">
            <SignupSocial></SignupSocial>
          </template>
          <template v-else>
            <SignupForm></SignupForm>
          </template>
        </div>
      </div>
    </div>
  </container-cover>
</template>

<script>
import SignupForm from "../components/forms/Signup.vue";
import SignupSocial from "../components/forms/SignupSocial.vue";
import { useRoute } from "vue-router";

export default {
  components: { SignupForm, SignupSocial },
  setup() {
    const route = useRoute();
    const { action } = route.params;
    return { action };
  },
};
</script>

<style lang="scss" scoped>
.signup-form-container {
  overflow: hidden;
  &::before {
    content: "";
    // background: transparent linear-gradient(180deg, #000000 0%, #00000000 100%)
    //   0% 0% no-repeat padding-box;
    width: 100%;
    height: 100%;
    max-height: 270px;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.45;
    z-index: -1;
  }
}
.signup-form-container {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
</style>


