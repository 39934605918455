<template>
  <ion-page>
    <ion-content
      :fullscreen="true"
      :scroll-events="true"
      @ionScroll="
        $refs.header.setFixed($event.detail.scrollTop),
          setSliderFocus($event.detail.scrollTop)
      "
      class="bg-danger"
    >
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <!-- Heading -->
        <xali-header ref="header"></xali-header>
        <div class="container max-w-full mt-0 px-6 my-5 md:mt-5 lg:px-12">
          <!-- Welcome Message-->
          <section class="mb-8">
            <WelcomeMessage></WelcomeMessage>
          </section>
          <!-- Journey Profile -->
          <section
            class="mb-7 -mx-12 md:mb-8"
            v-bind:class="{ hidden: !hasRecommend }"
          >
            <JourneyProfile></JourneyProfile>
          </section>
          <!-- Journey Box -->
          <section class="mb-7 md:mb-8" v-bind:class="{ hidden: hasRecommend }">
            <JourneyBox></JourneyBox>
          </section>
          <div class="line mb-7 md:mb-8"></div>
        </div>
        <div class="container max-w-full mt-0 px-0">
          <!-- Fitness -->
          <section class="mb-7 md:mb-11" v-if="workoutCarousel">
            <Carousel
              title="Move"
              v-bind:items="workoutCarousel"
              :titleLink="'/move/'"
              :link="{ text: 'Browse All', url: '/move/' }"
            ></Carousel>
          </section>
          <!-- Nutrition -->

          <section class="mb-7 md:mb-11" v-if="nutritionCarousel">
            <Carousel
              title="Eat"
              v-bind:items="nutritionCarousel"
              :titleLink="'/eat'"
              :link="{ text: 'Browse All', url: '/eat' }"
            ></Carousel>
          </section>
          <!-- Learn -->
          <section class="mb-7 md:mb-11" v-if="learnCarousel">
            <Carousel
              title="Learn"
              v-bind:items="learnCarousel"
              :titleLink="'/learn'"
              :link="{ text: 'Browse All', url: '/learn' }"
            ></Carousel>
          </section>
        </div>
        <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
          <div class="carousel-title flex mb-4">
            <a
              href="https://www.facebook.com/groups/914482186165247"
              class="flex items-center"
              target="_new"
            >
              <h2 class="text-2xl md:text-3xl font-semibold">Connect</h2>
              <span class="pt-3 ml-2"
                ><ion-icon :icon="chevronForward"></ion-icon
              ></span>
            </a>
          </div>
          <section class="mb-7 md:mb-8">
            <a @click="connect()" class="cursor-pointer">
              <CommunityBox></CommunityBox>
            </a>
          </section>
          <div class="line mb-7 md:mb-6"></div>
        </div>
        <!-- Footer -->
        <xali-footer></xali-footer>
        <div class="h-20 md:hidden"></div>
      </template>
      <!-- <div v-if="allActivity"><ion-item v-for="item in allActivity" :key="item.id"><ion-label>
            {{ item.name }} {{ item.status }} ({{ item.species }})
          </ion-label></ion-item></div> -->
    </ion-content>
    <!-- <pwa> </pwa> -->

    <subscription-expired v-if="isExpired"> </subscription-expired>
  </ion-page>
</template>

<script>
import { chevronForward } from "ionicons/icons";
import { IonContent, IonPage, IonIcon, alertController } from "@ionic/vue";
import WelcomeMessage from "../components/pages/WelcomeMessage.vue";
import JourneyProfile from "../components/pages/JourneyProfile.vue";
import JourneyBox from "../components/pages/JourneyBox.vue";
import CommunityBox from "../components/pages/CommunityBox.vue";
import Carousel from "../components/pages/carousel/Carousel.vue";
import { useRoute } from "vue-router";
import { setSliderFocus, filterResults, formatSlideData } from "@/_helpers";
import _ from "lodash";
import VueCookies from "vue-cookies";
import moment from "moment";
//import { getRandomLearn } from "@/_helpers/learn";
//import Pwa from '../components/commons/Pwa.vue';
import SubscriptionExpired from "./subscription/Subscription-Expired.vue";
import { trackingTrigger } from "@/_helpers";

export default {
  components: {
    IonContent,
    IonPage,
    WelcomeMessage,
    JourneyBox,
    JourneyProfile,
    CommunityBox,
    Carousel,
    IonIcon,
    //Pwa,
    SubscriptionExpired,
  },
  data() {
    const route = useRoute();
    const { profile } = route.params;
    return {
      loading: true,
      workoutCarousel: false,
      nutritionCarousel: false,
      profile,
      hasRecommend: false,
      learnCarousel: [],
      chevronForward,
      isExpired: false,
    };
  },
  mounted() {
    const userData = this.$store.getters["accounts/getUser"];
    if (
      userData &&
      userData.detail &&
      (
        (userData.detail.is_expired && userData.detail.subscription.plan_name != "Free") ||
        (!userData.detail.subscription.remaining_days && userData.detail.subscription.plan_name != "Free")
      )
    ) {
      this.isExpired = true;
    }
    if (
      userData.date_joined &&
      userData.detail.subscription.plan_name == null &&
      userData.detail.subscription.remaining_days == null
    ) {
      var now = moment(new Date()); //todays date
      var end = moment(userData.date_joined); // another date
      var duration = moment.duration(now.diff(end));
      var hrs = duration.asHours();
      //console.log('days--',hrs);
      if (hrs >= 336) {
        //console.log('Expiired');
        this.isExpired = true;
      } else {
        //console.log('Not expired');
        this.isExpired = false;
      }
    }
    if (process.env.VUE_APP_PAYMENT == "OFF") this.isExpired = false;

    VueCookies.set("first_login", "no");
    this.$store.dispatch(`pages/getHomeData`).then(() => {
      this.workoutCarousel = this.$store.getters["pages/getHomeData"].fitness;
      // this.nutritionCarousel = this.$store.getters["pages/getHomeData"].nutrition;

      const userData = this.$store.getters["accounts/getUser"];
      if (userData.recommendation.workout) this.hasRecommend = true;

      // Learn
      this.$store.dispatch("accounts/getRecommendedLearn").then((learn) => {
        let learn_arr = _.map(filterResults(learn), (data) =>
          formatSlideData(data, "learn")
        );
        // console.log("learn_arr", learn_arr);
        this.learnCarousel = learn_arr;
        this.learnCarousel.push({
          name: "Browse More",
          link: "/learn",
          tags: [
            { text: "Articles", url: "" },
            { text: "Learn", url: "" },
          ],
          cover: "/assets/images/browse-all-learn.jpg",
          browse: true,
        });
        this.loading = false;
      });
    });
  },
  methods: {
    async connect() {
      const alert = await alertController.create({
        cssClass: ["my-custom-class"],
        mode: "ios",
        header: "Connect on Facebook",
        message:
          "This will open our facebook group page, would you like to continue?",
        buttons: [
          {
            text: "No",
            handler: () => {
              console.log("Confirm Okay");
            },
          },
          {
            text: "Yes",
            handler: () => {
              trackingTrigger("connect-open");
              setTimeout(() => {
                window.open("https://www.facebook.com/groups/914482186165247");
              }, 500);
            },
          },
        ],
      });
      return alert.present();
    },
    setSliderFocus(sTop) {
      setSliderFocus(sTop);
    },
  },
  computed: {
    userData() {
      return this.$store.getters["accounts/getUser"];
    },
    getNutritionCarousel() {
      return this.$store.getters["pages/getHomeData"].nutrition;
    },
  },
  watch: {
    userData(userData) {
      // console.log("userData", userData);
      this.hasRecommend = false;
      if (userData) {
        if (_.has(userData, "username") && _.has(userData, "email")) {
          if (userData.recommendation.workout) this.hasRecommend = true;
        }
      }
    },
    getNutritionCarousel(newData) {
      // console.log("newData", newData);
      this.nutritionCarousel = newData;
    },
  },
};
</script>
