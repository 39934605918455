<script>
import "./registerServiceWorker";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonIcon,
  IonLabel,
  IonChip,
  IonButton,
  toastController,
  IonSpinner,
} from "@ionic/vue";
import {
  closeCircle,
  chevronBack,
  close,
  chevronForward,
} from "ionicons/icons";
import { defineComponent } from "vue";
import { trackingTrigger } from "@/_helpers";
import VueCookies from "vue-cookies";

import journeySetupData from "@/data/journey_setup_data.json";
import IntroVideo from "@/components/pages/journey_setup/IntroVideo.vue";
// import IntroSetup from "@/components/pages/journey_setup/IntroSetup.vue";
import JourneyPwa from "@/components/pages/journey_setup/pwa.vue";
import Step1 from "@/components/pages/journey_setup/Step1.vue";
import Step2 from "@/components/pages/journey_setup/Step2.vue";
import Step3 from "@/components/pages/journey_setup/Step3.vue";
import Step4 from "@/components/pages/journey_setup/Step4.vue";
export default defineComponent({
  name: "journey-setup",
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonIcon,
    IonLabel,
    IonChip,
    IonButton,
    IonSpinner,
    IntroVideo,
    //IntroSetup,
    Step1,
    Step2,
    Step3,
    Step4,
    JourneyPwa,
  },
  data() {
    let step = 1;
    let select = journeySetupData.select;
    let forms = journeySetupData.forms;
    let valid = journeySetupData.valid;
    let open = journeySetupData.open;
    let processData = false;
    return {
      maxSteps: 4,
      installEvent: undefined,
      shown: false,
      forms,
      valid,
      select,
      open,
      close,
      closeCircle,
      chevronBack,
      chevronForward,
      step,
      processData,
      steps_1: [],
      steps_2: [],
      steps_3: [],
      diets: [],
      journeyQuestions: [],
      formObj: {
        level: null,
        journey: null,
        dietary_preferences: [],
        available_equipments: [],
        temp_dietary_preferences: [],
      },
      dietCategories: [],
      dietPreferences: [],
      foods: [],
      selectedArray: [],

      progressWidth: 0,
      loading: false,
      hasExisting: false,
      showIntroVideo: false,
      pageLoad: true,
    };
  },
  mounted() {
    // VueCookies.set("newUser", "yes");
    this.$store.dispatch(`accounts/getJourneyQuestions`).then(() => {
      const JourneyQuestions =
        this.$store.getters["accounts/getJourneyQuestions"];
      // console.log("JourneyQuestions--", JourneyQuestions);
      this.journeyQuestions = JourneyQuestions;
      this.steps_1 =
        JourneyQuestions && JourneyQuestions.level.length > 0
          ? JourneyQuestions.level
          : [];
      this.steps_2 =
        JourneyQuestions && JourneyQuestions.allJourney.length > 0
          ? JourneyQuestions.allJourney
          : [];
      this.dietCategories =
        JourneyQuestions &&
        JourneyQuestions.dietaryPreferenceCategories.length > 0
          ? JourneyQuestions.dietaryPreferenceCategories
          : [];
      this.dietPreferences =
        JourneyQuestions && JourneyQuestions.dietaryPreferences.length > 0
          ? JourneyQuestions.dietaryPreferences
          : [];

      // Convert the value from string to integer
      this.steps_1.forEach((obj) => {
        obj.value = +obj.value; // use `+` to convert your string to a number
      });
      // console.log("this.steps_1", this.steps_1);
      // Sort by value in ascending order
      this.steps_1.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );
      // console.log(JSON.parse(JSON.stringify(this.steps_1)));
      // Get the max value

      this.handleProgress();
      this.pageLoad = false;
    });

    let local_storage_data = VueCookies.get("journeySetupData");
    if (local_storage_data) {
      this.formObj = local_storage_data;
    }
    this.step = this.$route.params.step;
    console.log("this.step", this.step);
    if (this.$route.params.step == this.maxSteps) {
      this.processData = true;
    }
    if (this.formObj && this.formObj.dietary_preferences.length > 0) {
      this.hasExisting = true;
    }
    // let newUser = VueCookies.get("newUser");
    // console.log("newUser", newUser);
    // this.showIntroVideo = newUser && newUser == "yes" ? true : false;
    // if (newUser && newUser === "no") {
    //   this.showIntroVideo = false;
    // } else {
    //   this.showIntroVideo = true;
    // }
    this.showIntroVideo = this.step === "intro" ? true : false;
    console.log("this.showIntroVideo", this.showIntroVideo);
    //this.pageLoad = false;
    VueCookies.set("first_login", "no");
  },
  watch: {
    "$route.params.step"(value) {
      this.processData = false;
      if (value == this.maxSteps) {
        this.processData = true;
      }
      this.step = value;
    },
    step() {
      this.handleProgress();
    },
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    levels() {
      // console.log("this.formObj.level", this.formObj.level);
      let data = [];
      this.steps_1.forEach((obj) => {
        // console.log(obj);
        let label = parseInt(obj.label);
        // console.log("label", label);
        if (isNaN(label)) {
          obj.id = parseInt(obj.id);
          obj.isChecked =
            this.formObj.level && obj.id == this.formObj.level ? true : false;
          data.push(obj);
        }
      });
      return data;
    },
    dietaryPreferences() {
      let selected_dietary_preferences = [];
      for (let i = 0; i < this.formObj.dietary_preferences.length; i++) {
        selected_dietary_preferences.push(
          parseInt(this.formObj.dietary_preferences[i])
        );
      }
      // console.log("this.hasExisting", this.hasExisting);
      // console.log("selected_dietary_preferences", selected_dietary_preferences);
      let data = [];
      for (let i = 0; i < this.dietCategories.length; i++) {
        let item = this.dietCategories[i];
        let preferences = this.dietPreferences.filter((list) => {
          let category = list.category;
          if (category && category.id && category.id == item.id) {
            list.id = parseInt(list.id);
            return list;
          }
        });
        // console.log("preferences", preferences);
        let diet_preferences = [];
        let checked_items = 0;
        for (let x = 0; x < preferences.length; x++) {
          let item = preferences[x];
          if (!this.hasExisting) {
            item.isChecked = true;
            checked_items++;
          }

          if (this.hasExisting) {
            if (selected_dietary_preferences.includes(item.id)) {
              // console.log(item.id, item.label);
              item.isChecked = true;
              checked_items++;
            }
          }
          diet_preferences.push(item);
        }

        let isChecked = item.value == "gluten" ? false : true;
        let isDisabled = item.value == "gluten" ? true : false;

        if (this.hasExisting) {
          if (checked_items > 0 && item.value == "gluten") {
            isChecked = true;
            isDisabled = true;
          }
          if (checked_items == 0 && item.value == "gluten") {
            isChecked = false;
            isDisabled = true;
          }
          if (checked_items > 0 && item.value !== "gluten") {
            isChecked = true;
            isDisabled = false;
          }

          if (checked_items == 0 && item.value !== "gluten") {
            isChecked = false;
            isDisabled = false;
          }
        }

        data.push({
          id: parseInt(item.id),
          label: item.label,
          value: item.value,
          isChecked: isChecked,
          disabled: isDisabled,
          preferences: diet_preferences,
        });
      }
      return data;
    },
    avatar() {
      return parseInt(this.step) == 4
        ? "/assets/images/sam.jpg"
        : "/assets/images/naz.jpg";
    },
    journeyCategories() {
      let items = this.steps_2;
      let data = [];

      let filter_results = this.steps_1.filter(
        (obj) => parseInt(obj.id) == this.formObj.level
      );

      let user_level =
        filter_results.length > 0 ? filter_results[0].value : null;

      for (let i = 0; i < items.length; i++) {
        let is_recommend = false;
        let item = items[i];
        // console.log(item);
        // data.push(item);
        let filter_results = journeySetupData.journey_categories.filter(
          (obj) => obj.value == item.value
        );
        // console.log(filter_results);
        let levels =
          filter_results && filter_results.length > 0
            ? filter_results[0].levels
            : [1, 2];

        if (user_level) {
          if (user_level >= levels[0] && user_level <= levels[1]) {
            // console.log("between", item.label);
            is_recommend = true;
          }
        }

        let obj = {
          id: item.id,
          label: item.label,
          value: item.value,
          text:
            filter_results && filter_results.length > 0
              ? filter_results[0].text
              : "",
          level:
            filter_results && filter_results.length > 0
              ? filter_results[0].level
              : "",
          levels: levels,
          is_recommend: is_recommend,
          isChecked:
            this.formObj.journey && item.id == this.formObj.journey
              ? true
              : false,
        };

        data.push(obj);
      }

      data.sort(function (a, b) {
        return a.is_recommend - b.is_recommend;
      });
      return data.reverse();
    },
  },
  beforeMount() {
    console.log("beforeMount fired");

    const userData = this.$store.getters["accounts/getUser"];
    if (
      userData &&
      userData.detail &&
      (
        (userData.detail.is_expired && userData.detail.subscription.plan_name != "Free") ||
        (!userData.detail.subscription.remaining_days && userData.detail.subscription.plan_name != "Free")
      )
    ) {
      
      if(this.$route.name.toLocaleLowerCase() != 'root' ){
        this.$router.replace("/");
      }
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      console.log("beforeinstallprompt fired");
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
      this.maxSteps = 5;
      console.log("beforeinstallprompt fired", e);
    });

    window.addEventListener("appinstalled", (e) => {
      this.shown = false;
      console.log("appinstalled fired", e);
    });

    // let event = new Event("beforeinstallprompt");
    // window.dispatchEvent(event)

    // window.dispatchEvent(event, (e) => {
    //   e.preventDefault();
    //   console.log("dispatchEvent fired", e);
    // });

    // if (!this.installEvent) {
    //   let event = new Event("beforeinstallprompt");
    //   this.shown = window.dispatchEvent(event);
    //   this.installEvent = event;
    //   console.log('new Event("beforeinstallprompt") fired', event);
    // }
  },
  methods: {
    handler: function (e) {
      //do stuff
      e.preventDefault();
    },
    dismissPrompt() {
      this.shown = false;
    },
    installPWA() {
      console.log(this.installEvent, "this.installEvent clicked");
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt(); // Hide the prompt once the user's clicked
        if (choice.outcome === "accepted") {
          // Do something additional if the user chose to install
          this.shown = false;
          this.onSubmit();
        } else {
          // Do something additional if the user declined
        }
        this.installEvent = null;
      });
    },
    handleProgress() {
      setTimeout(() => {
        let width = 0;
        let points = 0;
        let journey_setup_data = VueCookies.get("journeySetupData");
        // console.log("journey_setup_data", journey_setup_data);
        // console.log("step", this.step);
        if (journey_setup_data) {
          //   console.log("journey_setup_data", journey_setup_data);
          let obj = journey_setup_data;

          if (obj.level) {
            points = points + 25;
          }
          if (obj.journey) {
            points = points + 25;
          }
          if (obj.available_equipments.length > 0) {
            points = points + 25;
          }
          if (obj.dietary_preferences.length > 0) {
            points = points + 25;
          }
        }
        width = points;
        // console.log("points", points);
        this.progressWidth = `width: ${width}%`;
      }, 200);
    },
    getNextPage: function (step) {
      step++;
      if (step == 3) {
        this.processData = true;
      }
      return "/journey-setup/" + step;
    },
    setupFormData() {
      // console.log("this.formObj", this.formObj);
      VueCookies.set("journeySetupData", this.formObj);
      this.$store.commit("accounts/setJourneySetupResponse", this.formObj);
      this.handleProgress();
    },
    async openToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 5000,
        color: color,
        position: "middle",
      });
      return toast.present();
    },
    onSubmit() {
      this.loading = true;
      let diets = [];
      var checkedBoxes = document.getElementsByName("chkbx");
      checkedBoxes.forEach((item) => {
        if (item.value) {
          diets.push(parseInt(item.value));
        }
      });
      if (diets.length == 0) {
        this.loading = false;
        this.openToast(
          "Please select at least one dietary preference!",
          "danger"
        );
        return;
      }
      this.formObj.temp_dietary_preferences = diets;

      trackingTrigger("journey-setup-completed", this.formObj);

      // console.log(this.formObj);
      this.setupFormData();
      setTimeout(async () => {
        await this.$store
          .dispatch("accounts/saveUsersJourney")
          .then((response) => {
            if (!response) {
              this.openToast(
                "There's something wrong in saving your journey. Please try again later!",
                "danger"
              );
              this.loading = false;
            }
            if (response.status === 201) {
              this.loading = false;
              this.$router.push(`/journey-success`);
            }
          });
      }, 500);
    },
    handleStep1Input(item) {
      this.formObj.level = parseInt(item.id);
      this.setupFormData();
    },
    handleStep2Input(item) {
      this.formObj.journey = parseInt(item.id);
      this.setupFormData();
      this.$router.push(`/journey-setup/3`);
    },
    handleStep3Input(item) {
      this.formObj.available_equipments = item;
      this.setupFormData();
    },
    hideIntroVideo() {
      // this.showIntroVideo = false;
      // this.$router.push(`/journey-setup/1`);
      // VueCookies.set("newUser", "no");
      window.location.href = `/journey-setup/1`;
    },
  },
});
</script>

<style lang="scss" scoped>
.next-container {
  box-shadow: 0px -3px 16px #00000014;
}
.level {
  .status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border: solid 1px var(--ion-color-black);
    border-radius: 100%;
    margin-left: 3px;
    &.checked {
      background: var(--ion-color-black);
    }
  }
}
.item-chkbx {
  z-index: auto;
}
.question-progress {
  color: gray;
  margin-bottom: 5px;
}
.text-very-light {
  color: #ededed;
}

.recommended {
  --background: yellow;
  --color: red;
}
</style>

<template>
  <ion-page>
    <ion-content :fullscreen="true" >
      <xali-loading v-if="pageLoad" loadingStyle="loader" class="z-50"
        >Loading</xali-loading
      >

      <ion-header v-if="step !== 4" class="ion-no-border mb-5">
        <div class="container max-w-full px-5 md:px-12 py-2">
          <div class="grid grid-cols-12">
            <div class="content col-span-10 md:col-span-11 my-auto">
              <ion-chip color="white" class="rounded-full px-6 bg-black">
                <ion-label color="white" class="font-hokyaa text-base"
                  >Xali <span class="text-primary">Journey</span></ion-label
                >
              </ion-chip>
            </div>
            <div class="nav col-span-2 md:col-span-1 flex">
              <a href="/home" class="my-auto ml-2 md:ml-auto relative">
                <ion-icon
                  :icon="closeCircle"
                  class="block text-light text-5xl text-very-light"
                ></ion-icon>
                <ion-icon
                  :icon="close"
                  class="
                    block
                    text-black text-3xl
                    absolute
                    top-0
                    m-2
                    border border-transparent
                  "
                ></ion-icon>
              </a>
            </div>
          </div>
          <div
            v-if="!showIntroVideo"
            class="back absolute mt-9 md:mt-11 flex"
            @click="$router.go(-1)"
          >
            <ion-icon
              :icon="chevronBack"
              class="block my-auto mr-2 text-xl"
            ></ion-icon>
            <span class="mb-0.5">Back</span>
          </div>
        </div>
        <div class="relative">
          <div class="overflow-hidden h-2 mb-4 text-xs flex bg-light">
            <div
              :style="progressWidth"
              class="
                flex flex-col
                text-center
                whitespace-nowrap
                text-white
                justify-center
                bg-primary
                rounded-r
              "
            ></div>
          </div>
        </div>
      </ion-header>

      <div class="container max-w-full px-6 md:px-12 mt-10">
        <div class="flex justify-center">
          <div class="w-full max-w-md">
            <div class="question-box my-4">
              <div
                v-if="!showIntroVideo"
                class="
                  rounded-full
                  h-12
                  w-12
                  flex
                  items-baseline
                  justify-end
                  overflow-hidden
                  ml-3
                  float-right
                "
              >
                <img :src="avatar" />
              </div>
              <!--<IntroSetup v-if="step === 'intro'" /> -->
               <IntroVideo v-if="step === 'intro'" />

              <!-- Step 1 -->
              <!-- Level -->
              <Step1
                v-if="(!showIntroVideo && step == 1) || step == ''"
                :items="levels"
                @setSelect="handleStep1Input"
              />

              <!-- Step 2 -->
              <!-- Journey -->
              <Step2
                v-if="!showIntroVideo && step == 2"
                :items="journeyCategories"
                @setSelect="handleStep2Input"
              />

              <!-- Step 3 -->
              <!-- Available Equipments -->
              <Step3
                v-if="!showIntroVideo && step == 3"
                @setSelect="handleStep3Input"
              />

              <!-- Step 4 -->
              <!-- Diet Preferences -->
              <Step4
                v-if="
                  (!showIntroVideo && step == 4) ||
                  (!showIntroVideo && step == 5)
                "
                :class="step == 5 && shown ? 'hidden' : ''"
                :items="dietaryPreferences"
              />

              <journey-pwa v-if="step == 5 && shown"> </journey-pwa>
            </div>
          </div>
        </div>
      </div>
      <!-- Next -->
      <div
        class="
          container
          max-w-full
          px-5
          py-3
          next-container
          bg-white
          bottom-0
        "
        
        :class="iOS ? 'ios-spacing' : 'fixed'"
        v-if="!showIntroVideo && step < 5 && forms[step].next && !processData"
      >
        <div class="flex justify-center">
          <div class="w-full max-w-md">
            <router-link :to="getNextPage(step)" @contextmenu="handler($event)">
              <ion-button color="black" expand="block" :disabled="!valid[step]"
                ><div class="px-10 py-3.5 text-white">Next</div></ion-button
              >
            </router-link>
          </div>
        </div>
      </div>
      <!-- Next button for the Intro video -->
      <div
        id="showIntroVideo"
        class="container max-w-full px-5 py-3"
        v-if="step === 'intro'"
      >
        <div class="flex justify-center">
          <div class="w-full max-w-md">
            <ion-button color="black" expand="block" @click="hideIntroVideo()">
              <div class="px-10 py-3.5 text-white">
                  <p class="inline-block align-top">Set up My Journey</p>
                  <ion-icon :icon="chevronForward" size="medium" class="inline-block align-middle"></ion-icon>
           
              </div>
            </ion-button>
          </div>
        </div>
      </div>
      <!-- end -->

      <div
        class="
          container
          max-w-full
          px-5
          py-3
          bottom-0
          next-container
          bg-white
          z-50
        "
        :class="iOS ? 'ios-spacing' : 'fixed'"
        v-if="processData && !shown"
      >
        <div class="flex justify-center">
          <div class="w-full max-w-md">
            <ion-button
              color="black"
              expand="block"
              @click="onSubmit()"
              :disabled="loading"
            >
              <ion-spinner class="p-5" v-if="loading"></ion-spinner>
              <div class="px-10 py-3.5 text-white" v-if="!loading">Finish</div>
            </ion-button>
          </div>
        </div>
      </div>

      <div
        class="container max-w-full fixed bottom-0 next-container bg-white z-50"
        v-if="step == 5"
      >
        <div class="flex justify-center">
          <div class="w-full max-w-md">
            <div class="flex justify-center px-5 py-3" v-if="!iOS && shown">
              <ion-button color="white" expand="block" @click="onSubmit">
                <div class="px-3 py-3.5 text-black">Skip</div>
              </ion-button>

              <ion-button color="black" expand="block" @click="installPWA">
                <div class="px-2 py-3.5 text-white">Add to my Home Screen</div>
              </ion-button>
            </div>

            <div class="w-full max-w-md px-5 py-3" v-if="iOS">
              <ion-button
                color="black"
                expand="block"
                @click="onSubmit()"
                :disabled="loading"
              >
                <ion-spinner class="p-5" v-if="loading"></ion-spinner>
                <div class="px-10 py-3.5 text-white" v-if="!loading">
                  Finish
                </div>
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
