<script>
import {
  IonCheckbox,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonIcon,
  IonSpinner,
  IonSelect,
  IonSelectOption,
  toastController,
} from "@ionic/vue";
import {
  logoApple,
  logoFacebook,
  logoGoogle,
  checkmarkCircle,
} from "ionicons/icons";
import {
  initFacebookSdk,
  loginFacebookSdk,
  initGoogle,
  signinGoogle,
  trackingTrigger,
} from "@/_helpers";
//import router from "@/router";
import _ from "lodash";
import countries from "@/data/countries.json";
import excluded_countries from "@/data/excluded_countries.json";
import { iconEye } from "@/_helpers/icons";
import VueCookies from "vue-cookies";
//import mixpanel from 'mixpanel-browser';
import { useRoute } from "vue-router";

export default {
  components: {
    IonButton,
    IonLabel,
    IonInput,
    IonItem,
    IonCheckbox,
    IonIcon,
    IonSpinner,
    IonSelect,
    IonSelectOption,
  },
  data() {
    let formData = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      country: "AU",
      agree: false,
    };
    return {
      checkmarkCircle,
      loading: false,
      logoApple,
      logoFacebook,
      logoGoogle,
      formData,
      errors: {},
      fbInitReponse: null,
      countries,
      excluded_countries,
      iconEye,
      showEye: false,
      disableFacebook: false,
      disableGoogle: false,
      coupon: "",
      couponDetails: {},
      nextPage : "/subscription/choose-plan/"
    };
  },
  mounted() {
    const route = useRoute();
    if (route.query.coupon) {
      this.loading = true
      this.$store
        .dispatch(`pages/getCoupon`, route.query.coupon)
        .then((res) => {
          this.couponDetails = res;
          this.coupon = route.query.coupon;
          this.loading = false
            if(this.coupon){
              this.nextPage = `${this.nextPage}?coupon=${this.coupon}`
            }
        });
    }

    this.checkLocationAllow();
    initFacebookSdk().then((response) => {
      console.log("Facebook JS SDK Loaded");
      this.fbInitReponse = response;
    });

    initGoogle().then(() => {
      console.log("Google JS SDK Loaded");
    });
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    filtered_countries() {
      let excluded = _.map(excluded_countries, (item) => {
        return item.code;
      });
      return _.filter(countries, (v) => !_.includes(excluded, v.code));
    },
  },
  watch: {
    "formData.password"() {
      // console.log(this.formData.password);
      if (this.formData.password) {
        this.showEye = true;
      } else {
        this.showEye = false;
      }
    },
  },
  methods: {
    isUpperCase(str) {
      var i = 0;
      var character = "";
      while (i <= str.length) {
        character = str.charAt(i);
        if (character) {
          if (character === character.toUpperCase()) {
            return true;
          }
        }
        i++;
      }
      return false;
    },
    isNumber(str) {
      var i = 0;
      var character = "";
      while (i <= str.length) {
        character = str.charAt(i);
        if (character) {
          if (!isNaN(character * 1)) {
            return true;
          }
        }
        i++;
      }
      return false;
    },
    async signUpFacebook() {
      await loginFacebookSdk(this.coupon).then(async (response) => {
        if (response) {
          console.log("Sign up success!", response);
          VueCookies.set("newUser", "yes");
          VueCookies.set("event", "signup");
          VueCookies.set("first_login", "yes");
          if (response.is_new) {
            await trackingTrigger("signup", {
              method: "Facebook",
            });
            setTimeout(() => {
              window.location.href = this.nextPage;
            }, 1000);
          } else {
            await trackingTrigger("login", {
              method: "Facebook",
            });
            
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          }
          // const urlParams = new URLSearchParams(window.location.search);
          // const debug = urlParams.get("debug");
          // if (!debug) {
          //   setTimeout(() => {
          //     window.location.href = "/journey-setup";
          //   }, 1000);
          // }
        }
      });
    },
    async signUpGoogle() {
      await signinGoogle(this.coupon).then(async (response) => {
        this.loading = true;
        console.log("Signup success!", response);
        VueCookies.set("newUser", "yes");
        VueCookies.set("event", "signup");
        VueCookies.set("first_login", "yes");
        if (response.is_new) {
          await trackingTrigger("signup", {
            method: "Google Connect",
          });
          setTimeout(() => {
            window.location.href = this.nextPage;
          }, 1000);
        } else {
          await trackingTrigger("login", {
            method: "Google Connect",
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        }
        // const urlParams = new URLSearchParams(window.location.search);
        // const debug = urlParams.get("debug");
        // if (!debug) {
        //   setTimeout(() => {
        //     window.location.href = "/journey-setup";
        //   }, 1000);
        // }
      });
    },
    submitForm: async function (e) {
      e.preventDefault();
      this.errors = {};
      Object.keys(this.formData).forEach((field) => {
        if (!this.formData[field])
          this.errors[field] =
            '<span class="capitalize">' + field + "</span> is required.";
      });
      //   console.log(JSON.parse(JSON.stringify(this.formData)));
      // Submit to API services
      if (_.isEmpty(this.errors, true)) {
        this.loading = true;
        try {
          await this.$store
            .dispatch("accounts/create", {
              coupon: this.coupon,
              formData: this.formData,
            })
            .then((response) => {
              if (response.status === 201) {
                const toLogin = {
                  username: this.formData.email,
                  password: this.formData.password,
                };
                this.formData = {
                  first_name: "",
                  last_name: "",
                  email: "",
                  password: "",
                  agree: false,
                };
                VueCookies.set("newUser", "yes");
                VueCookies.set("first_login", "yes");

                if (window.dataLayer) {
                  // setTimeout(() => {
                  //   window.location.href = "/journey-setup";
                  // }, 500);
                  this.$store
                    .dispatch("accounts/login", toLogin)
                    .then(async (response) => {
                      console.log("toLogin--", toLogin, response);
                      if (response.status === 200) {
                        trackingTrigger("signup", {
                          method: "Email",
                        });

                        this.loading = false;
                        //window.location.href = "/journey-setup";
                        const urlParams = new URLSearchParams(
                          window.location.search
                        );
                        const debug = urlParams.get("debug");
                        if (!debug) {
                          setTimeout(() => {
                            window.location.href = this.nextPage;
                          }, 1000);
                        }
                      }
                    });
                }
                // let counter = 0;
                // var klavSignup = setInterval(() => {
                //   counter++;
                //   if(counter == 100){
                //     window.location.href = "/login";
                //   }
                //   if(window.klaviyo.signup && window.identifySignup) {
                //     clearInterval(klavSignup);
                //     setTimeout(()=> {
                //       window.location.href = "/login";
                //     }, 500)
                //   }
                // }, 50);
                this.openToast(
                  "Your account is successfully created.",
                  "success"
                );
              } else {
                this.openToast("" + response.messages[0], "danger");
                this.loading = false;
              }
            });
        } catch (err) {
          console.log("Error dispatch accounts/create #1", err);
          this.openToast("Error", "danger");
        }
      }
      e.preventDefault();
    },
    async openToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 5000,
        color: color,
      });
      return toast.present();
    },
    showPassword() {
      let ele = document.getElementById("signup-password");
      let icon = document.getElementById("signup-eyeIcon");
      if (ele.type === "password") {
        ele.type = "text";
        icon.classList.remove("text-light");
        icon.classList.add("text-primary");
      } else {
        ele.type = "password";
        icon.classList.remove("text-primary");
        icon.classList.add("text-light");
      }
    },
    async checkLocationAllow() {
      const Http = new XMLHttpRequest();
      Http.open("GET", "https://ipinfo.io?token=c8dba6e991f0b6");
      Http.send();
      Http.onreadystatechange = () => {
        if (Http.responseText) {
          const info = JSON.parse(Http.responseText);
          //const notAllow = ['Canada','CA','U.S','US','United States']
          const notAllow = [];
          if (notAllow.includes(info.country)) {
            console.log("Disable Facebook");
            this.disableFacebook = true;
            this.disableGoogle = true;
            return false;
          }
        }
      };
    },
  },
};
</script>
<style scoped>
ion-select {
  /* Applies to the value and placeholder color */
  color: #000;
  background: none !important;
}

.password-badge > span {
  background: #d7d7d7;
}
.password-badge > span.text-grey {
  color: #676666;
}
.password-badge > span.active {
  background: #dcf4f5;
}
</style>

<template>
  <div class="alternative">
    <ion-button
      class="mb-2"
      expand="block"
      fill="outline"
      color="black"
      @click="signUpFacebook"
      v-if="!disableFacebook"
    >
      <div class="px-10 py-2.5 md:py-3.5 flex">
        <ion-icon :icon="logoFacebook" class="text-lg mr-2"></ion-icon> Continue
        with Facebook
      </div>
    </ion-button>
    <ion-button
      class="mb-2"
      expand="block"
      fill="outline"
      color="black"
      @click="signUpGoogle"
      v-if="!disableGoogle"
    >
      <div class="px-10 py-2.5 md:py-3.5 flex">
        <ion-icon :icon="logoGoogle" class="text-lg mr-2"></ion-icon> Continue
        with Google
      </div>
    </ion-button>
    <!-- <ion-button expand="block" fill="outline" color="white">
      <div class="px-10 py-3.5 font-normal flex">
        <ion-icon :icon="logoApple" class="text-lg mr-2"></ion-icon> Continue
        with Apple
      </div>
    </ion-button> -->
  </div>
  <div class="line mt-12 mb-7 md:mb-8 flex">
    <span class="bg-white m-auto p-4 -mt-8">or</span>
  </div>
  <div class="signup-form relative">
    <form @submit="submitForm">
      <ion-item>
        <ion-label position="floating">First Name</ion-label>
        <ion-input
          required="true"
          v-model="formData.first_name"
          v-on:keyup.enter="submitForm"
          autocomplete="off"
          name="first_name"
        ></ion-input>
      </ion-item>
      <div
        class="text-sm text-danger px-2 h-1"
        v-if="errors.first_name"
        v-html="errors.first_name"
      ></div>
      <ion-item>
        <ion-label position="floating">Last Name</ion-label>
        <ion-input
          required="true"
          v-model="formData.last_name"
          v-on:keyup.enter="submitForm"
          autocomplete="off"
          name="last_name"
        ></ion-input>
      </ion-item>
      <div
        class="text-sm text-danger px-2 h-1"
        v-if="errors.last_name"
        v-html="errors.last_name"
      ></div>
      <ion-item>
        <ion-label position="floating">Email</ion-label>
        <ion-input
          required="true"
          v-model="formData.email"
          v-on:keyup.enter="submitForm"
          autocomplete="off"
          name="email"
        ></ion-input>
      </ion-item>
      <div
        class="text-sm text-danger px-2 h-1"
        v-if="errors.email"
        v-html="errors.email"
      ></div>
      <!-- <ion-item>
        <ion-label position="floating">Set a password</ion-label>
        <ion-input
          required="true"
          color="white"
          type="password"
          clearOnEdit="true"
          v-model="formData.password"
          v-on:keyup.enter="submitForm"
        ></ion-input>
      </ion-item> -->
      <ion-item>
        <ion-label position="floating">Set a Password</ion-label>
        <ion-input
          id="signup-password"
          required="true"
          type="password"
          clearOnEdit="true"
          v-model="formData.password"
          v-on:keyup.enter="submitForm"
          autocomplete="off"
          name="password"
        >
        </ion-input>
        <ion-icon
          id="signup-eyeIcon"
          :icon="iconEye"
          v-if="showEye"
          class="mt-10 cursor-pointer text-light"
          slot="end"
          @click="showPassword"
          style="height: 16px"
        ></ion-icon>
      </ion-item>
      <div class="text-xs my-2 flex password-badge" v-if="formData.password">
        <span
          class="rounded-2lg py-0.5 px-1.5 pl-1 mr-1 flex items-center"
          :class="
            formData.password.length > 7 ? 'text-primary active' : 'text-grey'
          "
        >
          <ion-icon :icon="checkmarkCircle" class="mr-1"> </ion-icon>
          <span>8 Char min</span>
        </span>

        <span
          class="rounded-2lg py-0.5 px-1.5 pl-1 mr-1 flex items-center"
          :class="
            isUpperCase(formData.password) ? 'text-primary active' : 'text-grey'
          "
        >
          <ion-icon :icon="checkmarkCircle" class="mr-1"> </ion-icon>
          <span>1 Uppercase</span>
        </span>

        <span
          class="rounded-2lg py-0.5 px-1.5 pl-1 flex items-center"
          :class="
            isNumber(formData.password) ? 'text-primary active' : 'text-grey'
          "
        >
          <ion-icon :icon="checkmarkCircle" class="mr-1"> </ion-icon>
          <span>1 Number</span>
        </span>
      </div>

      <div
        class="text-sm text-danger px-2 h-1"
        v-if="errors.password"
        v-html="errors.password"
      ></div>
      <ion-item>
        <ion-label position="floating">Country</ion-label>
        <ion-select mode="ios" v-model="formData.country" interface="alert">
          <ion-select-option
            class="white-option"
            v-for="(country, index) in filtered_countries"
            :key="index"
            :value="country.code"
            style="color: black !important"
            >{{ country.name }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      <div
        class="text-sm text-danger px-2 h-1"
        v-if="errors.country"
        v-html="errors.country"
      ></div>
      <div class="flex my-5">
        <div class="flex">
          <ion-checkbox
            color="black"
            class="my-auto mr-4"
            v-model="formData.agree"
          ></ion-checkbox>
        </div>
        <div>
          <label
            for="sample"
            class="text-sm"
            v-bind:class="[errors.agree ? 'text-danger' : '']"
            >You have read, understand and agree to our
            <a
              href="https://xali.com.au/terms-conditions/"
              class="underline"
              target="_blank"
              >Terms & Conditions</a
            >
            &
            <a
              href="https://xali.com.au/privacy-policy/"
              class="underline"
              target="_blank"
              >Privacy Policy</a
            >.
          </label>
        </div>
      </div>

      <ion-button
        color="black"
        expand="block"
        @click="submitForm"
        :disabled="loading"
      >
        <div class="px-10 py-2.5 text-base md:py-3.5 font-bold" v-if="!loading">
          <span> Next </span>
        </div>
        <div class="px-10 py-2.5 md:py-2.5" v-if="loading">
          <ion-spinner name="bubbles"></ion-spinner>
        </div>
      </ion-button>
      <p class="text-xs text-center mt-5" :class="iOS ? 'ios-spacing' : ''"  v-if="!loading">
        <span v-if="!coupon">Cancel anytime within 7 days and you will not be charged.</span>
        <span v-if="coupon">Cancel anytime within {{ couponDetails.interval }} {{ couponDetails.duration }} and you will not be charged.</span>
        
      </p>
    </form>
  </div>
</template>
