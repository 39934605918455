export default {
  setHomeData(state, value) {
    state.home = value;
  },
  setFitnessData(state, value) {
    state.fitness = value;
  },
  setFitnessStretchflowData(state, value) {
    state.fitnessStretchflow = value;
  },
  setFitnessType(state, value) {
    state.fitness.type = value;
  },
  setFitnessCategory(state, value) {
    state.category.category = value;
  },
  setFitnessCategoryID(state, value) {
    state.category.id = value;
    state.fitness.category = value;
  },
  setCurrentCategory(state, value) {
    state.category = value;
  },
  setActivities(state, value) {
    state.category.activity = value;
  },
  addCurrentCategory(state, value) {
    var activity = JSON.parse(JSON.stringify(state.category.activity));
    activity = activity.concat(value.activity);
    state.category.activity = activity;
  },

  setAllActivityData(state, value) {
    state.allActivity = value;
  },
  setCurrentActivity(state, value) {
    state.activity = value;
  },
  setActivityPostProduction(state, value) {
    state.activityPostProduction = value;
  },
  setNutritionData(state, value) {
    state.nutrition = value;
  },
  setNutritionCategory(state, data) {
    state.recipe_category[data.key] = data.value;
  },

  addNutritionCategory(state, data) {
    var currentItems = JSON.parse(
      JSON.stringify(state.recipe_category[data.key])
    );
    currentItems = currentItems.concat(data.value);
    state.recipe_category[data.key] = currentItems;
  },
  setCurrentRecipe(state, value) {
    state.recipe = value;
  },
  setIngredient(state, value) {
    state.ingredients.all = value;
  },
  setCurrentIngredient(state, value) {
    state.ingredients.current = value;
  },
  setMoveType(state, value) {
    state.move.type = value;
  },
  setMovePaginationFirst(state, value) {
    state.move.first = value;
  },
  setMovePaginationOffset(state, value) {
    state.move.offset = value;
  },
  setMoveData(state, value) {
    state.move.data = value;
  },
  setFieldOptions(state, options) {
    state.fieldOptions = options;
  },
  setRecipeFieldOptions(state, options) {
    state.recipeFieldOptions = options;
  },
  setDietaryPreferences(state, data) {
    state.dietaryPreferences = data;
  },
  setFilterArgs(state, filter) {
    state.filterArgs = filter;
  },
  setFavouritesData(state, value) {
    state.favourites = value;
  },
  sortActivity(state, value) {
    state.category.activity = value;
  },
};
