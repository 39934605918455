<template>
  <div class="workout-setup-chat px-6 pb-6 h-full flex">
    <div class="chat-box min-w-full" ref="chatBox">
      <div class="space h-1/6"></div>
      <Chat
        v-for="(chat, i) in chatData"
        :key="i"
        :avatar="chat.avatar"
        :from="chat.from"
        :type="chat.type"
        :selected="chat.selected"
      >
        <div
          @click.prevent="chatCallback(i, chat.group)"
          class="py-3 px-6 -my-3 -mx-6 cursor-pointer min-w-full"
          v-html="chat.message"
          v-if="chat.callback"
        ></div>
        <template v-if="chat.type == 'action'">
          <div class="min-w-full flex" v-html="chat.message"></div>
        </template>
        <template v-if="!chat.type">
          <span v-if="!chat.callback" v-html="chat.message"></span>
        </template>
      </Chat>

      <div class="bubble-chat my-5" v-if="showAction">
        <ion-slides :options="slideOpts" v-if="recipes.length">
          <ion-slide
            v-for="(item, i) in recipes"
            :key="i++"
            v-bind:class="[i == 1 ? '' : '', i == recipes.length ? '' : '', '']"
          >
            <CarouselItem v-bind:data="item"></CarouselItem>
          </ion-slide>
        </ion-slides>
        <div v-if="!recipes.length" class="text-center">
          No recommend recipes found.
        </div>
        <router-link to="/eat">
          <ion-button
            expand="block"
            fill="outline"
            color="black"
            class="mt-5 font-medium"
          >
            <div class="px-10 py-2.5 md:py-3.5 flex font-medium">
              Browse more recipes
            </div>
          </ion-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { IonButton, IonSlides, IonSlide } from "@ionic/vue";
import Chat from "../commons/BubbleChat.vue";
import CarouselItem from "./carousel/CarouselItem.vue";
import { trackingTrigger } from "@/_helpers";
import _ from "lodash";

export default {
  components: { IonButton, Chat, IonSlides, IonSlide, CarouselItem },
  props: ["user"],
  data() {
    const slideOpts = {
      slidesPerView: 1,
      setWrapperSize: true,
      spaceBetween: 15,
    };
    let recipes = [];
    return { slideOpts, chatData: [], showAction: false, recipes: recipes };
  },
  mounted() {
    setTimeout(() => {
      this.chatData = [
        {
          message: `Hey ${this.user.first_name}, what do you feel like eating?`,
          avatar: "/assets/images/sam.jpg",
        },
        {
          message: "I want to <span class='font-medium'>boost my brain power</span>",
          _message: "I want to boost my brain power",
          from: "right",
          callback: "brain-function",
          group: 1,
        },
        {
          message:"I just worked out - <span class='font-medium'>refuel me please!</span>",
          _message:"I just worked out - refuel me please!",
          from: "right",
          callback: "power",
          group: 1,
        },
        {
          message: "I need an <span class='font-medium'>immunity boost</span>",
          _message: "I need an immunity boost",
          from: "right",
          callback: "immunity-boosting",
          group: 1,
        },
        {
          message:"I need some <span class='font-medium'>anti-inflammatory, gut healing food</span>",
          _message:"I need some anti-inflammatory, gut healing food",
          from: "right",
          callback: "restorative",
          group: 1,
        },
        {
          message:"I want to <span class='font-medium ml-1'>increase my energy</span>",
          message:"I want to increase my energy",
          from: "right",
          callback: "fresh-vital",
          group: 1,
        },
      ];
      this.scrollToEnd();

      // Restore from last recommend request
      // const recommend = this.$store.getters["accounts/getRecommended"];
      // console.log('recommend1--',recommend);
      // if(!_.isEmpty(recommend.recipe)){

      //   // this.$store.dispatch('accounts/getRecommendedRecipe',recommend.recipe.ids).then(() => {
      //   //   const recommend = this.$store.getters["accounts/getRecommended"];
      //     this.chatData.push({
      //       message:"All right, I picked you a selection of 3 recipes.",
      //       avatar: "/assets/images/sam.jpg",
      //       group: 'action',
      //     });
      //     this.scrollToEnd();
      //     setTimeout(() => {
      //       this.$router.push('/eat/recommended');
      //       setTimeout(() => {
      //         this.$emit("close");
      //       }, 500);
      //     }, 2000);

      //   //   this.recipes = recommend.recipe.recipes;
      //   //
      //     //this.showAction = true;
      //     // setTimeout(() => {
      //     //   this.$router.push('/eat/recommended');
      //     // }, 1000);
      //   //})
      // }
    }, 100);
  },
  methods: {
    gotoWorkout() {
      console.log("go to --> /workout");
      //this.$router.push(this.actionLink);
    },
    scrollToEnd: function (delay = 300) {
      setTimeout(() => {
        var chatBox = this.$refs.chatBox;
        if (chatBox) {
          chatBox.scrollTop = chatBox.lastElementChild.offsetTop;
        }
      }, delay);
    },
    chatCallback: async function (id, group = null) {
      if (id && group) {
        this.chatData.forEach((cData) => {
          if (cData.group == group) {
            cData.selected = false;
          }
        });
      }
      this.chatData[id].selected = true;

      // Call function
      if (this.chatData[id].callback) {
        trackingTrigger("recipe-recommended", this.chatData[id]);
        this.getRecommend(this.chatData[id].callback);
      }
    },
    getRecommend: function (effect) {
      this.chatData = _.compact(
        _.map(this.chatData, (d) => {
          if (d.group != "action") return d;
        })
      ); //Remove previus action message
      // console.log("this.chatData--", this.chatData);
      // this.chatData.push({
      //   message:"All right, I picked you a selection of 3 recipes.",
      //   avatar: "/assets/images/sam.jpg",
      //   group: 'action',
      // });
      // TODO: Need optimization
      this.$store.dispatch("accounts/setRecommendedRecipe", effect).then(() => {
        // const data = this.$store.getters["accounts/getRecommended"];
        // this.recipes = data.recipe.recipes;
        // this.recipes = _.map(this.recipes, (data) => {
        //   return { ...data, tags: [] };
        // });
        this.scrollToEnd();
        setTimeout(() => {
          this.$emit("close");
          window.location.href = `/eat/recommended/`;
        }, 300);
        // setTimeout(() => {
        //   const recommend = this.$store.getters["accounts/getRecommended"];
        //   // console.log(recommend);
        //   let recipes = [];
        //   if (!_.isEmpty(recommend.recipe)) {
        //     recipes = _.map(recommend.recipe.ids, (n) => {
        //       return n.toString();
        //     });
        //     window.location.href = `/eat/recommended/${recipes[0]}`;
        //   }
        //   window.location.href = `/eat/recommended/`;
        //   setTimeout(() => {
        //     this.$emit("close");
        //   }, 300);
        // }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.workout-setup-chat {
  position: relative;
  overflow: hidden;
  // &::before {
  //   background: transparent
  //     linear-gradient(180deg, var(--unnamed-color-ffffff) 0%, #ffffff00 100%) 0%
  //     0% no-repeat padding-box;
  //   background: transparent linear-gradient(180deg, #ffffff 0%, #ffffff00 100%)
  //     0% 0% no-repeat padding-box;
  //   content: "";
  //   width: 100%;
  //   height: 170px;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: 1;
  // }
  .chat-box {
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    margin-right: -41px;
    padding-right: 23px;
    @media (pointer: none), (pointer: coarse) {
      margin-right: -10px;
      padding-right: 10px;
    }
  }
}
</style>
