<script>
import { IonIcon, IonList, IonItem } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
export default {
  name: "setting-item",
  emits: ["setSelect", "onClose"],
  components: {
    IonList,
    IonItem,
    IonIcon,
  },
  data() {
    return {
      chevronForward,
    };
  },
  methods: {
    selected(item) {
      this.$emit("setSelect", item);
    },
    onclick() {
      setTimeout(() => {
        this.$emit("onClose");
      }, 2000);
    },
  },
};
</script>
<template>
  <ion-list>
    <ion-item>
      <router-link
        to="/favourites"
        @click="onclick"
        class="flex justify-between items-center w-full"
      >
        Favourites
        <ion-icon :icon="chevronForward" size="medium"></ion-icon>
      </router-link>
    </ion-item>
    <ion-item>
      <div
        class="flex justify-between items-center w-full cursor-pointer"
        @click="selected('profile')"
      >
        Profile
        <ion-icon :icon="chevronForward" size="medium"></ion-icon>
      </div>
    </ion-item>
    <ion-item>
      <router-link
        to="/journey-setup"
        @click="onclick"
        class="flex justify-between items-center w-full"
      >
        Journey
        <ion-icon :icon="chevronForward" size="medium"></ion-icon>
      </router-link>
    </ion-item>
    <ion-item>
      <div
        class="flex justify-between items-center w-full cursor-pointer"
        @click="selected('subscription')"
      >
        Subscription
        <ion-icon :icon="chevronForward" size="medium"></ion-icon>
      </div>
    </ion-item>
    <ion-item>
      <div
        class="flex justify-between items-center w-full cursor-pointer"
        id="intercom_msg"
      >
        Help & Support
        <ion-icon :icon="chevronForward" size="medium"></ion-icon>
      </div>
    </ion-item>
    <ion-item>
      <router-link
        to="/history"
        @click="onclick"
        class="flex justify-between items-center w-full"
      >
        Recently viewed
        <ion-icon :icon="chevronForward" size="medium"></ion-icon>
      </router-link>
    </ion-item>
  </ion-list>
</template>
