<script>
import { searchOutline } from "ionicons/icons";
import CarouselItem from "@/components/pages/carousel/CarouselItem.vue";
import FilterTemplate from "../filters/activity.vue";
// import ListContent from "@/components/pages/move/ListContent.vue";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonInput,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
  IonImg,
} from "@ionic/vue";

import api from "@/api";
export default {
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonInput,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    CarouselItem,
    IonSpinner,
    IonImg,
    FilterTemplate,
  },
  data() {
    return {
      type: "workout",
      id: null,
      category: null,
      searchOutline,
      loading: true,
      title: "",
      items: [],
      first: 0,
      page: 1,
      rowsperpage: 10,
      totalCount: 0,
      maxpage: 0,
      searching: false,
      loadmoreBtn: true,
      loadmoreLoading: false,
      isSearchStatus: false,
      args: "",
      search: "",
    };
  },
  mounted() {
    this.$store.dispatch(`pages/fieldOptions`);
    this.type = this.$route.params.type;
    if (this.type == "workout") this.title = "Workout";
    if (this.type == "warmup") this.title = "Warmup";
    if (this.type == "stretch-flow") {
      this.title = "Stretch Flow";
      this.type = "stretch_flow";
    }

    window.document.title = this.title ? `${this.title}s | Xali` : "Xali";
    // console.log("AL this.type", this.type);
    // this.loading = false;
    this.$store.commit("pages/setMoveData", []);
    this.$store.commit("pages/setMoveType", this.type);
    this.items = [];
    this.loading = true;
    this.initialLoad();
  },
  methods: {
    async initialLoad() {
      const response = await api.getTotalCountByActivity(this.type);
      if (response) {
        this.totalCount = response.data.allActivity.totalCount;
        this.maxpage = Math.ceil(this.totalCount / this.rowsperpage);
        // console.log("this.totalCount", this.totalCount);
        this.loading = false;
        this.searching = true;
        setTimeout(() => {
          this.onSearch();
        }, 500);
      }
    },
    async filterUpdates(e) {
      this.args = e.args;
      this.refrestType(e.params);
      const response = await api.getTotalCountByActivity(this.args);
      if (response) {
        this.totalCount = response.data.allActivity.totalCount;
        this.maxpage = Math.ceil(this.totalCount / this.rowsperpage);
        console.log("this.totalCount", this.totalCount);
        this.loading = false;
        this.searching = true;
        this.$store.commit("pages/setMoveData", []);
        this.items = [];
        this.page = 1;
        setTimeout(() => {
          this.onSearch();
        }, 500);
      }
    },
    refrestType(params) {
      // this.type = this.$route.params.type;
      setTimeout(() => {
        console.log("params", params);
        if (params.type == "workout") this.title = "Workout";
        if (params.type == "warmup") this.title = "Warmup";
        if (params.type == "stretch_flow") {
          this.title = "Stretch Flow";
          this.type = "stretch_flow";
        }
      }, 500);
    },
    onScroll(event) {
      console.log(event.detail);
    },
    onSearch() {
      // console.log("this.args", this.args);
      this.loadmoreLoading = true;
      this.searching = true;
      let offset = (this.page - 1) * this.rowsperpage;
      // console.log("this.rowsperpage", this.rowsperpage);
      // console.log("offset", offset);
      // console.log("this.maxpage", this.maxpage);
      this.$store.commit(
        "pages/setMovePaginationFirst",
        parseInt(this.rowsperpage)
      );
      this.$store.commit("pages/setMovePaginationOffset", parseInt(offset));
      this.$store
        .dispatch(`pages/getAllActivityByType`, this.args)
        .then((res) => {
          for (let i = 0; res.length > i; i++) {
            this.items.push(res[i]);
          }
          // console.log("res", res);
          this.loadmoreLoading = false;
          this.loadmoreBtn = this.searchStatus();
        });
      this.page = this.page + 1;
      // console.log("this.page", this.page);
    },
    searchStatus() {
      return !this.loadmoreLoading &&
        !this.loading &&
        this.page - 1 < this.maxpage
        ? true
        : false;
    },

    sorting(e) {
      // SHORTEST TO LONGEST
      if (e.sort == "shortest") {
        this.items.sort(function (a, b) {
          var a_duration = 0;
          var b_duration = 0;
          var a_activity = "0mins";
          var b_activity = "0mins";

          var a_count_tag = JSON.parse(JSON.stringify(a.tags)).length;
          var b_count_tag = JSON.parse(JSON.stringify(b.tags)).length;

          if (a_count_tag > 0 && a.tags[a.tags.length - 1].text) {
            a_activity = a.tags[a.tags.length - 1].text;
          }

          if (b_count_tag > 0 && b.tags[b.tags.length - 1].text) {
            b_activity = b.tags[b.tags.length - 1].text;
          }

          if (a_activity.includes("mins") && a.play) {
            a_duration = a_activity.split("mins")[0];
          }
          if (b_activity.includes("mins") && b.play) {
            b_duration = b_activity.split("mins")[0];
          }
          return parseInt(a_duration) - parseInt(b_duration);
        });
      }

      // LONGEST TO SHORTEST
      if (e.sort == "longest") {
        this.items.sort(function (a, b) {
          var a_duration = 0;
          var b_duration = 0;
          var a_activity = "0mins";
          var b_activity = "0mins";

          var a_count_tag = JSON.parse(JSON.stringify(a.tags)).length;
          var b_count_tag = JSON.parse(JSON.stringify(b.tags)).length;
          if (a_count_tag > 0 && a.tags[a.tags.length - 1].text) {
            a_activity = a.tags[a.tags.length - 1].text;
          }

          if (b_count_tag > 0 && b.tags[b.tags.length - 1].text) {
            b_activity = b.tags[b.tags.length - 1].text;
          }

          if (a_activity.includes("mins") && a.play) {
            a_duration = a_activity.split("mins")[0];
          }
          if (b_activity.includes("mins") && b.play) {
            b_duration = b_activity.split("mins")[0];
          }
          return parseInt(b_duration) - parseInt(a_duration);
        });
      }

      //LEVEL
      if (e.sort == "level") {
        this.items.sort(function (a, b) {
          var level_a = 1;
          var level_b = 1;

          if (a.data.levels.length) {
            level_a = a.data.levels[a.data.levels.length - 1].value;
          }
          if (b.data.levels.length) {
            b.data.levels[b.data.levels.length - 1].value;
          }

          if (a.data.primaryLevel) {
            level_a = a.data.primaryLevel.value;
          }
          if (b.data.primaryLevel) {
            level_b = b.data.primaryLevel.value;
          }

          return parseInt(level_a) - parseInt(level_b);
        });
      }
    },
    searchFunc() {
      this.loading = true;
      this.$store.dispatch(`pages/searchActivity`, this.search).then((res) => {
        this.loading = false;
        this.loadmoreBtn = false;
        //this.$store.commit("pages/setMoveData", res);
        let activities = [];
        for (let i = 0; res.length > i; i++) {
          if (res[i].type.toLowerCase() == this.type.toLowerCase())
            activities.push(res[i]);
        }
        this.items = activities;
      });
    },

    updateSearchValue(e) {
      if (this.search && e.keyCode === 13) {
        this.searchFunc()
      }
      this.searchValue = this.search;
      if (this.search) {
        this.searchBtn = true;
      } else {
        this.searchBtn = false;
      }
    },
    openSearch() {
      this.isSearchStatus = true;
      this.$refs.searchbar.$el.focus();
    },
  },
};
</script>

<template>
  <ion-page>
    <ion-content>
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <!-- Heading -->
        <xali-header
          :settings="{ back: true, hidemobilenav: true }"
        ></xali-header>
        <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
          <div class="heading flex flex-col sm:flex-row">
            <h1 class="text-4.5xl font-bold">{{ title }}</h1>
            <div
              class="
                search
                flex
                bg-black bg-opacity-5
                rounded-2lg
                mt-4
                sm:hidden
              "
            >
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
              <ion-input
                :value="search"
                @ionInput="search = $event.target.value"
                placeholder="Search a workout"
                v-on:keyup="searchFunc"
              ></ion-input>
            </div>
            <div class="search-button ml-auto my-auto hidden sm:block">
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark ml-auto"
                @click="openSearch"
                v-if="!isSearchStatus"
              >
              </ion-icon>

              <div
                class="
                  search
                  flex
                  bg-light bg-opacity-30
                  rounded-md
                  block
                  mt-2.5
                "
                v-if="isSearchStatus"
                :class="searchBtn ? 'mr-1' : ''"
              >
                <ion-icon
                  :icon="searchOutline"
                  class="block text-2xl text-dark my-auto ml-2"
                ></ion-icon>
                <ion-input
                  @ionInput="search = $event.target.value"
                  :placeholder="'Search a ' + title.toLowerCase()"
                  v-on:keyup="updateSearchValue"
                  autofocus="true"
                  ref="searchbar"
                  :value="search"
                ></ion-input>
              </div>
            </div>
            <ion-button
              class="bg-primary rounded-md mt-3"
              fill="outline"
              @click="searchFunc"
              v-if="searchBtn"
            >
              <div class="px-2 py-3 font-normal flex text-white">Search</div>
            </ion-button>
          </div>
        </div>

        <h3 class="px-6 text-2xl" v-if="!items">No result found.</h3>
        <div
          @scroll="onScroll()"
          class="
            container
            max-w-full
            mt-0
            px-6
            lg:px-12
            grid grid-cols-12
            gap-0
            sm:gap-4
          "
          v-if="items"
        >
          <div
            v-for="(item, i) in items"
            :key="i++"
            class="col-span-12 sm:col-span-6 lg:col-span-4 mb-5"
          >
            <CarouselItem v-bind:data="item"></CarouselItem>
          </div>
        </div>

        <div class="mt-10 mb-10 flex flex-col" v-if="loadmoreLoading">
          <ion-spinner
            name="bubbles"
            color="primary"
            class="mx-auto transform scale-200"
          ></ion-spinner>
          <ion-img
            :src="'/assets/images/icon.svg'"
            class="w-10 spinner hidden"
          ></ion-img>
        </div>
        <div
          class="mt-10 mb-10 flex justify-center"
          v-if="loadmoreBtn && !loadmoreLoading"
          id="loadmore"
        >
          <ion-infinite-scroll
            @ionInfinite="onSearch($event)"
            threshold="100px"
            id="infinite-scroll"
            :disabled="!loadmoreBtn"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more data..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </div>

        <filter-template
          :id="id"
          @filter-updates="filterUpdates"
          @sortingEmit="sorting"
        >
        </filter-template>

        <!-- <ListContent :items="items" /> -->
        <!-- <div class="ml-5 mr-5">
          <p v-if="searching && !loading" class="text-center">
            <ion-spinner name="dots"></ion-spinner>
          </p>
          <div v-if="searchStatus()">
            <ion-button class="mb-2" color="primary" clear @click="onSearch">
              <div class="p-2">Load More</div>
            </ion-button>
          </div>
        </div> -->
        <!-- Footer -->
        <xali-footer></xali-footer>
        <div class="h-20 md:hidden"></div>
      </template>
    </ion-content>
  </ion-page>
</template>
