import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/core.scss";

// Commons
import Header from "./components/commons/Header.vue";
import Footer from "./components/commons/Footer.vue";
import Loading from "./components/commons/Loading.vue";
// Containers
import CoverContainer from "./components/containers/Cover.vue";
// Recipe
import Recipe from "@/pages/recipe/Recipe.vue";
// 
//import { createGtm } from '@gtm-support/vue-gtm';


// console.log('ENV Log', process.env);


const app = createApp(App)
  //.provide(DefaultApolloClient, defaultClient)
  .use(IonicVue)
  .use(router)
  .use(store);

app.component("xali-header", Header);
app.component("xali-footer", Footer);
app.component("xali-loading", Loading);
app.component("container-cover", CoverContainer);
app.component("recipe-view", Recipe);


router.isReady().then(() => {
  app.mount("#app");
});
