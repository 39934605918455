<template>

  <div class="community-box" v-if="loading">
    <div class="background rounded-2lg"></div>
    <div class="content p-6 lg:p-10">
      <div class="top grid grid-cols-12 mb-14 md:mb-16">
        <div class="col-span-6 flex"><ion-img :src="'/assets/images/icon-white.svg'" class="w-16 md:w-14"></ion-img></div>
        <div class="col-span-6">
          <div class="flex flex-row-reverse justify-start">
            <div class="rounded-full bg-gray-100 h-8 w-8 flex items-center justify-center overflow-hidden -ml-3"></div>
            <div class="rounded-full bg-gray-100 h-8 w-8 flex items-center justify-center overflow-hidden -ml-3"></div>
            <div class="rounded-full bg-gray-100 h-8 w-8 flex items-center justify-center overflow-hidden -ml-3"></div>
            <div class="rounded-full bg-gray-100 h-8 w-8 flex items-center justify-center overflow-hidden -ml-3"></div>
          </div>
          <div class="text-right text-white"><ion-skeleton-text animated class="py-1 w-2/12 ml-auto"></ion-skeleton-text></div>
        </div>
      </div>
      <div class="chips flex uppercase font-hokyaa mb-5">
        <ion-skeleton-text animated class="py-3 w-1/12 rounded-full"></ion-skeleton-text>
      </div>
      <div class="grid grid-cols-12">
        <div class="col-span-12 md:col-span-6 flex">
          <div class="caption md:text-lg text-white mb-12 md:mb-0 w-full">
              <ion-skeleton-text animated class="text-xl md:text-4xl font-medium md:font-bol w-10/12"></ion-skeleton-text>
              <ion-skeleton-text animated class="text-xl md:text-4xl font-medium md:font-bol w-9/12"></ion-skeleton-text>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 flex">
          <div class="flex md:ml-auto mt-auto text-white w-full">
            <ion-skeleton-text animated class="ml-auto text-2xl font-semibold w-5/12"></ion-skeleton-text>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="community-box" v-if="!loading">
    <div class="background rounded-2lg"></div>
    <div class="content p-6 lg:p-10">
      <div class="top grid grid-cols-12 mb-14 md:mb-16">
        <div class="col-span-6 flex"><ion-img :src="'/assets/images/icon-white.svg'" class="w-16 md:w-14"></ion-img></div>
        <div class="col-span-6">
          <div class="flex flex-row-reverse justify-start">
            <!-- <div class="rounded-full h-8 w-8 flex items-center justify-center overflow-hidden -ml-3">
              <img src="/assets/images/default-user.jpg">
            </div>
            <div class="rounded-full h-8 w-8 flex items-center justify-center overflow-hidden -ml-3">
              <img src="/assets/images/sam.jpg">
            </div>
            <div class="rounded-full h-8 w-8 flex items-center justify-center overflow-hidden -ml-3">
              <img src="/assets/images/naz-s.jpg">
            </div>
            <div class="rounded-full h-8 w-8 flex items-center justify-center overflow-hidden -ml-3">
              <img src="/assets/images/sam-s.jpg">
            </div> -->
          </div>
          <!-- <div class="text-right text-white">+1.5 k members</div> -->
        </div>
      </div>

      <div class="chips flex mb-5">
        <ion-chip color="white" class="rounded-full px-6 bg-black">
          <ion-label color="white" class="font-hokyaa text-base">Xali <span class="text-primary">Journey</span></ion-label>
        </ion-chip>
      </div>

      <div class="grid grid-cols-12">
        <div class="col-span-12 md:col-span-6 flex">
          <div class="caption md:text-lg text-white mb-12 md:mb-0">
              <h2 class="text-xl md:text-4xl font-medium md:font-bold">A place of connection and support with like-minded women</h2>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 flex">
          <div class="flex md:ml-auto mt-auto text-white">
            <h2 class="text-2xl font-semibold">Join the Community</h2> 
            <span class="mt-2.5 ml-2">
              <ion-icon :icon="chevronForward" class="text-xl"></ion-icon>
            </span>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { IonImg, IonSkeletonText, IonIcon, IonLabel, IonChip } from '@ionic/vue';
import { chevronForward } from 'ionicons/icons';

export default{
  components: { IonImg, IonSkeletonText, IonIcon, IonLabel, IonChip },
  data() {
    return { loading: true };
  },
  setup() {
    return { chevronForward }
  },
  async mounted() {
    //window.setTimeout(() => {
      this.loading = false;
    //}, 2000)
  }
}
</script>

<style lang="scss" scoped>
.community-box{
  // max-height: 320px;
  // overflow: hidden;
  position: relative;
  .background{
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
  .background::after{
    content: "";
    background: var(--ion-color-black);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.45;
    z-index: 2;
  }
  .content{
    position: relative;
    z-index: 20;
  }
  .background::before{
    content: "";
    background-image: url('/assets/images/connect-tile-bg.jpg');
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-position: center 25%;
    background-size: cover;
    z-index: 1;
  }
  @screen sm {
    
  }
}
</style>


