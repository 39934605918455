
<template>
   <ion-page class="max-w-md mx-auto">
       <ion-content :fullscreen="true">


            <ion-header class="user-header ion-no-border flex rounded-b-2xl z-40">
                <div class="container max-w-full px-6 py-6">
                    <div class="back flex -mt-1 -ml-4"><ion-back-button defaultHref="/home" :icon="chevronBack" class="text-xl"></ion-back-button><span class="my-auto -ml-2 pb-2px">Back</span></div>
                    <div class="search flex bg-light bg-opacity-30 rounded-2lg">
                        <ion-icon :icon="searchOutline" class="block text-2xl text-dark my-auto ml-2"></ion-icon>
                        <ion-input placeholder="Search a recipe" value="Mushr"></ion-input>
                        <div class="my-auto ml-auto">
                            <router-link to="/home" class="relative">
                                <ion-icon :icon="closeOutline" class="block text-black text-xl bg-white rounded-full p-1 mr-1"></ion-icon>
                            </router-link>
                        </div>
                    </div>
                </div>
            </ion-header>

            <div class="container max-w-full px-6">
                <div class="search-history">
                    <div class="search-item flex items-center mb-4">
                        <div class="flex items-center">
                            <ion-icon :icon="searchOutline" class="text-xl text-dark opacity-30"></ion-icon>
                            <div class="s-image rounded-2lg w-16 h-12 mx-5">
                                <ion-img :src="'/assets/images/eat/nutrition-1.jpg'"></ion-img>
                            </div>
                        </div>
                        <div class="leading-5"><span class="font-medium">Mush</span>room Chia Meatless Meatballs</div>
                    </div>
                    <div class="search-item flex items-center my-4">
                        <div class="flex items-center">
                            <ion-icon :icon="searchOutline" class="text-xl text-dark opacity-30"></ion-icon>
                            <div class="s-image rounded-2lg w-16 h-12 mx-5">
                                <ion-img :src="'/assets/images/eat/nutrition-2.jpg'"></ion-img>
                            </div>
                        </div>
                        <div class="leading-5"><span class="font-medium">Mush</span>room and Scallop Stir Fry</div>
                    </div>
                    <div class="search-item flex items-center my-4">
                        <div class="flex items-center">
                            <ion-icon :icon="searchOutline" class="text-xl text-dark opacity-30"></ion-icon>
                            <div class="s-image rounded-2lg w-16 h-12 mx-5">
                                <ion-img :src="'/assets/images/eat/nutrition-3.jpg'"></ion-img>
                            </div>
                        </div>
                        <div class="leading-5"><span class="font-medium">Mush</span>room Stir Fry</div>
                    </div>
                </div>
            </div>


       
        
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonContent, IonBackButton, IonIcon, IonImg } from '@ionic/vue';
import { searchOutline, chevronBack, stopwatchOutline, closeCircle, closeOutline } from 'ionicons/icons';

export default{
  components: { IonPage, IonContent, IonBackButton, IonIcon, IonImg },
  data(){
      return { searchOutline, chevronBack, stopwatchOutline, closeCircle, closeOutline }
  }
}
</script>

<style lang="scss" scoped>
.s-image{
    overflow: hidden;
    img, ion-img{
        height: 100%;
        width: 100%;
        object-fit: cover
    }
}
</style>
