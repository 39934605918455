<template>
  <div v-if="!ingredient.length">
    <div class="text-center">No Ingredient Found.</div>
  </div>

  <template v-if="ingredient.length">
    <div class="serving rounded-2lg my-5 flex border px-5 py-2">
      <span class="my-auto">Serving</span>
      <div class="ml-auto h-6 flex">
        <a
          href="#"
          @click.prevent="minusServing()"
          class="text-2xl text-primary"
          v-bind:class="[
            this.serving == this.recipe.min_serving
              ? 'cursor-default text-light'
              : '',
          ]"
          ><ion-icon :icon="removeCircle"></ion-icon
        ></a>
        <span class="mx-2">{{ serving }}</span>
        <a
          href="#"
          @click.prevent="addServing()"
          class="text-2xl text-primary"
          v-bind:class="[
            this.serving == this.recipe.max_serving
              ? 'cursor-default text-light'
              : '',
          ]"
          ><ion-icon :icon="addCircle"></ion-icon
        ></a>
      </div>
    </div>
    <div class="ingredient-list">
      <div v-for="(ing, ingI) in ingredient" :key="ingI++" class="items mb-4">
        <div class="name font-medium px-5 py-2">{{ ing.name }}</div>
        <div
          v-for="(item, itemI) in ing.items"
          :key="itemI++"
          class="
            item
            shadow-md
            px-5
            py-2
            text-sm
            rounded-lg
            border border-opacity-25
            mb-2.5
          "
        >
          <div class="grid grid-cols-5">
            <div class="col-span-3 flex">
              <img
                class="w-6 h-6 my-auto"
                :src="item.image"
                v-if="item.image"
              />
              <div class="mx-3 my-auto">
                <div class="">{{ item.name }}</div>
                <div class="text-xs font-light italic" v-if="item.sub_name">
                  {{ item.sub_name }}
                </div>
              </div>
            </div>
            <div class="col-span-2 flex items-center justify-end">
              {{ displayRecipeQuantity(item.name, item.quantity) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { removeCircle, addCircle } from "ionicons/icons";
import { displayQuantity } from "@/_helpers";

export default {
  components: { IonIcon },
  props: {
    ingredient: {
      type: Array,
      default: function () {
        return [];
      },
    },
    recipe: {
      type: Object,
    },
  },
  data() {
    return {
      serving: 0,
      removeCircle,
      addCircle,
      max_serving: 10,
    };
  },
  mounted() {
    this.serving = this.recipe.min_serving;
    // console.log("this.recipe", this.recipe);
  },
  methods: {
    addServing: function () {
      // the recipe has max servings
      if (this.recipe.max_serving) {
        if (this.serving < this.recipe.max_serving) {
          this.serving++;
        }
      } else {
        // limit the servings
        if (this.serving < this.max_serving) this.serving++;
      }
    },
    minusServing: function () {
      if (this.recipe.min_serving) {
        if (this.serving != this.recipe.min_serving) this.serving--;
      } else {
        if (this.serving != 1) this.serving--;
      }
    },
    displayRecipeQuantity(name, qty) {
      // console.log("name", name);
      let num_array = qty.split(" ");
      if (num_array[0] && isNaN(parseInt(num_array[0]))) {
        return qty;
      }
      return displayQuantity(qty, this.recipe.serves, this.serving);
    },
  },
};
</script>

<style lang="scss" scoped></style>
