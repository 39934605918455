<template>
  <h1 class="text-4xl md:text-5xl">
    <strong>Welcome {{ user.first_name }}</strong>
  </h1>
  <p class="text-xl text-dark hidden"></p>
  <div class="hidden skeleton">
    <ion-skeleton-text animated class="text-4xl md:text-5xl w-3/12"></ion-skeleton-text>
    <ion-skeleton-text animated class="text-xl text-dark hidden md:block w-4/12"></ion-skeleton-text>
  </div>
</template>

<script>
import { IonSkeletonText } from "@ionic/vue";
export default {
  components: {
    IonSkeletonText
  },
  computed: {
    user() {
      const user = this.$store.getters["accounts/getUser"];
      return user ? user:{};
    }
  }
};
</script>
