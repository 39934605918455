<script>
import ForgotPasswordForm from "../components/forms/ForgotPassword.vue";
import router from "@/router";

export default {
  components: { ForgotPasswordForm },
  created() {
    // redirect to home if already logged in
    if (this.$store.getters["accounts/isAuthenticated"]) {
      router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <container-cover>
    <div class="bg-white flex h-full">
      <div class="max-w-sm w-full m-auto px-12">
        <h1 class="text-4xl font-bold">
          Welcome to
          <span class="font-hokyaa font-normal text-primary mb-3">Xali</span>
        </h1>
        <p class="mb-16 text-sm"></p>
        <ForgotPasswordForm></ForgotPasswordForm>
      </div>
    </div>
  </container-cover>
</template>
