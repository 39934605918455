export default {
  home: {
    fitness: [],
    nutrition: [],
    learn: []
  },
  fitness: {
    type: "workout",
    category: null,
    style: [],
    bodypart: [],
    mode: [],
    workout: [],
    warmup: [],
    stretchflow: []
  },
  fitnessStretchflow: {
    latest: [],
    activity: []
  },
  category: {
    id: null,
    title: "",
    activity: []
  },
  allActivity: [],
  activity: {},
  activityPostProduction: {},
  nutrition: {
    mostpopular: [],
    type: [],
    category: [],
    nutrition: [],
    level: []
  },
  allRecipe: [],
  recipe: {},
  recipe_category: {
    id: null,
    type: null,
    category: null,
    title: "",
    recipe: []
  },
  ingredients: {
    all: [],
    current: []
  },
  move: {
    type: "warmup",
    first: 2,
    offset: 0,
    data: []
  },
  fieldOptions: {},
  recipeFieldOptions: {},
  dietaryPreferences: {},
  filterArgs: {},
  favourites: {}
};
