
<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <container-cover>
        <div class="bg-white flex h-full">
          <div class="subscription-container relative w-full">
            <div class="md:px-16 lg:px-22 xl:px-44">
              <header class="pt-16 px-6">
                <ion-icon
                  :icon="checkmarkCircle"
                  size="large"
                  :color="'white'"
                ></ion-icon>
              </header>

              <div class="body-content mt-5 px-6">
                <p class="text-xl tracking-tight">
                  Thank you {{ userData.full_name }}!
                </p>
                <h2
                  class="text-3xl font-bold tracking-tight leading-8 my-4 mb-8"
                >
                  Your payment has been successful.
                </h2>

                <p class="mb-4 tracking-tight text-md">
                  You are now part of Team Xali. Let’s get into it!
                </p>
              </div>

              <footer
                class="md:px-16 lg:px-22 xl:px-44 absolute bottom-5 left-0"
              >
                <div class="w-full px-6">
                  <!-- <p class="mb-4 text-sm leading-6">
                    <b>Important:</b>
                    <br />
                    You will be charged through your iTunes account at
                    confirmation of purchase. Subscriptions automatically renew
                    unless you turn off auto-renew at least 24 hours before the
                    end of the billing period.
                    <br />
                    <br />
                    For any subscription queries, head to our T&Cs.
                  </p> -->
                  <a href="/home">
                    <ion-button
                      expand="block"
                      color="white"
                      text="black"
                      class="text-lg mb-4 tracking-tight"
                      type="submit"
                    >
                      <span class="p-4 flex text-black">Return to Home</span>
                    </ion-button>
                  </a>
                  <p class="text-xs" :class="iOS ? 'ios-spacing' : ''">
                    Subscriptions automatically renew unless you turn off
                    auto-renew at least 24 hours before the end of the billing
                    period. <br />
                    You have read, understand and agree to our
                    <a
                      href="https://xali.com.au/terms-conditions/"
                      class="text-primary underline"
                      target="_blank"
                      >Terms & Conditions</a
                    >
                    &
                    <a
                      href="https://xali.com.au/privacy-policy/"
                      class="text-primary underline"
                      target="_blank"
                      >Privacy Policy</a
                    >.
                  </p>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </container-cover>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  checkmarkCircle,
} from "ionicons/icons";

import { iconLogo } from "@/_helpers/icons";
import { setHtmlPageTitle } from "@/_helpers";

export default {
  components: {},
  data() {
    return {
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      iconLogo,
      chevronBack,
      checkmarkCircle,
      plan: {
        month: "1 month",
        price: "$80",
        description: "Cancel anytime after",
      },
    };
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    userData() {
      return this.$store.getters["accounts/getUser"];
    },
  },
  mounted() {
    setHtmlPageTitle("Subscription confirmation");
  },
  methods: {
    changePlan(plan) {
      this.plan = plan;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-container {
  min-height: 100%;
  overflow-y: auto;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.9);
  background-size: cover !important;
  background-position: center !important;
  color: #fff;

  @media (max-width: 768px) {
    background: url("../../../public/assets/images/signupbg.jpg");
  }
  .logo {
    width: 150px;
  }
}
</style>
