<template>
  <div class="results-options fixed bottom-5 left-0 w-full z-20">
    <div
      class="
        filter
        flex
        rounded-lg
        border border-black
        shadow
        bg-white
        w-60
        mx-auto
      "
    >
      <ion-button
        class="w-1/2 m-0"
        @click="openFilter"
        :color="hasFilter ? 'primary' : 'white'"
      >
        <div
          class="px-0 py-2.5 md:py-3.5 flex text-dark font-normal"
          :class="hasFilter ? 'text-white' : ''"
        >
          <ion-icon :icon="funnelOutline" class="text-lg mr-2"></ion-icon>
          Filters
        </div>
      </ion-button>
      <ion-button color="white" class="w-1/2 m-0" @click="openPicker">
        <div class="px-0 py-2.5 md:py-3.5 flex text-dark font-normal">
          Sort
          <ion-icon :icon="swapVerticalOutline" class="text-lg ml-2"></ion-icon>
        </div>
      </ion-button>
    </div>
  </div>
  <Modal
    :close_float="true"
    ref="modal"
    class=""
    :class="modalType == 'filter' ? 'filter-container' : ''"
  >
    <!-- Workout Setup -->

    <div
      class="overflow-y-auto h-full pb-10 scroll-wrapper"
      v-if="modalType == 'filter'"
    >
      <header class="mb-5">
        <div class="text-xl font-medium text-center relative">
          <span>Filters</span>
        </div>
      </header>
      <div class="filter">
        <div class="filter-name text-lg">Types</div>

        <div class="filter-options flex py-2 overflow-x-auto">
          <ion-button
            expand="block"
            fill="outline"
            class="sm mr-2"
            :color="
              filterParams.type == 'workout' ||
              (filterParams.type == '' && currentType == 'workout')
                ? 'primary'
                : 'light'
            "
            :class="
              filterParams.type == 'workout' ? 'bg-primary bg-opacity-10' : ''
            "
            @click="changeType('workout')"
          >
            <div
              class="py-1 text-sm flex font-medium"
              :class="
                filterParams.type != 'workout' ||
                (filterParams.type == '' && currentType == 'workout')
                  ? 'text-dark'
                  : ''
              "
            >
              Workout
            </div>
          </ion-button>
          <ion-button
            expand="block"
            fill="outline"
            class="sm mr-2"
            :color="
              filterParams.type == 'warmup' ||
              (filterParams.type == '' && currentType == 'warmup')
                ? 'primary'
                : 'light'
            "
            :class="
              filterParams.type == 'warmup' ||
              (filterParams.type == '' && currentType == 'warmup')
                ? 'bg-primary bg-opacity-10'
                : ''
            "
            @click="changeType('warmup')"
          >
            <div
              class="py-1 text-sm flex font-medium"
              :class="filterParams.type != 'warmup' ? 'text-dark' : ''"
            >
              Warm Up
            </div>
          </ion-button>
          <ion-button
            expand="block"
            fill="outline"
            class="sm mr-2"
            :color="
              filterParams.type == 'stretch_flow' ||
              (filterParams.type == '' && currentType == 'stretch_flow')
                ? 'primary'
                : 'light'
            "
            :class="
              filterParams.type == 'stretch_flow' ||
              (filterParams.type == '' && currentType == 'stretch_flow')
                ? 'bg-primary bg-opacity-10'
                : ''
            "
            @click="changeType('stretch_flow')"
          >
            <div
              class="py-1 text-sm flex font-medium"
              :class="filterParams.type != 'stretch_flow' ? 'text-dark' : ''"
            >
              Stretch flow
            </div>
          </ion-button>
        </div>
        <div class="border-b border-light mx-6 my-3"></div>
      </div>
      <div class="filter">
        <div class="filter-name text-lg">Levels</div>
        <div class="filter-options flex py-2 overflow-x-auto">
          <template v-for="level in sortLevelOptions" :key="level.id">
            <ion-button
              v-if="level.text != '5'"
              expand="block"
              fill="outline"
              class="sm mr-2"
              :color="
                filterParams.levels.includes(parseInt(level.value))
                  ? 'primary'
                  : 'light'
              "
              :class="
                filterParams.levels.includes(parseInt(level.value))
                  ? 'bg-primary bg-opacity-10'
                  : ''
              "
              @click="filtersFunc('levels', level.value)"
            >
              <div
                class="py-1 text-sm flex font-medium"
                :class="
                  !filterParams.levels.includes(parseInt(level.value))
                    ? 'text-dark'
                    : ''
                "
              >
                {{ level.text }}
              </div>
            </ion-button>
          </template>
        </div>
        <div class="border-b border-light mx-6 my-3"></div>
      </div>
      <!-- <div class="filter">
        <div class="filter-name text-lg">Aspirations</div>
        <div class="filter-options flex py-2 overflow-x-auto">
          <ion-button
            expand="block"
            fill="outline"
            color="primary"
            class="sm mr-2 bg-primary bg-opacity-10"
          >
            <div class="py-1 font-medium text-sm flex">Aligment</div>
          </ion-button>
          <ion-button
            expand="block"
            fill="outline"
            color="light"
            class="sm mr-2"
          >
            <div class="py-1 font-normal text-sm text-dark flex">Health</div>
          </ion-button>
          <ion-button
            expand="block"
            fill="outline"
            color="primary"
            class="sm mr-2 bg-primary bg-opacity-10"
          >
            <div class="py-1 font-medium text-sm flex">Aesthetic</div>
          </ion-button>
          <ion-button
            expand="block"
            fill="outline"
            color="light"
            class="sm mr-2"
          >
            <div class="py-1 font-normal text-sm text-dark flex">Fun</div>
          </ion-button>
        </div>
        <div class="border-b border-light mx-6 my-3"></div>
      </div> -->
      <div class="filter" v-if="!isCategory">
        <div class="filter-name text-lg">Styles</div>
        <div class="filter-options flex py-2 overflow-x-auto">
          <ion-button
            expand="block"
            fill="outline"
            class="sm mr-2"
            :color="
              filterParams.benefits3.includes(parseInt(benefit.value))
                ? 'primary'
                : 'light'
            "
            :class="
              filterParams.benefits3.includes(parseInt(benefit.value))
                ? 'bg-primary bg-opacity-10'
                : ''
            "
            v-for="benefit in benefitsOptions"
            :key="benefit.id"
            @click="filtersFunc('benefits3', benefit.value)"
          >
            <div
              class="py-1 text-sm flex font-medium"
              :class="
                !filterParams.benefits3.includes(parseInt(benefit.value))
                  ? 'text-dark'
                  : ''
              "
            >
              {{ benefit.text }}
            </div>
          </ion-button>
        </div>
        <div class="border-b border-light mx-6 my-3"></div>
      </div>
      <div class="filter">
        <div class="filter-name text-lg">Body targets</div>
        <div class="filter-options flex py-2 overflow-x-auto">
          <ion-button
            expand="block"
            fill="outline"
            class="sm mr-2"
            :color="
              filterParams.bodyParts.includes(parseInt(bodyPart.value))
                ? 'primary'
                : 'light'
            "
            :class="
              filterParams.bodyParts.includes(parseInt(bodyPart.value))
                ? 'bg-primary bg-opacity-10'
                : ''
            "
            v-for="bodyPart in bodyPartsOptions"
            :key="bodyPart.id"
            @click="filtersFunc('bodyParts', bodyPart.value)"
          >
            <div
              class="py-1 text-sm flex font-medium"
              :class="
                !filterParams.bodyParts.includes(parseInt(bodyPart.value))
                  ? 'text-dark'
                  : ''
              "
            >
              {{ bodyPart.text }}
            </div>
          </ion-button>
        </div>
        <div class="border-b border-light mx-6 my-3"></div>
      </div>
      <div class="filter">
        <div class="filter-name text-lg">Modes</div>
        <div class="filter-options flex py-2 overflow-x-auto">
          <ion-button
            expand="block"
            fill="outline"
            class="sm mr-2"
            :color="
              filterParams.modes.includes(parseInt(mode.value))
                ? 'primary'
                : 'light'
            "
            :class="
              filterParams.modes.includes(parseInt(mode.value))
                ? 'bg-primary bg-opacity-10'
                : ''
            "
            v-for="mode in modes"
            :key="mode.id"
            @click="filtersFunc('modes', mode.value)"
          >
            <div
              class="py-1 text-sm flex font-medium"
              :class="
                !filterParams.modes.includes(parseInt(mode.value))
                  ? 'text-dark'
                  : ''
              "
            >
              {{ mode.text }}
            </div>
          </ion-button>
        </div>
        <div class="border-b border-light mx-6 my-3"></div>
      </div>
      <div class="filter">
        <div class="filter-name text-lg">Intensity</div>

        <div class="filter-options flex py-2 overflow-x-auto">
          <ion-button
            expand="block"
            fill="outline"
            class="sm mr-2"
            :color="
              filterParams.intensitys.includes(parseInt(intensity.value))
                ? 'primary'
                : 'light'
            "
            :class="
              filterParams.intensitys.includes(parseInt(intensity.value))
                ? 'bg-primary bg-opacity-10'
                : ''
            "
            v-for="intensity in intensitys"
            :key="intensity.id"
            @click="filtersFunc('intensitys', intensity.value)"
          >
            <div
              class="py-1 text-sm flex font-medium"
              :class="
                !filterParams.intensitys.includes(parseInt(intensity.value))
                  ? 'text-dark'
                  : ''
              "
            >
              {{ intensity.text }}
            </div>
          </ion-button>
        </div>
        <div class="border-b border-light mx-6 my-3"></div>
      </div>

      <footer class="absolute w-full left-0 bottom-0 z-50">
        <div
          class="
            rounded-3xl
            container
            max-w-full
            px-5
            py-3
            bottom-0
            next-container
            bg-white
            flex
            justify-center
          "
        >
          <div
            class="my-auto mr-5 text-dark font-medium cursor-pointer"
            @click="clearFilter"
          >
            Clear filters
          </div>
          <ion-button color="black" expand="block" small @click="applyFilters">
            <div class="text-white">Apply Filters</div>
          </ion-button>
        </div>
      </footer>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/commons/Modal.vue";
import { IonIcon, IonButton, pickerController } from "@ionic/vue";
import VueCookies from "vue-cookies";

import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  searchOutline,
  chevronBack,
  stopwatchOutline,
  heartOutline,
  funnelOutline,
  swapVerticalOutline,
} from "ionicons/icons";

export default {
  name: "FilterTemplate",
  components: { IonIcon, IonButton, Modal },
  props: ["currentPage", "id", "isCategory"],
  data() {
    return {
      modes: [],
      modalType: null,
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      searchOutline,
      chevronBack,
      stopwatchOutline,
      heartOutline,
      funnelOutline,
      swapVerticalOutline,
      filter: false,
      args: "",
      filterParams: {
        type: "",
        levels: [],
        benefits3: [],
        bodyParts: [],
        modes: [],
        intensitys: [],
      },
      selectedSortIndex: 0,
      pickingOptions: [
        { text: "Shortest to longest", value: "shortest" },
        { text: "Longest to shortest", value: "longest" },
        { text: "Easiest to hardest", value: "level" },
      ],
      hasFilter: false,
    };
  },
  computed: {
    ...mapGetters({
      fieldOptionsChunked: "pages/fieldOptionsChunked",
      levelOptions: "pages/levelOptions",
      benefitsOptions: "pages/benefitsOptions",
      bodyPartsOptions: "pages/bodyPartsOptions",
      modeOptions: "pages/modeOptions",
      sfModeOptions: "pages/sfModeOptions",
      intensityOptions: "pages/intensityOptions",
      sfIntensityOptions: "pages/sfIntensityOptions",
      filterArgs: "pages/filterArgs",
    }),
    currentType() {
      const url = this.$route.path;
      if (url.includes("workout")) {
        return "workout";
      }
      if (url.includes("warmup")) {
        return "warmup";
      }
      if (url.includes("stretch-flow")) {
        return "stretch_flow";
      }
      return "";
    },
    sortLevelOptions() {
      return this.levelOptions.slice().sort(function (a, b) {
        return a.text - b.text;
      });
    },
  },

  mounted() {
    this.modes = this.modeOptions;
    this.intensitys = this.intensityOptions;
    if (this.currentType) {
      this.filterParams.type = this.currentType;
      if (this.currentType == "stretch_flow") {
        this.modes = this.sfModeOptions;
        this.intensitys = this.sfIntensityOptions;
      }
    }

    const journeySetupData = VueCookies.get("journeySetupData");
    this.filterParams.levels.push(journeySetupData.level);
    setTimeout(() => {
      this.showPicker = true;
    }, 200);
    if (this.filterParams.type != "") {
      this.hasFilter = true;
    }
    if (this.filterParams.levels.length) {
      this.hasFilter = true;
    }
  },

  methods: {
    async openPicker() {
      console.log(this.selectedSortIndex);
      const picker = await pickerController.create({
        columns: [
          {
            name: "sort",
            options: this.pickingOptions,
            selectedIndex: this.selectedSortIndex,
          },
        ],
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Confirm",
            handler: (value) => {
              const v = value.sort.value;
              this.selectedSortIndex = this.pickingOptions.findIndex(
                (i) => i.value == v
              );

              this.$emit("sortingEmit", {
                sort: value.sort.value,
              });
            },
          },
        ],
      });
      picker.columns[0].options.forEach((element) => {
        delete element.selected;
        delete element.duration;
        delete element.transform;
      });
      await picker.present();
    },

    handleModalClose() {
      this.$refs.modal.toggleModal();
    },
    openFilter() {
      this.$refs.modal.toggleModal();
      this.modalType = "filter";
    },
    openSorting() {
      this.$refs.modal.toggleModal();
      this.modalType = "sorting";
    },
    changeType(type) {
      this.filterParams.type = type;
      if (type == "stretch_flow") {
        this.modes = this.sfModeOptions;
        this.intensitys = this.sfIntensityOptions;
      } else {
        this.modes = this.modeOptions;
        this.intensitys = this.intensityOptions;
      }
    },
    filtersFunc(type, value) {
      if (this.filterParams[type].includes(parseInt(value))) {
        this.filterParams[type].splice(
          this.filterParams[type].indexOf(parseInt(value)),
          1
        );
      } else {
        this.filterParams[type].push(parseInt(value));
      }
    },
    applyFilters() {
      this.args = "";
      const params = JSON.parse(JSON.stringify(this.filterParams));

      this.args += `type:"${
        params.type != "" ? params.type : this.currentType
      }"`;

      if (params.levels.length) {
        this.args += `,levels:[${params.levels}]`;
      }

      if (params.benefits3.length) {
        this.args += `,benefits:[${params.benefits3}]`;
      }

      if (params.bodyParts.length) {
        this.args += `,bodyParts:[${params.bodyParts}]`;
      }

      if (params.modes.length) {
        this.args += `,modes:[${params.modes}]`;
      }

      if (params.intensitys.length) {
        this.args += `,intensitys:[${params.intensitys}]`;
      }

      this.$emit("filter-updates", {
        loading: true,
      });
      this.$refs.modal.toggleModal();

      const url = this.$route.path;
      if (url.includes(params.type)) {
        this.$store
          .dispatch(`pages/filterActivity`, {
            id: this.id,
            args: this.args,
          })
          .then(() => {
            this.$emit("filter-updates", {
              args: this.args,
              loading: false,
            });
          });
      } else {
        let type = params.type;
        if (params.type == "stretch_flow") {
          type = "stretch-flow";
        }
        const nextURL = `./move/` + type;
        const nextTitle = "Xali";
        const nextState = "";

        window.history.pushState(nextState, nextTitle, nextURL);
        this.$emit("filter-updates", {
          loading: false,
          args: this.args,
          isRedirect: true,
          params: params,
        });
        this.$store.commit("pages/setFilterArgs", {
          args: params,
          formated: this.args,
        });
      }
    },
    clearFilter() {
      this.args = "";
      this.filterParams = {
        type: this.filterParams.type
          ? this.filterParams.type
          : this.currentType,
        levels: [],
        benefits3: [],
        bodyParts: [],
        modes: [],
        intensitys: [],
      };

      this.args = `,type:"${
        this.filterParams.type ? this.filterParams.type : this.currentType
      }"`;

      this.$refs.modal.toggleModal();
      this.$emit("filter-updates", {
        loading: true,
      });

      this.$store
        .dispatch(`pages/filterActivity`, {
          id: this.id,
          page: 1,
          args: this.args,
        })
        .then(() => {
          this.$emit("filter-updates", {
            args: this.args,
            loading: false,
          });
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.filter {
  .filter-options {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      @media (max-width: 767px) {
        display: none;
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0.45);
      border-radius: 10px;
      box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
      transition: all 0.2s;
      &:hover {
        background: rgba(0, 0, 0, 0.55);
      }
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.45);
      }
    }

    &::-webkit-scrollbar:vertical {
      display: none;
    }
  }
}
ion-toggle {
  &.sm {
    width: 40px;
    height: 22.5px;
    --handle-width: 19.5px;
    --handle-height: 18px;
  }
  &.xs {
    width: 32px;
    height: 18px;
    --handle-width: 15px;
  }
}
.filter-buttons {
  box-shadow: 0px -1px 10px #00000014;
}
.scroll-wrapper {
  &::-webkit-scrollbar {
    width: 7px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    margin: 54px 0 53px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
    transition: all 0.2s;
    &:hover {
      background: rgba(0, 0, 0, 0.55);
    }
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.45);
    }
  }
}
</style>
