<script>
import {
  IonSkeletonText,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonSpinner,
  alertController
} from "@ionic/vue";

import _ from "lodash";
import api from "@/api";
export default {
  components: {
    IonSkeletonText,
    IonButton,
    IonLabel,
    IonInput,
    IonItem,
    IonSpinner
  },
  data() {
    let formData = {
      email: ""
    };
    return {
      formData,
      errors: {},
      loading: false,
      message: null
    };
  },
  async mounted() {
    console.log("Forgot Password mounted");
  },

  methods: {
    async submitForm() {
      this.errors = {};
      Object.keys(this.formData).forEach(field => {
        if (!this.formData[field])
          this.errors[field] =
            '<span class="capitalize">' + field + "</span> is required.";
      });

      // Submit to API services
      if (_.isEmpty(this.errors, true)) {
        this.loading = true;
        const response = await api.forgotPassword(this.formData);
        // console.log(response);
        if (response && response.status == 200) {
          this.message =
            response.data && response.data.message ? response.data.message : "";
        } else {
          this.presentAlert();
        }
        this.loading = false;
      }
    },
    showPassword() {
      let ele = document.getElementById("mypassword");
      let icon = document.getElementById("eyeIcon");
      if (ele.type === "password") {
        ele.type = "text";
        icon.classList.add("text-primary");
      } else {
        ele.type = "password";
        icon.classList.remove("text-primary");
      }
    },
    async presentAlert() {
      const alert = await alertController.create({
        cssClass: ["my-custom-class text-center text-red"],
        mode: "ios",
        header: "Error",
        message: "No active account found with the given credentials",
        buttons: [
          {
            text: "close",
            handler: () => {
              console.log("Confirm Okay");
            }
          }
        ]
      });
      return alert.present();
    }
  }
};
</script>
<style scoped>
ion-item {
  --background-focused: #ffffff;
}
</style>

<template>
  <ion-skeleton-text
    animated
    class="text-4xl md:text-5xl w-3/12 hidden"
  ></ion-skeleton-text>

  <div class="login-form relative">
    <form @submit.prevent="submitForm" action="./" method="post">
      <div class="title text-xl font-medium mb-2">
        Reset your password
      </div>
      <div class="mb-5">
        Enter your user account's verified email address and we will send you a
        password reset link.
      </div>
      <ion-item>
        <ion-label position="floating">Email Address</ion-label>
        <ion-input
          v-model="formData.email"
          v-on:keyup.enter="submitForm"
          :disabled="message"
        ></ion-input>
      </ion-item>
      <div class="text-sm text-danger px-2" v-if="errors.email">
        Please enter a valid email address.
      </div>

      <div
        v-if="message"
        class="mt-10 border rounded-lg mb-5 py-3.5 px-6 bg-primary bg-opacity-5 border-primary"
      >
        {{ message }}
      </div>

      <div class="mt-20 flex">
        <ion-button
          expand="block"
          color="black"
          class="float-right ml-auto text-xl"
          type="submit"
          :disabled="loading || message"
        >
          <span class="py-4 px-1" v-if="!loading">Submit</span>
          <span class="py-2.5 px-1" v-if="loading"
            ><ion-spinner name="bubbles"></ion-spinner
          ></span>
        </ion-button>
      </div>
    </form>
  </div>
</template>
