import NutritionFilters from './Nutrition-Filters.vue'
import NutritionSearchinitialstate from './Nutrition-Search-initial-state.vue';
import NutritionSearchtypingstate from './Nutrition-Search-typing-state.vue'
import NutritionSearchresultsstate from './Nutrition-Search-results-state.vue'
import ChoosePlan from './Choose-Plan.vue'
import SubscriptionExpired from './Subscription-Expired.vue'
import Payment from './Payment.vue'
import PaymentSuccess from './Payment-Success.vue'

import FitnessFilters from './Fitness-Filters.vue'
import Settings from './Settings.vue'
import Packages from './Packages.vue'
import CancelSubscription from './CancelSubscription.vue'
import CancelSuccess from './Cancel-Success.vue'

const staticRoutes = [
    {
        path: '/static/nutrition-filters',
        component: NutritionFilters,
    },
    {
        path: '/static/nutrition-search-initial-state',
        component: NutritionSearchinitialstate,
    },
    {
        path: '/static/nutrition-search-typing-state',
        component: NutritionSearchtypingstate,
    },
    {
        path: '/static/nutrition-search-results-state',
        component: NutritionSearchresultsstate,
    },
    {
        path: '/static/fitness-filters',
        component: FitnessFilters,
    },
    {
        path: '/static/settings',
        component: Settings,
    },
    {
        path: '/static/subscription-expired',
        component: SubscriptionExpired,
    },
    {
        path: '/static/choose-plan',
        component: ChoosePlan,
    },
    {
        path: '/static/payment',
        component: Payment,
    },
    {
        path: '/static/payment-success',
        component: PaymentSuccess,
    },
    {
        path: '/static/packages',
        component: Packages,
    },
    {
        path: '/static/cancel-subscription',
        component: CancelSubscription,
    },
    {
        path: '/static/cancel-success',
        component: CancelSuccess,
    },
]
export default staticRoutes