<script>
import CarouselItem from "@/components/pages/carousel/CarouselItem.vue";
import { IonContent, IonPage, IonSpinner, IonImg } from "@ionic/vue";
// import { filterResults } from "@/_helpers/learn";
// import api from "@/api";
import _ from "lodash";
export default {
  components: {
    IonContent,
    IonPage,
    CarouselItem,
    IonSpinner,
    IonImg,
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedItems: [],
      learns: [],
    };
  },
  async mounted() {
    this.$store.dispatch("accounts/getRecommendedLearn").then((learn) => {
      const learnObj = _.map(learn, (data) => {
        const nFormat = {
          name: `${data.title}`,
          id: data.id ? parseInt(data.id) : null,
          categories: data.categories,
          tags: _.map(data.categories, function (data) {
            return { text: data, url: "" };
          }),
          cover: `${data.featuredImage}`,
          link: `${data.link}?src=app`,
          favourite: false,
          play: false,
          parentType: "learn",
        };
        return nFormat;
      });
      this.items = learnObj;
      // console.log("w--", this.items);
      this.loading = false;
    });

    // let result = await api.favouriteLearn();
    // console.log("result", result);
    // if (_.has(result.data.userFavourites, "learn")) {
    //   this.selectedItems =
    //     result && result.data.userFavourites.learn.length > 0
    //       ? result.data.userFavourites.learn
    //       : [];
    // }
    // this.selectedItems =
    //   this.selectedItems.length > 0
    //     ? JSON.parse(JSON.stringify(this.selectedItems))
    //     : [];
    let obj = {};
    obj.type = "learn";
    let result = await this.$store.dispatch(
      "pages/getAllFavouritesByType",
      obj
    );
    let selectedItems = _.map(result, (data) => {
      return data.id;
    });
    this.selectedItems = JSON.parse(JSON.stringify(selectedItems));
    setTimeout(() => {
      // console.log("this.selectedItems", this.selectedItems);
      let data = [];
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        // console.log("item.id", item.id);
        if (this.selectedItems.includes(item.id)) {
          item.favourite = true;
        }
        data.push(item);
      }
      this.learns = data;
    }, 500);
    this.loading = false;
  },
};
</script>

<template>
  <ion-page>
    <ion-content>
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <!-- Heading -->
        <xali-header></xali-header>
        <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
          <div class="heading flex flex-col sm:flex-row">
            <h1 class="text-4.5xl font-bold">Learn</h1>
          </div>
        </div>

        <div class="mt-10 mb-10 flex flex-col" v-if="loading">
          <ion-spinner
            name="bubbles"
            color="primary"
            class="mx-auto transform scale-200"
          ></ion-spinner>
          <ion-img
            :src="'/assets/images/icon.svg'"
            class="w-10 spinner hidden"
          ></ion-img>
        </div>

        <div
          class="
            container
            max-w-full
            mt-0
            px-6
            lg:px-12
            grid grid-cols-12
            gap-0
            sm:gap-4
          "
          v-if="items"
        >
          <div
            v-for="(item, i) in learns"
            :key="i++"
            class="col-span-12 sm:col-span-6 lg:col-span-4 mb-5"
          >
            <CarouselItem v-bind:data="item"></CarouselItem>
          </div>
        </div>

        <!-- Footer -->
        <xali-footer></xali-footer>
        <div class="h-20 md:hidden"></div>
      </template>
    </ion-content>
  </ion-page>
</template>
