<template>
  <ion-page>
    <ion-content
      :scroll-events="true"
      @ionScroll="$refs.header.setFixed($event.detail.scrollTop)"
    >
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <!-- Heading -->
        <xali-header
          ref="header"
          :settings="{ back: true, hidemobilenav: true }"
        >
          <!-- <template v-slot:secondary>
            <div class="flex text-2xl text-black -mr-3 md:hidden">
              <ion-icon
                :icon="receiptOutline"
                class="block text-xl my-auto mx-2"
              ></ion-icon>
            </div>
          </template> -->
        </xali-header>

        <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
          <div class="heading flex flex-col sm:flex-row">
            <h1 class="text-4.5xl font-bold">{{ title }}</h1>
            <div
              class="
                search
                flex
                bg-black bg-opacity-5
                rounded-2lg
                mt-4
                mb-5
                sm:hidden
              "
            >
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
              <ion-input
                placeholder="Search a recipe"
                :value="search"
                @ionInput="search = $event.target.value"
                v-on:keyup="searchFunc"
              ></ion-input>
            </div>

            <div class="search-button ml-auto my-auto hidden sm:block">
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark ml-auto"
                @click="openSearch"
                v-if="!searchStatus"
              >
              </ion-icon>

              <div
                class="
                  search
                  flex
                  bg-light bg-opacity-30
                  rounded-md
                  block
                  mt-2.5
                "
                v-if="searchStatus"
                :class="searchBtn ? 'mr-1' : ''"
              >
                <ion-icon
                  :icon="searchOutline"
                  class="block text-2xl text-dark my-auto ml-2"
                ></ion-icon>
                <ion-input
                  @ionInput="search = $event.target.value"
                  placeholder="Search a recipe"
                  v-on:keyup="updateSearchValue"
                  autofocus="true"
                  ref="searchbar"
                  :value="search"
                ></ion-input>
              </div>
            </div>
            <ion-button
              class="bg-primary rounded-md mt-3"
              fill="outline"
              @click="searchFunc"
              v-if="searchBtn"
            >
              <div class="px-2 py-3 font-normal flex text-white">Search</div>
            </ion-button>
          </div>
        </div>

        <div
          class="container max-w-full"
          v-if="!nutritionCategory.recipe.length"
        >
          <h3 class="px-6 text-2xl">No result found.</h3>
        </div>
        <div class="mt-28 flex flex-col" v-if="loadingFilter">
          <ion-spinner
            name="bubbles"
            color="primary"
            class="mx-auto transform scale-200"
          ></ion-spinner>
          <ion-img
            :src="'/assets/images/icon.svg'"
            class="w-10 spinner hidden"
          ></ion-img>
        </div>
        <div
          @scroll="onScroll()"
          class="
            container
            max-w-full
            mt-0
            px-6
            lg:px-12
            grid grid-cols-12
            gap-0
            sm:gap-4
          "
          v-if="nutritionCategory.recipe && !loadingFilter"
        >
          <div
            v-for="(item, i) in nutritionCategory.recipe"
            :key="i++"
            class="col-span-12 sm:col-span-6 lg:col-span-4 mb-5"
          >
            <CarouselItem v-bind:data="item"></CarouselItem>
          </div>
        </div>
        <div class="mt-10 mb-10 flex flex-col" v-if="loadmoreLoading">
          <ion-spinner
            name="bubbles"
            color="primary"
            class="mx-auto transform scale-200"
          ></ion-spinner>
          <ion-img
            :src="'/assets/images/icon.svg'"
            class="w-10 spinner hidden"
          ></ion-img>
        </div>
        <div
          class="mt-10 mb-10 flex justify-center"
          v-if="loadmoreBtn && !loadmoreLoading"
          id="loadmore"
        >
          <ion-infinite-scroll
            @ionInfinite="loadmore($event)"
            threshold="100px"
            id="infinite-scroll"
            :disabled="!loadmoreBtn"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more data..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </div>
        <filter-template
          :currentPage="currentPage"
          @filter-updates="filterUpdates"
        >
        </filter-template>
        <!-- Footer -->
        <xali-footer></xali-footer>
        <div class="h-20 md:hidden"></div>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapGetters } from "vuex";
import { heartOutline, searchOutline, receiptOutline } from "ionicons/icons";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonInput,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
  IonImg,
} from "@ionic/vue";

import CarouselItem from "@/components/pages/carousel/CarouselItem.vue";
import FilterTemplate from "../filters/recipe.vue";
import VueCookies from "vue-cookies";

export default {
  components: {
    IonContent,
    IonPage,
    CarouselItem,
    IonIcon,
    IonInput,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    IonImg,
    FilterTemplate,
  },
  data() {
    return {
      search: null,
      timeout: null,
      isSearch: false,
      loading: true,
      items: [],
      type: null,
      category: null,
      title: "",
      heartOutline,
      searchOutline,
      receiptOutline,
      currentPage: 1,
      loadmoreBtn: true,
      loadmoreLoading: false,
      args: "",
      searchStatus: false,
      searchBtn: false,
      loadingFilter: false,
      searchValue: "",
    };
  },

  computed: {
    ...mapGetters({
      nutritionCategory: "pages/getNutritionCategory",
    }),
    journeySetupData() {
      return VueCookies.get("journeySetupData");
    },
  },

  mounted() {
    this.type = this.$route.params.type;
    this.category = this.$route.params.category;
    this.$store.dispatch(`pages/getDietaryPreferencesOptions`);
    this.$store.dispatch(`pages/recipeFieldOptions`);
    this.$store.commit("pages/setNutritionCategory", {
      key: "type",
      value: this.type,
    });
    this.$store.commit("pages/setNutritionCategory", {
      key: "category",
      value: this.category,
    });
    this.loadInit();
  },

  methods: {
    loadInit() {
      let journey;
      if (this.journeySetupData && this.journeySetupData.dietary_preferences) {
        journey = this.journeySetupData.dietary_preferences;
      }
      this.$store
        .dispatch(`pages/getRecipeCategoryData`, {
          category: this.category,
          journey,
        })
        .then((res) => {
          // console.log(res);
          this.loadmoreBtn = res.pageInfo.hasNextPage;
          const data = this.nutritionCategory;
          this.title = data.title;
          window.document.title = this.title ? `${this.title} | Xali` : "Xali";
          this.loading = false;
          this.loadmoreBtn;
        });
    },
    filterUpdates(e) {
      this.loadingFilter = e.loading;
      this.currentPage = 1;
      if (e.args) this.args = e.args;
    },
    showSearch() {
      this.isSearch = true;
    },
    // searchFunc() {
    //   if (this.timeout) clearTimeout(this.timeout);
    //   this.timeout = setTimeout(() => {
    //     this.loading = true;
    //     this.$store.dispatch(`pages/searchRecipes`, this.search).then(() => {
    //       const data = this.nutritionCategory;
    //       this.title = data.title;
    //       this.loading = false;
    //     });
    //   }, 1000);
    // },
    searchFunc() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loading = true;
        if (this.search) {
          this.$store
            .dispatch(`pages/searchRecipes`, this.search)
            .then((res) => {
              this.loading = false;
              this.loadmoreBtn = false;
              this.$store.commit("pages/setNutritionCategory", {
                key: "recipe",
                value: res,
              });
            });
        } else {
          this.loadInit();
        }
      }, 1000);
    },
    loadmore() {
      this.loadmoreLoading = true;
      let journey;
      if (this.journeySetupData && this.journeySetupData.dietary_preferences) {
        journey = this.journeySetupData.dietary_preferences;
      }
      this.$store
        .dispatch(`pages/loadmore`, {
          page: this.currentPage,
          search: this.search,
          args: this.args,
          journey: journey,
        })
        .then((res) => {
          this.loadmoreBtn = res.pageInfo.hasNextPage;
          const data = this.$store.getters["pages/getNutritionCategory"];
          this.title = data.title;
          this.items = data.recipe;
          this.loadmoreLoading = false;
          this.currentPage = this.currentPage + 1;
        });
    },
    updateSearchValue(e) {
      if (this.search && e.keyCode === 13) {
        this.searchFunc();
      }
      this.searchValue = this.search;
      if (this.search) {
        this.searchBtn = true;
      } else {
        this.searchBtn = false;
      }
    },
    openSearch() {
      this.searchStatus = true;
      this.$refs.searchbar.$el.focus();
    },
  },
};
</script>