<template>
  <ion-page>
    <ion-content
      :scroll-events="true"
      @ionScroll="$refs.header.setFixed($event.detail.scrollTop)"
    >
      <xali-loading loadingStyle="loader" :fixed="true" v-if="loading"
        >Loading</xali-loading
      >

      <!-- Heading -->
      <xali-header ref="header">
        <!-- <template v-slot:secondary>
            <div class="flex text-2xl text-black -mr-3 md:hidden">
              <ion-icon
                :icon="receiptOutline"
                class="block text-xl my-auto mx-2"
              ></ion-icon>
            </div>
          </template> -->
      </xali-header>

      <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
        <div class="heading flex flex-col sm:flex-row">
          <h1 class="text-4.5xl font-bold">Eat</h1>
          <div
            class="search flex bg-black bg-opacity-5 rounded-2lg mt-4 sm:hidden"
          >
            <ion-icon
              :icon="searchOutline"
              class="block text-2xl text-dark my-auto ml-2"
            ></ion-icon>
            <ion-input
              :value="search"
              @ionInput="search = $event.target.value"
              v-on:keyup="updateSearchValue"
              placeholder="Search a recipe"
            ></ion-input>
          </div>

          <div class="search-button ml-auto my-auto hidden sm:block">
            <ion-icon
              :icon="searchOutline"
              class="block text-2xl text-dark ml-auto"
              @click="openSearch"
              v-if="!searchStatus"
            >
            </ion-icon>

            <div
              class="search flex bg-light bg-opacity-30 rounded-md block mt-2.5"
              v-if="searchStatus"
              :class="searchBtn ? 'mr-1' : ''"
            >
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
              <ion-input
                @ionInput="search = $event.target.value"
                placeholder="Search a recipe"
                v-on:keyup="updateSearchValue"
                autofocus="true"
                ref="searchbar"
              ></ion-input>
            </div>
          </div>

          <router-link :to="`/eat/search/${searchValue}`" v-if="searchBtn">
            <ion-button class="bg-primary rounded-md mt-3" fill="outline">
              <div class="px-2 py-3 font-normal flex text-white">Search</div>
            </ion-button>
          </router-link>
        </div>
      </div>

      <div class="container max-w-full mt-0 px-0">
        <!-- Most popular recipes -->
        <section class="mb-7 md:mb-11" v-if="popular.length">
          <Carousel
            title="Popular recipes"
            v-bind:items="popular"
            :link="{ text: 'Browse All', url: '/eat/popular' }"
          ></Carousel>
        </section>
        <!-- Explore by type -->
        <section class="mb-7 md:mb-11" v-if="byType.length">
          <Carousel title="Explore by type" v-bind:items="byType"></Carousel>
        </section>
        <!-- Explore by category -->
        <section class="mb-7 md:mb-11" v-if="byCategory.length">
          <Carousel
            title="Explore by category"
            v-bind:items="byCategory"
          ></Carousel>
        </section>
        <!-- Explore by benefits -->
        <section class="mb-7 md:mb-11" v-if="byBenefits.length">
          <Carousel
            title="Explore by benefits"
            v-bind:items="byBenefits"
          ></Carousel>
        </section>
      </div>

      <!-- Footer -->
      <xali-footer></xali-footer>
      <div class="h-20 md:hidden"></div>
    </ion-content>
  </ion-page>
</template>

<script>
/* eslint-disable */
import { searchOutline, heartOutline, receiptOutline } from "ionicons/icons";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonIcon,
  IonInput,
  IonImg,
  IonLabel,
  IonButton,
} from "@ionic/vue";
import Carousel from "@/components/pages/carousel/Carousel.vue";
import VueCookies from "vue-cookies";

export default {
  components: {
    IonContent,
    IonPage,
    Carousel,
    IonIcon,
    IonInput,
    IonHeader,
    IonImg,
    IonLabel,
    IonButton,
  },
  data() {
    return {
      loading: true,
      popular: [],
      byType: [],
      byCategory: [],
      byBenefits: [],
      byLevel: [],
      searchOutline,
      heartOutline,
      receiptOutline,
      searchStatus: false,
      searchBtn: false,
      search: null,
      searchValue: "",
    };
  },
  computed: {
    journeySetupData() {
      return VueCookies.get("journeySetupData");
    },
  },
  mounted() {
    let journey;
    if (this.journeySetupData && this.journeySetupData.dietary_preferences) {
      journey = this.journeySetupData.dietary_preferences;
    }
    this.$store.dispatch(`pages/getIngredient`);
    this.$store.dispatch(`pages/getRecipeData`, journey).then(() => {
      this.popular = this.$store.getters["pages/getNutrition"].mostpopular;
      this.byType = this.removeEmpty(
        this.$store.getters["pages/getNutrition"].type
      );
      this.byCategory = this.removeEmpty(
        this.$store.getters["pages/getNutrition"].category
      );
      // this.byBenefits = this.removeEmpty(
      //   this.$store.getters["pages/getNutrition"].benefits
      // );
      this.byBenefits = this.$store.getters["pages/getNutrition"].benefits;
      this.byLevel = this.removeEmpty(
        this.$store.getters["pages/getNutrition"].level
      );

      setTimeout(() => {
        this.loading = false;
      }, 100);
    });
  },
  methods: {
    removeEmpty(data) {
      data = _.map(data, function square(ctg) {
        if (ctg.data.count) return ctg;
      });
      return _.compact(data);
    },
    openSearch() {
      this.searchStatus = true;
      this.$refs.searchbar.$el.focus();
    },

    updateSearchValue(e) {
      if (this.search && e.keyCode === 13) {
        this.searchFunc();
      }
      this.searchValue = this.search;
      if (this.search) {
        this.searchBtn = true;
      } else {
        this.searchBtn = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.level {
  .status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border: solid 1px var(--ion-color-dark);
    border-radius: 100%;
    margin-left: 3px;
    &.checked {
      background: var(--ion-color-dark);
    }
  }
}

.search-history {
  max-height: 400px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    margin: 10px 0 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.45);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
    transition: all 0.2s;
    &:hover {
      background: rgba(0, 0, 0, 0.55);
    }
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.45);
    }
  }

  .play-button {
    position: absolute;
    top: 30%;
    left: calc(50% - 15px);
    width: 30px;
  }
}
</style>
