/* eslint-disable */
import _ from "lodash";
import moment from 'moment';
import store from "../store";
import VueCookies from "vue-cookies";
import Pluralize from "pluralize";
import Fraction from "fraction.js";

window.fraction = Fraction;
// Filter graphQL Results data to format
function filterResults(results) {
  if (!_.has(results, "edges")) return results;
  return _.map(results.edges, function (data) {
    data.node = decodeID(data.node);
    return _.omit(data.node, ["__typename"]);
  });
}

function filterDuplicates(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()];
}


// Decode if from base64 support object or string
function decodeID(data) {
  if (_.isObject(data)) {
    if (_.has(data, "id")) {
      const id = data.id;
      if (Number.isNaN(parseInt(id))) {
        if (btoa(atob(id)) == id) {
          const idArr = atob(id).split(":");
          data.id = _.last(idArr).trim();
        }
      }
    }
    Object.keys(data).forEach(id => {
      if (_.isObject(data[id])) {
        data[id] = decodeID(data[id]);
      }
    });
  } else {
    const id = data;
    if (Number.isNaN(parseInt(id))) {
      if (btoa(atob(id)) == id) {
        const idArr = atob(id).split(":");
        data = _.last(idArr).trim();
      }
    }
  }
  return data;
}

// Format api response to slide data
function formatSlideData(data, dataFrom) {
  let newDataFormat = {
    name: dataFrom + " not found.",
    tags: [],
    cover: "/assets/images/placeholder-" + dataFrom + ".jpg",
    favourite: false,
    play: false
  };

  if (_.isEmpty(data)) return newDataFormat;

  // Activity data to slide item data
  if (dataFrom == "activity") {

    let level = _.isEmpty(data.levels)
      ? "1"
      : data.levels.slice(-1).pop().value;

    // Primary Level
    if (data.primaryLevel) {
      level = data.primaryLevel.value;
    }

    var thumbnail = "/assets/images/placeholder-" + dataFrom + ".jpg";
    var portrait = '';
    if (!_.isEmpty(data.postProduction)) {
      if (data.postProduction["landscapeThumbnail"]) thumbnail = data.postProduction["landscapeThumbnail"];
      if (data.postProduction["portraitThumbnail"]) portrait = data.postProduction["portraitThumbnail"];
    }



    let videoID = false;
    if (!_.isEmpty(data.videocomponentWorkout)) {
      const standalone = _.find(data.videocomponentWorkout, { renderType: "STANDALONE" });
      if (standalone) if (standalone.duration) { videoID = standalone.brightcoveId }
      const chapter = _.find(data.videocomponentWorkout, { renderType: "CHAPTERISED" });
      if (chapter) if (chapter.duration) { videoID = chapter.brightcoveId }
      if (Number.isNaN(Number(videoID))) videoID = false;
    }

    newDataFormat = {
      id: data.id,
      type: data.type.toLowerCase(),
      level: _.isEmpty(level) ? `1/4` : `${level}/4`,
      name: data.workingTitle,
      tags: _.map(data.tag, function (data) {
        return { text: data, url: "" };
      }),
      cover: thumbnail,
      coverPortrait: portrait,
      favourite: data.favourite ? true : false,
      play: videoID ? true : false,
      link: "/move/" + data.type.toLowerCase() + "/view/" + data.id,
      data: data,
      parentType: "activity"
    };

    // Duration tag
    if (data.videocomponentWorkout) {
      let durations = [];
      let standaloneD = _.find(data.videocomponentWorkout, { 'renderType': 'STANDALONE' });
      if (standaloneD) {
        if (standaloneD.duration) {
          durations = [timeConvert(standaloneD.duration, "milisecs")];
        }
      }
      let chapterD = _.find(data.videocomponentWorkout, { 'renderType': 'CHAPTERISED' });
      if (chapterD) {
        if (chapterD.duration) {
          durations = [timeConvert(chapterD.duration, "milisecs")];
        }
      }

      // data.videocomponentWorkout.forEach((vCom,i)=> {
      //   if (vCom.duration && i == 0) { // i == 0 mean only chapterized video
      //     durations.push(timeConvert(vCom.duration, "milisecs"));
      //   }
      // });

      if (!_.isEmpty(durations)) {
        newDataFormat.tags.push({ text: durations.join(" - "), url: "" });
      }

    }
  }

  // Recipe data to slide item data
  if (dataFrom == "recipe") {
    let cover = "/assets/images/placeholder-recipe.jpg";
    if (data.featuredImagePortrait) cover = data.featuredImagePortrait;
    if (!data.featuredImagePortrait && data.featuredImageLandscape)
      cover = data.featuredImageLandscape;

    // Tag
    let tags = {};
    if (data.tag)
      tags = _.map(data.tag, function (data) {
        return { text: data, url: "" };
      });

    // Level
    //if(data.skillLevels) tags.push({ text: data.skillLevels.label, url: "" });

    if (data.prepTime) {
      tags.push({ text: `Prep ` + timeConvert(data.prepTime), url: "" });
    }
    if (data.cookTime) {
      tags.push({ text: `Cook ` + timeConvert(data.cookTime), url: "" });
    }

    newDataFormat = {
      id: data.id,
      level: false,
      name: data.title,
      tags: tags,
      cover: cover,
      favourite: data.favourite ? true : false,
      play: false,
      link: "/eat/view/" + data.id,
      data: data,
      parentType: "recipe"
    };
  }

  // Explore
  if (dataFrom == "explore") {
    let assetsURL = "/assets/images/fitness/style/";
    if (process.env.VUE_APP_ASSETS_URL)
      assetsURL = process.env.VUE_APP_ASSETS_URL + `/video/benefits/`;
    let preview = `${assetsURL}${data.value}.mp4`;
    var invalidPreview = VueCookies.get("invalidPreview");
    invalidPreview = invalidPreview ? invalidPreview.split(',') : [];
    if (invalidPreview && invalidPreview.includes(preview)) preview = ``;

    newDataFormat = {
      id: data.id,
      name: data.label,
      value: data.value,
      tags: [],
      cover: `/assets/images/${data.type}/${data.category}/${data.value}.jpg`,
      preview: preview,
      link: `/move/${data.type == "fitness" ? "workout" : data.type}/${data.category
        }/${data.id}`,
      data: data
    };

    if (data.count) {
      newDataFormat.tags.push({
        text: `${data.count} workout${data.count > 1 ? "s" : ""}`,
        url: ""
      })
    }

  }

  if (dataFrom == "explore-recipe") {
    //let cNumber = Math.floor(Math.random() * 16) + 1;

    newDataFormat = {
      id: data.id,
      name: data.label,
      value: data.value,
      tags: [{ text: data.count + " recipes", url: "" }],
      cover: `/assets/images/eat/${data.category}/${slug(
        data.label
      )}.jpg`,
      link: `/eat/${data.category}/${data.value}`,
      data: data
    };
  }

  if (dataFrom == "learn") {
    newDataFormat = {
      name: `${data.title}`,
      id: data.id ? parseInt(data.id) : null,
      tags: _.map(data.categories, function (data) {
        return { text: data, url: "" };
      }),
      cover: `${data.featuredImage}`,
      link: `${data.link}?src=app`,
      favourite: true,
      play: false,
      parentType: "learn",
    };
  }

  return newDataFormat;
}

// Response Handler for API response to hande error with message and forward data
function responseHandler(response) {
  response = _.pick(response, ["status", "statusText", "data"]);
  let message = [];
  if (response.status === 400) {
    if (response.data) {
      Object.keys(response.data).forEach((key, i) => {
        message.push(response.data[key]);
      });
    }
  }
  if (response.status === 404) {
    message = ["Not Found"];
  }
  if (response.status === 401) {
    message = ["Unauthorized"];
  }
  if (response.status === 500) {
    message = ["Internal Server Error, Please try again later."];
  }
  if (message) response.messages = _.uniq(_.flatten(message));
  return response;
}

function nl2br(str) {
  if (str) return str.replace(/(?:\r\n|\r|\n)/g, '<div class="mb-2"></div>');
}

function gcd(a, b) {
  // console.log("A", a);
  // console.log("B", b);
  // if (b < 0.0000001) return a;
  // return gcd(b, Math.floor(a % b));
  return b ? gcd(b, a % b) : a;
}

function decimalToFraction(fraction) {
  var len = fraction.toString().length - 2;
  // console.log("fraction", fraction);
  // console.log("len", len);
  var denominator = Math.pow(10, len);
  var numerator = fraction * denominator;
  // console.log("numerator", numerator);
  // console.log("denominator", denominator);
  var divisor = gcd(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;
  let numb = Math.floor(numerator) / Math.floor(denominator);
  return parseFloat(numb).toFixed(2);
}

function decimalToFractionStr(fraction) {
  var len = fraction.toString().length - 2;
  // console.log("fraction", fraction);
  // console.log("len", len);
  var denominator = Math.pow(10, len);
  var numerator = fraction * denominator;
  var divisor = gcd(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;
  let _num = Math.floor(numerator);
  let _den = Math.floor(denominator);
  let numb = `${_num}/${_den}`;
  return numb;
}

function customFraction(n) {
  // .25 = 1/4
  // .33 = 1/3
  // .50 = 1/2
  // .66 = 2/3
  // .75 = 3/4

  if (n >= 0.8 && n <= 0.99) {
    return 1;
  } else if (n >= 0.7 && n < 0.8) {
    return 0.75;
  } else if (n > 0.6 && n < 0.7) {
    return 0.66;
  } else if (n > 0.4 && n < 0.6) {
    return 0.5;
  } else if (n > 0.3 && n < 0.4) {
    return 0.33;
  } else if (n >= 0.2 && n < 0.3) {
    return 0.25;
  } else if (n >= 0.125 && n < 0.19) {
    return 0.125;
  } else {
    return parseFloat(n);
  }
}

function displayQuantity(num, serves, servings) {
  // console.log("num", num);
  let num_array = num.split(" ");
  let quantity = num_array[0] ? num_array[0] : 1;
  let measurement = num_array[1];
  let total_quantity = (quantity / serves) * servings;
  if (total_quantity <= 0) {
    return `${total_quantity} ${measurement}`;
  }
  // console.log("total_quantity", total_quantity.toFixed(2));
  let qty = total_quantity.toFixed(2);
  // let x = new Fraction(qty);
  // let frac = x.toFraction(true);

  let whole_number = parseInt(Math.floor(qty));
  // console.log("whole_number", whole_number);
  let decimal = parseFloat(qty) - whole_number;
  // console.log("decimal", decimal.toFixed(2));

  let frac2 = "";
  if (decimal > 0) {
    // console.log("fraction ", customFraction(decimal.toFixed(2)));
    let result = customFraction(decimal.toFixed(2));
    let y = new Fraction(result);
    frac2 = y.toFraction(true);
    if (result == 0.33) {
      frac2 = "1/3";
    }
    if (result == 0.66) {
      frac2 = "2/3";
    }
  }
  let fraction_str = `${whole_number > 0 ? whole_number : ""
    } ${frac2} ${measurement}`;
  return fraction_str;
  // return `${frac} ${measurement} | ${qty} --> ${fraction_str}`;
}

function slug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }
  return os;
}

function timeConvert(n, type = "mins") {
  if (type == "mins") {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    let time = "";
    if (rhours) time += `${rhours}${Pluralize("hr", rhours)} `;
    if (rminutes) time += `${rminutes}${Pluralize("min", rminutes)} `;
    return time;
  }
  if (type == "milisecs") {
    // 1- Convert to seconds:
    var seconds = n / 1000;
    // 2- Extract hours:
    var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = parseInt(seconds % 60);
    let time = ``;
    if (hours) time += `${hours}${Pluralize("hr", hours)} `;
    if (minutes) time += `${minutes}${Pluralize("min", minutes)} `;
    //if(seconds) time += `${seconds}${Pluralize("sec", seconds)} `
    return time;
  }
}

// Slider focus
function setSliderFocus(sTop = 0) {
  if (window.innerWidth < 768) {
    var sliders = document.getElementsByClassName("swiper-container");
    if (sliders) {
      var windowHeight = window.innerHeight,
        gridTop = windowHeight * 0.2,
        gridBottom = windowHeight * 0.8;
      for (let slide of sliders) {
        var thisTop = slide.offsetTop - sTop;
        if (thisTop > gridTop && thisTop + slide.offsetHeight < gridBottom) {
          slide.classList.add("focus");
        } else {
          slide.classList.remove("focus");
        }
      }
    }
  }
}

function decodeEntities(str) {
  // this prevents any overhead from creating the object each time
  var element = document.createElement("div");

  if (str && typeof str === "string") {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = "";
  }
  return str;
}

function getRandomInt(num_display, max) {
  let arr = [];
  while (arr.length < num_display) {
    let index = Math.floor(Math.random() * max);
    if (arr.indexOf(index) === -1) arr.push(index);
  }
  return arr;
}


async function trackingTrigger(event, data = {}) {
  // console.log('trackingTrigger - ', event,);

  if(event == 'connect-open'){
    const me = VueCookies.get("userData");
    window.dataLayer.push({
      'event': event,
      'email': me.email,
    });
  }
  
  if(event == 'blog-post-open'){
    data.event = event
    window.dataLayer.push(data);
  }
  
  if(event == 'plan-changed' || event == 'plan-cancelled'){
    data.event = event
    window.dataLayer.push(data);
  }

  if(event == 'payment-executed'){
    data.event = event
    window.dataLayer.push(data);
  }

  if (event == 'pageview') {
    const me = VueCookies.get("userData");
    if (me) {
      window.currentUser = {
        'email': me.email,
      }
      window.dataLayer.push({
        'event': event,
        'email': me.email,
      });
    }
  }

  if (event == 'login' || event == 'signup') {
    const me = await store.dispatch("accounts/getMe");
    if (me.status == 200) {
      window.dataLayer.push({
        'event': event,
        'firstname': me.data.first_name,
        'lastname': me.data.last_name,
        'email': me.data.email,
        'signupdate': moment(me.data.date_joined).format('YYYY-MM-DDTHH:mm:ss'),
        'email_confirmed' :me.data.detail.email_confirmed,
        'method' : data.method,
        'journey': {
          'available_equipments' :me.data.detail.available_equipments,
          'dietary_preferences' :me.data.detail.dietary_preferences,
          'level' :me.data.detail.level,
          'available_equipments' :me.data.detail.available_equipments,
          'journey' :me.data.detail.journey,
        },
        'subscription': {
          'plan_name': me.data.detail.subscription.plan_name,
          'plan_type': me.data.detail.subscription.plan_type,
          'remaining_days': me.data.detail.subscription.remaining_days,
          'is_expired': me.data.detail.is_expired,
          'expiry_date': me.data.detail.subscription.next_renewal,
          'status': me.data.detail.subscription.remaining_days != 0 && !me.data.detail.is_expired ? 'active' : 'inactive'
        }
      });

      // For Klaviyo
      window.currentUser = {
        'email': me.data.email,
        'firstname': me.data.first_name,
        'lastname': me.data.last_name,
        'signupdate': moment(me.data.date_joined).format('YYYY-MM-DDTHH:mm:ss'),
        'journey': {
          'available_equipments' :me.data.detail.available_equipments,
          'dietary_preferences' :me.data.detail.dietary_preferences,
          'level' :me.data.detail.level,
          'available_equipments' :me.data.detail.available_equipments,
          'journey' :me.data.detail.journey,
        },
        'subscription': {
          'plan_name': me.data.detail.subscription.plan_name,
          'plan_type': me.data.detail.subscription.plan_type,
          'remaining_days': me.data.detail.subscription.remaining_days,
          'is_expired': me.data.detail.is_expired,
          'status': me.data.detail.subscription.remaining_days != 0 && !me.data.detail.is_expired ? 'active' : 'inactive'
        }
      }

      // console.log('window._learnq--',window._learnq.identify());
      // window.identify = window._learnq.push(['identify', { '$email' : me.data.email }]);
      // console.log('window._learnq--',window._learnq.identify(),window.identify);
      // setTimeout(()=> {
      //   var isIdentifiedS = setInterval(()=>{
      //     if(window._learnq.isIdentified() && window.identify) {
      //       clearInterval(isIdentifiedS);
      //       if(event == 'login'){
      //         var track = window._learnq.push(['track', 'Login']);
      //         console.log('Klav login --',track,window._learnq);
      //       }
      //       if(event == 'signup'){
      //         var track = window._learnq.push(['track', 'Signup']);
      //         console.log('Klav signup --',trackq,window._learnq);
      //       }
      //     }
      //   }, 50);
      // }, 300)

      // window.klaviyo = window.klaviyo || {};
      // var ccurrentUser = setInterval(function(){
      //   if(window.currentUser) {
      //     clearInterval(ccurrentUser);
      //     console.log('identify 1 - ',window._learnq.identify());
      //     window.identifyLogin = window._learnq.push(['identify', { '$email' : window.currentUser.email }]);
      //     console.log('identify 2 - ',window._learnq.identify(),window.identifyLogin);
      //     var cidentify = setInterval(function(){
      //       if(window._learnq.isIdentified() && window.identifyLogin) {
      //         clearInterval(cidentify);
      //         window.klaviyo.login = window._learnq.push(['track', 'Login']);
      //         console.log('Klav login --',window.klaviyo);
      //       }
      //     }, 100);
      //   }
      // }, 100);


    }
  }

  if (event == 'journey-setup-completed') {
    console.log('event -- journey-setup-completed', data);
  }

  // Workout
  let workoutData = {}
  if (event == 'workout-open' || event == 'workout-video-start' || event == 'workout-video-play' || event == 'workout-video-pause' || event == 'workout-video-end') {
    let eqp = [];
    if (!_.isEmpty(data.formated.equipment)) {
      Object.keys(data.formated.equipment).forEach(i => {
        if (!_.isEmpty(data.formated.equipment[i]['items'])) {
          data.formated.equipment[i]['items'].forEach(ii => {
            eqp.push(ii.name)
          });
        }
      });
    }
    
    let benefits = []
    if(data.formated.tags){
      data.formated.tags.forEach(value => {
        benefits.push(value.text)
      })
    }

    let video_version = '';
    let duration = data.formated.videoID ? _.last(data.formated.tags)['text'] : '0mins'

    if(data.videoType){
      video_version = data.videoType == 'chapterised' ? 'Full Session' : 'Workout Only'
      let videoData = _.find(data.raw.videocomponentWorkout, {
        renderType: data.videoType.toUpperCase(),
      });
      duration = videoData.duration / 1000
    }
    
    var duration_watched = 0
    var watched_percentage = 0
    if(data.formated.durationWatched){
      duration_watched=parseInt(data.formated.durationWatched)

      // get percentage total watched
      watched_percentage = duration_watched / parseInt(duration) 
      watched_percentage= watched_percentage * 100
    }

   

    workoutData = {
      'event': event,
      'workout': {
        'id': data.formated._id ? data.formated._id : data.raw.id,
        'title': data.formated.title,
        'isRecommend': data.formated.isRecommend,
        'level': data.raw.primaryLevel ? data.raw.primaryLevel.value : '',
        'type': data.raw.type,
        'equipment': eqp,
        'duration': parseInt(duration),
        'benefit': benefits,
        'video_version': video_version
      },
      "duration_watched": duration_watched,
      "watched_percentage": parseFloat(parseFloat(watched_percentage).toFixed(2)),
    }

   
  }

  console.log(workoutData,'----workoutData')

  if (event == 'workout-open') {
    window.dataLayer.push(workoutData);
  }
  // if (event == 'workout-video-start' || event == 'workout-video-play' || event == 'workout-video-pause' || event == 'workout-video-end') {
  //   if (data.formated['durationWatched']) workoutData['duration_watched'] = Math.floor(data.formated['durationWatched'] / 60)
  //   if (data.formated['perWatched']) workoutData['percentage_watched'] = Math.round(data.formated['perWatched'])
  // }
  if (event == 'workout-video-start') {
    workoutData.action = 'Start';
    window.dataLayer.push(workoutData);
  }
  if (event == 'workout-video-play') {
    workoutData.action = 'Play';
    window.dataLayer.push(workoutData);
  }
  if (event == 'workout-video-pause') {
    workoutData.action = 'Paused';
    window.dataLayer.push(workoutData);
  }
  if (event == 'workout-video-end') {
    workoutData.action = 'End';
    window.dataLayer.push(workoutData);
  }
  if (event == 'workout-video-completed') {
    workoutData.action = 'Completed';
    workoutData.event = 'workout-video-completed';
    window.dataLayer.push(workoutData);
  }


  if (event == 'workout-recommended') {
    window.dataLayer.push({
      'event': event,
      'response': data.message,
      'workout': undefined,
    });
  }
  if (event == 'recipe-recommended') {
    console.log('event -- recipe-recommended', data);
    window.dataLayer.push({
      'event': event,
      'response': data._message,
      'recipe': undefined,
    });
  }

  // Recipe
  if (event == 'recipe-open') {
    let recipeData = {
      'event': event,
      'recipe': {
        'id': data.formated._id ? data.formated._id : data.raw.id,
        'title': data.formated.title,
        'tags': data.raw.tag,
        'level': data.formated.level,
        'isRecommend': data.formated.recommend
      }
    }
    window.dataLayer.push(recipeData);
  }

  // if (event) {
  //   console.log('dataLayer - ', window.dataLayer);
  // }
}

function setHtmlPageTitle(title) {
  window.document.title = title ? `${title} | Xali` : 'Xali'
}

export {
  filterResults,
  formatSlideData,
  responseHandler,
  decodeID,
  nl2br,
  slug,
  displayQuantity,
  generateUUID,
  getOS,
  timeConvert,
  setSliderFocus,
  decodeEntities,
  getRandomInt,
  trackingTrigger,
  setHtmlPageTitle,
  filterDuplicates
};
