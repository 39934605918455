<template>
  <ion-page>
    <ion-content
      :fullscreen="false"
      :scroll-events="true"
      @ionScroll="logScrolling($event)"
    >
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <div
          class="
            container
            max-w-full
            min-h-screen
            px-0
            cover-container
            bg-white
          "
          v-bind:class="[slideItem ? '' : '']"
        >
          <div class="grid grid-cols-12 gap-0 min-h-screen">
            <div
              class="
                content
                flex flex-col
                col-span-12
                md:col-span-7
                xl:col-span-6
              "
              v-bind:class="[slideItem ? '' : '']"
            >
              <xali-header
                type="header-w-back"
                :settings="{
                  back: true,
                  logo: false,
                  favorite: true,
                  recipeid: recipeid,
                  recommended: recipe.recommend 
                }"
              ></xali-header>
              <div
                class="
                  mobile-cover
                  -mt-20
                  pt-2
                  relative
                  h-98
                  block
                  md:hidden
                  z-0
                  bg-dark
                "
                v-bind:class="[slideItem ? '' : '']"
              >
                <img class="cover-bg" :src="recipe.cover" />
              </div>
              <div
                class="
                  workout-container
                  w-full
                  max-w-md
                  m-auto
                  px-6
                  lg:px-0
                  z-10
                  -mt-52
                  md:mt-4
                "
              >
                <div class="heading">
                  <div
                    class="connection flex -mt-4 z-10"
                    v-if="recipe.recommend"
                  >
                    <ion-chip
                      color="white"
                      class="
                        rounded-tl-none rounded-br-none
                        px-6
                        bg-primary
                        m-0
                        mr-auto
                      "
                    >
                      <ion-label
                        color="white"
                        class="
                          font-founders-condensed
                          text-lg
                          uppercase
                          mt-2
                          whitespace-nowrap
                        "
                        >Recommended Recipe</ion-label
                      >
                    </ion-chip>
                  </div>
                  <h1
                    class="
                      text-3xl
                      md:text-4xl
                      leading-8
                      font-bold
                      text-white
                      md:text-black
                      my-5
                    "
                  >
                    {{ recipe.title }}
                  </h1>
                  <div
                    class="
                      chips
                      text-xs
                      sm:text-sm
                      text-white
                      md:text-dark
                      mb-5
                    "
                  >
                    <span
                      v-for="(chip, i) in recipe.tags"
                      :key="i"
                      class="
                        bg-black bg-opacity-5
                        rounded-2lg
                        py-0.5
                        px-3
                        mr-2
                        inline-block
                        mb-1
                      "
                    >
                      <!-- <a v-bind:href="chip.url">{{ chip.text }}</a> -->
                      {{ chip.text }}
                    </span>
                  </div>
                  <div class="flex">
                    <div class="my-auto mx-2 text-3xl w-8">
                      <ion-icon
                        v-if="prevRecipe"
                        @click="move('prev')"
                        :icon="chevronBack"
                        class="
                          float-right
                          shadow-lg
                          w-8
                          h-8
                          p-1
                          cursor-pointer
                          rounded-full
                          bg-white
                        "
                      ></ion-icon>
                    </div>
                    <div class="w-full">
                      <div
                        class="
                          meta
                          bg-white
                          rounded-2lg
                          shadow-lg
                          my-5
                          p-5
                          border border-opacity-30
                          text-center text-sm
                          max-w-xs
                          mx-auto
                        "
                      >
                        <div class="grid grid-cols-2 gap-2">
                          <div class="flex flex-col">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="mx-auto color-secondary w-6 mb-1 mt-auto"
                              viewBox="0 0 40 33.485"
                            >
                              <g
                                id="Group_1390"
                                data-name="Group 1390"
                                transform="translate(0 0.833)"
                              >
                                <path
                                  id="Path_756"
                                  data-name="Path 756"
                                  d="M37.5,29.167A1.667,1.667,0,0,0,39.167,27.5V5.833A1.667,1.667,0,0,0,37.5,4.167H8.74A1.692,1.692,0,0,0,7.16,5.312,4.252,4.252,0,0,1,4.308,8.167,5,5,0,0,0,.833,12.933V20.4a5,5,0,0,0,3.475,4.765,4.287,4.287,0,0,1,2.858,2.867,1.69,1.69,0,0,0,1.575,1.133H20.833"
                                  transform="translate(0 -4.167)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.667"
                                />
                                <path
                                  id="Path_757"
                                  data-name="Path 757"
                                  d="M6.223,21.955A.833.833,0,0,0,7.5,21.25V12.083a.833.833,0,0,0-1.277-.705A4.4,4.4,0,0,0,4.167,15.1V18.23a4.4,4.4,0,0,0,2.057,3.725Z"
                                  transform="translate(0 -4.167)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.667"
                                />
                                <path
                                  id="Path_758"
                                  data-name="Path 758"
                                  d="M37.012,32.988a1.667,1.667,0,1,1-2.357,2.357l-4.713-4.714L32.3,28.275Z"
                                  transform="translate(0 -4.167)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.667"
                                />
                                <path
                                  id="Path_759"
                                  data-name="Path 759"
                                  d="M24.767,29A16.707,16.707,0,0,1,19.89,17.92a.833.833,0,0,1,1.422-.625L32.3,28.282l-3.537,3.535a.833.833,0,0,1-1.178,0Z"
                                  transform="translate(0 -4.167)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.667"
                                />
                              </g>
                            </svg>
                            <div class="text-dark">Prep</div>
                            <div class="font-medium">{{ recipe.prepTime }}</div>
                          </div>
                          <div class="flex flex-col">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="mx-auto color-secondary w-4 mb-1 mt-auto"
                              viewBox="0 0 16 24"
                            >
                              <path
                                id="Path_747"
                                data-name="Path 747"
                                d="M14.5,21.5v-3m3,3V20m-6,1.5V20m-3,1.5v-3m-3,3V20m-1,3.5h14m-1-10a2,2,0,0,1-2,2h-7a2,2,0,0,1-2-2V2.5h11Zm-11-8h-2m13,0h2m-14-3h13M10,2.5a2,2,0,1,1,4,0"
                                transform="translate(-4)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                            </svg>
                            <div class="text-dark">Cook</div>
                            <div class="font-medium">{{ recipe.cookTime }}</div>
                          </div>
                          <div class="flex flex-col hidden">
                            <svg
                              id="Group_1184"
                              data-name="Group 1184"
                              xmlns="http://www.w3.org/2000/svg"
                              class="mx-auto color-secondary w-6 mb-1 mt-auto"
                              viewBox="0 0 24 24"
                            >
                              <path
                                id="Path_748"
                                data-name="Path 748"
                                d="M15,.5a.5.5,0,0,0-.5.5V5a2.5,2.5,0,0,1-5,0V1A.5.5,0,0,0,9,.5H7.5a2,2,0,0,0-2,2c0,.253-.972,8.868-1,20.5a.5.5,0,0,0,.5.5H19a.5.5,0,0,0,.5-.5c-.027-11.628-1-20.24-1-20.5a2,2,0,0,0-2-2Z"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                              <path
                                id="Path_749"
                                data-name="Path 749"
                                d="M16.5,15a.5.5,0,0,0-.5-.5H8a.5.5,0,0,0-.5.5,4.5,4.5,0,0,0,9,0ZM4.706,14H3.914A3.936,3.936,0,0,0,1.5,15a3.936,3.936,0,0,0-1,2.414V18.5M19.294,14h.792A3.936,3.936,0,0,1,22.5,15a3.936,3.936,0,0,1,1,2.414V18.5"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                            </svg>
                            <div class="text-dark">Level</div>
                            <div class="font-medium">{{ recipe.level }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="my-auto mx-2 text-3xl w-8">
                      <ion-icon
                        v-if="nextRecipe"
                        @click="move('next')"
                        :icon="chevronForward"
                        class="
                          shadow-lg
                          w-8
                          h-8
                          p-1
                          cursor-pointer
                          rounded-full
                          bg-white
                        "
                      ></ion-icon>
                    </div>
                  </div>
                  <div class="tab-nav my-5 flex justify-center">
                    <ion-button
                      :color="isActive('intro').color"
                      :fill="isActive('intro').fill"
                      class="sm:mx-2"
                      @click.prevent="changeTab('intro')"
                    >
                      <div
                        class="py-0.5 px-1 text-sm flex"
                        v-bind:class="isActive('intro').text"
                      >
                        <span class="my-auto font-normal">Intro</span>
                      </div>
                    </ion-button>
                    <ion-button
                      :color="isActive('ingredients').color"
                      :fill="isActive('ingredients').fill"
                      class="sm:mx-2"
                      @click.prevent="changeTab('ingredients')"
                    >
                      <div
                        class="py-0.5 px-1 text-sm flex"
                        v-bind:class="isActive('ingredients').text"
                      >
                        <span class="my-auto font-normal">Ingredients</span>
                      </div>
                    </ion-button>
                    <ion-button
                      :color="isActive('method').color"
                      :fill="isActive('method').fill"
                      class="sm:mx-2"
                      @click.prevent="changeTab('method')"
                    >
                      <div
                        class="py-0.5 px-1 text-sm flex"
                        v-bind:class="isActive('method').text"
                      >
                        <span class="my-auto font-normal">Method</span>
                      </div>
                    </ion-button>
                  </div>
                </div>
                <div class="body mb-8" :class="iOS ? 'ios-spacing' : ''" >
                  <div class="tabs">
                    <!-- Intro -->
                    <div v-if="currentTab == 'intro'" class="tabcontent">
                      <div class="details mb-5">
                        <div
                          class="description font-light"
                          v-html="recipe.description"
                        ></div>
                      </div>
                      <div class="details mb-5" v-if="recipe.storage.length">
                        <div class="title text-lg font-medium mb-2">
                          Storage
                        </div>
                        <div class="description font-light">
                          <p v-for="(storage, i) in recipe.storage" :key="i">
                            {{ storage }}
                          </p>
                        </div>
                      </div>
                      <div class="mb-5" v-if="recipe.author">
                        Recipe by <strong>{{ recipe.author }}</strong>
                      </div>
                    </div>

                    <!-- Ingredients -->
                    <div v-if="currentTab == 'ingredients'" class="tabcontent">
                      <Ingredient
                        :ingredient="recipe.ingredient"
                        :recipe="recipe"
                      ></Ingredient>
                    </div>
                    <!-- Ingredients -->
                    <div v-if="currentTab == 'method'" class="tabcontent">
                      <Method
                        :method="recipe.method"
                        :recipeid="recipeid"
                      ></Method>
                    </div>
                    <!-- Related Recipes -->
                    <div class="related-recipe"  v-if="relatedRecipes.length">
                      <div class="title text-lg font-medium mb-2">
                        Related Recipes
                      </div>
                      <div class="slider">
                        <section class="mb-7 md:mb-11 -mr-6 lg:-ml-6 lg:-mr-12">
                          <Carousel
                            title=""
                            v-bind:items="relatedRecipes"
                            v-bind:breakpoints="{ 768: 2, 1024: 2 }"
                          ></Carousel>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="adapt py-12 px-3 sm:px-10 hidden">
                  <ion-button color="black" class="bottom-7">
                    <div
                      class="
                        px-2
                        py-2.5
                        text-base text-white
                        md:text-lg md:py-3
                        flex
                      "
                    >
                      <span class="my-auto">Not feeling this recipe?</span>
                    </div>
                  </ion-button>
                </div>
              </div>
            </div>
            <div
              class="cover relative hidden md:block md:col-span-5 xl:col-span-6"
              v-bind:class="[slideItem ? '' : 'rounded-l-5xl']"
            >
              <div
                class="cover-bg-container"
                :style="'top:' + coverTop + 'px;'"
                v-bind:class="[slideItem ? '' : 'rounded-l-5xl']"
              >
                <img class="cover-bg" :src="recipe.cover" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </ion-content>
    <ion-fab
      vertical="bottom"
      horizontal="start"
      slot="fixed"
      title="Switch to Xali Admin"
      v-if="isAdmin"
    >
      <a :href="editURL">
        <ion-fab-button title="Switch to Xali Admin">
          <ion-icon :icon="pencilOutline"></ion-icon>
        </ion-fab-button>
      </a>
    </ion-fab>
  </ion-page>
</template>

<script>
import Ingredient from "@/components/pages/recipe/Ingredient.vue";
import Method from "@/components/pages/recipe/Method.vue";
import Carousel from "@/components/pages/carousel/RelatedRecipeCarousel.vue";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonChip,
  IonButton,
  IonIcon,
  IonFab,
  IonFabButton,
} from "@ionic/vue";
import { pencilOutline, chevronBack, chevronForward } from "ionicons/icons";
import { filterResults, nl2br, timeConvert, trackingTrigger } from "@/_helpers";
import _ from "lodash";

export default {
  components: {
    IonContent,
    IonPage,
    IonLabel,
    IonChip,
    IonButton,
    IonIcon,
    IonFab,
    IonFabButton,
    Ingredient,
    Method,
    Carousel,
  },
  props: {
    recipeid: {
      type: String,
      default: "",
    },
    slideItem: {
      type: Boolean,
      default: false,
    },
    nextRecipe: {
      type: String,
      default: "",
    },
    prevRecipe: {
      type: String,
      default: "",
    },
  },
  data() {
    let recipe = {
      recommend: false,
      title: "",
      tags: [],
      prepTime: 0,
      cookTime: 0,
      level: "None",
      description: "",
      storage: [],
      ingredient: [],
      method: [],
      cover: "/assets/images/eat/nutrition-24.jpg",
      author: "",
      relatedRecipes: {},
    };
    return {
      loading: true,
      id: null,
      currentTab: "intro",
      recipe,
      pencilOutline,
      chevronBack,
      chevronForward,
      editURL: `${process.env.VUE_APP_BACKEND_URL}/#/recipe/${this.id}/`,
      isAdmin: this.$store.getters["accounts/getisAdmin"],
      coverTop: 0,
    };
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.recipeid) this.id = this.recipeid;
    this.getRecipe(this.id);
    // console.log("src/pages/recipe/Recipe.vue");
  },
  watch: {
    "$route.params.id"() {
      // console.log(this.$route.params.id);
      this.getRecipe(this.$route.params.id);
    },
  },
  methods: {
    getRecipe(id) {
      this.loading = true;
      this.$store.dispatch(`pages/getRecipe`, id).then(() => {
        let recipes = this.$store.getters["pages/getCurrentRecipe"];
        this.recipe = this.formatData(recipes);
        this.recipe = { ...this.recipe, _id: id };
        trackingTrigger("recipe-open", {
          formated: this.recipe,
          raw: this.$store.getters["pages/getCurrentRecipe"],
        });
        // console.log("this.recipe", this.recipe);
        window.document.title = this.recipe.title
          ? `${this.recipe.title} | Xali`
          : "Eat | Xali";
        this.relatedRecipes = recipes.relatedRecipes;
        const recomm = this.$store.getters["accounts/getRecommended"];
        if (!_.isEmpty(recomm.recipe.ids)) {
          if (_.includes(recomm.recipe.ids, Number(id)))
            this.recipe.recommend = true;
        }
        this.loading = false;
      });
    },
    logScrolling(event) {
      this.coverTop = event.detail.scrollTop;
    },
    changeTab: function (tab) {
      this.currentTab = tab;
      return tab;
    },
    isActive: function (tab) {
      if (this.currentTab == tab) {
        return { color: "black", fill: "solid", text: "text-white" };
      } else {
        return { color: "light", fill: "outline", text: "text-dark" };
      }
    },
    formatData: function (data) {
      let storage = [];
      if (!_.isEmpty(data.storage)) {
        storage = _.map(filterResults(data.storage), (data) => {
          return data.label;
        });
      }

      // Format Ingredient data
      let ingredient = [];
      if (!_.isEmpty(data.ingredientGroups)) {
        data.ingredientGroups.forEach((ing_group) => {
          const items = [];
          if (!_.isEmpty(ing_group.items)) {
            ing_group.items.forEach((item) => {
              if (item.ingredient) {
                const unit = item.ingredient.unit
                  ? `${item.amount} ${item.ingredient.unit.name}`
                  : `x ${item.amount}`;
                items.push({
                  name: item.ingredient.name,
                  sub_name: item.note,
                  image: item.ingredient.image,
                  quantity: unit,
                });
              }
            });
          }
          ingredient.push({ name: ing_group.name, items: items });
        });
      }

      // Format Method data
      let num = 1,
        method = [];
      if (!_.isEmpty(data.instructionGroups)) {
        data.instructionGroups.forEach((ins_group) => {
          const items = [];
          if (!_.isEmpty(ins_group.instructions)) {
            ins_group.instructions.forEach((item) => {
              items.push({
                number: num++,
                text: item.instruction,
                done: item.instruction,
                checked: false,
              });
            });
          }
          method.push({ name: ins_group.name, items: items });
        });
      }

      return {
        recommend: false,
        title: data.title,
        tags: _.map(data.tag, (data) => {
          return { text: data, url: "" };
        }),
        prepTime: timeConvert(data.prepTime),
        cookTime: timeConvert(data.cookTime),
        level: _.isEmpty(data.skillLevels) ? "None" : data.skillLevels.label,
        description: nl2br(data.description),
        storage: storage,
        ingredient: ingredient,
        method: method,
        cover: data.featuredImagePortrait
          ? data.featuredImagePortrait
          : "/assets/images/eat/nutrition-24.jpg",
        author: data.author,
        max_serving: data.maximumServing,
        min_serving: data.minimumServing,
        serves: data.serves,
      };
    },
    move: function (to) {
      this.$emit("move", to);
      // if(to == 'prev' && this.prevRecipe){
      //     console.log('-- ',to,this.prevRecipe);
      //     //this.$router.replace("./"+this.prevRecipe)
      // }
      // if(to == 'next' && this.nextRecipe){
      //     console.log('-- ',to,this.nextRecipe);
      //     this.$router.replace("/eat/recommended/"+this.nextRecipe);
      //     //this.$emit('eventname', to)
      //     //this.$router.replace("./"+this.nextRecipe)
      // }
      //this.$router.push("/eat/recommended");
    },
  },
};
</script>

<style lang="scss" scoped>
.related-recipe {
  .slider {
    margin-left: -1.5rem;
  }
}
.level {
  .status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border: solid 1px var(--ion-color-dark);
    border-radius: 100%;
    margin-left: 3px;
    &.checked {
      background: var(--ion-color-dark);
    }
  }
}
.cover,
.mobile-cover {
  overflow: hidden;
  .cover-bg {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}
.cover-bg-container {
  max-height: 100vh;
  height: 100vh;
  z-index: 0;
  top: 0px;
  position: absolute;
  width: 100%;
  overflow: hidden;
}
.mobile-cover {
  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 252px;
    background: transparent
      linear-gradient(180deg, #00000000 0%, var(--unnamed-color-000000) 100%) 0%
      0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%)
      0% 0% no-repeat padding-box;
    opacity: 0.45;
  }
}
.tabs {
  position: relative;
  .tabcontent {
  }
}
</style>
