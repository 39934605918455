import api from "@/api";
import store from "@/store";
import VueCookies from "vue-cookies";

const tokenKey = "userToken";
const refreshtokenKey = "userRefreshToken";
const userKey = "userData";
const fbUser = "fbUser";
const gUser = "gUser";

export async function fbLoginSignup(data) {
    const response = await api.fbLoginSignup(data);
    return response;
}
export function signinGoogle(coupon) {
    return new Promise(resolve => {
        window.gauth2
            .signIn()
            .then(async googleUser => {
                // console.log("Google Data Return - ", googleUser);
                // console.log("GoogleUser.getAuthResponse", googleUser.getAuthResponse());
                if (googleUser) {
                    // console.log(
                    //   "GoogleUser.getAuthResponse",
                    //   googleUser.getAuthResponse()
                    // );

                    let userInfo = {
                        loginType: "google-oauth2",
                        google: {
                            user: {
                                id: googleUser.getBasicProfile().getId(),
                                name: googleUser.getBasicProfile().getName(),
                                email: googleUser.getBasicProfile().getEmail(),
                                first_name: googleUser.getBasicProfile().getGivenName(),
                                last_name: googleUser.getBasicProfile().getFamilyName(),
                                image: googleUser.getBasicProfile().getImageUrl()
                            }
                        }
                    };
                    // console.log("Google Data Return - ", googleUser);
                    // console.log("Google User -", userInfo);
                    VueCookies.set(gUser, userInfo);
                    VueCookies.get(fbUser);
                    // console.log("Try to login in ...");

                    let formData = {
                        provider: "google-oauth2",
                        access_token: googleUser.getAuthResponse().access_token,
                        access_token_secret: "string"
                    };
                    const response = await api.fbLoginSignup(coupon, formData);
                    if (response) {
                        VueCookies.set(userKey, response.data);
                        VueCookies.set(tokenKey, response.data.token.access);
                        VueCookies.set(refreshtokenKey, response.data.token.refresh);

                        store.commit("accounts/setToken", response.data);
                        store.commit("accounts/setUser", response.data);
                        resolve(response.data)
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            })
            .catch(error => {
                console.error("Google Sign Up or Login Error: ", error);
                resolve(false);
            });
    });
    // return new Promise(resolve => {
    //   window.gauth2
    //     .grantOfflineAccess()
    //     .then(async response => {
    //       console.log("response", response);
    //       console.log("Try to login in ...");
    //       let formData = {
    //         provider: "google-oauth2",
    //         access_token: response.code,
    //         access_token_secret: "string"
    //       };
    //       const result = await api.fbLoginSignup(formData);
    //       if (result) {
    //         VueCookies.set(tokenKey, response.data.access);
    //         console.log("response.data", response.data);
    //         VueCookies.set(userKey, JSON.stringify(response.data));
    //         store.commit("accounts/setToken", response.data);
    //         store.commit("accounts/setUser", response.data);

    //         console.log("Redirect to /home");
    //         // window.location.href = "/home";
    //         resolve(true);
    //       } else {
    //         resolve(false);
    //       }
    //     })
    //     .catch(error => {
    //       console.error("Google Sign Up or Login Error: ", error);
    //       resolve(false);
    //     });
    // });
}
export function loginFacebookSdk(coupon) {
    let params = { scope: "public_profile,email" };
    return new Promise(resolve => {
        window.FB.login(response => {
            // console.log("Response - ", response);
            if (response && response.authResponse) {
                let userInfo = {
                    loginType: "fb",
                    fb: {
                        auth: response.authResponse
                    }
                };
                VueCookies.set(fbUser, userInfo);
                let url = "/me?fields=name,email,gender,verified,link";
                window.FB.api(url, async userResponse => {
                    if (userResponse) {
                        // console.log("userResponse", userResponse);
                        let userInfo = {
                            loginType: "fb",
                            fb: {
                                auth: response.authResponse,
                                user: userResponse
                            }
                        };
                        VueCookies.set(fbUser, userInfo);
                        let fbData = VueCookies.get(fbUser);
                        // fbData = JSON.parse(fbData);
                        // console.log("fbData", fbData);
                        if (fbData && fbData.fb && fbData.fb.user && fbData.fb.user.id) {
                            // console.log("Try to login in ...");
                            let formData = {
                                provider: "facebook",
                                access_token: fbData.fb.auth.accessToken,
                                access_token_secret: "string"
                            };
                            const response = await api.fbLoginSignup(coupon, formData);
                            if (response) {
                                VueCookies.set(tokenKey, response.data.token.access);
                                VueCookies.set(refreshtokenKey, response.data.token.refresh);
                                VueCookies.set(userKey, response.data);
                                VueCookies.remove(gUser);
                                store.commit("accounts/setToken", response.data);
                                store.commit("accounts/setUser", response.data);
                                // console.log("Returning true");
                                resolve(response.data);
                            } else {
                                resolve(false);
                            }
                        }
                        resolve(false);
                    }
                });
            }
        }, params);
    });
}