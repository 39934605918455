<script>
import { IonButton } from "@ionic/vue";
import journeySetupData from "@/data/journey_setup_data.json";
export default {
  name: "step-1",
  components: { IonButton },
  props: ["items"],
  emits: ["setSelect"],
  data() {
    return {
      step: 1,
      select: journeySetupData.select,
      valid: journeySetupData.valid,
    };
  },
  computed: {
    iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    fitness_coach_text() {
      return `Meet your Fitness Coach (${this.step}/4)`;
    },
    max_level() {
      return Math.max.apply(
        Math,
        this.items.map(function (o) {
          return parseInt(o.value);
        })
      );
    },
  },
  methods: {
    isSelected: function (item, i) {
      if (this.select[this.step].selected == i || item.isChecked) {
        return "border rounded-lg mb-5 border-primary bg-primary bg-opacity-5 ion-color ion-color-primary md button button-block button-clear ion-activatable ion-focusable hydrated";
      } else {
        return "border rounded-lg mb-5 ion-color ion-color-primary md button button-block button-clear ion-activatable ion-focusable hydrated";
      }
    },
    selected(step, i, item) {
      // console.log("item", item);
      this.select[step]["selected"] = i;
      this.valid[step] = true;
      this.$emit("setSelect", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.level {
  .status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border: solid 1px var(--ion-color-black);
    border-radius: 100%;
    margin-left: 3px;
    &.checked {
      background: var(--ion-color-black);
    }
  }
}
</style>

<template>
  <section>
    <div class="question-details mb-5">
      <div class="question-progress">
        {{ fitness_coach_text }}
      </div>
      <h1 class="question text-3xl font-black mb-5">How do you move?</h1>
    </div>
    <div class="question-options mt-10 md:mt-16 "
        :class="iOS ? 'mb-14' : 'mb-28'">
      <ion-button
        v-for="(item, index) in items"
        :key="index"
        :class="isSelected(item, index)"
        color="primary"
        expand="block"
        fill="clear"
        @click.prevent="selected(step, index, item)"
      >
        <div
          class="
            py-2.5
            text-base text-black
            md:text-xl md:py-3.5
            max-w-full
            whitespace-normal
          "
        >
          {{ item.label }}
          <div class="level flex justify-center mt-2">
            <span
              v-for="n in max_level"
              :key="n"
              class="status"
              v-bind:class="{ checked: n <= item.value }"
            ></span>
          </div>
        </div>
      </ion-button>
    </div>
  </section>
</template>
