<template>
  <div class="card-carousel-container">
    <div class="carousel-title flex mb-4 px-6 lg:px-12">
      <router-link v-if="link.url" :to="link.url" class="flex items-center">
        <h2 class="text-2xl md:text-3xl font-semibold">{{ title }}</h2>
        <span class="pt-3 ml-2">
          <ion-icon :icon="chevronForward" v-if="link.url"></ion-icon>
        </span>
      </router-link>
      <h2 v-if="!link.url" class="text-2xl md:text-3xl font-semibold">
        {{ title }}
      </h2>
      <div
        class="ml-auto mt-2 text-xs md:text-sm invisible md:visible"
        v-if="link.url"
      >
        <router-link
          :to="link.url"
          class="border-b border-black py-0.5 md:py-1"
        >
          {{ link.text }}
        </router-link>
      </div>
    </div>
    <ion-slides
      ref="mySlides"
      :options="slideOpts"
      v-on:ionSlidesDidLoad="ionSlidesDidLoad"
      v-on:ionSlideDidChange="ionSlideDidChange"
    >
      <ion-slide
        v-for="(item, i) in items"
        :key="i++"
        class="pr-12 md:pr-12 lg:pr-24"
      >
        <CarouselItem v-bind:data="item"></CarouselItem>
      </ion-slide>
      <!-- <ion-slide class="max-w-0 hidden md:block" v-if="items.length <= 3"></ion-slide> -->
    </ion-slides>
  </div>
</template>

<script>
import CarouselItem from "@/components/pages/carousel/CarouselItem.vue";
import { IonSlides, IonSlide, IonIcon } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Carousel",
    },
    titleLink: {
      type: String,
      default: "",
    },
    link: {
      type: Object,
      default: function () {
        return {}; //text: 'Browse All', url: '/more'
      },
    },
    items: {
      type: Array,
      default: function () {
        return [
          // {
          //   level: '2/4',
          //   name:'Aerobic Movement 1',
          //   tags:[
          //     { text: 'Cardio', url: '/Cardio' },
          //     { text: 'Sculpt & Tone', url: '/Sculpt&Tone' },
          //     { text: '31 min', url: '/31min' },
          //   ],
          //   cover:'/assets/images/workout/workout-1.jpg',
          //   favoewurite: play
          //   favourite: true
          // }
        ];
      },
    },
    breakpoints: {
      type: Object,
      default: function () {
        return {}; //text: 'Browse All', url: '/more'
      },
    },
  },
  components: { IonSlides, IonSlide, CarouselItem, IonIcon },
  data() {
    const slideOpts = {
      autoplay: true,
      slidesPerView: 1,
      spaceBetween: -35,
      setWrapperSize: true,
      allowTouchMove: true,
      shortSwipes: true,
      preventClicksPropagation: true,
      preventClicks: true,
      mousewheel: {
        invert: true,
      },
      breakpoints: {
        1024: {
          slidesPerView: 1,
          spaceBetween: -70,
        },
      },
    };
    return { slideOpts, chevronForward };
  },
  // async mounted() {
  //   const mySlides = this.$refs.mySlides;
  //   const s = await mySlides;
  //   console.log("mySlides", s);
  // },
  methods: {
    ionSlideDidChange: function (e) {
      let sClass = e.target.classList;
      if (sClass.value.includes("focus")) {
        var active = e.target.getElementsByClassName("swiper-slide-active")[0];
        var vpreview = active.getElementsByClassName("video-preview")[0];
        if (vpreview) {
          var video = vpreview.getElementsByTagName("video")[0];
          video.play();
        }
      }
    },
    ionSlidesDidLoad: function () {
      // setTimeout(() => {
      //   var wrap = e.target.getElementsByClassName("swiper-wrapper")[0];
      //   if(wrap){
      //     wrap.classList.add("left-6");
      //     wrap.classList.add("lg:left-12");
      //     setTimeout(() => {
      //       this.ionSlideDidChange(e)
      //     }, 50);
      //   }else{
      //     setTimeout(() => {
      //       if(!wrap.classList.value.includes('left-6')){
      //         console.log('Recheck slider alignment');
      //         this.ionSlidesDidLoad(e);
      //       }
      //     }, 300);
      //   }
      // }, 10);
    },
  },
});
</script>

<style lang="scss" scoped>
.length-1-index-0 {
  margin-left: 8rem;
}
</style>
