
<template>
  <ion-page class="max-w-md mx-auto">
    <ion-content :fullscreen="true">
      <div class="container p-6 setting-container">
        <header class="mb-5 relative">
          <div class="back flex -mt-1 -ml-4">
            <ion-back-button
              defaultHref="/home"
              :icon="chevronBack"
              class="text-xl"
            ></ion-back-button
            ><span class="my-auto -ml-2 pb-2px">Back</span>
          </div>
          <div class="flex absolute -top-0 -right-1">
            <router-link to="/home" class="relative">
              <ion-icon
                :icon="closeCircle"
                class="block text-light text-5xl opacity-30"
              ></ion-icon>
              <ion-icon
                :icon="closeOutline"
                class="
                  block
                  text-black text-3xl
                  absolute
                  top-0
                  m-2
                  border border-transparent
                "
              ></ion-icon>
            </router-link>
          </div>
          <div class="text-4.5xl font-bold leading-10">
            <div>We’ll miss you Emily!</div>
          </div>
        </header>

        <p class="font-bold mb-4">
          Thanks for letting us be a part of your health journey.
        </p>
        <p class="font-bold">
          Would you mind letting us know the reason/s why you’re leaving?
        </p>

        <div class="feedback-list mt-6 mb-2">
          <ul class="list-style-type p-0 m-0">
            <li
              v-for="choice in feedbackChoices"
              :key="choice.id"
              class="inline-block cursor-pointer"
              @click="addChoice(choice.id)"
            >
              <span
                class="block rounded-md mr-2 mb-2 text-sm px-2 py-1 font-medium"
                :class="
                  selectedFeedback.includes(parseInt(choice.id))
                    ? 'bg-opacity-5 bg-primary active'
                    : ''
                "
              >
                {{ choice.value }}
              </span>
            </li>
          </ul>
        </div>

        <ion-textarea
          placeholder="Tell us your suggestion"
          class="border-b mb-8"
        ></ion-textarea>
        <p class="mb-8">
          Your subscription will remain active until 10th May 2022. After that,
          you won’t be able to access any Xali content.
        </p>

        <ion-button expand="block" color="danger" fill="outline" class="mb-2">
          <span class="block p-4 text-danger w-full"> Pause my subscription </span>
        </ion-button>

        <ion-button expand="block" color="danger">
          <span class="block p-4"> Cancel subscription </span>
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonContent,
  IonBackButton,
  IonIcon,
  IonTextarea,
} from "@ionic/vue";
import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  logOutOutline,
  checkmarkOutline,
} from "ionicons/icons";

export default {
  components: { IonPage, IonContent, IonBackButton, IonIcon, IonTextarea },
  data() {
    return {
      checkmarkOutline,
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      chevronBack,
      logOutOutline,
      selectedFeedback: [1, 4],
      feedbackChoices: [
        {
          id: 1,
          value: "Too expensive",
        },
        {
          id: 2,
          value: "Not using it enough",
        },
        {
          id: 3,
          value: "Needed a different workout style",
        },
        {
          id: 4,
          value: "Not reaching my goal",
        },
        {
          id: 5,
          value: "Too expensive",
        },
        {
          id: 6,
          value: "It's just not for me",
        },
        {
          id: 7,
          value: "Other",
        },
      ],
    };
  },
  methods: {
    changePlan(plan) {
      this.plan = plan;
    },
    addChoice(value) {
      if (this.selectedFeedback.includes(parseInt(value))) {
        this.selectedFeedback.splice(
          this.selectedFeedback.indexOf(parseInt(value)),
          1
        );
      } else {
        this.selectedFeedback.push(parseInt(value));
      }
    },
  },
};
</script>

<style lang="scss">
.feedback-list {
  span {
    border: 1px solid #dcdcdc;
    color: #727272;
    &.active {
      border: 1px solid #00b5b6;
      color: #00b5b6;
    }
  }
}
</style>