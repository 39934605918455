import VueCookies from 'vue-cookies';
export default {
  setToken(state, value) {
    state.token = value.access;
    state.refreshToken = value.refresh;
  },
  setUser(state, value) {
    if (value) {
      state.user = value;
      let content = value;
      if (typeof value !== "string") {
        content = JSON.stringify(value);
      }
      if (value.detail) VueCookies.set("journeySetupData", value.detail);
      VueCookies.set("userData", content);
    }
  },
  setFormData(state, value) {
    state.formData = value;
  },
  setResponseData(state, value) {
    state.responseData = value;
  },
  setFbUserData(state, value) {
    state.fbUserData = value;
    let content = value;
    if (typeof value !== "string") {
      content = value;
    }
    VueCookies.set(state.fbUser, content);
  },
  setJourneyQuestions(state, value) {
    state.journeyQuestions = value;
  },
  setJourneySetupResponse(state, value) {
    state.journeySetupResponse = value;
  },
  setRecommendActivity(state, value) {
    state.recommend.activity = value;
    VueCookies.set("userRecommend", state.recommend);
  },
  setRecommendRecipe(state, value) {
    state.recommend.recipe = value;
    VueCookies.set(
      "userRecommend",
      state.recommend
    );
  },
  setRecommendLearn(state, value) {
    state.recommend.learn = value;
    VueCookies.set(
      "userRecommend",
      state.recommend
    );
  },
  setFavouritesData(state, value) {
    // console.log("value", value);
    state.favourites = value;

  },
  setHistoryData(state, value) {
    state.history = value;
  }
};
