<template>
  <ion-app>
    <ion-router-outlet mode="md" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import VueCookies from "vue-cookies";
//import _ from "lodash"
// import mixpanel from 'mixpanel-browser';


//import moment from 'moment';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  async mounted(){

    //mixpanel.init('0d6ef7a3e32086155c06f4b31d5e414c', {debug: true}); 
    const currentEvent = VueCookies.get("event");
    
    //if(currentEvent == 'login' || currentEvent == 'signup'){

      //const me = await this.$store.dispatch("accounts/getMe");
      //const me = VueCookies.get("userData")
      // if (me.status == 200) {
      //   window._learnq.push(['identify', { '$email' : me.data.email }]);
      //   var isIdentified = setInterval(function() {
      //     if(window._learnq.isIdentified()) {
      //       clearInterval(isIdentified);
      //       var loginT = window._learnq.push(['track', _.startCase(currentEvent)]);
      //       console.log('loginT--',loginT,me.data.email,window._learnq,window._learnq.identify(),window._learnq.account());
      //     }
      //   }, 100);
      // }

    //}
    if(currentEvent){
      //console.log('Klav -',_learnq)
      console.log('Recent Event -- ',currentEvent, window.dataLayer);
    }
    VueCookies.set("event","")
  }
});
</script>