<template>
  <div v-if="!method.length">
    <div class="text-center">No Method Found.</div>
  </div>
  <template v-if="method.length">
    <div class="method-list">
      <div v-for="(method, metI) in method" :key="metI" class="items mb-4">
        <div class="name font-medium py-2">{{ method.name }}</div>
        <div
          v-for="(item, itemI) in method.items"
          :key="itemI"
          class="item flex mb-4"
        >
          <div class="relative">
            <div
              @click.prevent="markasDone(metI, itemI)"
              class="
                border
                rounded-full
                w-6
                h-6
                flex
                items-center
                justify-center
                text-sm
                cursor-pointer
              "
            >
              {{ item.number }}
            </div>
            <ion-icon
              @click.prevent="markasDone(metI, itemI)"
              v-if="checkValue[metI + '-' + itemI]"
              :icon="checkmarkCircle"
              class="
                bg-white
                text-primary text-2xl
                absolute
                top-0
                rounded-full
                cursor-pointer
              "
            ></ion-icon>
          </div>
          <div
            class="ml-4 text-sm cursor-pointer"
            @click.prevent="markasDone(metI, itemI)"
          >
            <div v-if="!checkValue[metI + '-' + itemI]">{{ item.text }}</div>
            <div class="text-dark" v-if="checkValue[metI + '-' + itemI]">
              {{ item.done }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { checkmarkCircle } from "ionicons/icons";
import VueCookies from "vue-cookies";

export default {
  components: { IonIcon },
  props: {
    recipeid: {
      type: String,
      default: "",
    },
    method: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  mounted() {
    this.id = this.$route.params.id ? this.$route.params.id : this.recipeid;
    // console.log("this.id", this.id);
    this.checkValue = VueCookies.get(`method_session_${this.id}`) || {};
    // console.log("this.checkValue", this.checkValue);
  },
  data() {
    // // Original methods format
    // let methods = [
    //     {
    //         name: 'For the salad',
    //         items:[
    //             {
    //                 number:1,
    //                 text:'Preheat the oven to 220°C.',
    //                 done:'Preheat the oven to 200 C. Line a baking tray with parchment paper.',
    //                 checked:false
    //             },
    //             {
    //                 number:2,
    //                 text:'Cover the lamb cutlets in the red curry paste, leaving to marinate at room temperature while you prepare the salad.',
    //                 done:'Scatter all ingredients in the baking tray, drizzle with olive oil, season and bake for 30 minutes. Note that you can cover with foil if the tofu starts to burn.',
    //                 checked:false
    //             },
    //             {
    //                 number:3,
    //                 text:'Remove from the oven, divide onto serving plates or serve as an accompaniment.',
    //                 done:'Depending on the cucumber size I use about 1 to 1.5 cucumbers for ribbons and a little extra chopped finely for the raita. Prepare and set aside.',
    //                 checked:false
    //             },
    //             {
    //                 number:4,
    //                 text:'Mix yogurt, diced cucumber, diced mint together in a small bowl.',
    //                 done:'Mix yogurt, diced cucumber, diced mint together in a small bowl.',
    //                 checked:false
    //             },
    //         ]
    //     },
    //     {
    //         name: 'Raspberry vinaigrette',
    //         items:[
    //             {
    //                 number:5,
    //                 text:'Set a pot to boil, or the kettle if you prefer. Trim and prep the two kinds of beans placing them in the boiling water for 3 minutes to lightly blanche. Remove from the water and strain to dry.',
    //                 done:'Set a pot to boil, or the kettle if you prefer. Trim and prep the two kinds of beans placing them in the boiling water for 3 minutes to lightly blanche. Remove from the water and strain to dry.',
    //                 checked:false
    //             },
    //             {
    //                 number:6,
    //                 text:'Slice the onion thinly. Now let&#39;s get the lamb on to cook, during which we can construct the salad.',
    //                 done:'Slice the onion thinly. Now let&#39;s get the lamb on to cook, during which we can construct the salad.',
    //                 checked:false
    //             },
    //         ]
    //     },
    // ];
    return { id: null, checkValue: {}, checkmarkCircle };
  },
  methods: {
    markasDone: function (pi, i) {
      //   console.log("pi", pi);
      //   console.log("i", i);
      this.checkValue[`${pi}-${i}`] = !this.checkValue[`${pi}-${i}`];
      VueCookies.set(`method_session_${this.id}`, this.checkValue);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

