<template>
    <div class="bubble-chat mb-3" v-if="from == 'left'">
        <div class="flex">
            <div class="bg-light rounded-3xl rounded-bl-none py-3 px-6 mr-auto" v-if="!type">
                <slot></slot>
            </div>
            <template v-if="type == 'action'">
                <slot></slot>
            </template>
        </div>
        <ion-avatar slot="start" class="w-8 h-8 mt-2 relative mb-3" v-if="avatar">
            <img class="w-full h-full" :src="avatar">
        </ion-avatar>
    </div>
    <div class="bubble-chat mb-3" v-if="from == 'right'">
        <div class="flex">
            <div v-if="!type" class="border border-primary rounded-3xl rounded-br-none py-3 px-6 text-right ml-auto" v-bind:class="[selected ? 'bg-primary text-white':'bg-white']">
                <slot></slot>
            </div>
            <template v-if="type == 'action'">
                <slot></slot>
            </template>
        </div>
        <ion-avatar slot="start" class="w-8 h-8 mt-2 relative mb-3" v-if="avatar">
            <img class="w-full h-full" :src="avatar">
        </ion-avatar>
    </div>
</template>

<script>
import { IonAvatar } from '@ionic/vue';
export default{
    props: {
        from: {
            type: String,
            default: 'left'
        },
        avatar: {
            type: String,
            default: ''
        },
        selected: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
    },
    components: { IonAvatar },
}
</script>

<style lang="scss" scoped>

</style>

