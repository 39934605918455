<template>
  <ion-page>
    <ion-content
      :scroll-events="true"
      @ionScroll="$refs.header.setFixed($event.detail.scrollTop)"
    >
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <!-- Heading -->
        <xali-header
          ref="header"
          :settings="{ back: true, hidemobilenav: true }"
        ></xali-header>

        <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
          <div class="heading flex flex-col sm:flex-row">
            <h1 class="text-4.5xl font-bold capitalize">
              {{ title }} {{ type ? type + "s" : "" }}
            </h1>
            <div
              class="
                search
                flex
                bg-black bg-opacity-5
                rounded-2lg
                mt-4
                mb-5
                sm:hidden
              "
            >
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
              <ion-input
                :value="search"
                @ionInput="search = $event.target.value"
                placeholder="Search a workout"
                v-on:keyup="searchFunc"
              ></ion-input>
            </div>
            <div class="search-button ml-auto my-auto hidden sm:block">
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark ml-auto"
                @click="openSearch"
                v-if="!searchStatus"
              >
              </ion-icon>

              <div
                class="
                  search
                  flex
                  bg-light bg-opacity-30
                  rounded-md
                  block
                  mt-2.5
                "
                v-if="searchStatus"
                :class="searchBtn ? 'mr-1' : ''"
              >
                <ion-icon
                  :icon="searchOutline"
                  class="block text-2xl text-dark my-auto ml-2"
                ></ion-icon>
                <ion-input
                  @ionInput="search = $event.target.value"
                  placeholder="Search a workout"
                  v-on:keyup="updateSearchValue"
                  autofocus="true"
                  ref="searchbar"
                  :value="search"
                ></ion-input>
              </div>
            </div>

            <ion-button
              class="bg-primary rounded-md mt-3"
              fill="outline"
              @click="searchFunc"
              v-if="searchBtn"
            >
              <div class="px-2 py-3 font-normal flex text-white">Search</div>
            </ion-button>
          </div>
        </div>
        <div class="container max-w-full px-6" v-if="!items.length">
          <h3 class="px-6 text-2xl">No result found.</h3>
        </div>
        <div class="mt-28 flex flex-col" v-if="loadingFilter">
          <ion-spinner
            name="bubbles"
            color="primary"
            class="mx-auto transform scale-200"
          ></ion-spinner>
          <ion-img
            :src="'/assets/images/icon.svg'"
            class="w-10 spinner hidden"
          ></ion-img>
        </div>
        <div
          class="
            container
            max-w-full
            mt-0
            px-6
            lg:px-12
            grid grid-cols-12
            gap-0
            sm:gap-4
          "
          v-if="!loadingFilter"
        >
          <div
            v-for="(item, i) in items"
            :key="i++"
            class="col-span-12 sm:col-span-6 lg:col-span-4 mb-5"
          >
            <CarouselItem v-bind:data="item"></CarouselItem>
          </div>
        </div>
        <div class="mt-10 mb-10 flex flex-col" v-if="loadmoreLoading">
          <ion-spinner
            name="bubbles"
            color="primary"
            class="mx-auto transform scale-200"
          ></ion-spinner>
          <ion-img
            :src="'/assets/images/icon.svg'"
            class="w-10 spinner hidden"
          ></ion-img>
        </div>
        <div
          class="mt-10 mb-10 flex justify-center"
          v-if="loadmoreBtn && !loadmoreLoading"
          id="loadmore"
        >
          <ion-infinite-scroll
            @ionInfinite="loadmore()"
            threshold="100px"
            id="infinite-scroll"
            :disabled="!loadmoreBtn"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more data..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </div>
        <filter-template
          :currentPage="currentPage"
          :id="id"
          :isCategory="true"
          @filter-updates="filterUpdates"
          @sortingEmit="sorting"
        >
        </filter-template>
        <!-- Footer -->
        <xali-footer></xali-footer>
        <div class="h-20 md:hidden"></div>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapGetters } from "vuex";
import { heartOutline, searchOutline } from "ionicons/icons";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonInput,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
  IonImg,
} from "@ionic/vue";
import CarouselItem from "@/components/pages/carousel/CarouselItem.vue";
import FilterTemplate from "../filters/activity.vue";

export default {
  components: {
    IonContent,
    IonPage,
    CarouselItem,
    IonIcon,
    IonInput,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    IonImg,
    FilterTemplate,
  },
  data() {
    return {
      items: [],
      type: "workout",
      id: null,
      category: null,
      heartOutline,
      searchOutline,
      loading: true,
      title: "",
      currentPage: 1,
      search: null,
      loadmoreBtn: true,
      loadmoreLoading: false,
      args: "",
      isRedirected: false,
      activityList: [],
      searchBtn: false,
      searchStatus: false,
      loadingFilter: false,
      searchValue: "",
    };
  },
  computed: {
    ...mapGetters({
      pagesCategory: "pages/getCurrentCategory",
    }),
  },
  mounted() {
    this.type = this.$route.params.type;
    this.category = this.$route.params.category;
    this.id = this.$route.params.id;
    // console.log("this.type", this.type);
    // console.log("this.category", this.category);
    // console.log("this.id", this.id);
    this.$store.commit("pages/setFitnessType", this.type);
    this.$store.commit("pages/setFitnessCategory", this.category);
    this.$store.commit("pages/setFitnessCategoryID", this.id);

    this.$store.dispatch(`pages/fieldOptions`);
    this.$store.dispatch(`pages/getCategoryData`, this.id).then(() => {
      const selectedCtg = this.$store.getters["pages/getCurrentCategory"];
      this.items = JSON.parse(JSON.stringify(selectedCtg.activity));

      this.title = selectedCtg.title;
      window.document.title = this.title
        ? `${this.title} ${this.type}s | Xali`
        : "Xali";
      this.loading = false;
    });
  },
  watch: {
    "$route.params.type"(value) {
      this.type = value;
      this.$store.commit("pages/setFitnessType", this.type);
    },
    "$route.params.category"(value) {
      this.category = value;
      this.$store.commit("pages/setFitnessCategory", this.category);
    },
  },
  methods: {
    filterUpdates(e) {
      this.loadingFilter = true;
      this.currentPage = 0;
      if (e.args) {
        this.args = e.args;
        if (e.isRedirect) {
          this.isRedirected = true;
          this.$store
            .dispatch(`pages/loadmoreActivity`, {
              id: this.id,
              page: this.currentPage,
              search: this.search,
              args: this.args,
              isRedirected: this.isRedirected,
            })
            .then((res) => {
              this.items = res.formatted;
              this.loadingFilter = false;
              this.loadmoreBtn = res.pageInfo.hasNextPage;
              this.currentPage = this.currentPage + 1;
              this.title = "";
              this.type =
                e.params.type != "stretch_flows"
                  ? e.params.type
                  : "Stretch flow";
            });
        } else {
          this.$store
            .dispatch(`pages/loadmoreActivity`, {
              id: this.id,
              page: this.currentPage,
              search: this.search,
              args: this.args,
            })
            .then((res) => {
              this.items = res.formatted;
              this.loadingFilter = false;
              this.loadmoreBtn = res.pageInfo.hasNextPage;
              this.currentPage = 1;
            });
        }
      }
    },
    searchFunc() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loading = true;
        if (this.search) {
          this.$store
            .dispatch(`pages/searchActivity`, this.search)
            .then((res) => {
              this.items = res;
              this.loading = false;
              this.loadmoreBtn = false;
            });
        } else {
          this.$store.dispatch(`pages/getCategoryData`, this.id).then(() => {
            this.currentPage = 1;
            this.loading = false;
          });
        }
      }, 1000);
    },
    loadmore() {
      this.loadmoreLoading = true;
      this.$store
        .dispatch(`pages/loadmoreActivity`, {
          id: this.id,
          page: this.currentPage,
          search: this.search,
          args: this.args,
          isRedirected: this.isRedirected,
        })
        .then((res) => {
          for (let i = 0; res.formatted.length > i; i++) {
            this.items.push(res.formatted[i]);
          }
          this.loadmoreBtn = res.pageInfo.hasNextPage;
          this.loadmoreLoading = false;
          this.currentPage = this.currentPage + 1;
        });
    },
    sorting(e) {
      let activityList = JSON.parse(JSON.stringify(this.items));

      // SHORTEST TO LONGEST
      if (e.sort == "shortest") {
        this.items.sort(function (a, b) {
          var a_duration = 0;
          var b_duration = 0;
          var a_activity = a.tags[a.tags.length - 1].text;
          var b_activity = b.tags[b.tags.length - 1].text;

          if (a_activity.includes("mins") && a.play) {
            a_duration = a_activity.split("mins")[0];
          }
          if (b_activity.includes("mins") && b.play) {
            b_duration = b_activity.split("mins")[0];
          }

          return parseInt(a_duration) - parseInt(b_duration);
        });
      }

      // LONGEST TO SHORTEST
      if (e.sort == "longest") {
        this.items.sort(function (a, b) {
          var a_duration = 0;
          var b_duration = 0;
          var a_activity = a.tags[a.tags.length - 1].text;
          var b_activity = b.tags[b.tags.length - 1].text;

          if (a_activity.includes("mins")) {
            a_duration = a_activity.split("mins")[0];
          }
          if (b_activity.includes("mins")) {
            b_duration = b_activity.split("mins")[0];
          }
          return parseInt(b_duration) - parseInt(a_duration);
        });
      }

      //LEVEL
      if (e.sort == "level") {
        this.items.sort(function (a, b) {
          var level_a = a.data.levels[a.data.levels.length - 1].value;
          var level_b = b.data.levels[b.data.levels.length - 1].value;
          if (a.data.primaryLevel) {
            level_a = a.data.primaryLevel.value;
          }
          if (b.data.primaryLevel) {
            level_b = b.data.primaryLevel.value;
          }

          return parseInt(level_a) - parseInt(level_b);
        });
      }
      console.log(activityList);
      this.$store.commit("pages/sortActivity", activityList);
    },
    updateSearchValue(e) {
      if (this.search && e.keyCode === 13) {
        this.searchFunc()
      }
      this.searchValue = this.search;
      if (this.search) {
        this.searchBtn = true;
      } else {
        this.searchBtn = false;
      }
    },
    openSearch() {
      this.searchStatus = true;
      this.$refs.searchbar.$el.focus();
    },
  },
};
</script>