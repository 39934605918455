<template>
   <div class="text-center p-5">
     <h1>Page Not Found.</h1>
   </div>
</template>
<script>
//import {} from '@ionic/vue';
export default{
  components: { }
}
</script>

<style lang="scss" scoped>

</style>
