
<template>
  <div class="subscription-container relative">
    <header class="pt-8 px-6">
      <ion-icon :icon="chevronBack" size="large"></ion-icon>
    </header>

    <div class="body-content mt-8 px-6">
      <h2 class="text-4xl font-bold tracking-tight leading-8 mb-6">
        Payment
      </h2>
      <div class="border-2 rounded-md border-primary p-5 relative">
        <div
          class="bg-primary absolute w-full h-full left-0 top-0 opacity-50 z-1"
        ></div>
        <div class="relative z-2">
          <h2 class="text-2xl font-bold tracking-tight">{{ plan.month }}</h2>
          <p class="text-xs w-32">{{ plan.description }}</p>
          <ul class="leading-7 pl-3 mt-3 text-sm">
            <li class="flex items-center">
              <ion-icon
                :icon="checkmarkCircleOutline"
                size="small"
                :color="'white'"
                class="mr-1"
              ></ion-icon>
              <span><b>Move</b> unlimited</span>
            </li>
            <li class="flex items-center">
              <ion-icon
                :icon="checkmarkCircleOutline"
                size="small"
                :color="'white'"
                class="mr-1"
              ></ion-icon>
              <span><b>Eat</b> unlimited</span>
            </li>
            <li class="flex items-center">
              <ion-icon
                :icon="checkmarkCircleOutline"
                size="small"
                :color="'white'"
                class="mr-1"
              ></ion-icon>
              <span><b>Learn</b> unlimited</span>
            </li>
            <li class="flex items-center">
              <ion-icon
                :icon="checkmarkCircleOutline"
                size="small"
                :color="'white'"
                class="mr-1"
              ></ion-icon>
              <span><b>Connect</b> unlimited</span>
            </li>
            <li class="flex items-center">
              <ion-icon
                :icon="checkmarkCircleOutline"
                size="small"
                :color="'white'"
                class="mr-1"
              ></ion-icon>
              <span><b>Individualised journey</b> unlimited</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-5 mb-36">
        <!-- Tailwind CSS Playground : https://play.tailwindcss.com/KbKtNzkOBy -->


          <form class="flex flex-wrap gap-3 w-full p-5">

            <label class="relative w-full flex flex-col">
              <span class="font-bold mb-3">Card Name</span>
              <input v-model="name" id="card-holder-name" class="rounded-md peer pl-12 pr-2 py-2 border-2 border-gray-200 placeholder-gray-300" type="text" placeholder="Name" />
              <svg xmlns="http://www.w3.org/2000/svg" class="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
              </svg>
            </label>

            <div id="card-element" class="w-full bg-white p-5">
            </div>



          </form>
      </div>
    </div>

    <footer class="fixed w-full bottom-0">
      <div class="absolute w-full bottom-0 px-6 py-3 bg-black bg-opacity-80">
        <ion-button
          expand="block"
          color="white"
          class="text-xl mb-4 tracking-tight"
          type="submit"
          v-on:click="submitPaymentMethod()"
        >
          <span class="p-4 flex text-black">Purchase</span>
        </ion-button>
        <p class="text-xs">
          Subscriptions automatically renew unless you turn off auto-renew at
          least 24 hours before the end of the billing period. By continuing,
          you accept our
          <a href="#" class="text-primary underline">terms of service</a> and
          <a href="#" class="text-primary underline">privacy policy</a>.
        </p>
      </div>
    </footer>
  </div>
</template>
<script>

import {
  closeCircle,
  closeOutline,
  chevronUp,
  chevronDown,
  chevronBack,
  checkmarkCircleOutline,
  checkmarkOutline,
} from "ionicons/icons";
import { iconLogo } from "@/_helpers/icons";
//import Stripe from 'stripe';
// const stripe = new Stripe('sk_test_t4ArGxkwUyCXAA8JhXNuPtZ800r3pVyHoK');



export default {
  components: {
    
  },
  data() {
    return {
      closeCircle,
      closeOutline,
      chevronUp,
      chevronDown,
      iconLogo,
      chevronBack,
      checkmarkCircleOutline,
      checkmarkOutline,
      plan: {
        month: "1 month",
        price: "$80",
        description: "Cancel anytime after",
      },
      stripeAPIToken: 'pk_test_51K50v9H6jAgySH98SW4hkhceTVW1zMmVKsOMRpoIcDtXhLZgFugfgM2EroH90MQ4bZaHgXzpA21bvDp0xqZllp2y00qXIowPWf',
      stripe: '',
      elements: '',
      card: '',
      name: '',
    };
  },
  mounted(){
    this.includeStripe('js.stripe.com/v3/', function(){
        this.configureStripe();
    }.bind(this) );
  },
  methods: {
    changePlan(plan) {
      this.plan = plan;
    },
    includeStripe( URL, callback ){
        let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = '//' + URL;
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    async configureStripe(){
      this.stripe = await window.Stripe('pk_test_51K50v9H6jAgySH98SW4hkhceTVW1zMmVKsOMRpoIcDtXhLZgFugfgM2EroH90MQ4bZaHgXzpA21bvDp0xqZllp2y00qXIowPWf');
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            iconColor: '#000',
            color: '#000',
            // lineHeight: '40px',
            fontWeight: 400,
            // fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '18px',
            '::placeholder': {
              color: '#000'
            }
          }
        }
      });
      this.card.mount('#card-element');
    },
    submitPaymentMethod(){
      this.addPaymentStatus = 1;
      this.stripe.createToken(this.card, { name:this.name }).then(respon => {
        console.log('Stripe Res -- ', respon);
      })

    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-container {
  height: 100vh;
  background: url("../../public/assets/images/journey-bg.jpg");
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.9);
  background-size: cover;
  background-position: center;
  overflow-y: auto;
  color: #fff;
  .scroll-y {
    background: red;
  }
  .logo {
    width: 150px;
  }
}
</style>
