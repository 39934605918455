<script>
import { IonButton, IonIcon } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import journeySetupData from "@/data/journey_setup_data.json";
export default {
  name: "step-2",
  components: { IonButton, IonIcon },
  emits: ["setSelect"],
  props: ["items"],
  data() {
    return {
      step: 2,
      open: journeySetupData.open,
      chevronForward,
    };
  },
  computed: {
    fitness_coach_text() {
      return `Meet your Fitness Coach (${this.step}/4)`;
    },
    max_level() {
      return Math.max.apply(
        Math,
        this.items.map(function (o) {
          return parseInt(o.value);
        })
      );
    },
  },
  methods: {
    setOpen: function (step, i) {
      this.open[step][i] = !this.open[step][i];
    },
    isOpen: function (step, i, is_recommend) {
      if (is_recommend) {
        let temp = this.open[step][i];
        temp = !temp;
        if (temp && is_recommend) return true;
      } else {
        if (this.open[step][i]) return true;
      }
    },
    selected(step, i, item) {
      // console.log("item", item);
      this.$emit("setSelect", item);
    },
  },
};
</script>

<template>
  <section>
    <div class="question-details mb-5">
      <div class="question-progress">
        {{ fitness_coach_text }}
      </div>
      <h1 class="question text-3xl font-black mb-5">
        Your body, your journey. What’s your motivation?
      </h1>
      <p>Choose your intention. You can always change it later!</p>
    </div>

    <div class="question-options mt-16 mb-28">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="border rounded-lg mb-5 p-6"
        v-bind:class="[
          item.isChecked ? 'border-primary bg-primary bg-opacity-5' : '',
        ]"
      >
        <div class="flex cursor-pointer" @click="setOpen(step, index)">
          <div class="mr-auto flex">
            
            <div class="font-semibold text-2xl mr-2">
              {{ item.label }}
            </div>

            <p class="text-sm text-dark capitalize my-auto">
              <span
                v-if="item.is_recommend"
                style="
                  padding-top: 0.1em;
                  padding-bottom: 0.1rem;
                  background-color: rgba(239, 246, 255);
                  color: teal;
                "
                class="px-3 text-teal-800 rounded-full color-primary"
                >Recommended</span
              >
            </p>
          </div>
          <ion-icon
            :icon="chevronForward"
            class="block my-auto text-4xl transform"
            :class="
              isOpen(step, index, item.is_recommend)
                ? 'rotate-90 md hydrated'
                : 'md hydrated'
            "
          ></ion-icon>
        </div>
        <div class="open" v-show="isOpen(step, index, item.is_recommend)">
          <div class="description my-5 text-sm text-dark">
            {{ item.text }}
            <p>
              <strong class="text-dark"
                >Recommended for {{ item.level }}</strong
              >
            </p>
          </div>
          <div class="flex">
            <ion-button
              @click="selected(step, index, item)"
              color="black"
              class="mr-auto"
              ><div class="px-6 py-3.5 font-normal">Select</div></ion-button
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
