import VueCookies from "vue-cookies";
export default {
  token: VueCookies.get("userToken") || "",
  refreshToken: VueCookies.get("userRefreshToken") || "",
  user: VueCookies.get("userData") || {},
  formData: null,
  responseData: null,
  fbUserData: null,
  fbUser: "fbUser",
  journeyQuestions: {},
  journeySetupResponse: {},
  recommend: {
    activity: {},
    recipe: {}
  },
  favourites: {
    workouts: [],
    recipes: [],
    articles: [],
    learns: [],
    first: 1,
    offset: 0,
    data: [],
    type: "activity"
  },
  history: {
    workouts: [],
    recipes: [],
    articles: [],
    learns: [],
    first: 1,
    offset: 0,
    data: [],
    type: "activity"
  }
};
