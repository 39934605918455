<script>
import {
  IonSkeletonText,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonSpinner,
  toastController,
  IonIcon,
} from "@ionic/vue";
import { iconEye } from "@/_helpers/icons";
import _ from "lodash";
import api from "@/api";
export default {
  name: "reset-password-form",
  props: ["token"],
  components: {
    IonSkeletonText,
    IonButton,
    IonLabel,
    IonInput,
    IonItem,
    IonSpinner,
    IonIcon,
  },
  data() {
    let formData = {
      new_password: "",
      confirm_password: "",
    };
    return {
      formData,
      errors: {},
      loading: false,
      showEye: false,
      isReset: false,
      result: "",
      iconEye,
    };
  },
  async mounted() {
    console.log("Reset password form mounted");
  },
  watch: {
    "formData.new_password"() {
      if (this.formData.new_password) {
        this.showEye = true;
      } else {
        this.showEye = false;
      }
    },
  },
  methods: {
    async submitForm() {
      this.errors = {};
      Object.keys(this.formData).forEach((field) => {
        if (!this.formData[field])
          this.errors[field] =
            '<span class="capitalize">' + field + "</span> is required.";
      });

      // Submit to API services
      if (_.isEmpty(this.errors, true)) {
        this.loading = true;
        try {
          console.log(this.formData);
          this.loading = false;
          const response = await api.validateResetPasswordToken({
            token: this.token,
          });
          // console.log("response", response);
          if (
            response &&
            response.status == 200 &&
            response.data.message == "Valid token."
          ) {
            const result = await api.resetPassword(this.formData, this.token);
            console.log("result", result);
            if (
              result &&
              result.status == 200 &&
              result.data.message == "Successfully password reset."
            ) {
              console.log("HERE");
              this.formData.new_password = "";
              this.formData.confirm_password = "";
              this.isReset = true;
              this.result = result.data.message;
            }
          }
        } catch (e) {
          console.log("Error", e);
          this.openToast("Error", "danger");
          this.loading = false;
        }
      }
    },
    async openToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
        color: color,
      });
      return toast.present();
    },
    showPassword(fieldId, eyeId) {
      let ele = document.getElementById(fieldId);
      let icon = document.getElementById(eyeId);
      if (ele.type === "password") {
        ele.type = "text";
        icon.classList.add("text-primary");
      } else {
        ele.type = "password";
        icon.classList.remove("text-primary");
      }
    },
  },
};
</script>
<style scoped>
ion-item {
  --background-focused: #ffffff;
}
</style>

<template>
  <ion-skeleton-text
    animated
    class="text-4xl md:text-5xl w-3/12 hidden"
  ></ion-skeleton-text>

  <div class="login-form relative">
    <form
      v-if="!isReset"
      @submit.prevent="submitForm"
      action="./"
      method="post"
    >
      <div class="title text-xl font-medium mb-2">Update Password</div>

      <ion-item>
        <ion-label position="floating">New Password</ion-label>
        <ion-input
          id="reset-new-password"
          type="password"
          v-model="formData.new_password"
          v-on:keyup.enter="submitForm"
        >
        </ion-input>
        <ion-icon
          id="reset-eyeIcon"
          :icon="iconEye"
          v-if="showEye"
          class="mt-10 cursor-pointer"
          slot="end"
          @click="showPassword('reset-new-password', 'reset-eyeIcon')"
        ></ion-icon>
      </ion-item>
      <div class="text-sm text-danger px-2" v-if="errors.new_password">
        Please enter a valid password.
      </div>

      <ion-item>
        <ion-label position="floating">Confirm Password</ion-label>
        <ion-input
          id="confirm_new_password"
          type="password"
          v-model="formData.confirm_password"
          v-on:keyup.enter="submitForm"
        >
        </ion-input>
        <ion-icon
          id="reset-new_eyeIcon"
          :icon="iconEye"
          v-if="showEye"
          class="mt-10 cursor-pointer"
          slot="end"
          @click="showPassword('confirm_new_password', 'reset-new_eyeIcon')"
        ></ion-icon>
      </ion-item>
      <div class="text-sm text-danger px-2" v-if="errors.confirm_password">
        Please enter a valid password.
      </div>

      <div class="mt-20 flex">
        <ion-button
          expand="block"
          color="black"
          class="float-right ml-auto text-xl"
          type="submit"
          :disabled="loading"
        >
          <span class="py-4 px-1" v-if="!loading">Submit</span>
          <span class="py-2.5 px-1" v-if="loading"
            ><ion-spinner name="bubbles"></ion-spinner
          ></span>
        </ion-button>
      </div>
    </form>

    <div
      v-if="isReset"
      class="
        mt-10
        border
        rounded-lg
        mb-5
        py-3.5
        px-6
        bg-primary bg-opacity-5
        border-primary
      "
    >
      {{ result }}
      <router-link to="/login">
        <span style="color: blue; cursor: pointer; font-weight: bold"
          >Login</span
        >
      </router-link>
    </div>
  </div>
</template>
