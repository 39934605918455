<script>
import { searchOutline } from "ionicons/icons";
import CarouselItem from "@/components/pages/carousel/CarouselItem.vue";
// import ListContent from "@/components/pages/move/ListContent.vue";
import {
  IonContent,
  IonPage,
  //IonIcon,
  //IonInput,
  IonImg,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";

import api from "@/api";
export default {
  components: {
    IonContent,
    IonPage,
    IonImg,
    // IonIcon,
    // IonInput,
    IonSpinner,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    CarouselItem,
  },
  data() {
    return {
      id: null,
      category: null,
      searchOutline,
      loading: true,
      title: "Popular Recipes",
      items: [],
      first: 0,
      page: 1,
      rowsperpage: 9,
      totalCount: 0,
      maxpage: 0,
      searching: false,
      loadmoreBtn: true,
      loadmoreLoading: false,
    };
  },
  async mounted() {
    // console.log("Popular Recipes", this.rowsperpage);

    // console.log("AL this.type", this.type);
    // this.loading = false;
    this.items = [];
    this.loading = true;
    const response = await api.getTotalCountPopularRecipe();
    if (response && response.data.mostFavouriteRecipe.totalCount) {
      this.totalCount = response.data.mostFavouriteRecipe.totalCount;
      this.maxpage = Math.ceil(this.totalCount / this.rowsperpage);
      // console.log("this.totalCount", this.totalCount);
      // console.log("this.maxpage", this.maxpage);
      this.loading = false;
      this.searching = true;
      setTimeout(() => {
        this.onSearch();
      }, 500);
    }
  },
  methods: {
    onScroll(event) {
      console.log(event.detail);
    },
    onSearch() {
      this.loadmoreLoading = true;
      this.searching = true;
      let offset = (this.page - 1) * this.rowsperpage;
      let obj = {};
      obj.type = this.type;
      obj.offset = parseInt(offset);
      obj.rowsperpage = this.rowsperpage;
      // console.log(obj);
      this.$store.dispatch(`pages/getPopularRecipes`, obj).then((res) => {
        // console.log("res", res);
        let items = this.items;
        this.items = [];
        this.items = [...items, ...res];
        this.searching = false;
        this.loadmoreLoading = false;
        this.loadmoreBtn = this.searchStatus();
        this.page = this.page + 1;
      });
    },
    searchStatus() {
      return !this.loadmoreLoading &&
        !this.loading &&
        this.maxpage > 1 &&
        this.page - 1 < this.maxpage
        ? true
        : false;
    },
  },
};
</script>

<template>
  <ion-page>
    <ion-content>
      <xali-loading
        loadingStyle="loader"
        :fixed="false"
        wrapperClass="bg-opacity-80"
        v-if="loading"
        >Loading</xali-loading
      >
      <template v-if="!loading">
        <!-- Heading -->
        <xali-header
          :settings="{ back: true, hidemobilenav: true }"
        ></xali-header>
        <div class="container max-w-full mt-0 md:mt-5 px-6 my-5 lg:px-12">
          <div class="heading flex flex-col sm:flex-row">
            <h1 class="text-4.5xl font-bold">{{ title }}</h1>
            <!-- <div
              class="
                search
                flex
                bg-black bg-opacity-5
                rounded-2lg
                mt-4
                sm:hidden
              "
            >
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
              <ion-input placeholder="Search a workout"></ion-input>
            </div>
            <div class="search-button ml-auto my-auto hidden sm:block">
              <ion-icon
                :icon="searchOutline"
                class="block text-2xl text-dark my-auto ml-2"
              ></ion-icon>
            </div> -->
          </div>
        </div>

        <div
          @scroll="onScroll()"
          class="
            container
            max-w-full
            mt-0
            px-6
            lg:px-12
            grid grid-cols-12
            gap-0
            sm:gap-4
          "
          v-if="items"
        >
          <div
            v-for="(item, i) in items"
            :key="i++"
            class="col-span-12 sm:col-span-6 lg:col-span-4 mb-5"
          >
            <CarouselItem v-bind:data="item"></CarouselItem>
          </div>
        </div>

        <div class="mt-10 mb-10 flex flex-col" v-if="loadmoreLoading">
          <ion-spinner
            name="bubbles"
            color="primary"
            class="mx-auto transform scale-200"
          ></ion-spinner>
          <ion-img
            :src="'/assets/images/icon.svg'"
            class="w-10 spinner hidden"
          ></ion-img>
        </div>
        <div
          class="mt-10 mb-10 flex justify-center"
          v-if="loadmoreBtn && !loadmoreLoading"
          id="loadmore"
        >
          <ion-infinite-scroll
            @ionInfinite="onSearch($event)"
            threshold="100px"
            id="infinite-scroll"
            :disabled="!loadmoreBtn"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more data..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </div>
        <!-- Footer -->
        <xali-footer></xali-footer>
        <div class="h-20 md:hidden"></div>
      </template>
    </ion-content>
  </ion-page>
</template>
